/* eslint-disable */
import { useRef, useState } from "react";
import Draggable from "react-draggable";
import MnemoschemeTemplateSettings from "./components/MnemoschemeTemplateSettings";
import Modal from "components/Modal";
import TableList from "./components/TableList";
import { useTranslation } from "react-i18next";
import "./styles.css";

const MnemoschemeModal = ({
  visible,
  closeModal
}) => {

  const { t } = useTranslation();

  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y + 48,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <Modal
      centered={false}
      title={
        <div
          style={{
            width: '100%',
            cursor: 'grab',
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
        >
          {t("mnemoschemeSettings")}
        </div>
      }
      bodyStyle={{
        maxHeight: "calc(100vh - 220px)",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "20px"
      }}
      destroyOnClose={true}
      visible={visible}
      onCancel={() => closeModal()}
      className="trends-modal"
      width="650px"
      footer={null}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <MnemoschemeTemplateSettings />
      <TableList />
    </Modal>
  );
};

export default MnemoschemeModal;
