/* eslint-disable */
import { useCallback, useState } from "react";
import { Observer, observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { Table, Typography, Button, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined, ReloadOutlined, FilterFilled } from "@ant-design/icons";
import { useResizeDetector } from "react-resize-detector";
import { useStore } from "hooks/useStore";
import { AddUserModal } from "./components/AddUserModal";
import { EditUserModal } from "./components/EditUserModal";
import Tooltip from "components/Tooltip";
import Search from "components/Search";
import { useTranslation } from "react-i18next";
import { ruCollator } from "utils/collator";
import "./styles.css";

const UsersSettings = observer(() => {

  const { t } = useTranslation();

  const { adminUserStore, adminGroupStore } = useStore();

  const tableDataSource = adminUserStore.tableDataSource;

  const [searchValues, setSearchValues] = useState(
    { surname: "", name: "", patronymic: "", login: "", group: "", email: "", phoneNumber: "", }
  );

  const getFilteredColumnProperties = useCallback((filterKey) => ({
    filteredValue: [searchValues[filterKey]],
    onFilter: (value, record) => record[filterKey]?.toLowerCase()
      .includes(value.toLowerCase()) ?? (!value && true),
    filterDropdown: (props) => {
      const handleChangeSearchValue = (value) => {
        setSearchValues(prev => ({ ...prev, [filterKey]: value }));
      }

      return (
        <Search
          value={props.selectedKeys.at(0)}
          handleChangeValue={handleChangeSearchValue}
          fontSize="14px"
        />
      );
    },
    filterIcon: () => (
      <FilterFilled
        style={{
          color: searchValues[filterKey] !== "" ? "#1890ff" : "#bfbfbf"
        }}
      />
    ),
  }), [searchValues]);

  const getFilteredGroupColumnProperties = useCallback(() => ({
    ...getFilteredColumnProperties("group"),
    onFilter: (value, record) => (adminGroupStore.groups.list
      .find(group => group.id === record.groupId)?.name ?? "")
      .toLowerCase()
      .includes(value.toLowerCase()),
  }), [getFilteredColumnProperties]);

  const tableColumns = [
    {
      title: () => (
        <div style={{ fontFamily: "Roboto Medium" }}>{t("surname")}</div>
      ),
      dataIndex: "surname",
      // width: "10%",
      sorter: (a, b) => ruCollator.compare(a.surname, b.surname),
      showSorterTooltip: false,
      ...getFilteredColumnProperties("surname"),
    },
    {
      title: () => (
        <div style={{ fontFamily: "Roboto Medium" }}>{t("name")}</div>
      ),
      dataIndex: "name",
      // width: "10%",
      sorter: (a, b) => ruCollator.compare(a.name, b.name),
      showSorterTooltip: false,
      ...getFilteredColumnProperties("name"),
    },
    {
      title: () => (
        <div style={{ fontFamily: "Roboto Medium" }}>{t("patronymic")}</div>
      ),
      dataIndex: "patronymic",
      // width: "10%",
      sorter: (a, b) => ruCollator.compare(a.patronymic, b.patronymic),
      showSorterTooltip: false,
      ...getFilteredColumnProperties("patronymic"),
    },
    {
      title: () => (
        <div style={{ fontFamily: "Roboto Medium" }}>{t("login")}</div>
      ),
      dataIndex: "login",
      // width: "10%",
      sorter: (a, b) => ruCollator.compare(a.login, b.login),
      showSorterTooltip: false,
      ...getFilteredColumnProperties("login"),
    },
    {
      title: () => (
        <div style={{ fontFamily: "Roboto Medium" }}>{t("group")}</div>
      ),
      dataIndex: "groupId",
      // width: "10%",
      ...getFilteredGroupColumnProperties(),
      render: (groupId) =>
        <Observer>
          {() => (
            adminGroupStore.groups.list.find(group => group.id === groupId)?.name ?? null
          )}
        </Observer>
    },
    {
      title: () => (
        <div style={{ fontFamily: "Roboto Medium" }}>{t("email")}</div>
      ),
      dataIndex: "email",
      // width: "10%",
      ...getFilteredColumnProperties("email"),
    },
    {
      title: () => (
        <div style={{ fontFamily: "Roboto Medium" }}>{t("phoneNumber")}</div>
      ),
      dataIndex: "phoneNumber",
      // width: "10%",
      ...getFilteredColumnProperties("phoneNumber"),
    },
    {
      title: () => (
        <div style={{ fontFamily: "Roboto Medium" }}>{t("defaultLanguage")}</div>
      ),
      dataIndex: "defaultLanguageId",
      // width: "10%",
      render: (languageId) => (
        adminUserStore.languages.find(language => language.id === languageId)?.name ?? null
      )
    },
    {
      title: () => (
        <div style={{ fontFamily: "Roboto Medium" }}>{t("comment")}</div>
      ),
      dataIndex: "comment",
      // width: "10%",
    },
    {
      title: (
        <ReloadOutlined
          style={{ display: "block", width: "max-content", marginLeft: "auto" }}
          onClick={() => {
            adminGroupStore.fetchGroups();
            adminUserStore.fetchUsers()
          }}
        />
      ),
      width: "72px",
      render: (_nothing, record) => (
        <div className="admin-users-settings-operations-column">
          {record.editable ?
            <Typography.Link onClick={() => adminUserStore.setCurrentEditableUser(record)}>
              <EditOutlined />
            </Typography.Link>
            :
            <Typography.Link>
              <Tooltip title={t("accessDenied")} placement="left">
                <EditOutlined style={{ color: "gray" }} />
              </Tooltip>
            </Typography.Link>
          }

          {record.deletable ?
            <Popconfirm
              title={t("areYouSureToDeleteUser")}
              okText={t("apply")}
              cancelText={t("cancel")}
              onConfirm={() => adminUserStore.deleteUser(record.id)}
              placement="left"
            >
              <Typography.Link>
                <DeleteOutlined />
              </Typography.Link>
            </Popconfirm>
            :
            <Typography.Link>
              <Tooltip title={t("accessDenied")} placement="left">
                <DeleteOutlined style={{ color: "gray" }} />
              </Tooltip>
            </Typography.Link>
          }
        </div>
      )
    }
  ];

  const { height: tableContainerHeight, ref: tableContainerRef } = useResizeDetector();

  const tableHeaderHeight = 55;

  return (
    <div className="admin-users-settings">
      <AddUserModal
        visible={adminUserStore.isAddUserModalVisible}
        onSubmit={(event) => adminUserStore.addUser(event)}
        onCancel={() => adminUserStore.closeAddUserModal()}
        fields={adminUserStore.addUserFields}
      />

      <EditUserModal
        visible={adminUserStore.isEditUserModalVisible}
        onSubmit={(event) => adminUserStore.editUser(event)}
        onCancel={() => adminUserStore.closeEditUserModal()}
        user={adminUserStore.currentEditableUser}
        fields={adminUserStore.editUserFields}
      />

      <div ref={tableContainerRef} className="admin-users-settings-table">
        <Table
          scroll={{ y: `${tableContainerHeight - tableHeaderHeight}px` }}
          bordered
          dataSource={toJS(tableDataSource)}
          columns={tableColumns}
          pagination={false}
          rowKey={"id"}
        />
      </div>

      <div style={{ margin: "6px auto", width: "150px" }}>
        <Button
          danger type="primary"
          onClick={() => adminUserStore.openAddGroupModal()}
        >
          {t("addUser")}
        </Button>
      </div>
    </div>
  );
});

export default UsersSettings;
