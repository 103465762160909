/* eslint-disable */

export const getInheritedRoleId = (location, findLocation, changes) => {
  const change = changes.find(change =>
    change.locationId === location.id
  );
  if (change) return change.roleId;

  if (location.roleId) return location.roleId;
  if (!location.parentId) return null;
  const parent = findLocation(location.parentId) ?? null;
  if (!parent) return null;
  return getInheritedRoleId(parent, findLocation, changes);
}
