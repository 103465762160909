/* eslint-disable */
import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { task } from "mobx-fetch-task";
import { Switch, Typography } from "antd";
import { useResizeDetector } from "react-resize-detector";
import { useStore } from "hooks/useStore";
import useDebounce from "hooks/useDebounce";
import WellBreadcrumbs from "../Trends/components/TrendsHeader/components/WellBreadcrumbs";
import MnemoTable from "./components/MnemoTable";
import { useTranslation } from "react-i18next";
import request, { REQUEST_PROTOCOL } from "utils/request";
import "./styles.css";

const Mnemoscheme = observer(() => {

  const { mnemoStore, wellStore, accessStore } = useStore();

  const tables = mnemoStore.tableListStore?.tables;

  const [isTablesFixed, setIsTablesFixed] = useState(true);

  const toggleTablesFixed = () => {
    setIsTablesFixed(!isTablesFixed);
  };

  const wellBreadcrumbs = toJS(wellStore.wellBreadcrumbs);

  const { width, height, ref } = useResizeDetector();

  const { i18n } = useTranslation();
  const storageTranslations = JSON.parse(
    sessionStorage.getItem("translations")
  );
  const storageTranslationsUnits = JSON.parse(
    sessionStorage.getItem("translationsUnits")
  );
  const currentLanguage = i18n.language;

  const chooseTranslateFromArray = (id, array, lang) => {
    const translationIndex =
      array &&
      array[lang] &&
      array[lang].findIndex((x) => x.parameterId === id);
    const parameterIndex =
      array &&
      array?.parameters &&
      array?.parameters.findIndex((x) => x.id === id);

    if (translationIndex >= 0) {
      return array[lang][translationIndex].translation
        ? array[lang][translationIndex].translation
        : array?.parameters[parameterIndex].alias;
    }

    return parameterIndex >= 0 ? array?.parameters[parameterIndex].alias : id;
  };

  const chooseTranslateFromArrayUnits = (alias, array, lang) => {
    if (alias == null) return "";
    if (array == null) return "";
    const id = array.units.findIndex((x) => x.id === alias);
    const translationIndex = array[lang].findIndex((x) => x.unitId === id + 1);

    return translationIndex >= 0
      ? array[lang][translationIndex].translation
        ? array[lang][translationIndex].translation
        : array.units[id].alias
      : array.units[id].alias;
  };

  const backgroundImageUrl = `/images/${mnemoStore.selectedTemplate?.url}`
    ?? null;

  useEffect(() => {
    if (!mnemoStore.selectedTemplate?.url) {
      backgroundImageLoadTask(null);
      return;
    }
    backgroundImageLoadTask(backgroundImageUrl);
  }, [mnemoStore.selectedTemplate?.url]);

  const [backgroundImageLoadTask] = useState(() =>
    task(async (url) => {
      if (!url) return null;

      const { data } = await request
        .get(url, { responseType: "blob" });
      const imageObjectURL = URL.createObjectURL(data);
      return imageObjectURL;
    }));


  const setWidth = useCallback((table, width) => {
    const styles = structuredClone(toJS(table.styles));
    styles.common.width = width;

    mnemoStore.tableListStore
      ?.editTableStyles(table.id, styles);
  }, []);

  const debouncedSetWidth = useDebounce(setWidth);

  return (
    <div className="mnemo-area-wrapper">
      <div className="mnemo-area-header">
        <div className="mnemo-breadcrumbs">
          {<WellBreadcrumbs breadcrumbs={wellBreadcrumbs} />}
        </div>

        {Boolean(accessStore.checkPermission("edit_mnemoscheme")) &&
          <div className="mnemo-switch">
            {isTablesFixed ? <Typography style={{ color: "#008257" }}>Таблицы зафиксированы</Typography>
              :
              <Typography>Зафиксировать таблицы</Typography>}
            <Switch checked={isTablesFixed} onChange={toggleTablesFixed} />
          </div>
        }
      </div>

      <div className="mnemo-img-wrapper">
        {backgroundImageLoadTask.resolved &&
          <>
            <img
              ref={ref}
              className="mnemo-img"
              src={backgroundImageLoadTask.result}
            />

            <div style={{ position: "relative", width, height }}>
              {tables?.map(table => {

                const parameters = table.parameters
                  .map(parameter => {
                    const name = chooseTranslateFromArray(
                      parameter.id,
                      storageTranslations,
                      currentLanguage
                    );
                    const unit = chooseTranslateFromArrayUnits(
                      parameter.unit,
                      storageTranslationsUnits,
                      currentLanguage
                    );

                    return {
                      id: parameter.id,
                      name,
                      unit,
                      color: "#000",
                      value: parameter.value
                    }
                  });

                const position = {
                  x: table.position.x,
                  y: table.position.y
                }

                const setPosition = (position) => {
                  mnemoStore.tableListStore
                    ?.setPosition(table.id, position);
                }

                return (
                  <MnemoTable
                    key={table.id}
                    id={table.id}
                    styles={table.styles}
                    name={table.name}
                    parameters={parameters}
                    position={position}
                    setPosition={setPosition}
                    setWidth={(width) => debouncedSetWidth(table, width)}
                    isDraggable={!isTablesFixed}
                  />
                );
              })}
            </div>
          </>
        }
        {backgroundImageLoadTask.pending &&
          <div
            style={{
              display: "block",
              marginTop: "30%"
            }}
            className="lds-roller"
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        }
        {
          backgroundImageLoadTask.rejected &&
          <h1
            style={{
              textAlign: "center",
              marginTop: "30%"
            }}
          >
            Не удалось загрузить мнемосхему
          </h1>
        }
      </div>
    </div>
  );
});

export default Mnemoscheme;
