/*eslint-disable*/
import { ReactComponent as RedUpArrow } from "assets/images/redUpArrow.svg";
import { ReactComponent as GreenUpArrow } from "assets/images/greenUpArrow.svg";
import "./styles.css";

const AnalysisChartHeader = ({
  parameters,
  visibleDatasetsIndexes,
  setVisibleDatasetsIndexes
}) => {

  const handleClickParameter = (index) => {
    const parameter = parameters[index];

    setVisibleDatasetsIndexes(prev => {
      if (prev.includes(index)) {
        return [...prev].filter(i => i !== index);
      } else {
        return [...prev, index];
      };
    });
  };

  return (
    <div className="deviation-analysis-chart-header" >
      {parameters.map((parameter, index) => {
        let label = parameter.label;
        let arrow = null;
        let backgroundColor = parameter.backgroundColor;

        if (label.includes(" ↑")) {
          arrow = <GreenUpArrow
            width={11}
            style={{ marginLeft: 5 }}
          />;
          label = label.slice(-label.length, -1);
        } else if (label.includes(" ↓")) {
          arrow = <RedUpArrow
            width={11}
            style={{ marginLeft: 5, transform: "rotate(180deg)" }}
          />;
          label = label.slice(-label.length, -1);
        };

        if (!visibleDatasetsIndexes.includes(index)) {
          label = <s>{label}</s>;
          backgroundColor = "#999999";
        };

        return (
          <div
            key={index}
            onClick={() => handleClickParameter(index)}
            style={{
              fontSize: "18px"
            }}
          >
            <div
              style={{
                width: 30,
                height: 12,
                backgroundColor,
                marginRight: 5,
              }}
            />
            {label}
            {arrow}
          </div>
        );
      })}
    </div>
  );
};

export default AnalysisChartHeader;
