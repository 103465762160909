/* eslint-disable */
import { Field } from "react-final-form";
import { Translation, useTranslation } from "react-i18next";
import Checkbox from "formAdapters/Checkbox";
import Select from "formAdapters/Select";
import Input from "formAdapters/Input";
import Tooltip from "components/Tooltip";
import "./styles.css";

export const configTrends = (values) => [
  {
    title: () => (
      <Translation>
        {(t) => <div className="title">{t("trendName")}</div>}
      </Translation>
    ),
    dataIndex: "name",
    key: "name",
    render: (text) => <div className="title">{text}</div>,
  },
  {
    title: () => (
      <Translation>
        {(t) => <div className="title">{t("active")}</div>}
      </Translation>
    ),
    dataIndex: "active",
    key: "active",
    render: (text, record, index) => (
      <Field
        key={index}
        type="checkbox"
        name={`trends[${index}].active`}
        component={Checkbox}
      />
    ),
  },
  {
    title: "",
    dataIndex: "scaleNumber",
    key: "scaleNumber",
    render: (text, record, index) => {
      const { t } = useTranslation();
      const selectOptions = [
        {
          id: 1,
          name: `${t("scale")} 1`,
        },
        {
          id: 2,
          name: `${t("scale")} 2`,
        },
        {
          id: 3,
          name: `${t("scale")} 3`,
        },
        {
          id: 4,
          name: `${t("scale")} 4`,
        },
      ];

      return (
        <Field
          key={index}
          name={`trends[${index}].scaleNumber`}
          defaultValue={1}
          disabled={!values[index].active}
          style={{ width: 120 }}
          options={selectOptions}
          component={Select}
        />
      );
    },
  },
];

export const configScales = (isEnableToChange) =>[
  {
    title: () => (
      <Translation>
        {(t) => <div className="title">{t("scale")}</div>}
      </Translation>
    ),
    dataIndex: "scaleNumber",
    key: "id",
    render: (scaleNumber) => {
      // const { t } = useTranslation();
      // const scaleName = {
      //   1: `${t("scale")} 1`,
      //   2: `${t("scale")} 2`,
      //   3: `${t("scale")} 3`,
      //   4: `${t("scale")} 4`,
      // };
      const scaleName = {
        1: `Шкала 1`,
        2: `Шкала 2`,
        3: `Шкала 3`,
        4: `Шкала 4`,
      };

      return (
        <div className="title">
          {/* {scaleName[scaleNumber]} {t("axis")} Y: */}
          {scaleName[scaleNumber]} Ось Y:
        </div>
      );
    },
  },
  {
    title: "",
    dataIndex: "min",
    key: "min",
    render: (text, record, index) => {
      const isAuto = record.auto;
      return (
        <Tooltip title="Минимальный предел шкалы">
          <div
            style={{
              opacity: isAuto ? "0.5" : "1",
              pointerEvents: isAuto ? "none" : "auto",
            }}
            className="field-with-description"
          >
            <div className="descriptor">Min:</div>
            <Field
              key={index}
              type="number"
              name={`scales[${index}].min`}
              style={{ width: "48px" }}
              component={Input}
            />
          </div>
        </Tooltip>
      );
    },
  },
  {
    title: "",
    dataIndex: "max",
    key: "max",
    render: (text, record, index) => {
      const isAuto = record.auto;
      return (
        <Tooltip title="Максимальный предел шкалы">
          <div
            style={{
              opacity: isAuto ? "0.5" : "1",
              pointerEvents: isAuto ? "none" : "auto",
            }}
            className="field-with-description"
          >
            <div className="descriptor">Max:</div>
            <Field
              key={index}
              type="number"
              name={`scales[${index}].max`}
              style={{ width: "48px" }}
              component={Input}
            />
          </div>
        </Tooltip>
      );
    },
  },
  {
    title: "",
    dataIndex: "auto",
    key: "auto",
    render: (text, record, index) => {
      // const { t } = useTranslation();

      return (
        <Tooltip title="Пределы шкалы назначаются автоматически">
          <div className="field-with-description">
            <Field
              key={index}
              type="checkbox"
              name={`scales[${index}].auto`}
              component={Checkbox}
              disabled={!isEnableToChange}
            />
            {/* <div className="descriptor">{t("auto")}</div> */}
            <div className="descriptor">Авто</div>
          </div>
        </Tooltip>
      );
    },
  },
];
