/* eslint-disable */
import { Collapse } from "antd";
import { useTranslation } from "react-i18next";
import DivergenceCommonSettings
  from "../../../WellTree/components/MenuData/components/ControlPanel/components/DivergenceModal/DivergenceCommonSettings";
import DivergenceWells
  from "../../../WellTree/components/MenuData/components/ControlPanel/components/DivergenceModal/DivergenceIndividualSettings";

const DivergenceAnalysisCollapse = ({
                              wellsData,
                              divergenceData,
                              divergenceSettingsData,
                              handleSearch,
                              commonIndividual,
                              postSettings,
                              setDivergenceData
                            }) => {
  const { t } = useTranslation();
  const { Panel } = Collapse;
    if (
      divergenceData?.length &&
      divergenceSettingsData
    ) {
    const result = [];
    for (let i = 0; i < commonIndividual.length; i++) {
      if (commonIndividual[i] === commonIndividual[0]) {
        result.push(
          <Collapse accordion ghost>
            <Panel
              key={commonIndividual[0]?.uniqueId}
              header={t(commonIndividual[0])}
            >
                <DivergenceCommonSettings
                  divergenceSettingsData={divergenceSettingsData}
                  handleSetNewData={postSettings}
                />
            </Panel>
          </Collapse>
        );
      } else {
        result.push(
          <Collapse accordion ghost>
            <Panel
              key={commonIndividual[1]?.uniqueId}
              header={t(commonIndividual[1])}
            >
                <DivergenceWells
                  handleSearch={handleSearch}
                  wellsData={wellsData}
                  handleSetNewData={postSettings}
                  divergenceData={divergenceData}
                  setDivergenceData={setDivergenceData}
                />
            </Panel>
          </Collapse>
        );
      }
    }
    return result;
  }
  return <></>;
};

export default DivergenceAnalysisCollapse;
