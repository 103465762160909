/* eslint-disable */
import { useMemo, useState } from "react";
import { VariableSizeGrid } from "react-window";
import { Field } from "react-final-form";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import Tooltip from "components/Tooltip";
import Checkbox from "formAdapters/Checkbox";

const ParameterTable = ({ parameters }) => {

  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const storageTranslations = JSON.parse(
    sessionStorage.getItem("translations")
  );
  const storageTranslationsUnits = JSON.parse(
    sessionStorage.getItem("translationsUnits")
  );
  const currentLanguage = i18n.language;

  const [searchValue, setSearchValue] = useState("");

  const renderColHeader = (text) => (
    <div className="title">{text}</div>
  );

  const renderParamTitle = (text) => <div className="title">{text}</div>;

  const chooseTranslateFromArray = (id, array, lang) => {
    const translationIndex =
      array &&
      array[lang] &&
      array[lang].findIndex((x) => x.parameterId === id);
    const parameterIndex =
      array &&
      array?.parameters &&
      array?.parameters.findIndex((x) => x.id === id);

    if (translationIndex >= 0) {
      return array[lang][translationIndex].translation
        ? array[lang][translationIndex].translation
        : array?.parameters[parameterIndex].alias;
    }

    return parameterIndex >= 0 ? array?.parameters[parameterIndex].alias : id;
  };

  const chooseTranslateFromArrayUnits = (alias, array, lang) => {
    if (alias == null) return "";
    if (array == null) return "";
    const id = array.units.findIndex((x) => x.id === alias);
    const translationIndex = array[lang].findIndex((x) => x.unitId === id + 1);

    return translationIndex >= 0
      ? array[lang][translationIndex].translation
        ? array[lang][translationIndex].translation
        : array.units[id].alias
      : array.units[id].alias;
  };

  const isParameterMatchesSearch = (parameter) => {
    const name = chooseTranslateFromArray(
      parameter?.id,
      storageTranslations,
      currentLanguage
    );

    if (searchValue !== "" && !name
      .toLowerCase()
      .includes(searchValue.toLowerCase())) return false;
    return true;
  }

  const columnWidths = [350, 250];

  const renderCell = useMemo(() => {
    return ({ columnIndex, rowIndex, style, data }) => {
      const itemData = data[rowIndex];

      const index = itemData.index;

      switch (columnIndex) {
        case 0:
          let itemName = chooseTranslateFromArray(
            itemData?.id,
            storageTranslations,
            currentLanguage
          );
          itemName += ", ";
          itemName += chooseTranslateFromArrayUnits(
            itemData?.unit,
            storageTranslationsUnits,
            currentLanguage
          );

          return (
            <div
              style={{
                ...style,
                whiteSpace: "nowrap",
                background: rowIndex % 2 === 0 ? "#F5F6FA" : "#FFFFFF",
              }}
            >
              {itemName.length > 43 ? (
                <Tooltip
                  tooltipText={<div className="tooltip-text">{itemName}</div>}
                >
                  {renderParamTitle(itemName.slice(0, 43) + "...")}
                </Tooltip>
              ) : (
                <div>{renderParamTitle(itemName)}</div>
              )}
            </div>
          );
        case 1:
          return (
            <div
              style={{
                ...style,
                textAlign: "right",
                background: rowIndex % 2 === 0 ? "#F5F6FA" : "#FFFFFF",
              }}
            >
              <Field
                type="checkbox"
                name={`parameters[${index}].active`}
                component={Checkbox}
              />
            </div>
          )
      }
    }
  }, [currentLanguage, searchValue]);

  const filteredParameters = useMemo(() =>
    parameters
      .map((param, index) => ({ ...param, index }))
      .filter(param => isParameterMatchesSearch(param)),
    [parameters, searchValue]);

  return (
    <div style={{ margin: "0 29px" }}>
      <Input
        placeholder={"Название параметра"}
        addonBefore={"Поиск параметров"}
        allowClear
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />

      <ul
        style={{
          display: "flex",
          justifyContent: "space-between",
          listStyle: "none",
          width: "100%",
          margin: "15px 0",
          padding: 0,
        }}
      >
        <li>
          <div>{renderColHeader(t("parameter"))}</div>
        </li>
        <li>
          <div style={{ textAlign: "right" }}>
            {renderColHeader(t("active"))}
          </div>
        </li>
      </ul>

      <VariableSizeGrid
        columnCount={2}
        columnWidth={(index) => columnWidths[index]}
        height={450}
        rowCount={filteredParameters.length}
        rowHeight={(index) => 33}
        width={600}
        style={{ overflowX: "hidden" }}
        itemData={filteredParameters}
      >
        {renderCell}
      </VariableSizeGrid>
    </div>
  );
}

export default ParameterTable;
