/* eslint-disable */
import { makeAutoObservable } from "mobx";
import i18n from "i18n";
import request from "utils/request";
import i18next from "i18next";

const fetchTranslations = async () => {
  const { data } = await request.get("/translations/dynamicRead");

  return {
    parameters: data.parametersData,
    units: data.unitsData,
    actions: data.actionsData,
    workReasons: data.workReasonData,
  }
}

class TranslationStore {

  storage = undefined;

  currentLanguage = i18n.language;

  constructor() {
    makeAutoObservable(this);

    i18next.on("languageChanged", (language) => {
      this.currentLanguage = language;
    });
  }

  async fetchTranslations() {
    this.storage = await fetchTranslations();
  }

  translateParameterAlias(alias) {
    const array = this.storage?.parameters;

    if (alias == null) return alias;
    if (array == null) return alias;
    let id = array.parameters
      .findIndex((x) => x.alias === alias);
    id = array.parameters[id].id;
    const translationIndex =
      array &&
      array[this.currentLanguage] &&
      array[this.currentLanguage].findIndex((x) => x.parameterId === id);

    return translationIndex >= 0
      ? array[this.currentLanguage][translationIndex].translation
        ? array[this.currentLanguage][translationIndex].translation
        : alias
      : alias;
  }

  translateParameterUnit(unitId) {
    const array = this.storage?.units;

    if (unitId === null) return "";
    if (array == null) return "";
    const id = array.units.findIndex((x) => x.id === unitId);
    const translationIndex = array[this.currentLanguage]
      ?.findIndex((x) => x.unitId === id + 1);

    return translationIndex >= 0
      ? array[this.currentLanguage][translationIndex].translation
      : array.units[id] && array.units[id].alias;
  }
}

export default TranslationStore;
