/* eslint-disable */

const processData = (data, period) => {
  const scaleParametersInfo = new Array(data.length);
  for (let scaleInd = 0; scaleInd < scaleParametersInfo.length; scaleInd++) {
    const scale = data[scaleInd];
    scaleParametersInfo[scaleInd] = [];
    for (const parameter of scale.charts) {
      scaleParametersInfo[scaleInd].push({
        id: parameter.parameterId,
      });
    }
  }

  const startPeriodTimestamp = new Date(period.startDate).getTime() / 1000;
  const endPeriodTimestamp = new Date(period.endDate).getTime() / 1000;

  const map = new Map();

  map.set(startPeriodTimestamp, []);
  map.set(endPeriodTimestamp, []);

  for (let scaleIndex = 0; scaleIndex < data.length; scaleIndex++) {
    const scale = data[scaleIndex];
    for (const parameter of scale.charts) {
      for (const point of parameter.points) {
        const pointInfo = {
          scaleIndex: scaleIndex,
          parameterId: parameter.parameterId,
          value: point.value,
        };
        const timestamp = new Date(point.date).getTime() / 1000;
        const prev = map.get(timestamp);
        if (prev) prev.push(pointInfo);
        else map.set(timestamp, [pointInfo]);
      }
    }
  }

  const sortedMap = new Map([...map].sort((a, b) => a[0] - b[0]));

  const scalesData = new Array(data.length);
  for (let scaleIndex = 0; scaleIndex < scalesData.length; scaleIndex++) {
    const parameterCount = data[scaleIndex].charts.length;
    scalesData[scaleIndex] = new Array(parameterCount);
    for (let j = 0; j < parameterCount; j++) {
      scalesData[scaleIndex][j] = new Array(sortedMap.size);
      for (let k = 0; k < sortedMap.size; k++)
        scalesData[scaleIndex][j][k] = null;
    }
  }

  const timestamps = new Array(sortedMap.size);
  let timestampIndex = 0;
  for (const entrie of sortedMap) {
    const timestamp = entrie[0];
    const pointInfos = entrie[1];

    timestamps[timestampIndex] = timestamp;

    for (const pointInfo of pointInfos) {
      const paramIndex = scaleParametersInfo[pointInfo.scaleIndex].findIndex(
        (param) => param.id === pointInfo.parameterId
      );
      scalesData[pointInfo.scaleIndex][paramIndex][timestampIndex] =
        pointInfo.value;
    }

    timestampIndex++;
  }

  // continue last points
  const lastParameterPointInfos = [];
  for (let scaleIndex = 0; scaleIndex < data.length; scaleIndex++) {
    const scale = data[scaleIndex];
    for (const parameter of scale.charts) {
      const value = parameter.points.at(-1)?.value ?? null;
      lastParameterPointInfos.push({
        scaleIndex: scaleIndex,
        parameterId: parameter.parameterId,
        value,
      });
    }
  }

  for (const lastParamPoint of lastParameterPointInfos) {
    const scaleIndex = lastParamPoint.scaleIndex;
    const paramIndex = scaleParametersInfo[scaleIndex].findIndex(
      (param) => param.id === lastParamPoint.parameterId
    );
    scalesData[scaleIndex][paramIndex][sortedMap.size - 1] =
      lastParamPoint.value;
  }

  return {
    timestamps,
    scales: scalesData.map((scaleData, index) => {
      return {
        id: data[index].scaleNumber,
        data: scaleData,
        parameters: data[index].charts.map((param) => {
          return {
            id: param.parameterId,
            label: param.name,
            closestLeftPoint: {
              ...param.closestLeftPoint,
              timestamp: new Date(param.closestLeftPoint?.date)?.getTime() / 1000
            },
            isDisabled: param.isDisabled
          };
        }),
      };
    }),
  };
};

export default processData;
