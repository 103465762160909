import './styles.css'

const Burger = () => (
  <div className="burger-icon">
    <span />
    <span />
    <span />
  </div>
)

export default Burger
