/* eslint-disable */
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "hooks/useStore";
import useRequest from "hooks/useRequest";
import { useTranslation } from "react-i18next";
import { ParametersColors } from "containers/Home/components/Trends/parametersColors";
import Tooltip from "components/Tooltip";
import useTimeConverter from "hooks/useTimeConverter";
import moment from "moment";
import "./styles.css";

const ParametersInfo = observer(
  ({ expandScreenMode, paramsList, realtimeSocket, activeParams }) => {
    const { wellStore, trendsStore } = useStore(); //check what uses wellStore
    const [realtimeValues, setRealtimeValues] = useState({});
    const [localActiveTrends, setLocalActiveTrends] = useState([]);
    //const { data } = useRequest(wellStore.id ? `/chart/${wellStore.id}/parameters` : null)
    const { i18n } = useTranslation();
    const storageTranslations = JSON.parse(
      sessionStorage.getItem("translations")
    );
    const storageTranslationsUnits = JSON.parse(
      sessionStorage.getItem("translationsUnits")
    );
    const currentLanguage = i18n.language;

    const fromUtcToLocale = useTimeConverter({
      toOffset: 0,
      fromOffsetDefault: new Date().getTimezoneOffset() / 60,
    });

    const realtimeListener = (data) => {
      const newValue = {};
      // newValue[data.id] = data.value;
      newValue[data.id] = {
        value: data.value,
        ts: data.ts,
      };
      setRealtimeValues((oldValues) => Object.assign({}, oldValues, newValue));
    };

    useEffect(() => {
      if (!activeParams || !realtimeSocket) return;

      const relevantParamIds = activeParams.map((trend) => trend.alias);
      realtimeSocket.subscribe(relevantParamIds, realtimeListener);

      return () => realtimeSocket.unsubscribe(realtimeListener);
    }, [activeParams, wellStore?.id, realtimeSocket]);

    useEffect(() => {
      setRealtimeValues({});
    }, [wellStore?.id]);

    // const handleTrendClick = (id) => {
    //   const existingTrend = localActiveTrends?.find(
    //     (trend) => trend === id
    //   );

    //   if (existingTrend) {
    //     const newActiveTrends = localActiveTrends?.filter(
    //       (trend) => trend !== id
    //     );

    //     setLocalActiveTrends(newActiveTrends);
    //   } else {
    //     const newActiveTrends = localActiveTrends?.slice();

    //     newActiveTrends.push(id);

    //     setLocalActiveTrends(newActiveTrends);
    //   }
    // };

    // const iconStyle = (trendId) => {
    //   const isActive = localActiveTrends?.find((id) => id === trendId);

    //   if (isActive) {
    //     return {
    //       backgroundColor:
    //         ParametersColors[trendId % ParametersColors.colorsAmount],
    //       height: "6px",
    //     };
    //   }

    //   return {
    //     backgroundColor: "#ECECEC",
    //     height: "2px",
    //   };
    // };

    useEffect(() => {
      if (!paramsList) return;
      const activeTrends = paramsList.filter((trend) => trend.active === true);
      const activeTrendsIds = activeTrends.map((activeTrend) => activeTrend.id);

      setLocalActiveTrends(activeTrendsIds);
    }, [paramsList]);

    if (expandScreenMode) {
      return null;
    }

    const chooseTranslateFromArray = (alias, array, lang) => {
      if (alias === null) return alias;
      if (array == null) return alias;
      let id = array?.parameters?.findIndex((x) => x.alias === alias);
      id = array?.parameters[id].id;
      const translationIndex =
        array && array[lang].findIndex((x) => x.parameterId === id);

      return translationIndex >= 0
        ? array && array[lang][translationIndex].translation
        : alias;
    };

    const chooseTranslateFromArrayUnits = (unitId, array, lang) => {
      if (unitId === null) return "";
      if (array == null) return 0;
      const id = array?.units?.findIndex((x) => x.id === unitId);
      const translationIndex = array[lang]?.findIndex(
        (x) => x.unitId === id + 1
      );

      return translationIndex >= 0
        ? array[lang][translationIndex].translation
        : array?.units[id] && array?.units[id].alias;
    };

    return (
      <div className="workParams-info">
        {paramsList &&
          paramsList.map((trend) => (
            <div
              // key={trend.id}
              // className={`trends-info-row ${trend.active && "active"}`}
              // onClick={() => handleTrendClick(trend.id)}

              key={trend.id}
              className="workParams-info-row"
            >
              <div className="widthWorkParam70">
                {/* <div className="name-with-icon"> */}
                {/* {trend.active && (
                    <div className="scale-icon" style={iconStyle(trend.id)} />
                  )} */}
                <div>
                  {chooseTranslateFromArray(
                    trend?.alias,
                    storageTranslations,
                    currentLanguage
                  )}
                </div>
                {/* </div> */}
              </div>
              {trend.active && (
                <div
                  className="widthWorkParam30"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div>
                    <div>
                      {realtimeValues[trend.alias]?.value ? (
                        <Tooltip
                          placement="top"
                          title={moment(
                            fromUtcToLocale(realtimeValues[trend.alias]?.ts)
                          )
                            .format("DD-MM-YYYYTHH:mm:ss")
                            .replace("T", " ")}
                        >
                          {parseFloat(realtimeValues[trend.alias]?.value)
                            .toFixed(2)
                            .toString()}
                        </Tooltip>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div>
                    {realtimeValues[trend.alias]?.value
                      ? " " +
                        chooseTranslateFromArrayUnits(
                          trend?.unitId,
                          storageTranslationsUnits,
                          currentLanguage
                        )
                      : ""}
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    );
  }
);

export default ParametersInfo;
