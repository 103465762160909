/* eslint-disable */
import { useState, createRef } from "react";
import { Menu, Dropdown, Button, Space, Input } from "antd";
import { useTranslation } from "react-i18next";
import { SnippetsOutlined, PushpinOutlined } from "@ant-design/icons";
import Tooltip from "components/Tooltip";

const SettingsPanel = ({
  templates, // list of pairs of shape { id: ..., name: ...} with ids and names of trends templates
  activeTemplateId, // id of currently active template
  chooseTemplate, // callback for click on another template in the list
  addTemplate, // callback on create new template submit click
  updateTemplate, // callback on update template into action
  deleteTemplate // delete specified by id template
}) => {
  if (!templates) {
    templates = [];
  }
  const activeTemplate =
    templates.find((t) => t.id == activeTemplateId) || null;
  if (!activeTemplateId) {
    const tmplt = templates.find((t) => t.isDefault);
    if (tmplt) {
      chooseTemplate(tmplt.id);
    }
  }

  const [renameModeOn, setRenameMode] = useState(false);
  const { t } = useTranslation();

  const handleAddClick = () => {
    let n = 1;
    const name = "Новый шаблон";

    while (templates.find((t) => t.name.includes(name + " " + n.toString()))) {
      n += 1;
    }
    addTemplate(name + " " + n.toString());
  };

  const handleChooseClick = (templateId) => {
    chooseTemplate(templateId);
  };

  const handleDeleteClick = () => {
    deleteTemplate(activeTemplateId);
  };

  const changeRenameMode = () => {
    setRenameMode(!renameModeOn);
  };

  const handleRenameSubmit = () => {
    updateTemplate(activeTemplateId, {
      name: textFieldRef.current.input.value, // name: textFieldRef.current.state.value,
    });
    changeRenameMode();
  };

  const dropdownElements = templates
    .sort((elem1, elem2) => {
      if (elem1.name < elem2.name || elem1.isDefault) return -1;
      else return 1;
    })
    .map((template) => (
      <Menu.Item
        onClick={() => handleChooseClick(template.id)}
        key={template.id}
        icon={template.isDefault ? <PushpinOutlined /> : <SnippetsOutlined />}
      >
        {template.isDefault ? template.name : template.name}
      </Menu.Item>
    ));

  // const newTemplateBtn = (
  //   <Menu.Item onClick={handleAddClick} key="add_new_btn" danger={true}>
  //     {t("createNewTemplate")}
  //   </Menu.Item>
  // );

  // dropdownElements.unshift(newTemplateBtn);

  const menu = <Menu>{dropdownElements}</Menu>;

  const textFieldRef = createRef();

  if (renameModeOn) {
    return (
      <>
        <Space
          style={{
            display: "flex",
            alignContent: "stretch",
            justifyContent: "space-between",
            padding: "5px 25px",
            width: "650px",
          }}
          direction="horizontal"
        >
          <Space wrap>
            <b>{t("template")}:</b>
            {/* </Space>
          <Space wrap> */}
            <Input
              ref={textFieldRef}
              placeholder={activeTemplate.name}
              defaultValue={activeTemplate.name}
              style={{ width: "270px" }}
            />
          </Space>
          <Space wrap>
            <Button onClick={handleRenameSubmit}>{t("Изменить")}</Button>
            <Button onClick={changeRenameMode}>{t("Отменить")}</Button>
          </Space>
        </Space>
        <Space></Space>
      </>
    );
  } else {
    return (
      <div
        style1={{ display: "flex", flexDirection: "column", width: "700px" }}
      >
        <Space
          style={{
            display: "flex",
            alignContent: "stretch",
            justifyContent: "space-between",
            padding: "5px 25px"
          }}
          direction="horizontal"
        >
          <Space wrap>
            <b>{t("template")}:</b>
            {/* </Space>
          <Space wrap> */}
            <Tooltip title="Название шаблона">
              <Dropdown.Button
                overlay={menu}
                trigger={["click"]}
                className={"block"}
              >
                {activeTemplate?.name || t("Шаблон не выбран")}
              </Dropdown.Button>
            </Tooltip>
          </Space>
          {activeTemplate && !activeTemplate.isDefault ? ( // do not show delete and rename buttons if chosen template is the default template
            <Space wrap>
              <Button onClick={changeRenameMode}>{t("Переименовать")}</Button>
              <Button onClick={handleDeleteClick}>{t("Удалить")}</Button>
            </Space>
          ) : (
            <></>
          )}
        </Space>
      </div>
    );
  }
};

export default SettingsPanel;
