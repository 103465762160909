/* eslint-disable */
import { useMemo } from "react";
import { Form, Button, Select, DatePicker, Typography } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";

const tailLayout = {
  wrapperCol: { offset: 10, span: 16 },
}

const analysisDeviationReasons = [
  {
    id: 1,
    alias: "Кпрод/Рпл (увелич)",
    label: "Кпрод/Рпл (увелич)",
    key: "Кпрод/Рпл (увелич)",
    value: 1,
  },
  {
    id: 2,
    alias: "Кпрод/Рпл (уменьш)",
    label: "Кпрод/Рпл (уменьш)",
    key: "Кпрод/Рпл (уменьш)",
    value: 2,
  },
  {
    id: 3,
    alias: "ГНО (увелич)",
    label: "ГНО (увелич)",
    key: "ГНО (увелич)",
    value: 3,
  },
  {
    id: 4,
    alias: "ГНО (уменьш)",
    label: "ГНО (уменьш)",
    key: "ГНО (уменьш)",
    value: 4,
  },
  {
    id: 5,
    alias: "Некорр. замер",
    label: "Некорр. замер",
    key: "Некорр. замер",
    value: 5,
  },
];

const dateFormat = "YYYY-MM-DD";

const ProcessingForm = ({
  data,
  handleSubmit,
  closeModal,
}) => {

  const { t } = useTranslation();

  const onFinish = (values) => {
    const result = {
      alarmId: data?.id,
    };

    let reasonsMap = [];
    for (const property in values.reason) {
      reasonsMap.push([property, values.reason[property]]);
    }

    result.reasons = reasonsMap;

    handleSubmit(result);
    closeModal();
  }

  const initialValues = useMemo(() => {
    const initialValues = {
      reason: {},
    }

    for (let i = 0; i < data?.reasons?.length; i++) {
      const key = data.reasons[i]?.id;
      initialValues["reason"][`${key}`] = data.reasons[i]?.reasonId;
    }

    return initialValues;
  }, [data]);

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      initialValues={initialValues}
    >
      <Form.Item label={`${t("dateOfAlarmCreation")}`}>
        <DatePicker
          value={moment(data?.createdAt, dateFormat)}
          disabled
        />
      </Form.Item>
      <Form.Item label={t("alarm")}>
        <Typography.Text className="ant-form-text" type="secondary">
          {data?.name}
        </Typography.Text>
      </Form.Item>

      {data?.reasons?.map((item) => (
        <Form.Item
          key={item.id}
          name={["reason", `${item.id}`]}
          label={t("reason")}
        >
          <Select
            options={!data?.selectedActions && analysisDeviationReasons}
          />
        </Form.Item>
      ))}
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          {t("confirm")}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ProcessingForm;
