/* eslint-disable */
import { useMemo } from "react";
import { ParametersColors } from "containers/Home/components/Trends/parametersColors";

const DataTooltip = ({
  data,
  scaleWidth,
  scaleHeight,
  showOnlyTargetScaleTooltip,
  targetScaleIndex
}) => {

  const tempX = 20;
  const xOffsets = useMemo(
    () =>
      data.scales.map((scale) => {
        if (data.x + 550 + tempX >= scaleWidth) {
          return -500 - tempX;
        }
        return tempX;
      }),
    [data.x, scaleWidth]
  );

  return (
    <>
      {data.scales.map((scale) => {
        if (!scale.points.find((point) => point.value !== null)) {
          return null;
        }

        if (
          showOnlyTargetScaleTooltip &&
          scale.scaleIndex !== targetScaleIndex
        ) {
          return null;
        }

        return (
          <div
            key={scale.scaleIndex}
            className="DataTooltipContainer"
            style={{
              left: isFinite(data.x)
                ? data.x + xOffsets[scale.scaleIndex] + 50
                : 0,
              top: scale.scaleIndex * scaleHeight,
            }}
          >
            <div>
              {scale.points
                .sort((a, b) => b.label - a.label)
                .map((point) => {
                  if (point.value === null) return null;

                  const date = new Date(point.timestamp * 1000).toLocaleString(
                    "ru",
                    formatOptions
                  );

                  let label = point.label;
                  if (!point.isWithinPeriod) {
                    label = "← " + label;
                  }

                  return (
                    <div key={point.paramId} className="DataTooltipPoint">
                      <span key={point.label} className="DataTooltipPointLabel">
                        {label}
                      </span>
                      <div className="DataTooltipPointValue">
                        <div
                          style={{
                            backgroundColor:
                              ParametersColors[
                                point.paramId % ParametersColors.colorsAmount
                              ],
                          }}
                        />
                        <span>{point.value.toFixed(2)}</span>
                      </div>
                      <span className="DataTooltipTime">{date}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </>
  );
};
export default DataTooltip;

const formatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};
