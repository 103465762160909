/*eslint-disable*/

export class LocationsTreeNode {
  constructor(id, name, parent = null, locations = [], wells = []) {
    this.id = id;
    this.name = name;
    this.parent = parent;
    this.locations = locations;
    this.wells = wells;
  };

  get isLeaf() {
    return this.locations.length === 0;
  };

  get hasLocations() {
    return !this.isLeaf;
  };
};

class LocationsTree {
  constructor(id, name) {
    this.root = new LocationsTreeNode(id, name);
  };

  static fromRoot(root) {
    const rootNode = new LocationsTreeNode(root.id, root.name, null, root.locations, root.wells);
    const tree = new LocationsTree();
    tree.root = rootNode;
    return tree;
  };

  *preOrderTraversal(node = this.root) {
    yield node;
    if (node.locations.length) {
      for (const child of node.locations) {
        yield* this.preOrderTraversal(child);
      };
    };
  };

  insert(parentNodeId, id, name) {
    for (const node of this.preOrderTraversal()) {
      if (node.id === parentNodeId) {
        node.locations.push(new LocationsTreeNode(id, name, node));
        return true;
      };
    };
    return false;
  };

  remove(id) {
    for (const node of this.preOrderTraversal()) {
      const filtered = node.locations.filter(c => c.id !== id);
      if (filtered.length !== node.locations.length) {
        node.locations = filtered;
        return true;
      };
    };
    return false;
  };

  find(id) {
    for (const node of this.preOrderTraversal()) {
      if (node.id === id) return node;
    };
    return undefined;
  };
};

export default LocationsTree;
