/*eslint-disable*/
import { useEffect } from "react";
import { useStore } from "./useStore";

const useTranslations = (userId) => {

  const { translationStore } = useStore();

  const load = async () => {
    translationStore.fetchTranslations();
  }

  useEffect(() => {
    if (!userId && JSON.parse(localStorage.getItem("AUTH_PROVIDER")) !== "krb") return;

    load();
  }, [userId]);
}

export default useTranslations;