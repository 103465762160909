/*eslint-disable*/
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "hooks/useStore";
import CustomTooltip from "components/Tooltip";
import { WellTreePickerModal } from "components/WellTreePicker";
import { useTranslation } from "react-i18next";
import "./styles.css";

const Buttons = observer(({
  isReportFormationAvailable,
  formReport,
}) => {

  const { t } = useTranslation();

  const { deviationAnalysisStore } = useStore();

  const [isOpenWellListModal, setIsOpenWellListModal] = useState(false);

  const handleFormReport = () => {
    formReport();
  }

  return (
    <div className="deviation-analysis-button-container">
      <div
        className={`deviation-analysis-button ${isOpenWellListModal && "active"}`}
        onClick={() => setIsOpenWellListModal(true)}
      >
        {t("chooseWells")}
      </div>

      <WellTreePickerModal
        visible={isOpenWellListModal}
        close={() => setIsOpenWellListModal(false)}
        pickedWellIds={deviationAnalysisStore.pickedWellIds}
        handleSubmit={(values) => deviationAnalysisStore.setPickedWellIds(values.pickedWellIds)}
      />

      {isReportFormationAvailable ?
        <div
          className="deviation-analysis-button"
          onClick={handleFormReport}
        >
          {t("toForm")}
        </div>
        :
        <CustomTooltip
          tooltipText="Необходимо выбрать хотя бы одну скважину и период формирования отчета"
        >
          <div
            className="deviation-analysis-button disabled"
          >
            {t("toForm")}
          </div>
        </CustomTooltip>
      }
    </div>
  );
});

export default Buttons;
