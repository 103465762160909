/* eslint-disable */
import { useRef, useState } from "react";
import { Form } from "react-final-form";
import { Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ModalFormField from "containers/Admin/components/ModalFormField";
import Modal from "components/Modal";
import { useTranslation } from "react-i18next";
import Draggable from "react-draggable";

const EditProfileModal = ({
  visible,
  onSubmit,
  onCancel,
  data,
  fields,
  loaded
}) => {

  const { t } = useTranslation();

  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y + 48,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={data}
      initialValuesEqual={(a, b) => {
        return JSON.stringify(a) === JSON.stringify(b);
      }}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        submitErrors,
        submitSucceeded
      }) => (
        <Modal
        title={
          <div
            style={{
              width: '100%',
              cursor: 'grab',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
          >
            {t("editProfile")}
          </div>
        }
          bodyStyle={{
            maxHeight: "calc(100vh - 220px)",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column"
          }}
          visible={visible}
          className="add-priority-modal"
          onCancel={() => { form.reset(); onCancel(); }}
          submitButton={
            <Button
              type="primary"
              loading={submitting}
              disabled={submitting || !loaded}
              onClick={handleSubmit}
              style={{
                backgroundColor:
                  submitSucceeded && pristine ? "lightseagreen" : "#40a9ff",
              }}
            >
              {submitSucceeded && pristine ? t("applied") : t("apply")}
            </Button>
          }
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
              nodeRef={draggleRef}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
        >
          <div style={{ padding: "0px 20px", overflowY: "auto" }}>
            {loaded ?
              fields.map(field => (
                <ModalFormField key={field.name} field={field} />
              ))
              :
              <div style={{ display: "flex", justifyContent: "center", padding: "25px" }}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
              </div>
            }
          </div>
          <div style={{ padding: "20px", textAlign: "center", color: "red" }}>
            {submitErrors && t("errorHasOccured")}
          </div>
        </Modal>
      )}
    />
  );
}

export default EditProfileModal;

