/* eslint-disable */
import {
  computed,
  makeObservable,
  observable,
  action,
} from "mobx";

class WellTree {
  id = null;
  status = "";
  data = {};
  selectedLocations = new Set();
  locationsTree = [];
  breadcrumbs = [];
  wellBreadcrumbs = [];
  isAlarmsReload = false;
  isLocationsChanged = false;

  constructor() {
    makeObservable(this, {
      id: observable,
      status: observable,
      data: observable,
      selectedLocations: observable,
      activeLeafLocations: computed,
      absolutelySelectedLocations: computed,
      setAlarmsCount: action,
      breadcrumbs: observable,
      wellBreadcrumbs: observable,
      setData: action,
      setStatus: action,
      setId: action,
      locationsList: computed,
      leafLocations: computed,
      isAlarmsReload: observable,
      isLocationsChanged: observable,
      setBreadcrumbs: action,
      setWellBreadcrumbs: action
    });
  }

  setBreadcrumbs(breadcrumbs) {
    this.breadcrumbs = breadcrumbs;
  }

  setWellBreadcrumbs(wellBreadcrumbs) {
    this.wellBreadcrumbs = wellBreadcrumbs;
  }

  setId(id) {
    this.id = parseInt(id, 10);
  }

  setStatus(status) {
    this.status = status;
  }

  setData(data) {
    this.data = data;
  }

  resetIsLocationsChanged() {
    this.isLocationsChanged = !this.isLocationsChanged;
  }

  resetIsAlarmsReload() {
    this.isAlarmsReload = !this.isAlarmsReload;
  }

  get locationsList() {
    function treeToList(tree) {
      if (!tree) return;
      const list = [];
      tree.forEach((i) => list.push(i, ...treeToList(i.locations)));
      return list;
    }

    const dataList = treeToList(this.locationsTree);

    return dataList;
  }

  get leafLocations() {
    return this.locationsList.filter((l) => l.locations.length === 0);
  }

  __makeBreadcrumbs() {
    const parentAmounts = [];
    const activeLocationsObjects = this.locationsList.filter((l) =>
      this.selectedLocations.has(l.id)
    );

    const findParentsAmount = (item, amount = 1) => {
      if (item.parent) {
        return findParentsAmount(item.parent, amount + 1);
      } else {
        return amount;
      }
    };

    activeLocationsObjects.forEach((i) => {
      parentAmounts.push(findParentsAmount(i));
    });

    stop = false;
    let i = 1;

    while (
      parentAmounts.findLastIndex((x) => x == i) ===
      parentAmounts.findIndex((x) => x == i) &&
      parentAmounts.findIndex((x) => x == i) !== -1
    ) {
      i += 1;
    }

    const bcs = new Array();
    for (let j = 1; j < i; j++) {
      const itemInd = parentAmounts.findIndex((a) => a == j);
      const item = activeLocationsObjects[itemInd];
      const itemName = item.name;

      bcs.push(itemName);
    }

    return bcs;
  }

  setAlarmsCount(alarmsCount) {
    this.alarmsCount = alarmsCount;
  }

  get absolutelySelectedLocations() {
    const allSelected = Array.from(this.selectedLocations);

    return Array.from(this.selectedLocations).filter((l) => {
      return Array.from(
        this.__getAllChildren(this.locationsList.find((i) => i.id == l))
      ).every((c) => this.selectedLocations.has(c));
    });
  }

  get activeLeafLocations() {
    return Array.from(this.selectedLocations).filter((l) =>
      this.leafLocations.map((leaf) => leaf.id)
    );
  }

  handleLocationClick(location) {
    if (typeof location !== "object")
      location = this.locationsList.find((i) => i.id == location);
    this.__selectSingle(location);
  }

  handleLocationClickCtrl(location) {
    const wasSelected = this.selectedLocations.has(location.id || location);
    const children = this.__getAllChildren(location);
    if (!wasSelected) {
      children.add(location.id).forEach((c) => this.__selectLocation(c));
    } else {
      children.add(location.id).forEach((c) => this.__unselectLocation(c));
    }
    this.__markParents(location, false);
  }

  __selectLocation(l) {
    this.selectedLocations.add(l.id || l);
  }

  __unselectLocation(l) {
    this.selectedLocations.delete(l.id || l);
  }

  __resetSelecting() {
    this.selectedLocations.clear();
  }

  __selectSingle(location) {
    this.__resetSelecting();
    this.__selectLocation(location);
  }

  __hasSelectedChildren(location) {
    const children = Array.from(this.__getAllChildren(location));
    return children.some((c) => this.selectedLocations.has(c));
  }

  __markParents(location, selected) {
    parent = location.parent;
    if (!parent) return;
    selected
      ? this.__selectLocation(parent)
      : !this.__hasSelectedChildren(parent) && this.__unselectLocation(parent);

    this.__markParents(parent, selected);
  }

  __getAllChildren(loc) {
    let allChildren = new Set();

    loc?.locations.forEach((l) => {
      allChildren.add(l.id || l);
      allChildren = new Set([...allChildren, ...this.__getAllChildren(l)]);
    });

    return allChildren;
  }
}

export default WellTree;
