/* eslint-disable */

import React, { useMemo, useState } from 'react'
import 'antd/dist/antd.min.css'
import {
  Form, Button, InputNumber, Switch, Input,
} from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import localeRU from 'antd/es/date-picker/locale/ru_RU'
import localeEN from 'antd/es/date-picker/locale/en_GB'
import localeES from 'antd/es/date-picker/locale/es_ES'
import Tooltip from "../../../../../../../../../../../../../../components/Tooltip";

const DivergenceIndividualData = ({ handleSetNewData, divergenceData, setDivergenceData, index, divergenceDataFull }) => {
  const { t } = useTranslation()
  const [buttonText, setButtonText] = useState(t("apply"));
  const [isToggled, setIsToggled] = useState(divergenceData?.status);

  const handleToggleIndividual = () => {
    setIsToggled(() => !isToggled);
    let data = Object.assign(divergenceData);
    data.status = !divergenceData.status;
    handleSetNewData(makeResultSettings(data));
  };
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  let locale = "";

  moment.locale("ru");
  switch (currentLanguage) {
    case "ru": {
      locale = localeRU;
      moment.locale("ru");
      break;
    }
    case "en": {
      locale = localeEN;
      moment.locale("en-gb");
      break;
    }
    case "es": {
      locale = localeES;
      moment.locale("es");
      break;
    }
    default: {
      locale = localeRU;
      moment.locale("ru");
      break;
    }
  }

  const makeResultSettings = (data) => {
    let result = {};
    result["wellId"] = divergenceData.wellId;
    result["state"] = Boolean(data.state);
    result["status"] = Boolean(data.status);
    result["calculatedHdin"] = data.calculatedHdin;
    result["tolerance"] = data.tolerance;
    result["debitRelevanceTime"] = data.debitRelevanceTime;
    result["isVrMLModelEnabled"] = Boolean(data.isVrMLModelEnabled);
    result["isVrPhysModelEnabled"] = Boolean(data.isVrPhysModelEnabled);
    return result;
  };

  const onFinish = (values) => {
    handleSetNewData(makeResultSettings(values.divergenceSettings));
    let localValues = Object.assign(divergenceDataFull);
    values.divergenceSettings["wellId"] = divergenceData.wellId;
    localValues[index] = values.divergenceSettings;
    setDivergenceData(localValues);
    setButtonText(t("applied"));
  };

  const makeInitialValues = () => {
    return {
      divergenceSettings: {
        wellId: divergenceData?.wellId,
        status: divergenceData?.status,
        state: divergenceData?.state,
        calculatedHdin: divergenceData?.calculatedHdin,
        tolerance: divergenceData?.tolerance,
        debitRelevanceTime: divergenceData?.debitRelevanceTime,
        isVrMLModelEnabled: divergenceData?.isVrMLModelEnabled,
        isVrPhysModelEnabled: divergenceData?.isVrPhysModelEnabled,
      },
    };
  };

  const layout = {
    labelCol: {
      span: 15,
    },
    wrapperCol: {
      span: 13,
    },
  }
  const showSettings = () => {

    return (
      <>
        <Form.Item
          name={["divergenceSettings", "state"]}
          valuePropName="checked"
          label={t("onOffAnalysisForIndividualWell")}
          labelAlign="left"
          wrapperCol={{
            offset: 0,
            span: 16,
          }}
        >
          <Switch />
        </Form.Item>

        {/*<Tooltip*/}
        {/*  placement="topLeft"*/}
        {/*  zIndex={1000000000001}*/}
        {/*  title={t("tooltipDefaultVal")}*/}
        {/*  overlayStyle={{ maxWidth: 300 }}*/}
        {/*>*/}
        {/*  <Form.Item*/}
        {/*    labelAlign="left"*/}
        {/*    label={t("acceptableDeviation")}*/}
        {/*    rules={[{ required: false, message: "Введите интервал" }]}*/}
        {/*  >*/}
        {/*    <Form.Item*/}
        {/*      noStyle*/}
        {/*      name={["divergenceSettings", "tolerance"]}*/}
        {/*      rules={[{ required: false, message: "Введите процент" }]}*/}
        {/*    >*/}
        {/*      <InputNumber*/}
        {/*        min="0"*/}
        {/*        max="100"*/}
        {/*        style={{*/}
        {/*          width: "30%",*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </Form.Item>*/}

        {/*    <span className="ant-form-text" style={{ paddingLeft: "5px" }}>*/}
        {/*      {" "}*/}
        {/*      {"%"}{" "}*/}
        {/*    </span>*/}
        {/*  </Form.Item>*/}
        {/*</Tooltip>*/}

        {/*<Tooltip*/}
        {/*  placement="topLeft"*/}
        {/*  zIndex={1000000000001}*/}
        {/*  title={t("tooltipTimeDebit")}*/}
        {/*  overlayStyle={{ maxWidth: 300 }}*/}
        {/*>*/}
          <Form.Item
            labelAlign="left"
            label={t("actualTimeDebit")}
            rules={[{ required: false, message: "Введите интервал" }]}
          >
            <Form.Item
              noStyle
              name={["divergenceSettings", "debitRelevanceTime"]}
              rules={[{ required: false, message: "Введите сутки" }]}
            >
              <InputNumber
                min="1"
                max="45"
                style={{
                  width: "30%",
                }}
              />
            </Form.Item>

            <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
              {" "}
              {t("shortDays")}{" "}
            </span>
          </Form.Item>
        {/*</Tooltip>*/}

        <Tooltip
          placement="topLeft"
          zIndex={1000000000001}
          title={t("tooltipHdin")}
          overlayStyle={{ maxWidth: 300 }}
        >
          <Form.Item
            name={["divergenceSettings", "calculatedHdin"]}
            valuePropName="checked"
            label={t("hdinOnOff")}
            labelAlign="left"
            wrapperCol={{
              offset: 0,
              span: 16,
            }}
          >
            <Switch />
          </Form.Item>
        </Tooltip>

        <Tooltip
          placement="topLeft"
          zIndex={1000000000001}
          // title={t("tooltipHdin")}
          overlayStyle={{ maxWidth: 300 }}
        >
          <Form.Item
            name={["divergenceSettings", "isVrMLModelEnabled"]}
            valuePropName="checked"
            label={t("calcVrMLModelOnOff")}
            labelAlign="left"
            wrapperCol={{
              offset: 0,
              span: 16,
            }}
          >
            <Switch />
          </Form.Item>
        </Tooltip>

        <Tooltip
          placement="topLeft"
          zIndex={1000000000001}
          // title={t("tooltipHdin")}
          overlayStyle={{ maxWidth: 300 }}
        >
          <Form.Item
            name={["divergenceSettings", "isVrPhysModelEnabled"]}
            valuePropName="checked"
            label={t("calcVrPhysModelOnOff")}
            labelAlign="left"
            wrapperCol={{
              offset: 0,
              span: 16,
            }}
          >
            <Switch />
          </Form.Item>
        </Tooltip>

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 10 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: buttonText === t("apply") ? "" : "lightseagreen",
            }}
          >
            {buttonText}
          </Button>
        </Form.Item>
      </>
    )
  }

  return (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      className
      initialValues={makeInitialValues()}
      onValuesChange={() => setButtonText(t("apply"))}
    >
      <Form.Item
        name={['divergenceSettings', 'status']}
        valuePropName="checked"
        label={t("OnOffIndividualSettings")}
        labelAlign="left"
        wrapperCol={{
          offset: 0,
          span: 16,
        }}
      >
        <Switch onClick={handleToggleIndividual} />
      </Form.Item>

      {isToggled === false ? <></> : showSettings()}
    </Form>
  );
}

export default DivergenceIndividualData
