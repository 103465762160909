import { useEffect, useRef, useState } from "react";
import "./styles.css";

const TreemapTooltip = ({
  customRef,
  data,
  settings,
  treemapWidth,
}) => {

  const timeoutRef = useRef(null);

  const [visible, setVisible] = useState(false);

  const node = data.node;
  const position = data.position;

  useEffect(() => {
    setVisible(false);
    if (timeoutRef.current != null) clearTimeout(timeoutRef.current);
    if (node == null) return;
    timeoutRef.current = setTimeout(() => setVisible(true), 500);
  }, [node, position]);

  const tooltipWidth = 300;
  const padding = 50;

  const actualPosition = {
    x: position.x + tooltipWidth + padding < treemapWidth ?
      position.x + padding / 2 : position.x - tooltipWidth - padding / 2,
    y: position.y,
  }

  return (
    <div
      ref={customRef}
      className="treemap-tooltip"
      data-visible={visible}
      style={{
        top: actualPosition.y,
        left: actualPosition.x,
      }}
    >
      {node != null &&
        <>
          <div className="treemap-tooltip-label">
            {settings.renderLabel(node)}
          </div>
          <div>
            {settings.renderValue(node)}
          </div>
        </>
      }
    </div>
  );
}

export default TreemapTooltip;
