/* eslint-disable */
import { useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { Input } from "antd";
import { IssuesCloseOutlined } from "@ant-design/icons";
import { useOutsideClick } from "hooks/useOutsideClick";
import CustomTooltip from "components/Tooltip";
import "./styles.css";

const ColorPicker = ({
  color,
  onChange
}) => {

  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef();

  useOutsideClick(() => setIsOpen(false), ref);

  const handleOpen = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px"
      }}
    >
      <span
        style={{
          position: "relative",
          display: "inline-block",
          width: "16px",
          height: "16px",
          backgroundColor: color,
          border: "1px solid black"
        }}
        onClick={handleOpen}
      >
        {isOpen &&
          <div
            ref={ref}
            className="color-picker"
            style={{
              position: "absolute",
              zIndex: 1000000000,
              left: 40,
              bottom: 30
            }}
          >
            <HexColorPicker
              color={color}
              onChange={onChange}
            />
          </div>
        }
      </span>

      <Input
        value={color?.substring(1)}
        onChange={(e) => onChange(`#${e.target.value}`)}
        size={"small"}
        style={{
          width: "80px"
        }}
        prefix={"#"}
      />

      {color?.length !== 7 &&
        <CustomTooltip
          tooltipText={"Длина hex цвета должна быть 6 символов"}
        >
          <IssuesCloseOutlined
            style={{ color: "red" }}
          />
        </CustomTooltip>
      }
    </div>
  );
}

export default ColorPicker;