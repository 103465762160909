import { useRef, useState, useMemo, useEffect, useCallback } from "react";
import { observer } from "mobx-react-lite";
import squarify from "squarify";
import TreemapTooltip from "./components/TreemapTooltip";
import Stack from "dataStructures/stack";
import {
  cutTreeByMaxDepth,
  evaluateNodeMinValues,
  evaluateRealNodeValues,
  findInTree,
  findNodeOnPosition,
  stretchEllipsisText,
  stretchEllipsisLines,
} from "./common";
import { useNavigate } from "react-router-dom";
import { useStore } from "hooks/useStore";
import "./styles.css";

const maxDepth = 2;

const Treemap = observer(({
  data,
  nodeIdKey = "id",
  minValueCoefficient = 0.02,
  colors,
  tooltipSettings,
  labelSettings,
  colorSettings,
  width,
  height,
}) => {
  const navigate = useNavigate();
  const {
    wellStore,
  } = useStore();
  const canvasRef = useRef(null);
  const tooltipRef = useRef(null);

  const rootContainer = useMemo(
    () => ({ x0: 0, y0: 0, x1: width, y1: height }),
    [width, height]
  );

  const [selectedIdHistory] = useState(new Stack());
  const [selectedId, setSelectedId] = useState(null);
  const renderedHierarchyRef = useRef(null);

  const [tooltipData, setTooltipData] = useState({
    node: null,
    position: { x: 0, y: 0 },
  });

  const evaluatedData = useMemo(() => {
    const dataCopy = structuredClone(data);
    evaluateRealNodeValues(dataCopy);
    return dataCopy;
  }, [data]);

  const maxDepthClampedData = useMemo(() => {
    let selectedItem = findInTree(evaluatedData, nodeIdKey, selectedId);
    if (selectedItem == null) {
      selectedItem = evaluatedData; // root
    }
    const result = structuredClone(selectedItem);
    cutTreeByMaxDepth(result, maxDepth);
    evaluateNodeMinValues(result, minValueCoefficient);
    return result;
  }, [evaluatedData, nodeIdKey, selectedId, maxDepth, minValueCoefficient]);

  const headerHeight = 35;

  const squarifyData = (node, container) => {
    const cuttedNode = structuredClone(node);
    cutTreeByMaxDepth(cuttedNode, 1);
    const result = squarify([cuttedNode], container);
    return result;
  }

  const drawHeader = (ctx, node, container) => {
    const width = container.x1 - container.x0;
    const color = colorSettings.getNodeBackgroundColor(node);

    ctx.fillStyle = color;
    ctx.fillRect(container.x0, container.y0, width, headerHeight);

    const labelPadding = 10;
    const measuredEllipsisLabel = stretchEllipsisText(
      ctx, node.label, width - labelPadding, headerHeight - 12, 20, 20
    );

    if (measuredEllipsisLabel != null) {
      ctx.fillStyle = colorSettings.getNodeLabelColor(node);
      ctx.font = `${measuredEllipsisLabel.fontSize}px Roboto`;
      ctx.shadowBlur = 1;
      ctx.textBaseline = "middle";
      ctx.textAlign = "center";
      ctx.fillText(measuredEllipsisLabel.text, container.x0 + width / 2, container.y0 + headerHeight / 1.6);
      ctx.shadowBlur = 0;
    }
  }

  const drawNode = (ctx, node, container, hierarchy = null) => {
    if (hierarchy == null) {
      hierarchy = {
        ...node,
        ...container,
      }
    } else {
      Object.assign(node, { ...container });
    }

    if (node.children != null && node.children.length > 0) {
      drawHeader(ctx, node, container);

      const containerWithoutHeader = {
        ...container,
        y0: container.y0 + headerHeight,
      }

      const sq = squarifyData(node, containerWithoutHeader);
      for (const rect of sq) {
        const childNode = node.children.find((x) => x.id === rect.id);
        drawNode(ctx, childNode, {
          x0: rect.x0,
          y0: rect.y0,
          x1: rect.x1,
          y1: rect.y1,
        }, hierarchy);

        ctx.lineWidth = 8;
        ctx.strokeStyle = "black";
        ctx.strokeRect(container.x0, container.y0, container.x1 - container.x0, container.y1 - container.y0);
      }
    } else {
      const width = container.x1 - container.x0;
      const height = container.y1 - container.y0;

      const color = colorSettings.getNodeBackgroundColor(node);

      ctx.fillStyle = color;
      ctx.fillRect(container.x0, container.y0, width, height);

      const label = labelSettings.getLabel(node);

      const gap = 12;
      const labelTopBottomPadding = 10;
      const labelLeftRightPadding = 10;

      const measuredEllipsisLabel = stretchEllipsisLines(
        ctx, label, width - labelLeftRightPadding, height - labelTopBottomPadding, 12, 48, gap
      );

      if (measuredEllipsisLabel != null) {
        ctx.fillStyle = colorSettings.getNodeLabelColor(node);
        ctx.font = `${measuredEllipsisLabel.fontSize}px Roboto`;
        ctx.shadowColor = "black";
        ctx.shadowBlur = 1;
        ctx.textAlign = "center";
        if (measuredEllipsisLabel.lines.length === 1) {
          ctx.textBaseline = "middle";
          ctx.fillText(measuredEllipsisLabel.lines[0], container.x0 + width / 2, container.y0 + height / 2);
        } else {
          ctx.textBaseline = "top";
          let lineIndex = 0;
          for (const line of measuredEllipsisLabel.lines) {
            const x = container.x0 + width / 2;
            const y = container.y0
              + height / 2
              - measuredEllipsisLabel.height / 2
              + lineIndex * measuredEllipsisLabel.height / measuredEllipsisLabel.lines.length
            ctx.fillText(line, x, y);
            lineIndex++;
          }
        }
        ctx.shadowBlur = 0;
      }

      ctx.lineWidth = 2;
      ctx.strokeStyle = "black";
      ctx.strokeRect(container.x0, container.y0, width, height);
    }

    return hierarchy;
  }

  const hasNodeChildren = (nodeId) => {
    const node = findInTree(data, nodeIdKey, nodeId);
    return node.children?.length > 0 ?? false;
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas == null) return;
    const ctx = canvas.getContext("2d");
    ctx.fillRect(0, 0, width, height);
    const hierarchy = drawNode(ctx, structuredClone(maxDepthClampedData), rootContainer);
    renderedHierarchyRef.current = hierarchy;
  }, [maxDepthClampedData, rootContainer]);

  const handleCanvasClick = (e) => {
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;

    if (renderedHierarchyRef.current == null) return;
    const clickedNode = findNodeOnPosition(x, y, renderedHierarchyRef.current);
    if (clickedNode.id === selectedId) return;
    // if (!hasNodeChildren(clickedNode.id)) return;
    if (!hasNodeChildren(clickedNode.id)) {
      const id = clickedNode.id.substring(1)
      const locationId = parseInt(clickedNode.parentId.substring(1), 10);
      wellStore.setId(id)
      wellStore.selectedLocations.clear()
      wellStore.selectedLocations.add(locationId)
      navigate("/analysis");//  return;
    }
    selectedIdHistory.add(selectedId);
    setSelectedId(clickedNode.id);
  }

  const up = useCallback(() => {
    const id = !selectedIdHistory.isEmpty() ? selectedIdHistory.pop() : "l000";
    setSelectedId(id);
  }, [evaluatedData, nodeIdKey, selectedId]);

  const handleContextMenuClick = (e) => {
    e.preventDefault();
  }

  const handleMouseMove = (e) => {
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;

    if (renderedHierarchyRef.current == null) return;
    const hoveredNode = findNodeOnPosition(x, y, renderedHierarchyRef.current);
    if (hoveredNode == null) {
      setTooltipData({
        node: null,
        position: { x: 0, y: 0 }
      });
    }
    else {
      setTooltipData({
        node: hoveredNode,
        position: { x, y }
      });
    }
  }

  const handleMouseLeave = (e) => {
    if (tooltipRef.current != null && e.relatedTarget instanceof Node && tooltipRef.current.contains(e.relatedTarget)) return;
    setTooltipData({
      node: null,
      position: { x: 0, y: 0 }
    });
  }

  useEffect(() => {
    document.addEventListener("contextmenu", up);
    return () => document.removeEventListener("contextmenu", up);
  }, [up]);

  return (
    <>
      <canvas
        ref={canvasRef}
        className="treemap"
        width={width}
        height={height}
        onClick={handleCanvasClick}
        onContextMenu={handleContextMenuClick}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      />
      <TreemapTooltip
        customRef={tooltipRef}
        data={tooltipData}
        settings={tooltipSettings}
        treemapWidth={width}
      />
    </>
  );
});

export default Treemap;
