import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";

const Search = ({ value, setValue }) => {

  const clear = () => {
    setValue("");
  }

  const onChangeValue = (e) => {
    setValue(e.target.value);
  }

  return (
    <Input
      value={value}
      onChange={onChangeValue}
      placeholder="Поиск..."
      suffix={<SearchOutlined style={{ opacity: "0.35" }} />}
    />
  );
}

export default Search;
