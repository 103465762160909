/*eslint-disable*/
import { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Table } from "antd";
import RGL, { WidthProvider } from "react-grid-layout";
import AnalysisTable from "../AnalysisTable";
import AnalysisChart from "./AnalysisChart";
import { useTranslation } from "react-i18next";
import { processData } from "./common";
import VerticalResizeHandle from "components/VerticalResizeHandle";
import { ReactComponent as GreenUpArrow } from "assets/images/greenUpArrow.svg";
import { ReactComponent as RedUpArrow } from "assets/images/redUpArrow.svg";
import "./styles.css";

const ReactGridLayout = WidthProvider(RGL);

const CommonAnalysis = observer(({
  data,
  isLoadingData,
  dataPeriod,
  divContentWidth,
  divContentHeight
}) => {

  const { t } = useTranslation();

  const [layouts, setLayouts] = useState([
    {
      x: 0,
      y: 0,
      w: 1,
      h: 14,
      minH: 2,
      maxH: 30,
      i: "table",
      resizeHandles: ["se"],
    },
    {
      x: 0,
      y: 2,
      w: 1,
      h: 18,
      minH: 2,
      maxH: 30,
      i: "chart",
      isResizable: false,
      resizeHandles: [],
    },
  ]);

  const resizeElements = (changedLayouts) => {
    if (!changedLayouts) return;
    if (!changedLayouts[0] || !changedLayouts[1]) return;

    const deltaHeight0 = layouts[0].h - changedLayouts[0].h;

    const deltaHeight1 = layouts[1].h - changedLayouts[1].h;

    changedLayouts[0].h += deltaHeight1;
    changedLayouts[1].h += deltaHeight0;

    setLayouts(changedLayouts);
  }

  const ArrowWidth = 11;
  const ArrowMargin = 2;

  const tableDataSource = useMemo(() => {
    // console.log("rawData:", data);
    if (!data) return [];
    const processedData = processData(data);
    // console.log("processedData:", processedData);
    return processedData;
  }, [data]);

  const tableColumns = [
    {
      title: t("wellGroup"),
      dataIndex: "name",
      key: "name",
      width: "30%",
      render: (name) => t(name)
    },
    {
      title: (
        <>
          {t("ppl")}
          <RedUpArrow
            width={ArrowWidth}
            style={{ marginLeft: ArrowMargin, transform: "rotate(180deg)" }}
          />
        </>
      ),
      dataIndex: "pplDecreased",
      key: "pplDecreased",
      align: "right",
      render: (text, record, index) => text.toFixed(2)
    },
    {
      title: (
        <>
          {t("ppl")}
          <GreenUpArrow
            width={ArrowWidth}
            style={{ marginLeft: ArrowMargin }}
          />
        </>
      ),
      dataIndex: "pplIncreased",
      key: "pplIncreased",
      align: "right",
      render: (text, record, index) => text.toFixed(2)
    },
    {
      title: (
        <>
          {t("gno")}
          <RedUpArrow width={ArrowWidth}
            style={{ marginLeft: ArrowMargin, transform: "rotate(180deg)" }}
          />
        </>
      ),
      dataIndex: "gnoDecreased",
      key: "gnoDecreased",
      align: "right",
      render: (text, record, index) => text.toFixed(2)
    },
    {
      title: (
        <>
          {t("gno")}
          <GreenUpArrow
            width={ArrowWidth}
            style={{ marginLeft: ArrowMargin }}
          />
        </>
      ),
      dataIndex: "gnoIncreased",
      key: "gnoIncreased",
      align: "right",
      render: (text, record, index) => text.toFixed(2)
    },
    {
      title: t("kakspl"),
      dataIndex: "kakspl",
      key: "kakspl",
      align: "right",
      render: (text, record, index) => text.toFixed(2)
    },
    {
      title: t("waterCutShort"),
      dataIndex: "obv",
      key: "obv",
      align: "right",
      render: (text, record, index) => text.toFixed(2)
    },
    {
      title: t("sum"),
      dataIndex: "sum",
      key: "sum",
      align: "right",
      render: (text, record, index) => text.toFixed(2)
    },
  ];

  const tableSummaryItems = [
    {
      key: "pplDecreased",
    },
    {
      key: "pplIncreased",
    },
    {
      key: "gnoDecreased",
    },
    {
      key: "gnoIncreased",
    },
    {
      key: "kakspl",
    },
    {
      key: "obv",
    },
    {
      key: "sum",
    },
  ];

  const rowHeight = divContentHeight / layouts
    .reduce((totalHeight, layout) => totalHeight += layout.h, 0);

  return (
    <ReactGridLayout
      className="deviation-analysis-grid-layout"
      cols={1}
      layout={layouts}
      isDraggable={false}
      margin={[0, 0]}
      rowHeight={rowHeight}
      onLayoutChange={(layouts) => resizeElements(layouts)}
      resizeHandle={<span><VerticalResizeHandle /></span>}
    >
      <div
        key="table"
        className="deviation-analysis-table-container"
      >
        <div className="deviation-analysis-table"  >
          <AnalysisTable
            loading={isLoadingData}
            dataSource={tableDataSource}
            columns={tableColumns}
            summary={(data) => (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <span className="analysis-table-row-value">Итого</span>
                </Table.Summary.Cell>
                {tableSummaryItems.map((item, index) => (
                  <Table.Summary.Cell key={item.key} index={1 + index}>
                    <span className="analysis-table-row-value">
                      {data.reduce((acc, val) => acc + val[item.key], 0).toFixed(2)}
                    </span>
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            )}
            onRow={(record, index) => ({
              style: { backgroundColor: record.color }, //  ?? (index % 2 == 0 ? "#666666" : "#ffffff")
            })}
          />
        </div>
      </div>

      <div
        key="chart"
        className="deviation-analysis-chart"
      >
        <AnalysisChart
          data={data}
          period={dataPeriod}
          width={divContentWidth}
        />
      </div>
    </ReactGridLayout>
  );
});

export default CommonAnalysis;
