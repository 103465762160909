/* eslint-disable */
const useDataTooltip = ({ setData }) => {
  const tooltipPlugin = (
    scaleIndex,
    scaleParametersClosestLeftPoints
  ) => {

    const setCursor = (u) => {
      const { series } = u;

      let left = null;
      if (u.cursor.sync.values[0] != null) {
        left = Math.round(u.valToPos(u.cursor.sync.values[0], "x"));
      }

      // в series[0] хранится пустой объект, не нужен он
      const filteredSeries = series.slice(1);
      const scaleData = {
        scaleIndex,
        points: new Array(filteredSeries.length)
      }

      for (let i = 0; i < filteredSeries.length; i++) {
        const serie = filteredSeries[i];

        const isParameterVisible = !serie.isDisabled;

        const index = u.cursor.idxs[i + 1];
        if (index === -1 && isParameterVisible) {
          // нужно искать значение за пределами графика

          const closestLeftPoint = scaleParametersClosestLeftPoints[i];

          const isWithinPeriod = closestLeftPoint.timestamp === u.data[0][0];

          scaleData.points[i] = {
            show: serie.show,
            label: serie.label,
            paramId: serie.paramId,
            timestamp: closestLeftPoint?.timestamp,
            value: closestLeftPoint?.value ?? null,
            isWithinPeriod
          }
        } else {
          scaleData.points[i] = {
            show: serie.show,
            label: serie.label,
            paramId: serie.paramId,
            timestamp: u.data[0][index],
            value: u.data[i + 1][index] ?? null,
            isWithinPeriod: true
          }
        }
      }

      setData((prev) => {
        const newData = { ...prev, x: left ?? prev.x }
        newData.scales[scaleIndex] = scaleData;
        return newData;
      });
    }

    return {
      hooks: {
        setCursor
      }
    }
  }
  return tooltipPlugin;
}

export default useDataTooltip;
