import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";

const Search = ({ value, handleChangeValue, fontSize = "14px", style = {} }) => {

  const clear = () => {
    handleChangeValue("");
  }

  const onChangeValue = (e) => {
    handleChangeValue(e.target.value);
  }

  return (
    <Input
      value={value}
      onChange={onChangeValue}
      placeholder="Поиск..."
      suffix={<SearchOutlined style={{ opacity: "0.35" }} />}
      style={{
        ...style,
        fontSize,
      }}
      autoFocus
    />
  );
}

export default Search;
