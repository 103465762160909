/* eslint-disable */
import { Routes, Route, Navigate } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import HomePage from 'pages/Home'
import StartMenuPage from 'pages/StartMenuPage'
import LoginPage from 'pages/Login'
import AdminPage from 'pages/Admin'
import DeviationAnalysisPage from 'pages/DeviationAnalysis'
import WellTreemapChartPage from 'pages/WellTreemapChart'
import PrivatRoute from './privatRoutes'

const NavigateRoutes = () => {
  const { user } = useAuth()

  return (
    <Routes>
      <Route index element={<Navigate replace to="/login" />} />
      <Route
        path="/login"
        element={!user ? <LoginPage /> : <Navigate replace to="/start" />}
      />
      <Route
        path="/start"
        element={<PrivatRoute component={<StartMenuPage />} />}
      />
      <Route
        exact
        path="/admin"
        element={<PrivatRoute component={<AdminPage />} />}
      />
      <Route
        path="/analysis"
        element={<PrivatRoute component={<HomePage />} />}
      />
      <Route
        path="/deviationAnalysis"
        element={<PrivatRoute component={<DeviationAnalysisPage />} />}
      />
      <Route
        path="/treemap"
        element={<PrivatRoute component={<WellTreemapChartPage />} />}
      />
      {/* <Route path="/login" element={!user ? <LoginPage /> : <Navigate replace to="/start" />} />
      <Route path="/start" element={<PrivatRoute component={<StartMenuPage />} allowedRoles={['ADMIN', 'SUPERADMIN', 'GUEST', 'USER', 'ENGINEER']} />} />
      <Route exact path="/admin" element={<PrivatRoute component={<AdminPage />} allowedRoles={['ADMIN', 'SUPERADMIN', 'ENGINEER']} />} />
      <Route path="/analysis" element={<PrivatRoute component={<HomePage />} allowedRoles={['ADMIN', 'SUPERADMIN', 'GUEST', 'USER', 'ENGINEER']} />} /> */}
    </Routes>
  )
}

export default NavigateRoutes
