/* eslint-disable */
import "./styles.css";

const DeviationReasonTable = ({
  wellPumpType,
  trendDebitOil,
  trendDynamicLevel,
}) => {

  const highlightStyles = {
    backgroundColor: "#ccdcf3",
  }

  const highlightReasonStyles = {
    ...highlightStyles,
    border: "2px solid #1890ff",
  }

  const highlightedRowIndex =
    trendDynamicLevel === null ? null :
    trendDynamicLevel === 0 ? 1
      : trendDynamicLevel === 1 ? 2 : 0;

  const highlightedColumnIndex =
  trendDebitOil === null ? null :
    trendDebitOil === 0 ? 1
      : trendDebitOil === 1 ? 2 : 0;

  const getDebitOilStyles = (columnIndex) =>
    columnIndex === highlightedColumnIndex ? highlightStyles : null;

  const getDynamicLevelStyles = (rowIndex) =>
    rowIndex === highlightedRowIndex ? highlightStyles : null;

  const isWellPumpType_ESP = wellPumpType.id === 1;

  const espCells = [
    [
      "ГНО (уменьш.)",
      "Некорр. замер",
      "Кпрод/Рпл (увелич.)",
    ],
    [
      "Некорр. замер",
      "-",
      "Кпрод/Рпл (увелич.)/ГНО (увелич.)",
    ],
    [
      "Кпрод/Рпл (уменьш.)",
      "Кпрод/Рпл (уменьш.)/ГНО (увелич.)",
      "ГНО(увелич.)",
    ]
  ];

  const anotherCells = [
    [
      "ГНО (уменьш.)",
      "Кпрод/Рпл (увелич.)",
      "Кпрод/Рпл (увелич.)",
    ],
    [
      "Некорр. замер",
      "-",
      "Кпрод/Рпл (увелич.)/ГНО (увелич.)",
    ],
    [
      "Кпрод/Рпл (уменьш.)/ГНО (уменьш.)",
      "Кпрод/Рпл (уменьш.)",
      "ГНО(увелич.)",
    ]
  ];

  const cells = isWellPumpType_ESP ? espCells : anotherCells;

  const renderReasonCell = (reasonName, rowIndex, columnIndex) => (
    <td
      key={columnIndex}
      style={rowIndex === highlightedRowIndex &&
        columnIndex === highlightedColumnIndex
        ? highlightReasonStyles : null
      }
    >
      {reasonName}
    </td>
  );

  return (
    <div>
      <span className="title">Причина отклонения, выбранная системой:</span>
      <table
        className="data-text deviation-reason-table"
        border={"1px"}
        bordercolor={"#f0f0f0"}
        cellPadding={"4px"}
        style={{
          width: "100%",
          marginTop: "12px",
          textAlign: "center",
        }}
      >
        <tbody>
          <tr>
            <td className="diagonal-header-cell">
              <span className="diagonal-line" />
              <span className="top">Отклонение<br />дебита жидкости</span>
              <span className="bottom">Отклонение<br />приведенного<br />динамического уровня</span>
            </td>
            <td style={getDebitOilStyles(0)}>Qж уменьшается</td>
            <td style={getDebitOilStyles(1)}>Qж не изменяется</td>
            <td style={getDebitOilStyles(2)}>Qж увеличивается</td>
          </tr>

          <tr>
            <td style={getDynamicLevelStyles(0)}>Ндин уменьшается</td>
            {cells[0].map((reasonName, index) => (
              renderReasonCell(reasonName, 0, index)
            ))}
          </tr>

          <tr>
            <td style={getDynamicLevelStyles(1)}>Ндин не изменяется</td>
            {cells[1].map((reasonName, index) => (
              renderReasonCell(reasonName, 1, index)
            ))}
          </tr>

          <tr>
            <td style={getDynamicLevelStyles(2)}>Ндин увеличивается</td>
            {cells[2].map((reasonName, index) => (
              renderReasonCell(reasonName, 2, index)
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default DeviationReasonTable;
