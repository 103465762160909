/* eslint-disable */
import { Collapse } from "antd";
import { useTranslation } from "react-i18next";
import VirtualMeasureSettings from "../../../WellTree/components/MenuData/components/ControlPanel/components/VirtualMeasureModal";

const VirtualMeasureCollapse = ({
  ids,
  wellsData,
  handleSearchWells,
  activePanel,
  setActivePanel,
  isSuperAdmin,
  onVirtualMeasureApply
}) => {
  const { t } = useTranslation();
  const { Panel } = Collapse;

  if (ids?.length) {
    const virtualTitles = [
      "machine_learning_model",
      "physical_model",
      "hybrid_model",
    ];
    return (
      <Collapse
        accordion
        ghost
        defaultActiveKey={activePanel}
        onChange={setActivePanel}
      >
        {ids?.map((title, index) => (
          <Panel header={t(virtualTitles[index])} key={title.alias}>
            <VirtualMeasureSettings
              parametersData={wellsData}
              handleSearch={handleSearchWells}
              ids={ids[index]}
              isSuperAdmin={isSuperAdmin}
              onVirtualMeasureApply={onVirtualMeasureApply}
            />
          </Panel>
        ))}
      </Collapse>
    );
  }
  return <></>;
};

export default VirtualMeasureCollapse;
