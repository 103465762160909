export const findInTree = (node, key, value) => {
  if (node[key] === value) return node;
  if (node.children == null) return null;
  for (const child of node.children) {
    const result = findInTree(child, key, value);
    if (result != null) return result;
  }
  return null;
}

export const evaluateRealNodeValues = (node) => {
  node.realValue = node.value;

  for (const child of node.children) {
    evaluateRealNodeValues(child);
  }
}

export const hexToRgb = (hex) => {
  const rgb = [
    parseInt(hex.slice(1, 3), 16),
    parseInt(hex.slice(3, 5), 16),
    parseInt(hex.slice(5, 7), 16)
  ];
  return rgb;
}

export const getGradientHexColor = (value, colors) => {
  if (Math.abs(0 - value) < 0.0001) return colors[0];
  if (Math.abs(1 - value) < 0.0001) return colors[colors.length - 1];
  const cuttedColors = colors.slice(1, colors.length - 1);
  if (cuttedColors.length === 0) return colors[0];

  const step = 1 / (colors.length - 1);
  let index;
  if (0.5 - step < value && value < 0.5 + step) {
    index = Math.floor(cuttedColors.length / 2);
  } else if (value >= 0.5 + step) {
    index = Math.floor(value / step) - 1;
  } else if (value <= 0.5 - step) {
    index = Math.ceil(value / step) - 1;
  }

  const hex = cuttedColors[index];
  return hex;
}

export const cutTreeByMaxDepth = (node, maxDepth, depth = 0) => {
  if (depth >= maxDepth) {
    delete node.children;
    return;
  }
  if (node.children == null) return;
  for (const child of node.children) {
    cutTreeByMaxDepth(child, maxDepth, depth + 1);
  }
}

export const evaluateNodeMinValues = (node, minValueCoefficient, rootValue = null) => {
  rootValue ??= node.value > 0 ? node.value : 1;

  if (node.children == null || node.children.length === 0) {
    const minValue = rootValue *= minValueCoefficient;
    node.value = Math.max(node.value, minValue);
  }
  else {
    let sum = 0;
    for (const child of node.children) {
      sum += evaluateNodeMinValues(child, minValueCoefficient, rootValue);
    }
    node.value = sum;
  }

  return node.value;
}

export const isPointInsideRect = (x, y, rect) => rect.x0 <= x && x <= rect.x1 && rect.y0 <= y && y <= rect.y1;

export const findNodeOnPosition = (x, y, node) => {
  if (node.parentId == null && !isPointInsideRect(x, y, node)) return null;
  if (node.children == null) return node;
  for (const child of node.children) {
    if (isPointInsideRect(x, y, child)) {
      const result = findNodeOnPosition(x, y, child);
      return result;
    }
  }
  return node;
}

export const stretchText = (ctx, text, maxWidth, maxHeight, minFontSize, maxFontSize = 32) => {
  let fontSize = minFontSize;

  ctx.font = `${fontSize}px Roboto`;
  const measured = ctx.measureText(text);
  let actualHeight = measured.actualBoundingBoxAscent + measured.actualBoundingBoxDescent;
  if (measured.width > maxWidth || actualHeight > maxHeight) return null;

  while (fontSize <= maxFontSize) {
    ctx.font = `${fontSize + 2}px Roboto`;
    const measured = ctx.measureText(text);
    actualHeight = measured.actualBoundingBoxAscent + measured.actualBoundingBoxDescent;
    if (measured.width < maxWidth && actualHeight < maxHeight) {
      fontSize += 2;
    } else {
      break;
    }
  }

  return {
    fontSize,
    height: actualHeight,
  }
}

export const stretchEllipsisText = (ctx, text, maxWidth, maxHeight, minFontSize, maxFontSize) => {
  let stretchResult = stretchText(ctx, text, maxWidth, maxHeight, minFontSize, maxFontSize);
  let fontSize, actualHeight;
  if (stretchResult != null) {
    fontSize = stretchResult.fontSize;
    actualHeight = stretchResult.height;
  }

  if (fontSize == null) {
    fontSize = minFontSize;

    ctx.font = `${fontSize}px Roboto`;
    let measured = ctx.measureText(text);
    actualHeight = measured.actualBoundingBoxAscent + measured.actualBoundingBoxDescent;
    if (actualHeight > maxHeight) return null;

    const ellipsisWidth = ctx.measureText("...").width;

    let len = text.length;
    let iter = 100;
    while (measured.width + ellipsisWidth > maxWidth && iter-- > 0) {
      if (len < 2) return null;
      text = text.substring(0, len--);
      measured = ctx.measureText(text);
    }
    if (iter <= 0) return null;

    text += "...";
  }

  return {
    text,
    fontSize,
    height: actualHeight,
  }
}

export const stretchEllipsisLines = (ctx, text, maxWidth, maxHeight, minFontSize, maxFontSize, lineGap) => {
  const lines = text.split("\n");

  const isLinesFitWidth = (lines) => {
    for (const line of lines) {
      const measured = ctx.measureText(line);
      if (measured.width > maxWidth) return false;
    }
    return true;
  }

  const getActualTextHeight = (lines) => {
    const measured = ctx.measureText("X");
    let height = measured.actualBoundingBoxAscent + measured.actualBoundingBoxDescent;
    height *= lines.length;
    height += (lines.length - 1) * lineGap;
    return height;
  }

  let fontSize = minFontSize;
  ctx.font = `${fontSize}px Roboto`;
  let actualHeight = getActualTextHeight(lines);
  if (actualHeight > maxHeight) return null;
  if (!isLinesFitWidth(lines)) {
    fontSize = minFontSize;

    ctx.font = `${fontSize}px Roboto`;
    let lineIndex = 0;
    for (let lineText of lines) {
      let measured = ctx.measureText(lineText);
      const ellipsisWidth = ctx.measureText("...").width;

      let len = lineText.length;
      let iter = 100;
      while (measured.width + ellipsisWidth > maxWidth && iter-- > 0) {
        if (len < 2) return null;
        lineText = lineText.substring(0, len--);
        measured = ctx.measureText(lineText);
      }
      if (iter <= 0) return null;

      lineText += "...";
      lines[lineIndex++] = lineText;
    }
  }

  while (fontSize <= maxFontSize) {
    ctx.font = `${fontSize + 2}px Roboto`;
    const localActualHeight = getActualTextHeight(lines);
    if (localActualHeight > maxHeight) break;
    if (!isLinesFitWidth(lines)) break;

    actualHeight = localActualHeight;
    fontSize += 2;
  }

  return {
    lines,
    fontSize,
    height: actualHeight,
  }
}
