/* eslint-disable */
import axios from "axios";
import cookie from "js-cookie";

let address = "";

export const REQUEST_PROTOCOL = "http://";

if (process.env.REACT_APP_ADDRESS_HOST_AS_CLIENT === "false") {
  address =
    REQUEST_PROTOCOL +
    process.env.REACT_APP_API_URL_HOST +
    process.env.REACT_APP_API_PORT +
    process.env.REACT_APP_API_PREFIX;
}

if (
  process.env.REACT_APP_ADDRESS_HOST_AS_CLIENT === "true" &&
  process.env.REACT_APP_DYNAMIC_PROTOCOL === "false"
) {
  address = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT}/api`;
}

if (
  process.env.REACT_APP_ADDRESS_HOST_AS_CLIENT === "true" &&
  process.env.REACT_APP_DYNAMIC_PROTOCOL === "true"
) {
  address = `${window.location.protocol}//${window.location.host}${process.env.REACT_APP_API_PREFIX}`;
}

const authAxiosInstance = axios.create({ baseURL: address });

if (cookie.get("accessToken") && JSON.parse(localStorage.getItem("AUTH_PROVIDER")) === "jwt") {
  authAxiosInstance.defaults.headers.common.Authorization =
    cookie.get("accessToken");
}

export default authAxiosInstance;
