/* eslint-disable */
import { useEffect } from "react";
import { useStore } from "./useStore";

const useUserInfo = (userId) => {

  const { userStore } = useStore();

  const load = async () => {
    userStore.fetchUserInfo();
    userStore.fetchIsAdmin();
  }

  const clear = () => {
    userStore.clearUserInfo();
  }

  useEffect(() => {
    if (!userId && JSON.parse(localStorage.getItem("AUTH_PROVIDER")) !== "krb") {
      clear();
      return;
    }
    load();
  }, [userId]);

  return {
    clear
  }
}

export default useUserInfo;
