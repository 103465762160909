/* eslint-disable */
import { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { task } from "mobx-fetch-task";
import { Collapse, Input, Popconfirm } from "antd"
import {
  PlusCircleOutlined,
  EditOutlined,
  MinusCircleOutlined,
  FormOutlined,
  SaveOutlined,
  LoadingOutlined,
  SettingOutlined
} from "@ant-design/icons";
import { useStore } from "hooks/useStore";
import Tooltip from "components/Tooltip";
import TableListItem from "./components/TableListItem";
import EditParametersModal from "./components/EditParametersModal";
import EditStyleModal from "./components/EditStyleModal";
import "./styles.css";

const TableList = observer(({

}) => {

  const { mnemoStore } = useStore();

  const tables = mnemoStore.tableListStore?.tables;
  const sortedTables = mnemoStore.tableListStore?.sortedTables;

  const [parameterEditableTableId,
    setParameterEditableTableId] = useState();

  const [styleEditableTableId,
    setStyleEditableTableId] = useState();

  const [renamableTableId,
    setRenamableTableId] = useState();

  const createTable = () => {
    mnemoStore.tableListStore?.createTable();
  }

  const handleCreateTable = (e) => {
    e.stopPropagation();
    createTable();
  }

  const deleteTable = async (id) => {
    await mnemoStore.tableListStore?.deleteTable(id);
  }

  const saveTableParameters = async (activeParameters) => {
    await mnemoStore.tableListStore
      ?.editTableParameters(parameterEditableTableId, activeParameters);

    setParameterEditableTableId(undefined);
  };

  const [renameTableTask] = useState(() => task(async (id, name) => {
    await mnemoStore.tableListStore
      ?.renameTable(id, name);
    setRenamableTableId(undefined);
  }));

  const handleRenameTable = (e, id) => {
    e.stopPropagation();
    setRenamableTableId(id);
  }

  const handleDeleteTableParameter = (e) => {
    e.stopPropagation();
  }

  const handleEditTableParameters = (e, id) => {
    e.stopPropagation();
    setParameterEditableTableId(id);
  }

  const handleCloseEditParametersModal = () => {
    setParameterEditableTableId(undefined);
  }

  const handleEditTableStyles = (e, id) => {
    e.stopPropagation();
    setStyleEditableTableId(id);
  }

  const handleCloseEditStylesModal = () => {
    setStyleEditableTableId(undefined);
  }

  const saveTableStyles = async (styles) => {
    await mnemoStore.tableListStore
      ?.editTableStyles(styleEditableTableId, styles);

    setStyleEditableTableId(undefined);
  }

  const renameTableInputRef = useRef();

  return (
    <div className="mnemo-tables">
      <Collapse
        ghost
        collapsible={tables?.length > 0 ? "header" : "disabled"}
      >
        <Collapse.Panel
          header={(
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px"
              }}
            >
              <span>Список таблиц</span>

              <Tooltip placement="right" tooltipText={"Добавить таблицу"}>
                <div
                  className="pad-3px"
                  onClick={handleCreateTable}
                  style={{ cursor: "pointer" }}
                >
                  <PlusCircleOutlined
                    style={{ color: "black" }}
                  />
                </div>
              </Tooltip>
            </div>
          )}
        >
          {tables?.length > 0 && (
            <Collapse className="disable-collapse-panel-padding">
              {sortedTables.map(table => (
                <Collapse.Panel
                  key={table.id}
                  header={(
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                      }}
                    >
                      <div>
                        {table.id === renamableTableId ?
                          <Input
                            ref={renameTableInputRef}
                            style={{ marginRight: "8px" }}
                            onClick={(e) => e.stopPropagation()}
                            defaultValue={table.name}
                            placeholder={"Имя таблицы"}
                            maxLength={64}
                            size="small"
                            suffix={
                              renameTableTask.pending ?
                                <LoadingOutlined />
                                :
                                <SaveOutlined
                                  onClick={(e) => {
                                    e.stopPropagation();

                                    const name = renameTableInputRef.current.input.value;
                                    if (name.length <= 0) return;

                                    renameTableTask(
                                      renamableTableId,
                                      name
                                    );
                                  }}
                                />
                            }
                          />
                          :
                          <span style={{ marginRight: "8px" }}>{table.name}</span>
                        }

                        {table.id !== renamableTableId &&
                          <Tooltip placement="top" tooltipText={"Редактировать название"}>
                            <div
                              className="pad-3px"
                              onClick={(e) => handleRenameTable(e, table.id)}
                              style={{
                                display: "inline",
                                cursor: "pointer"
                              }}
                            >
                              <EditOutlined />
                            </div>
                          </Tooltip>
                        }
                      </div>

                      <div>
                        <Tooltip placement="top" tooltipText={"Настройки стилей"}>
                          <div
                            className="pad-3px"
                            onClick={(e) => handleEditTableStyles(e, table.id)}
                            style={{
                              display: "inline",
                              cursor: "pointer"
                            }}
                          >
                            <SettingOutlined />
                          </div>
                        </Tooltip>

                        <Tooltip placement="top" tooltipText={"Изменить параметры"}>
                          <div
                            className="pad-3px"
                            onClick={(e) => handleEditTableParameters(e, table.id)}
                            style={{
                              display: "inline",
                              cursor: "pointer",
                              marginLeft: "8px"
                            }}
                          >
                            <FormOutlined />
                          </div>
                        </Tooltip>

                        <Tooltip placement="top" tooltipText={"Удалить таблицу"}>
                          <Popconfirm
                            onConfirm={() => deleteTable(table.id)}
                            onCancel={(e) => e.stopPropagation()}
                            onClick={(e) => e.stopPropagation()}
                            title={"Вы уверены, что хотите удалить таблицу?"}
                            cancelText={"Отмена"}
                            okText={"Удалить"}
                          >
                            <div
                              className="pad-3px"
                              onClick={handleDeleteTableParameter}
                              style={{
                                display: "inline",
                                cursor: "pointer",
                                marginLeft: "8px"
                              }}
                            >
                              <MinusCircleOutlined />
                            </div>
                          </Popconfirm>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                >
                  <TableListItem items={toJS(table.parameters)} />
                </Collapse.Panel>
              ))}
            </Collapse>
          )}

          <EditParametersModal
            visible={Boolean(parameterEditableTableId)}
            parameters={toJS(mnemoStore.tableListStore?.getEditParametersTableData(parameterEditableTableId))}
            closeModal={handleCloseEditParametersModal}
            onSave={saveTableParameters}
          />

          <EditStyleModal
            visible={Boolean(styleEditableTableId)}
            closeModal={handleCloseEditStylesModal}
            styles={toJS(tables
              ?.find(table => table.id === styleEditableTableId)
              ?.styles)}
            onSave={saveTableStyles}
          />

        </Collapse.Panel>
      </Collapse>
    </div>
  );
});

export default TableList;
