/* eslint-disable */
import { useState, useEffect, useReducer } from "react";
import SettingsModal from "containers/Home/components/SettingsModal";
import { observer } from "mobx-react-lite";
import { Input } from "antd";
import { useStore } from "hooks/useStore";
import { useTranslation } from "react-i18next";
import { ReactComponent as BlueLeftArrow } from "assets/images/blueLeftArrow.svg";
import { ReactComponent as BlueRightArrow } from "assets/images/blueRightArrow.svg";
import { ReactComponent as AlertIcon } from "assets/images/alertIcon.svg";
import { ReactComponent as TextFile } from "assets/images/textFile.svg";
import { ReactComponent as SettingsWhiteIcon } from "assets/images/settingsWhiteIcon.svg";
import { ReactComponent as SearchIcon } from "assets/images/searchIcon.svg";
import { ReactComponent as ClearIcon } from "assets/images/clearIcon.svg";
import AddPriorityModal from "./components/AddPriorityModal";
import DeletePriorityModal from "./components/DeletePriorityModal";
import ReportModal from "./components/ReportModal";
import "./styles.css";
import Tooltip from "components/Tooltip";

const ControlPanel = observer(
  ({
    settingsData,
    data,
    mutate,
    minimizedMode,
    onArrowClick,
    onSearchWell,
    onSearchCancel,
    showAddPriorityButton = true,
    showExtendedMenu = true,
    showReports = true,
    onVirtualMeasureApply
  }) => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const { wellStore, accessStore } = useStore();
    const [settings, setSettings] = useState(false);
    const [searchMode, setSearchMode] = useState(false);
    const addReasons = settingsData?.reasons.filter(
      ({ active }) => active !== false
    );
    const openSettings = () => setSettings(true);
    const closeSettings = () => setSettings(false);
    const [modalMode, setModalMode] = useState();
    // const activeWellReason = data?.find(item => item.id === wellStore.id)?.wellReason
    const [activeWellReason, setActiveWellReason] = useState(
      data?.find((item) => item.id === wellStore.id)?.wellReason
    );

    useEffect(() => {
      setActiveWellReason(
        data?.find((item) => item.id === wellStore.id)?.wellReason
      );
    }, [modalMode]);

    const translateReasonsData = (reasons) => {
      for (let i = 0; i < reasons?.length; i++) {
        const index = settingsData?.translationReasonsData[
          currentLanguage
        ].findIndex((x) => x.reasonId === reasons[i].id);

        if (index > -1) {
          reasons[i].name =
            settingsData?.translationReasonsData[currentLanguage][
              index
            ].translation;
        }
      }

      return reasons;
    };

    const translateSettingsReasons = (reasons) => {
      for (let i = 0; i < reasons?.length; i++) {
        const index = settingsData?.translationReasonsData[
          currentLanguage
        ].findIndex((x) => x.reasonId === reasons[i].id);

        if (index > -1) {
          reasons[i].name =
            settingsData?.translationReasonsData[currentLanguage][
              index
            ].translation;
        }
      }

      return reasons;
    };

    const translateActiveWellReason = (reason) => {
      if (reason !== null && reason !== undefined) {
        reason.reason.name =
          settingsData?.translationReasonsData[currentLanguage][
            reason.reason.id - 1
          ].translation;
      }

      return reason;
    };

    const handleSearchWell = (value) => {
      onSearchWell(value);
    };

    const handleSearchCancel = () => {
      setSearchMode(false);
      onSearchCancel();
    };

    return (
      <div className="well-nav-wrapper">
        <AddPriorityModal
          visible={modalMode === "add"}
          reasons={translateReasonsData(addReasons)}
          modalInfo={wellStore.data}
          closePriority={() => setModalMode(null)}
          selectedWellId={wellStore.id}
          setDeleteStatus={() => wellStore.setStatus("delete")}
        />
        <DeletePriorityModal
          visible={modalMode === "delete"}
          modalInfo={translateActiveWellReason(activeWellReason)}
          closePriority={() => setModalMode(null)}
          wellId={wellStore?.id}
          reasonId={activeWellReason?.reason.id}
          wellName={wellStore?.data?.name}
          setAddStatus={() => wellStore.setStatus("add")}
        />
        {modalMode === "report" &&
          <ReportModal
            visible={modalMode === "report"}
            onClose={() => setModalMode(null)}
          />
        }
        <SettingsModal
          visible={settings}
          reasonsProps={translateSettingsReasons(settingsData?.reasons)}
          analysis={settingsData?.analysisTypes}
          mutate={mutate}
          closeSettings={closeSettings}
          showExtended={showExtendedMenu}
          onVirtualMeasureApply={onVirtualMeasureApply}
        />
        {!searchMode && (
          <div className="minimized-button" onClick={onArrowClick}>
            {minimizedMode ? (
              <Tooltip
                placement="right"
                zIndex={1000000000001}
                title="Показать дерево скважин"
              >
                <BlueRightArrow />
              </Tooltip>
            ) : (
              <Tooltip
                placement="right"
                zIndex={1000000000001}
                title="Скрыть дерево скважин"
              >
                <BlueLeftArrow />
              </Tooltip>
            )}
          </div>
        )}
        {!minimizedMode &&
          (searchMode ? (
            <Input
              onChange={(e) => handleSearchWell(e.target.value)}
              suffix={<ClearIcon onClick={handleSearchCancel} />}
            />
          ) : (
            <div className="well-nav-container">
              {showAddPriorityButton && (
                <Tooltip
                  zIndex={1000000000001}
                  title="Настройки скважин первого приоритета"
                >
                  <AlertIcon
                    className={`
                        alert-icon
                        ${wellStore.id ? "available" : "disabled"}
                        ${wellStore.status === "delete" &&
                      "delete-priority-icon"
                      }
                      `}
                    onClick={() =>
                      accessStore.checkPermission("add_first_priority_well") && (wellStore.status === "delete"
                        ? setModalMode("delete")
                        : setModalMode("add"))
                    }
                  />
                </Tooltip>
              )}
              {showReports && (
                <Tooltip zIndex={1000000000001} title="Создать отчет">
                  <TextFile onClick={() => setModalMode("report")} />
                </Tooltip>
              )}
              <Tooltip zIndex={1000000000001} title="Настройки дерева скважин">
                <SettingsWhiteIcon onClick={openSettings} />
              </Tooltip>
              <Tooltip zIndex={1000000000001} title="Поиск">
                <SearchIcon onClick={() => setSearchMode(true)} />
              </Tooltip>
            </div>
          ))}
      </div>
    );
  }
);

export default ControlPanel;
