/* eslint-disable */
import { makeAutoObservable, observable, runInAction, toJS } from "mobx";
import { task } from "mobx-fetch-task";
import Input from "formAdapters/Input";
import request from "utils/request";
import { required } from "utils/validate";
import GroupsTreeNode from "./components/GroupsSettings/groupsTree";
import { FORM_ERROR } from "final-form";
import { ruCollator } from "utils/collator";

class AdminGroupStore {

  groups = [];

  isAddGroupModalVisible = false;

  addedGroupParentId = null;

  currentEditableGroup = null;

  constructor() {
    makeAutoObservable(this, {
      fetchGroups: false
    });
  }

  sortGroupsTree() {
    const sortNode = (node) => {
      if (node.children == null) return;

      node.children.sort((a, b) => ruCollator.compare(a.name, b.name));

      for (const child of node.children) {
        sortNode(child);
      }
    }

    sortNode(this.groups);
  }

  fetchGroups = task(async () => {
    try {
      this.setGroups([]);

      const { data } = await request.get("/groups");

      runInAction(() => {
        this.setGroups(data);
      });
    } catch (error) {
      this.setGroups([]);
    };
  });

  get list() {
    if (this.groups) return this.groups.list
      ?.sort((a, b) => ruCollator.compare(a.name, b.name));
    return [];
  }

  get listEditableGroup() {
    return this.list.filter(group => group.editable);
  }

  setGroups(root) {
    if (!root) {
      this.groups = null;
      return;
    }

    const tree = new GroupsTreeNode(
      root.id, root.name,
      root.parentId, root.children
    );

    makeAutoObservable(tree);

    this.groups = tree;
    this.sortGroupsTree();
  }

  async addGroup(values) {
    const addedGroup = {
      parentId: values.parentId,
      name: values.name
    }

    try {
      const { data: id } = await request.post("/groups", addedGroup);

      addedGroup.id = id;
      addedGroup.editable = true;
      addedGroup.deletable = true;
      addedGroup.children = [];

      runInAction(() => {
        this.groups.insert(addedGroup);
        this.sortGroupsTree();
        this.closeAddGroupModal();
      });
    } catch (error) {
      console.error(error);
      return {
        [FORM_ERROR]: null
      }
    }
  }

  async editGroup(values) {
    const editedGroupDTO = {
      id: values.id,
      name: values.name
    }

    try {
      await request.put(`/groups`, editedGroupDTO);

      const edited = this.groups.find(editedGroupDTO.id);
      if (!edited) return;

      runInAction(() => {
        edited.name = editedGroupDTO.name;
        this.sortGroupsTree();
        this.setCurrentEditableGroup(null);
      });
    } catch (error) {
      console.error(error);
      return {
        [FORM_ERROR]: null
      }
    }
  }

  async deleteGroup(id, type) {
    // type: "cascade" | "normal"

    if (type === "normal") {
      try {
        await request.delete(`/groups/normal/${id}`);

        const deletableGroup = this.groups.find(id);
        if (!deletableGroup) return;

        const parentGroup = this.groups.find(deletableGroup.parentId);

        runInAction(() => {
          this.groups.remove(id);
          if (parentGroup && deletableGroup.children) {
            if (!parentGroup.children) parentGroup.children = [];
            for (const child of deletableGroup.children) {
              parentGroup.children.push(child);
              child.parentId = parentGroup.id;
            }
          }
        });

        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    } else if (type === "cascade") {
      try {
        await request.delete(`/groups/cascade/${id}`);

        const deletableGroup = this.groups.find(id);
        if (!deletableGroup) return;

        runInAction(() => {
          this.groups.remove(id);
        });

        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    }
  }

  get tableDataSource() {
    if (!this.groups) return observable([]);
    return observable([this.groups]);
  }

  get isEditGroupModalVisible() {
    return this.currentEditableGroup !== null;
  }

  setCurrentEditableGroup(group) {
    this.currentEditableGroup = group;
  }

  openAddGroupModal(parentId) {
    runInAction(() => {
      this.addedGroupParentId = parentId;
      this.isAddGroupModalVisible = true;
    });
  }

  closeAddGroupModal() {
    this.isAddGroupModalVisible = false;
  }

  closeEditGroupModal() {
    this.currentEditableGroup = null;
  }

  get addGroupFields() {
    return [
      {
        label: "groupName",
        name: "name",
        component: Input,
        validate: required
      }
    ];
  }

  get editGroupFields() {
    return [
      {
        label: "groupName",
        name: "name",
        component: Input,
        validate: required
      }
    ];
  }
}

export default AdminGroupStore;
