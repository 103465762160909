import { Table } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import CustomTooltip from "components/Tooltip";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "./styles.css";

const EngineerScreenTable = ({ dataSource }) => {

  const { t } = useTranslation();

  const dateFormat = "DD.MM.YYYY HH:mm:ss";

  const columns = [
    {
      key: "indicator",
      className: "engineer-screen-table-indicator-column",
      width: "32px",
      render: (_, record) => {
        let lampClassName = null;
        if (record.isEnterThreshold) lampClassName = "engineer-screen-green-circle";
        else lampClassName = "engineer-screen-red-circle";

        return <div className={lampClassName} />;
      }
    },
    {
      title: t("parameter"),
      dataIndex: "parameter",
      key: "parameter",
    },
    {
      title: t("value"),
      dataIndex: "value",
      key: "value",
      render: (value, record) => {

        let formattedValue = value;
        if (record.valueType === "DATE") {
          formattedValue = dayjs(value).format(dateFormat);
        } else if (record.valueType === "PERIOD") {
          const dates = value.split(" ");
          dates[0] = dayjs(dates[0]).format(dateFormat);
          dates[1] = dayjs(dates[1]).format(dateFormat);
          formattedValue = `с ${dates.at(0)} по ${dates.at(1)}`;
        }

        let redIndicatorHintText =
          "Значение не входит в порог.";
        if (record.valueType === "DATE" && record.threshold?.max != null) {
          redIndicatorHintText += ` Время обновления должно быть не раньше ${dayjs().subtract(record.threshold.max, "minutes").format(dateFormat)}`;
        }

        return (
          <>
            <span style={{ color: record.isEnterThreshold ? "black" : "red" }}>
              {formattedValue}
            </span>
            {!record.isEnterThreshold &&
              <CustomTooltip
                tooltipText={redIndicatorHintText}
              >
                <QuestionCircleOutlined style={{ paddingLeft: "8px" }} />
              </CustomTooltip>
            }
          </>
        );
      }
    },
    {
      title: t("threshold"),
      dataIndex: "threshold",
      key: "threshold",
      render: (value, record) => {
        if (value == null) return <span>Нет</span>;
        return (
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {record.valueType === "DATE" ?
                <>
                  {value.max &&
                    <span>
                      Данные должны быть не старше <b>{value.max} минут</b>
                    </span>
                  }
                </>
                :
                <>
                  {value.min && <span>Минимальное значение {value.min}</span>}
                  {value.max && <span>Максимальное значение {value.max}</span>}
                </>
              }
            </div>
            <span>{record.threshold.postscript}</span>
          </>
        );
      }
    },
  ];

  return (
    <Table
      className="engineer-screen-table"
      sticky
      loading={false}
      dataSource={dataSource}
      columns={columns}
      rowKey={"id"}
      onRow={(data, index) => ({
        style: {
          backgroundColor: index < 3 ? "#66000006" : "inherit",
        }
      })}
      pagination={false}
      bordered={true}
    />
  );
}

export default EngineerScreenTable;
