/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import CheckboxGroup from "react-checkbox-group";
import { useTranslation } from "react-i18next";
import { Input, Form, Switch, Button, Checkbox } from "antd";
import request from "utils/request";
import { useStore } from "hooks/useStore";
import CheckBoxes from "./components/CheckBoxes";
import "./styles.css";

const VirtualMeasureSettings = ({
  parametersData,
  handleSearch,
  ids,
  isSuperAdmin,
  onVirtualMeasureApply
}) => {

  const tailLayout = {
    wrapperCol: { offset: 10, span: 16 },
  }

  const { t } = useTranslation();

  const { accessStore } = useStore();

  const params = {
    alias: "",
    wellsList: [],
    state: false,
  };

  const updateData = async () => {
    for (let i = 0; i < parametersData?.length; i++) {
      if (
        ids.wellsList[i]?.wellId !== parametersData[i]?.id) {
        if (!+ids.wellsList[i]?.wellId) {
          params.wellsList.push({ wellId: parametersData[i]?.id, status: false })
        }
        else params.wellsList.push({ wellId: ids.wellsList[i]?.wellId, status: ids.wellsList[i]?.status })
      }
    }
    await request.post(`/settings/model/update`, params);
  };

  useEffect(() => {
    if (ids.wellsList.length === parametersData.length) return;
    updateData().then();
  }, []);
  const [checkedWellIds, setCheckedWellIds] = useState(
    () => ids?.wellsList
      ?.filter(well => well.status)
      .map(well => well.wellId) ?? []
  );
  const [checkedSwitch, setCheckedSwitch] = useState(ids?.state);
  const [buttonText, setButtonText] = useState(t("apply"));

  const checkedAll = useMemo(() => {
    for (const wellInfo of ids?.wellsList) {
      if (!checkedWellIds.includes(wellInfo.wellId)) return false;
    }
    return true;
  }, [checkedWellIds, ids]);

  const indeterminate = useMemo(() => {
    let activeCount = 0;
    for (const wellInfo of ids?.wellsList) {
      if (!checkedWellIds.includes(wellInfo.wellId)) activeCount++;
    }
    return activeCount > 0 && activeCount < ids?.wellsList?.length;
  }, [checkedWellIds, ids]);

  const handleCheckAll = () => {
    const accessProhibitedWellIds = [];
    const accessedWellIds = [];

    for (let i = 0; i < ids?.wellsList?.length; i++) {
      if (ids?.wellsList[i]?.wellId != null) {
        if (accessStore.checkWellPermission(ids.wellsList[i].wellId, "virtual_flow_meter") !== 1) {
          accessProhibitedWellIds.push(ids.wellsList[i].wellId);
        } else {
          accessedWellIds.push(ids.wellsList[i].wellId);
        }
      }
    }

    const checkedAllAccessedWells = accessedWellIds
      .every(id => checkedWellIds.includes(id));

    if (!checkedAllAccessedWells) {
      const wellIds = [...checkedWellIds];
      for (const id of accessedWellIds) {
        if (!wellIds.includes(id)) wellIds.push(id);
      }

      handleChangeParameters(wellIds);
    } else {
      handleChangeParameters(accessProhibitedWellIds
        .filter(id => checkedWellIds.includes(id))
      );
    }
  }

  const handleChangeParameters = (checkedIds) => {
    setCheckedWellIds(checkedIds);
    setButtonText(t("apply"));
  }

  const handleChangeSwitch = () => {
    setCheckedSwitch(() => !checkedSwitch);
    setButtonText(t("apply"));
  }

  const updateParams = () => {
    ids.state = params.state;
    ids.alias = params.alias;
    //ids.wellsList = params.wellsList;
    for (let i = 0; i < params?.wellsList?.length; i++) {
      let index = ids.wellsList.findIndex((element) => element?.wellId == params?.wellsList[i]?.wellId)
      if (index > -1) {
        ids.wellsList[index].status = params.wellsList[i]?.status
      }
    }
    return ids;
  }

  const handleClickVirtualModal = async (alias, state) => {
    params.alias = alias;
    params.state = state;

    for (let i = 0; i < parametersData?.length; i++) {
      if (accessStore.checkWellPermission(parametersData[i]?.id, "virtual_flow_meter") === 1) {
        if (checkedWellIds.find((value) => value === parametersData[i].id)) {
          params.wellsList.push({ wellId: parametersData[i]?.id, status: true });
        } else {
          params.wellsList.push({ wellId: parametersData[i]?.id, status: false });
        }
      }
    }
    setButtonText(t("applied"));
    updateParams();
    await request.post(`/settings/model/update`, params);

    if (onVirtualMeasureApply != null) {
      onVirtualMeasureApply();
    }
  }

  return (
    <div>
      <Input
        addonBefore={"Поиск"}
        onChange={(e) => handleSearch(e.target.value)}
        style={{ paddingBottom: "20px" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingRight: "10px",
        }}
      >
        <Form.Item
          name={["model", "status"]}
          valuePropName="checked"
          label={t("modelOnOff")}
          labelAlign="left"
          style={{
            flex: "2",
          }}
          wrapperCol={{
            span: 2,
            offset: 1,
          }}
        >
          <Switch disabled={!isSuperAdmin} defaultChecked={ids?.state} onClick={handleChangeSwitch} />
        </Form.Item>
        <Form.Item label={t("setAll")}>
          <Checkbox
            indeterminate={indeterminate}
            checked={checkedAll}
            onChange={handleCheckAll}
          />
        </Form.Item>
      </div>
      <div
        style={{
          overflowY: "auto",
          height: "330px",
        }}
      >
        <CheckboxGroup
          value={checkedWellIds}
          onChange={handleChangeParameters}
          options={ids.wellsList}
        >
          {(Checkbox) => (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <CheckBoxes Checkbox={Checkbox} parametersData={parametersData} />
            </div>
          )}
        </CheckboxGroup>
      </div>
      <Form.Item
        style={{
          marginTop: "5%",
        }}
        {...tailLayout}
      >
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => handleClickVirtualModal(ids.alias, checkedSwitch)}
          style={{
            backgroundColor: buttonText === t("apply") ? "" : "lightseagreen",
          }}
        >
          {buttonText}
        </Button>
      </Form.Item>
    </div>
  );
};

export default VirtualMeasureSettings;
