/* eslint-disable */

const processData = (raw) => {
  if (!raw) return [];

  const calcNRH = structuredClone(raw.calcNRH);
  const factoryNRH = structuredClone(raw.factoryNRH);
  const workingPoint = { value: raw.workPointNRH.h, type: "work" };

  const map = new Map();

  calcNRH.forEach(d => {
    const point = {
      value: d.h,
      type: "calc"
    }
    const prev = map.get(d.q);
    if (prev) prev.push(point);
    else map.set(d.q, [point]);
  });

  factoryNRH.forEach(d => {
    const point = {
      value: d.h,
      type: "factory"
    }
    const prev = map.get(d.q);
    if (prev) prev.push(point);
    else map.set(d.q, [point]);
  });

  const mapWork = map.get(raw.workPointNRH.q);
  if (mapWork) mapWork.push(workingPoint);
  else map.set(raw.workPointNRH.q, [workingPoint]);

  const sortedMap = new Map([...map]
    .filter(entrie => entrie[0] != null)
    .sort((a, b) => a[0] - b[0]));

  const values = new Array(3);
  for (let index = 0; index < values.length; index++) {
    values[index] = new Array(sortedMap.size).fill(null);
  }

  const timestamps = new Array(sortedMap.size);

  let timestampIndex = 0;
  for (const entrie of sortedMap) {
    const q = entrie[0];
    const points = entrie[1];

    timestamps[timestampIndex] = q;

    for (const point of points) {
      if (point.type === "calc") {
        values[0][timestampIndex] = point.value;
      } else if (point.type === "factory") {
        values[1][timestampIndex] = point.value;
      } else if (point.type === "work") {
        values[2][timestampIndex] = point.value;
      }
    }

    timestampIndex++;
  }

  const processedData = [
    timestamps,
    ...values
  ];

  return processedData;
}

export default processData;
