/* eslint-disable */
import { useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import Draggable from "react-draggable";
import { Button, Typography } from "antd";
import Modal from "components/Modal";
import CustomInputNumber from "formAdapters/InputNumber";
import { useTranslation } from "react-i18next";
import CustomSwitch from "formAdapters/Switch";
import CustomColorPicker from "formAdapters/ColorPicker";

const FlexComponent = ({ label, children }) => (
  <div
    style={{
      display: "flex",
      gap: "8px",
      alignItems: "center"
    }}
  >
    <span>{label}</span>
    {children}
  </div>
);

const FlexBlock = ({ children }) => (
  <div
    style={{
      flexBasis: "50%",
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      marginBottom: "8px"
    }}
  >
    {children}
  </div>
);

//  styles: {
//    common: {
//      width: number
//      border: {
//        show: boolean
//        color: string (hex)
//        thickness: number
//        radius: number
//      }
//    }
//    header: {
//      show: boolean
//      fontSize: number
//      color: {
//        background: string (hex)
//        font: string (hex)
//      }
//    }
//    parameters: {
//      fontSize: number
//      evenColor: {
//        background: string (hex)
//        font: string (hex)
//      }
//      notEvenColor: {
//        background: string (hex)
//        font: string (hex)
//      }
//    }
//  }

const EditStyleModal = ({
  visible,
  styles,
  closeModal,
  onSave
}) => {

  const { t } = useTranslation();

  const [disabled, setDisabled] = useState(true);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y + 48,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const handleClose = (form) => {
    form.reset();
    closeModal();
  }

  const draggleRef = useRef(null);

  const handleSave = async (values) => {
    return await onSave(values);
  }

  const renderTitle = (title) => (
    <Typography.Title
      level={4}
      style={{ margin: "0px", marginBottom: "8px" }}
    >
      {title}
    </Typography.Title>
  );

  const renderSubTitle = (title) => (
    <Typography.Title
      level={5}
      style={{ margin: "0px" }}
    >
      {title}
    </Typography.Title>
  );

  return (
    <Form
      onSubmit={handleSave}
      initialValues={styles}
      initialValuesEqual={(a, b) => {
        return JSON.stringify(a) === JSON.stringify(b);
      }}
      render={({
        handleSubmit,
        submitting,
        form,
        pristine,
        submitSucceeded,
        submitErrors
      }) => (
        <Modal
          bodyStyle={{
            width: "600px",
            maxHeight: "calc(100vh - 220px)",
            overflowY: "auto",
            overflowX: "hidden"
          }}
          style={{
            zIndex: 10000000000
          }}
          title={
            <div
              style={{
                width: '100%',
                cursor: 'grab',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
            >
              {t("editTableStyles")}
            </div>
          }
          destroyOnClose
          visible={visible}
          onCancel={() => handleClose(form)}
          className="trends-modal"
          width="auto"
          footer={
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
              {submitErrors &&
                <span style={{ color: "red" }}>{t("errorHasOccured")}</span>
              }
              <Button
                key="submit"
                type="primary"
                onClick={handleSubmit}
                loading={submitting}
                disabled={submitting}
                style={{
                  marginLeft: "auto",
                  backgroundColor:
                    submitSucceeded && pristine ? "lightseagreen" : "#40a9ff",
                }}
              >
                {submitSucceeded && pristine ? t("applied") : t("apply")}
              </Button>
            </div>
          }
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "18px",
              padding: "12px"
            }}
          >
            <div>
              {renderTitle("Общие")}
              <div style={{ display: "flex" }}>
                <FlexBlock>
                  {renderSubTitle("Размер таблицы")}
                  <FlexComponent label={"Ширина"}>
                    <Field
                      name="common.width"
                      component={CustomInputNumber}
                      type="input"
                      size={"small"}
                      min={0}
                    />
                  </FlexComponent>
                </FlexBlock>

                <FlexBlock>
                  {renderSubTitle("Границы таблицы")}
                  <FlexComponent label={"Отображение границ"}>
                    <Field
                      name="common.border.show"
                      component={CustomSwitch}
                      type="switch"
                      size={"small"}
                    />
                  </FlexComponent>
                  <FlexComponent label={"Цвет границ"}>
                    <Field
                      name="common.border.color"
                      component={CustomColorPicker}
                      validate={(value) => value?.length !== 7}
                    />
                  </FlexComponent>
                  <FlexComponent label={"Толщина границ"}>
                    <Field
                      name="common.border.thickness"
                      component={CustomInputNumber}
                      type="input"
                      size={"small"}
                      min={0}
                    />
                  </FlexComponent>
                  <FlexComponent label={"Радиус границ"}>
                    <Field
                      name="common.border.radius"
                      component={CustomInputNumber}
                      type="input"
                      size={"small"}
                      min={0}
                    />
                  </FlexComponent>
                </FlexBlock>
              </div>
            </div>

            <div>
              {renderTitle("Заголовок")}
              <FlexComponent label={"Отображение заголовка"}>
                <Field
                  name="header.show"
                  component={CustomSwitch}
                  type="switch"
                  size={"small"}
                />
              </FlexComponent>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "8px"
                }}
              >
                <FlexBlock>
                  {renderSubTitle("Шрифт")}
                  <FlexComponent label={"Размер шрифта"}>
                    <Field
                      name="header.fontSize"
                      component={CustomInputNumber}
                      type="input"
                      size={"small"}
                      min={0}
                    />
                  </FlexComponent>
                </FlexBlock>

                <FlexBlock>
                  {renderSubTitle("Цвет")}
                  <FlexComponent label={"Цвет текста"}>
                    <Field
                      name="header.color.font"
                      component={CustomColorPicker}
                      validate={(value) => value?.length !== 7}
                    />
                  </FlexComponent>
                  <FlexComponent label={"Цвет фона"}>
                    <Field
                      name="header.color.background"
                      component={CustomColorPicker}
                      validate={(value) => value?.length !== 7}
                    />
                  </FlexComponent>
                </FlexBlock>
              </div>
            </div>

            <div>
              {renderTitle("Параметры")}
              <FlexBlock>
                {renderSubTitle("Шрифт")}
                <FlexComponent label={"Размер шрифта"}>
                  <Field
                    name="parameters.fontSize"
                    component={CustomInputNumber}
                    type="input"
                    size={"small"}
                    min={0}
                  />
                </FlexComponent>
                <FlexComponent label={"Высота строки"}>
                  <Field
                    name="parameters.lineHeight"
                    component={CustomInputNumber}
                    type="input"
                    size={"small"}
                    min={0}
                  />
                </FlexComponent>
              </FlexBlock>

              <FlexBlock>
                {renderSubTitle("Цвет")}
                <div
                  style={{
                    display: "flex",
                    gap: "32px"
                  }}
                >
                  <FlexBlock>
                    <span style={{ fontWeight: "700" }}>Четные строки</span>
                    <FlexComponent label={"Цвет текста"}>
                      <Field
                        name="parameters.evenColor.font"
                        component={CustomColorPicker}
                        validate={(value) => value?.length !== 7}
                      />
                    </FlexComponent>
                    <FlexComponent label={"Цвет фона"}>
                      <Field
                        name="parameters.evenColor.background"
                        component={CustomColorPicker}
                        validate={(value) => value?.length !== 7}
                      />
                    </FlexComponent>
                  </FlexBlock>
                  <FlexBlock>
                  <span style={{ fontWeight: "700" }}>Нечетные строки</span>
                    <FlexComponent label={"Цвет текста"}>
                      <Field
                        name="parameters.notEvenColor.font"
                        component={CustomColorPicker}
                        validate={(value) => value?.length !== 7}
                      />
                    </FlexComponent>
                    <FlexComponent label={"Цвет фона"}>
                      <Field
                        name="parameters.notEvenColor.background"
                        component={CustomColorPicker}
                        validate={(value) => value?.length !== 7}
                      />
                    </FlexComponent>
                  </FlexBlock>
                </div>
              </FlexBlock>
            </div>
          </div>
        </Modal>
      )}
    />
  );
}

export default EditStyleModal;
