import useSWR from 'swr'
import request from 'utils/request'

const fetcher = (url, data) => request({ url, params: { ...data } })

const useRequest = url => {
  const { data, mutate } = useSWR(
    url,
    fetcher,
    { revalidateOnFocus: false },
  )

  return { data: data?.data, mutate }
}

export default useRequest
