import {Collapse} from "antd";
import DivergenceIndividualData from "./DivergenceIndividualData";
import React from "react";

const WellLines = ({wellsData, divergenceData, handleSetNewData, setDivergenceData}) => {
  const { Panel } = Collapse;
  const result = [];
  for (let i = 0; i < wellsData?.length; i++) {
    result.push(
      <Collapse accordion ghost>
        <Panel
          style={{
            display: "flex",
            textAlign: "Left",
            flexDirection: "column",
          }}
          key={`warning${wellsData[i]}`}
          header={wellsData[i]?.name}
        >
          <DivergenceIndividualData
            divergenceData={divergenceData[i]}
            handleSetNewData={handleSetNewData}
            setDivergenceData={setDivergenceData}
            index={i}
            divergenceDataFull={divergenceData}
          />
        </Panel>
      </Collapse>
    );
  }
  return result;
};

export default WellLines