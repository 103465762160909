/*eslint-disable*/

class Stack {
  #array = [];

  add(value) {
    this.#array.push(value);
  }

  pop() {
    return this.#array.pop();
  }

  isEmpty() {
    return this.#array.length === 0;
  }
}

export default Stack;
