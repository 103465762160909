/*eslint-disable*/
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Table } from "antd";
import { FieldTimeOutlined, FilterFilled, ReloadOutlined } from "@ant-design/icons";
import { useStore } from "hooks/useStore";
import useGetRequest from "hooks/useGetRequest";
import useTimeConverter from "hooks/useTimeConverter";
import AlarmDeviationAnalysisModal from "containers/Home/components/ActiveAlarms/components/AlarmDeviationAnalysisModal";
import Search from "components/Search";
import Tooltip from "components/Tooltip";
import { useTranslation } from "react-i18next";
import request from "utils/request";
import { alarmColors } from "./common";
import dayjs from "dayjs";
import dayjsDuration from "dayjs/plugin/duration";
import { ReactComponent as OptionsIcon } from "assets/images/optionsIcon.svg";
import { ReactComponent as AlertIcon } from "assets/images/exclamationMarkIcon.svg";
import "./styles.css";

dayjs.extend(dayjsDuration);

const ACTIVE_ALARMS_REALTIME = "ACTIVE_ALARMS_REALTIME";

const loadActiveAlarmsRealtime = () => {
  const state = localStorage.getItem(ACTIVE_ALARMS_REALTIME);
  if (state === "true") return true;
  return false;
}

const saveActiveAlarmsRealtime = (value) => {
  localStorage.setItem(ACTIVE_ALARMS_REALTIME, value);
}

const ActiveAlarms = observer(() => {

  const { wellStore } = useStore();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const fromUtcToLocale = useTimeConverter({
    toOffset: 0,
    fromOffsetDefault: new Date().getTimezoneOffset() / 60,
  });

  const {
    data: alarms,
    fetch: fetchAlarms,
    pending: isAlarmsLoading
  } = useGetRequest("/alarm/deviationanalysis/get/alarms");

  const sortedByPriorityAlarms = useMemo(() =>
    alarms
      ?.sort((a, b) => a.wellId < b.wellId ? 1 : -1)
      .sort((a, b) => b.isFirstPriorityWell ? 1 : -1),
    [alarms]);

  const [selectedAlarm, setSelectedAlarm] = useState(null);

  const { data: parameters, pending: isParametersLoading } = useGetRequest("/settings/trends/get/paramsList");

  const [searchValues, setSearchValues] = useState(
    { wellName: "", locationName: "", alarmName: "" }
  );

  const [isRealtimeEnabled, setIsRealtimeEnabled] = useState(loadActiveAlarmsRealtime());

  useEffect(() => {
    if (!isRealtimeEnabled) return;

    const refreshIntervalSeconds = 60;

    const timer = setInterval(() => {
      fetchAlarms();
    }, 1000 * refreshIntervalSeconds);

    return () => clearInterval(timer);
  }, [isRealtimeEnabled, sortedByPriorityAlarms]);

  const onWellClick = (wellId, locationId) => {
    wellStore.handleLocationClick(locationId);
    wellStore.setId(wellId);

    navigate("/analysis");
  }

  const onCell = useCallback((record) => {
    const props = {
      rowSpan: 0,
      style: {
        backgroundColor: "#fff",
      },
    }

    const recordIndex = sortedByPriorityAlarms
      .findIndex(alarm => alarm.id === record.id);

    if (recordIndex > 0) {
      if (sortedByPriorityAlarms.at(recordIndex - 1).wellId === record.wellId) {
        return props;
      }
    }

    for (let i = recordIndex; i < sortedByPriorityAlarms.length; i++) {
      if (sortedByPriorityAlarms.at(i).wellId === record.wellId) {
        props.rowSpan++;
      }
      else break;
    }

    return props;
  }, [sortedByPriorityAlarms]);

  const handleChangeRealtimeMode = () => {
    setIsRealtimeEnabled(prev => {
      const isActive = !prev;
      saveActiveAlarmsRealtime(isActive);
      return isActive;
    });
  }

  const columns = useMemo(() => [
    {
      title: t("well"),
      dataIndex: "wellName",
      key: "wellName",
      width: "11%",
      onCell,
      filteredValue: [searchValues.wellName],
      onFilter: (value, record) => record.wellName.toLowerCase()
        .includes(value.toLowerCase()),
      filterDropdown: (props) => {
        const handleChangeSearchValue = (value) => {
          setSearchValues(prev => ({ ...prev, wellName: value }));
        }

        return (
          <Search
            value={props.selectedKeys.at(0)}
            handleChangeValue={handleChangeSearchValue}
            fontSize="18px"
          />
        );
      },
      filterIcon: () => (
        <FilterFilled
          style={{
            color: searchValues.wellName !== "" ? "#1890ff" : "#bfbfbf"
          }}
        />
      ),
      render: (value, record) => (
        <a
          className="alarm-page-well-label"
          onClick={() => onWellClick(record.wellId, record.locationId)}
        >
          {record.isFirstPriorityWell &&
            <Tooltip tooltipText={t("fpw")}>
              <AlertIcon
                style={{
                  height: "18px",
                  width: "6px",
                  marginRight: "4px",
                }}
              />
            </Tooltip>
          }
          {value}
        </a>
      )
    },
    {
      title: t("deposit"),
      dataIndex: "locationName",
      key: "locationName",
      width: "11%",
      onCell,
      filteredValue: [searchValues.locationName],
      onFilter: (value, record) => record.locationName.toLowerCase()
        .includes(value.toLowerCase()),
      filterDropdown: (props) => {
        const handleChangeSearchValue = (value) => {
          setSearchValues(prev => ({ ...prev, locationName: value }));
        }

        return (
          <Search
            value={props.selectedKeys.at(0)}
            handleChangeValue={handleChangeSearchValue}
            fontSize="18px"
          />
        );
      },
      filterIcon: () => (
        <FilterFilled
          style={{
            color: searchValues.locationName !== "" ? "#1890ff" : "#bfbfbf"
          }}
        />
      ),
    },
    {
      title: t("cnt"),
      dataIndex: "alarmCount",
      key: "alarmCount",
      width: "120px",
      sorter: (a, b) => {
        if (a.isFirstPriorityWell || b.isFirstPriorityWell) return 0;
        if (a.alarmCount === b.alarmCount) return 0;
        return a.alarmCount > b.alarmCount ? 1 : -1;
      },
      showSorterTooltip: false,
      onCell,
      align: "center",
    },
    {
      title: t("dateAndTimeOfCreation"),
      dataIndex: "createdAt",
      key: "createdAt",
      width: "255px",
      render: (value) => {
        const formatted = dayjs(fromUtcToLocale(value))
          .format("DD.MM.YYYY HH:mm:ss");
        return formatted;
      }
    },
    {
      title: t("warningType"),
      dataIndex: "type",
      key: "type",
      width: "235px",
      render: (value) => {
        switch (value) {
          case "CUSTOM": {
            return "Пользовательский";
          }
          case "TREND": {
            return "Трендовый";
          }
          case "INSTANTANEOUS": {
            return "Анализ отклонений";
          }
          case "POTENTIAL": {
            return "Потенциальный";
          }
          default: {
            return value;
          }
        }
      }
    },
    {
      title: t("warningName"),
      dataIndex: "name",
      key: "name",
      filteredValue: [searchValues.alarmName],
      onFilter: (value, record) => record.name.toLowerCase()
        .includes(value.toLowerCase()),
      filterDropdown: (props) => {
        const handleChangeSearchValue = (value) => {
          setSearchValues(prev => ({ ...prev, alarmName: value }));
        }

        return (
          <Search
            value={props.selectedKeys.at(0)}
            handleChangeValue={handleChangeSearchValue}
            fontSize="18px"
          />
        );
      },
      filterIcon: () => (
        <FilterFilled
          style={{
            color: searchValues.alarmName !== "" ? "#1890ff" : "#bfbfbf"
          }}
        />
      ),
    },
    {
      title: t("parameter"),
      dataIndex: "parameterId",
      key: "parameterId",
      width: "295px",
      render: (value) => {
        if (parameters == null) return null;
        const parameter = parameters.find(param => param.id === value);
        if (parameter == null) return null;

        return (
          // <Observer>
          //   {() => translationStore.translateParameterAlias(parameter.alias)}
          // </Observer>
          <>{t("WATER_DEBIT")}</>
        );
      }
    },
    {
      title: (
        <div
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <ReloadOutlined
            onClick={(e) => fetchAlarms()}
            style={{
              color: isAlarmsLoading ? "#1890ff" : "#bfbfbf"
            }}
            spin={isAlarmsLoading}
          />
          <Tooltip
            tooltipText={"Вкл/Выкл режим реального времени"}
            placement="bottomRight"
          >
            <FieldTimeOutlined
              onClick={handleChangeRealtimeMode}
              style={{
                color: isRealtimeEnabled ? "#1890ff" : "#bfbfbf"
              }}
            />
          </Tooltip>
        </div>
      ),
      key: "operations",
      width: "72px",
      align: "center",
      render: (_, record) => record.isAccessed ?
        <OptionsIcon
          onClick={() => setSelectedAlarm(record)}
          height={18}
        /> : null
    }
  ], [onCell, parameters, searchValues, t, isRealtimeEnabled, isAlarmsLoading]);

  const handleCloseDeviationAnalysisAlarm = async (reasons) => {
    await request.post(
      `/alarm/deviationanalysis/update/${selectedAlarm.wellId}`,
      reasons
    );

    await fetchAlarms();
  }

  const getAlarmColorSaturation = (alarmCreatedAt, seconds) => {
    const now = dayjs();

    if (now.isBefore(alarmCreatedAt.add(seconds, "seconds"))) {
      return 0;
    } else if (now.isBefore(alarmCreatedAt.add(2 * seconds, "seconds"))) {
      return 1;
    } else {
      return 2;
    }
  }

  const onRow = (data) => {
    const increaseOrDecrease =
      data.trendDebitOil === 1 ? "increase"
        : data.trendDebitOil === 2 ? "decrease" : null;

    if (increaseOrDecrease == null) return null;

    const createdAt = dayjs(fromUtcToLocale(data.createdAt));

    const delay = Math.floor(data.delaySubmitTimestamp / 3);

    const saturation = getAlarmColorSaturation(createdAt, delay);

    const color = alarmColors[increaseOrDecrease][saturation];
    const isBlinking = data.isFirstPriorityWell;

    return {
      className: isBlinking && (increaseOrDecrease === "decrease") ?
        "active-alarms-table-blinking" : null,
      style: {
        backgroundColor: color,
      },
    }
  }

  return (
    <>
      <div className="alarm-table-container">
        <Table
          dataSource={sortedByPriorityAlarms}
          columns={columns}
          loading={isAlarmsLoading || isParametersLoading}
          rowKey={"id"}
          onRow={onRow}
          className="alarm-table"
          pagination={false}
          bordered
          sticky
        />
      </div>
      <AlarmDeviationAnalysisModal
        visible={selectedAlarm != null}
        closeModal={() => setSelectedAlarm(null)}
        data={selectedAlarm}
        handleCloseDeviationAnalysisAlarm={handleCloseDeviationAnalysisAlarm}
      />
    </>
  );
});

export default ActiveAlarms;
