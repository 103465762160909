export const indexArray = (array, parentId = null) => {
  let index = {}

  array.forEach(item => {
    index[item.id] = {
      ...item,
      parentId,
    }

    if (item.locations.length !== 0) {
      const indexedChildren = indexArray(item.locations, item.id)

      index = { ...index, ...indexedChildren }
    }
  })

  return index
}

export const getHierarchy = (itemId, index, parents = []) => {
  const item = index[itemId]

  if (item.parentId !== null) {
    getHierarchy(item.parentId, index, parents)
  }

  parents.push(item.name)

  return parents
}
