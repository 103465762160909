/* eslint-disable */
import { makeAutoObservable } from "mobx";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

class Trends {
  activeTrends = null;

  activeTrendsWithPoints = [];

  periodLabelBeforeZoom = 1;

  period = null;

  constructor() {
    makeAutoObservable(this);

    const periodStringFromLS = localStorage.getItem("TRENDS_PERIOD") ?? null;
    const periodFromLS = periodStringFromLS ? JSON.parse(periodStringFromLS) : null;

    if (periodFromLS) {
      this.setPeriod(periodFromLS);
    } else {
      this.period = {
        startDate: dayjs().subtract(1, "hour").format("YYYY-MM-DDTHH:mm:ss"),
        endDate: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
      }
    }
  }

  setActiveTrends(trends) {
    this.activeTrends = trends;
  }

  setActiveTrendsWithPoints(trends) {
    this.activeTrendsWithPoints = trends;
  }

  setPeriod(period) {
    this.period = {
      startDate: period.startDate ? dayjs(period.startDate).format("YYYY-MM-DDTHH:mm:ss") : null,
      endDate: period.endDate ? dayjs(period.endDate).format("YYYY-MM-DDTHH:mm:ss") : null,
    };
    localStorage.setItem("TRENDS_PERIOD", JSON.stringify(period));
  }

  setPeriodLabelBeforeZoom(label) {
    this.periodLabelBeforeZoom = label;
  }
}

export default Trends;
