/* eslint-disable */
import "./styles.css";

const NRHChartLegend = ({ calc, factory, work }) => {

  const getSerieLegend = (serie) => (
    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
      <div style={{
        width: 35,
        borderTop: `2px ${serie.dash != undefined ?
          "dashed" : "solid"} ${serie.color}`
      }} />
      {serie.name}
    </div>
  );

  const getPointLegend = (point) => (
    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
      <div style={{
        width: 10,
        height: 10,
        borderRadius: "50%",
        border: `2px solid ${point.stroke}`,
        backgroundColor: point.fill
      }} />
      {point.name}
    </div>
  );

  return (
    <div className="nrh-chart-legend">
      <div>
        {getSerieLegend(calc)}
        {getSerieLegend(factory)}
      </div>
      <div style={{ marginLeft: 43 }}>
        {getPointLegend(work)}
        <div style={{ height: 18 }} />
      </div>
    </div>
  );
}

export default NRHChartLegend;
