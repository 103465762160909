/* eslint-disable */

const useNRHTooltip = ({ setData }) => {
  const tooltipPlugin = () => {
    const setCursor = (u) => {
      const { idx } = u.cursor;

      const q = u.data[0][idx];

      // притянуть point к точке
      const left = Math.round(u.valToPos(q, "x"));

      // в series[0] хранится пустой объект, не нужен он

      const calcH = u.data[1]?.[idx] ?? null;
      const factoryH = u.data[2]?.[idx] ?? null;
      const workH = u.data[3]?.[idx] ?? null;

      const newData = {
        calc: {
          left,
          top: u.valToPos(calcH, "y"),
          q,
          h: calcH
        },
        factory: {
          left,
          top: u.valToPos(factoryH, "y"),
          q,
          h: factoryH
        },
        work: {
          left,
          top: u.valToPos(workH, "y"),
          q,
          h: workH
        }
      };

      setData(newData);
    };

    return {
      hooks: {
        setCursor,
      },
    };
  };
  return tooltipPlugin;
};
export default useNRHTooltip;
