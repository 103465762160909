/* eslint-disable */
import { mutate } from "swr";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useStore } from "hooks/useStore";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal";
import Button from "components/Button";
import request from "utils/request";
import "./styles.css";

const DeletePriorityModal = observer(
  ({
    visible,
    closePriority,
    wellId,
    reasonId,
    wellName,
    setAddStatus,
    modalInfo,
  }) => {
    const { wellStore, accessStore } = useStore();
    const { t } = useTranslation();

    const deletePriority = async () => {
      if (accessStore.checkPermission("add_first_priority_well")) {
        await request({
          url: "/well/reason",
          method: "DELETE",
          data: {
            wellId,
            reasonId,
          },
        });
  
        setAddStatus();
  
        await request.post(`/alarm/operationalRecords/set/${wellStore.id}`, {
          source: 1,
          wellId: wellStore.id,
          comment: null,
          creatorId: 1,
          active: 0,
        });
  
        mutate(`/alarm/operationalRecords/get/${wellStore.id}`);
        wellStore.resetIsAlarmsReload();
      }
    };

    return (
      <Modal
        open={visible}
        titleText={wellName}
        width={272}
        className="add-priority-modal"
        onCancel={closePriority}
        submitButton={
          <Button
            text={t("apply")}
            onClick={() => {
              deletePriority();
              closePriority();
            }}
          />
        }
        destroyOnClose
      >
        <div className="delete-priority-modal">
          <div className="delete-warning">{t("deleteWellFirstPriority")}</div>
          <div className="dele">
            {t("added")}:{" "}
            {dayjs(modalInfo?.createdAt).format("DD.MM.YYYY HH:mm")}
          </div>
          <div>
            {t("reason")}: {modalInfo?.reason?.name}
          </div>
          <div>
            {t("comment")}: {modalInfo?.comment}
          </div>
        </div>
      </Modal>
    );
  }
);

export default DeletePriorityModal;
