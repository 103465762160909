/*eslint-disable*/

import { useCallback, useRef } from "react";

const useDebounce = (callback, delay = 250) => {
  const timeout = useRef(null);

  const debouncedCallback = useCallback((...args) => {

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(
      () => callback(...args),
      delay);

  }, [callback, delay]);

  return debouncedCallback;
}

export default useDebounce;
