/* eslint-disable */
import { observer } from "mobx-react-lite";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import HeaderCommon from "layouts/common/HeaderCommon";
import "../common/styles.css";

const DeviationAnalysisLayout = observer(({
  activeTab,
  handleChangeActiveTab,
  deviationTabs,
  children
}) => {

  const activeStyles = {
    background: "rgba(75, 126, 254, 0.16)",
    color: "#4b7efe"
  }

  return (
    <>
      <header>
        <div
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            marginLeft: "24px"
          }}
        >
          <Dropdown
            menu={{
              items: deviationTabs,
              selectable: true,
              selectedKeys: [activeTab.subTabKey],
              onClick: (info) => handleChangeActiveTab(
                { name: "deviation", subTabKey: info.key }
              )
            }}
            trigger={["click"]}
          >
            <Button
              style={activeTab.name === "deviation" ? activeStyles : {}}
            >
              Отклонения <DownOutlined />
            </Button>
          </Dropdown>

          <Button
            onClick={() => handleChangeActiveTab(
              { name: "activeAlarms" }
            )}
            style={activeTab.name === "activeAlarms" ? activeStyles : {}}
          >
            Активные предупреждения
          </Button>

          <Button
            onClick={() => handleChangeActiveTab(
              { name: "archiveAlarms" }
            )}
            style={activeTab.name === "archiveAlarms" ? activeStyles : {}}
          >
            Архив предупреждений
          </Button>
        </div>

        <HeaderCommon />
      </header>
      <div className="main-layout">{children}</div>
    </>
  );
});

export default DeviationAnalysisLayout;
