/* eslint-disable */

class RealtimeConnection {
  constructor(path, userId) {
    let websocketProtocol =
      window.location.protocol == "http:" ? "ws://" : "wss://";
    if (
      process.env.REACT_APP_ADDRESS_HOST_AS_CLIENT === "true" &&
      process.env.REACT_APP_DYNAMIC_PROTOCOL === "false"
    )
      this.socket = new WebSocket(
        websocketProtocol +
          `${window.location.hostname}` +
          ":8080" +
          process.env.REACT_APP_API_PREFIX +
          path
      ); // address = 'http://192.168.1.53:8080/api'
    if (
      process.env.REACT_APP_ADDRESS_HOST_AS_CLIENT === "true" &&
      process.env.REACT_APP_DYNAMIC_PROTOCOL === "true"
    )
      this.socket = new WebSocket(
        websocketProtocol +
          `${window.location.host}` +
          process.env.REACT_APP_API_PREFIX +
          path
      ); // address = 'http://192.168.1.53:8080/api'
    if (
      process.env.REACT_APP_ADDRESS_HOST_AS_CLIENT === "false" &&
      process.env.REACT_APP_DYNAMIC_PROTOCOL === "true"
    )
      this.socket = new WebSocket(
        websocketProtocol +
          process.env.REACT_APP_API_URL_HOST +
          ":8080" +
          process.env.REACT_APP_API_PREFIX +
          path
      ); // address = `${window.location.protocol}//${window.location.host}/api`
    if (
      process.env.REACT_APP_ADDRESS_HOST_AS_CLIENT === "false" &&
      process.env.REACT_APP_DYNAMIC_PROTOCOL === "false"
    )
      this.socket = new WebSocket(
        websocketProtocol +
          process.env.REACT_APP_API_URL_HOST +
          ":8080" +
          process.env.REACT_APP_API_PREFIX +
          path
      ); // address = `${window.location.protocol}//${window.location.host}/api`
    this.currentWellId = null;
    this.userId = userId;
    this.socket.onmessage = (data) => this.setValue(data);
    this.values = {};
    this.callbacks = {};
    this.parameters = null;
    this.currentWellIds = [];
  }

  start = ({ wellId, wellIds }) => {
    const sendStartMessage = () => {
      this.socket.send(
        JSON.stringify({
          action: "OPEN",
          userId: this.userId,
          wellId: this.currentWellId,
          wellIds: this.currentWellIds,
        })
      );
    };

    this.currentWellId = wellId;
    this.currentWellIds = wellIds;

    return new Promise((resolve) => {
      if (this.socket.readyState === 0) {
        this.socket.addEventListener("open", () => {
          sendStartMessage();
          resolve(true);
        });
      } else {
        sendStartMessage();
        resolve(true);
      }
    });
  };

  end = () => {
    if (this.socket.readyState !== 1) return;
    this.socket.send(
      JSON.stringify({
        action: "CLOSE",
        userId: this.userId,
        wellId: this.currentWellId,
      })
    );
    this.values = {};
    this.callbacks = {};
    this.callbackObservables = new WeakMap();
    this.currentWellId = null;
  };

  listen = (paramIds) => {
    this.parameters = paramIds;
    const sendListenMessage = () => {
      this.socket.send(
        JSON.stringify({
          action: "LISTEN",
          userId: this.userId,
          wellId: this.currentWellId,
          parameters: paramIds,
          wellIds: this.currentWellIds,
        })
      );
    };

    const newValues = {};
    Object.keys(this.values)
      .filter((i) => i in paramIds)
      .map((i) => (newValues[i] = this.values[i]));
    this.values = newValues;
    const newCallbacks = {};
    Object.keys(this.callbacks)
      .filter((i) => paramIds.includes(i))
      .map((i) => (newCallbacks[i] = this.callbacks[i]));
    this.callbacks = newCallbacks;

    sendListenMessage();
  };

  setValue = (message) => {
    const contents = JSON.parse(message.data);
    const newValue = {
      id: contents.parameter,
      ts: contents.time,
      value: contents.data,
      pointNumber: contents.pointNumber,
      q: contents.q,
      h: contents.h,
      type: contents.type,
      wellId: contents.wellId,
    };
    this.values = Object.assign({}, this.values, {
      [contents.parameter]: newValue,
    });
    this.callbacks[contents.parameter]?.forEach((cb) => cb(newValue));
  };

  subscribe = (paramIds, f) => {
    const newSubscribers = {};
    Array.isArray(paramIds)
      ? paramIds.forEach(
          (id) => (newSubscribers[id] = this.callbacks[id]?.concat(f) || [f])
        )
      : (newSubscribers[paramIds] = this.callbacks[paramIds]?.concat(f) || [f]);
    this.callbacks = Object.assign({}, this.callbacks, newSubscribers);
  };

  unsubscribe = (f, paramIds = null) => {
    if (paramIds) {
      const updates = {};
      paramIds.forEach(
        (id) =>
          this.callbacks[id] &&
          (updates[id] = this.callbacks[id].filter((cb) => cb !== f))
      );
      this.callbacks = Object.assign({}, this.callbacks, updates);
    } else {
      Object.keys(this.callbacks).forEach(
        (id) =>
          (this.callbacks[id] = this.callbacks[id].filter((cb) => cb !== f))
      );
    }
  };
}

export default RealtimeConnection;
