/* eslint-disable */
import { useTranslation } from "react-i18next";
import "./styles.css";

const NRHChartAxisLabels = ({ containerWidth, containerHeight }) => {

  const { t } = useTranslation();

  const QPosition = {
    left: containerWidth - 68,
    top: containerHeight - 90
  }

  return (
    <div className="nrh-chart-axis-labels">
      <div
        style={{
          left: 15,
          top: -5
        }}
      >
        H,{t("shortMinutes")}
      </div>

      <div style={{ ...QPosition }}>
        Q,{t("m3/day")}
      </div>
    </div>
  );
}

export default NRHChartAxisLabels;
