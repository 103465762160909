/* eslint-disable */

export const getIntersectionOfLineOnBorder = (point1, point2, borderDate) => {
  if (point1.value === point2.value) {
    // horizontal line
    return {
      date: borderDate.format("YYYY-MM-DDTHH:mm:ss"),
      value: point1.value,
    };
  }

  const deltaX =
    new Date(point2.date).getTime() - new Date(point1.date).getTime();
  const deltaY = point2.value - point1.value;
  const deltaBorderTime =
    new Date(borderDate).getTime() - new Date(point1.date).getTime();

  const y = point1.value + (deltaY / deltaX) * deltaBorderTime;

  const point = {
    date: borderDate.format("YYYY-MM-DDTHH:mm:ss"),
    value: y,
  };

  return point;
};