/* eslint-disable */
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "hooks/useStore";
import { useTranslation } from "react-i18next";
import { ParametersColors } from "containers/Home/components/Trends/parametersColors";
import TrendInfoValue from "./components/TrendInfoValue";
import Tooltip from "components/Tooltip";
import "./styles.css";

const TrendsInfo = observer(({
  expandScreenMode,
  paramsList,
  realtimeSocket,
  activeParams,
  onParameterHover,
}) => {

  const { wellStore, trendsStore, translationStore } = useStore();
  const [realtimeValues, setRealtimeValues] = useState({});
  const [realtimeUpdatedData, setRealtimeUpdatedData] = useState();
  //const { data } = useRequest(wellStore.id ? `/chart/${wellStore.id}/parameters` : null)

  const { t } = useTranslation();

  useEffect(() => {
    if (!paramsList) return;
    if (paramsList == undefined) return;

    const newRealtimeUpdatedData = {};
    paramsList.forEach((element) => {
      newRealtimeUpdatedData[element?.alias] = false;
    });

    setRealtimeUpdatedData(newRealtimeUpdatedData);
  }, [paramsList]);

  const realtimeListener = (data) => {
    const newValue = {};
    newValue[data.id] = {
      value: data.value,
      ts: data.ts,
    };

    setRealtimeValues((oldValues) => {
      // Если к realtimeValues обращаться напрямую, то значение всегда {}, а не то, что реально в нее записали
      if (oldValues.hasOwnProperty(data.id)) {
        if (oldValues[data.id].ts != data.ts) {
          const newBlink = {};
          newBlink[data.id] = true;

          setRealtimeUpdatedData((oldValues) =>
            Object.assign({}, oldValues, newBlink)
          );

          setTimeout(() => {
            setRealtimeUpdatedData((oldValues) => {
              const copy = { ...oldValues };
              copy[data.id] = false;
              return Object.assign({}, copy);
            });
          }, 1000);
        }
      }

      return Object.assign({}, oldValues, newValue);
    });
  }

  useEffect(() => {
    if (!activeParams || !realtimeSocket) return;

    const relevantParamIds = activeParams.map((trend) => trend.alias);
    realtimeSocket.subscribe(relevantParamIds, realtimeListener);

    return () => realtimeSocket.unsubscribe(realtimeListener);
  }, [activeParams, wellStore?.id, realtimeSocket]);

  useEffect(() => {
    setRealtimeValues({});
  }, [wellStore?.id, paramsList]);

  const handleTrendClick = (trendId) => {
    if (!trendsStore.activeTrendsWithPoints.find((id) => id === trendId))
      return;

    const existingTrend = trendsStore.activeTrends?.find(
      (id) => id === trendId
    );

    if (existingTrend) {
      const newActiveTrends = trendsStore.activeTrends?.filter(
        (id) => id !== trendId
      );

      trendsStore.setActiveTrends(newActiveTrends);
    } else {
      const newActiveTrends = trendsStore.activeTrends?.slice();

      newActiveTrends.push(trendId);

      trendsStore.setActiveTrends(newActiveTrends);
    }
  }

  const iconStyle = (trendId) => {
    const isActive = trendsStore.activeTrends?.find((id) => id === trendId);
    const havePoints = trendsStore.activeTrendsWithPoints.find(
      (id) => id === trendId
    );

    if (isActive && havePoints) {
      return {
        backgroundColor:
          ParametersColors[trendId % ParametersColors.colorsAmount],
        height: "6px",
      };
    }

    return {
      backgroundColor: "#ECECEC",
      height: "2px",
    };
  }

  useEffect(() => {
    if (!paramsList) return;
    const activeTrends = paramsList.filter((trend) => trend.active === true);
    const activeTrendsIds = activeTrends.map((activeTrend) => activeTrend.id);

    trendsStore.setActiveTrends(activeTrendsIds);
  }, [paramsList]);

  if (expandScreenMode) {
    return null;
  }

  const handleParameterHover = (trendId) => {
    const isActive = trendsStore.activeTrends?.find((id) => id === trendId);
    const havePoints = trendsStore.activeTrendsWithPoints.find(
      (id) => id === trendId
    );

    if (isActive && havePoints) {
      onParameterHover(trendId);
    }
  }

  return (
    <div className="trends-info">
      <div className={`trends-info-row`}>
        <Tooltip placement="topLeft" title={t("parameter")}>
          <div
            className="widthTrend70"
            style={{
              paddingLeft: "15px",
              fontSize: "12px",
              color: "#6b6c70",
              fontFamily: 'Roboto Condensed',
            }}
          >
            {t("parameter")}
          </div>
        </Tooltip>
        <Tooltip placement="top" title={t("lastActualValue")}>
          <div
            className="widthTrend30"
            style={{ fontSize: "12px", color: "#6b6c70", fontFamily: 'Roboto Condensed', }}
          >
            {t("value")}
          </div>
        </Tooltip>
      </div>
      {paramsList?.map((trend) => (
        <div
          key={trend.id}
          className={`trends-info-row ${trend.active && "active"}`}
          onClick={() => handleTrendClick(trend.id)}
          onMouseOver={() => handleParameterHover(trend.id)}
          onMouseLeave={() => onParameterHover(null)}
        >
          <div className="widthTrend70">
            <div className="name-with-icon">
              {trend.active && (
                <div className="scale-icon" style={iconStyle(trend.id)} />
              )}
              <div>
                {translationStore.translateParameterAlias(trend.alias)}
              </div>
            </div>
          </div>
          {trend.active && (
            <TrendInfoValue
              value={realtimeValues[trend.alias]?.value}
              ts={realtimeValues[trend.alias]?.ts}
              isBlinking={realtimeUpdatedData?.[`${trend.alias}`]}
              unitId={trend.unitId}
            />
          )}
        </div>
      ))}
    </div>
  );
}
);

export default TrendsInfo;
