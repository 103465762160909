/* eslint-disable */
import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Typography,
  InputNumber,
  Space,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import Modal from "components/Modal";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ConfirmationModal from "./ConfirmationModal";
import "./styles.css";

const tailLayout = {
  wrapperCol: { offset: 10, span: 16 },
};

const dateFormat = "YYYY-MM-DD";
const { TextArea } = Input;

const AlarmInfoModal = ({
  visible,
  closeModal,
  data,
  wellName,
  well_id,
  closeWork,
  doneWork,
  handleCloseObservation,
  handleDoneObservation,
  sendNewActionsRequest,
  handleCloseAlarm,
  sendNewObservationRequest,
}) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isObserve, setIsObserve] = useState(false);
  const [isInWork, setIsInWork] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const storageTranslationsActions = JSON.parse(
    sessionStorage.getItem("translationsActions")
  );
  const storageTranslationsWorks = JSON.parse(
    sessionStorage.getItem("translationsWorks")
  );
  const storageTranslationsWorkReasons = JSON.parse(
    sessionStorage.getItem("translationsWorkReasons")
  );
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [confirmationModalData, setConfirmationModalData] = useState(null);
  const [checkedWorkId, setCheckedWorkId] = useState(null);
  const [form] = Form.useForm();
  const [myData, setMyData] = useState(data);
  const [select, setSelect] = useState(null);

  useEffect(() => {
    setMyData(data);
    form.setFieldsValue({ chosenWorks: null });
    form.setFieldsValue({ action: select });
    form.setFieldsValue({ comment: null });
    form.setFieldsValue({ observedHours: null });
  });

  useEffect(() => {
    setIsObserve(false);
    setIsInWork(false);
    setIsClose(false);
    form.setFieldsValue({ action: null });
    form.setFieldsValue({ comment: null });
    form.setFieldsValue({ observedHours: null });
  }, []);

  const prepareWorkDataForConfirmationModal = (
    workId,
    comment,
    item,
    methodType,
    index
  ) => {
    const workData = {
      alarmId: data.id,
      id: workId,
      workComment: comment,
      workName:
        storageTranslationsWorks[currentLanguage][item?.workId - 1]
          ?.translation,
      type: "work",
      method: methodType,
    };

    setCheckedWorkId(index);
    setConfirmationModalData(workData);
    setIsConfirmationModalVisible(true);
  };

  const prepareObserveDataForConfirmationModal = (
    observationId,
    comment,
    titleName,
    methodType
  ) => {
    const observeData = {
      alarmId: data.id,
      id: observationId,
      workComment: comment,
      workName: titleName,
      type: "observe",
      method: methodType,
    };

    setConfirmationModalData(observeData);
    if (data?.selectedActions?.selectedObservations.length)
      setIsConfirmationModalVisible(true);
  };

  const getDataFromConfirmationModal = (data1) => {
    data1.alarmId = data.id;

    if (data1.method === "done" && data1.type === "observe") {
      data?.selectedActions?.selectedObservations.shift();
      form.setFieldsValue({
        observedHours:
          data?.selectedActions?.selectedObservations[0]?.observationHours,
      });
      form.setFieldsValue({
        commentObserve: data?.selectedActions?.selectedObservations[0]?.comment,
      });
      handleDoneObservation(data1.alarmId, data1.id, data1.commentFinal);
    }

    if (data1.method === "done" && data1.type === "work") {
      setMyData(
        myData?.selectedActions?.selectedWorks.splice(checkedWorkId, 1)
      );
      doneWork(data1.alarmId, data1.id, data1.commentFinal);
    }

    if (data1.method === "close" && data1.type === "observe") {
      data?.selectedActions?.selectedObservations.shift();
      form.setFieldsValue({
        observedHours:
          data?.selectedActions?.selectedObservations[0]?.observationHours,
      });
      form.setFieldsValue({
        commentObserve: data?.selectedActions?.selectedObservations[0]?.comment,
      });
      handleCloseObservation(data1.alarmId, data1.id, data1.commentFinal);
    }

    if (data1.method === "close" && data1.type === "work") {
      setMyData(
        myData?.selectedActions?.selectedWorks.splice(checkedWorkId, 1)
      );
      closeWork(data1.alarmId, data1.id, data1.commentFinal);
    }
  };

  const onFinish = (values) => {
    const result = {
      userId: 1,
      actionId: null,
      reasonId: null,
      comment: null,
      observedHours: null,
      wellId: well_id,
      chosenWorks: [], // {workId: workId, comment: comment}
    };

    result.actionId = values?.action;
    result.observedHours = values?.observedHours;
    result.reasonId = values?.reason;
    result.comment = values?.comment;
    result.chosenWorks = values?.chosenWorks;
    result.alarmId = data?.id;

    if (isClose) {
      if (result.comment !== null) {
        handleCloseAlarm(result.alarmId, result.comment);
      }
    }

    if (isInWork) {
      if (result?.chosenWorks?.length > 0) {
        sendNewActionsRequest(result);
      }
    }

    if (isObserve) {
      if (
        result.observedHours !== null &&
        result.observedHours !== undefined &&
        result.comment !== null
      ) {
        sendNewObservationRequest(result);
      }
    }

    setMyData(null);
    setSelect(null);
    setIsObserve(false);
    setIsInWork(false);
    setIsClose(false);
    closeModal();
  };

  const isRemainTime = (observationItem) => {
    const dateCreation = new Date(observationItem.creationDate);

    let dateNow = new Date();

    dateNow = Date.now();

    // QURNA FIX
    // const offsetHours = 0; // (new Date().getTimezoneOffset() * -1) / 60;

    // UTC TIME FIX
    const offsetHours = (new Date().getTimezoneOffset() * -1) / 60;

    const remainTime =
      dateCreation.getTime() +
      observationItem?.observationHours * 3600 * 1000 -
      dateNow +
      offsetHours * 3600 * 1000;

    if (remainTime > 0) {
      return true;
    }

    return false;
  };

  const calculateRemainObservationTime = (observationItem) => {
    const dateCreation = new Date(observationItem.creationDate);

    let dateNow = new Date();

    dateNow = Date.now();

    // QURNA FIX
    // const offsetHours = 0; // (new Date().getTimezoneOffset() * -1) / 60;

    // UTC TIME FIX
    const offsetHours = (new Date().getTimezoneOffset() * -1) / 60;

    const remainTime =
      dateCreation.getTime() +
      observationItem?.observationHours * 3600 * 1000 -
      dateNow +
      offsetHours * 3600 * 1000;

    const remainTimeInHoursAndMinutes = `${Math.floor(
      new Date(remainTime).getTime() / 3600000
    )}:${new Date(remainTime).getMinutes()}`;

    return remainTimeInHoursAndMinutes;
  };

  const isClosingAlarmAllowed = () => {
    if (myData?.selectedActions === null) return true;
    if (
      myData?.selectedActions?.selectedObservations.length === 0 &&
      myData?.selectedActions?.selectedWorks.length === 0
    )
      return true;

    return false;
  };

  const translateActions = () => {
    for (let i = 0; i < storageTranslationsActions?.actions.length; i++) {
      const id = storageTranslationsActions[currentLanguage].findIndex(
        (x) => x.actionId === i + 1
      );

      storageTranslationsActions.actions[i].label =
        storageTranslationsActions[currentLanguage][id].translation;
      storageTranslationsActions.actions[i].key =
        storageTranslationsActions.actions[i].alias;
      storageTranslationsActions.actions[i].value =
        storageTranslationsActions.actions[i].id;
    }

    if (storageTranslationsActions) {
      if (
        !isClosingAlarmAllowed() &&
        storageTranslationsActions.actions.length > 2
      ) {
        storageTranslationsActions.actions.splice(2, 1);
      }
    }

    return storageTranslationsActions?.actions;
  };

  const translateWorks = () => {
    for (let i = 0; i < storageTranslationsWorks?.works.length; i++) {
      const id = storageTranslationsWorks[currentLanguage].findIndex(
        (x) => x.workId === i + 1
      );

      storageTranslationsWorks.works[i].label =
        storageTranslationsWorks[currentLanguage][id].translation;
      storageTranslationsWorks.works[i].key =
        storageTranslationsWorks.works[i].alias;
      storageTranslationsWorks.works[i].value =
        storageTranslationsWorks.works[i].id;
    }

    return storageTranslationsWorks?.works;
  };

  const onSelect1 = (value, key) => {
    setSelect(value);
    switch (key?.key) {
      case "#action.observe": {
        setIsObserve(true);
        setIsInWork(false);
        setIsClose(false);
        break;
      }
      case "#action.work": {
        setIsObserve(false);
        setIsInWork(true);
        setIsClose(false);
        break;
      }
      case "#action.close": {
        setIsObserve(false);
        setIsInWork(false);
        setIsClose(true);
        break;
      }
      default: {
        setIsObserve(false);
        setIsInWork(false);
        setIsClose(false);
        break;
      }
    }
  };

  const translateWorkReasons = () => {
    for (
      let i = 0;
      i < storageTranslationsWorkReasons?.workReasons.length;
      i++
    ) {
      const id = storageTranslationsWorkReasons[currentLanguage].findIndex(
        (x) => x.workReasonId === i + 1
      );

      storageTranslationsWorkReasons.workReasons[i].label =
        storageTranslationsWorkReasons[currentLanguage][id].translation;
      storageTranslationsWorkReasons.workReasons[i].key =
        storageTranslationsWorkReasons.workReasons[i].alias;
      storageTranslationsWorkReasons.workReasons[i].value =
        storageTranslationsWorkReasons.workReasons[i].id;
    }

    return storageTranslationsWorkReasons?.workReasons;
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setIsObserve(false);
        setIsInWork(false);
        setIsClose(false);
        setMyData(null);
        setSelect(null);
        closeModal();
      }}
      titleText={`${wellName}  ${t("chooseAction")}`}
      footer={null}
      width="600px"
      maskStyle={{ background: "rgba(0, 0, 0, 0.2)" }}
      destroyOnClose
    >
      <div className="alarm-modal">
        <ConfirmationModal
          visible={isConfirmationModalVisible}
          handleComment={getDataFromConfirmationModal}
          workName={confirmationModalData?.workName}
          id={confirmationModalData?.id}
          workComment={confirmationModalData?.workComment}
          isConfirmationModalVisible
          closeModal={() => setIsConfirmationModalVisible(false)}
          type={confirmationModalData?.type}
          method={confirmationModalData?.method}
        />
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item label={t("dateOfCreation")}>
            <DatePicker
              defaultValue={moment(data?.createdAt, dateFormat)}
              disabled
            />
          </Form.Item>
          <Form.Item label={t("alarm")}>
            <Typography.Text className="ant-form-text" type="secondary">
              {data?.type}
            </Typography.Text>
          </Form.Item>
          <Form.Item
            name="reason"
            label={t("reason")}
            rules={[
              {
                required: data?.status === "OPEN",
                message: t("missingReason"),
              },
            ]}
          >
            <Select
              options={!data?.selectedActions && translateWorkReasons()}
              defaultValue={
                storageTranslationsWorkReasons &&
                (data?.selectedActions?.workReasonId
                  ? storageTranslationsWorkReasons[currentLanguage][
                      data?.selectedActions?.workReasonId - 1
                    ]?.translation
                  : null)
              }
              disabled={!(data?.status === "OPEN")}
            />
          </Form.Item>
          <Form.Item
            name="action"
            label={t("action")}
            rules={[{ required: true, message: t("missingAction") }]}
          >
            <Select
              options={translateActions()}
              onSelect={onSelect1}
              defaultValue={null}
            />
          </Form.Item>
          {isObserve &&
            data?.selectedActions?.selectedObservations.length > 0 && (
              <>
                <Form.Item label={t("within")}>
                  <Form.Item
                    name="observedHours"
                    noStyle
                    rules={[
                      { required: true, message: t("missingObservedHours") },
                    ]}
                  >
                    <InputNumber
                      defaultValue={
                        data?.selectedActions?.selectedObservations[0]
                          ?.observationHours
                      }
                      min={1}
                      max={100}
                      disabled
                    />
                  </Form.Item>
                  <span className="ant-form-text"> {t("shortHours")} </span>
                  <span className="ant-form-text"> {t("remain")} </span>
                  <Form.Item
                    name="remainObservedHours"
                    noStyle
                    rules={[
                      { required: true, message: t("missingObservedHours") },
                    ]}
                  >
                    <Input
                      style={{ width: "20%" }}
                      defaultValue={
                        isRemainTime(
                          data?.selectedActions?.selectedObservations[0]
                        )
                          ? calculateRemainObservationTime(
                              data?.selectedActions?.selectedObservations[0]
                            )
                          : "00:00"
                      }
                      disabled
                    />
                  </Form.Item>
                  <span className="ant-form-text"> {t("shortHours")} </span>
                  <CloseCircleOutlined
                    onClick={() => {
                      if (
                        isRemainTime(
                          data?.selectedActions?.selectedObservations[0]
                        )
                      ) {
                        prepareObserveDataForConfirmationModal(
                          data?.selectedActions?.selectedObservations[0]?.id,
                          data?.selectedActions?.selectedObservations[0]
                            ?.comment,
                          t("observe"),
                          "close"
                        );
                      }
                    }}
                  />
                  <CheckCircleOutlined
                    onClick={() => {
                      if (
                        !isRemainTime(
                          data?.selectedActions?.selectedObservations[0]
                        )
                      ) {
                        prepareObserveDataForConfirmationModal(
                          data?.selectedActions?.selectedObservations[0]?.id,
                          data?.selectedActions?.selectedObservations[0]
                            ?.comment,
                          t("observe"),
                          "done"
                        );
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item name="commentObserve" label={t("comment")}>
                  <TextArea
                    defaultValue={
                      data?.selectedActions?.selectedObservations[0]?.comment
                    }
                    disabled
                    autoSize
                    label={t("comment")}
                    rows={3}
                    cols={10}
                    maxLength={100}
                    rules={[{ required: true, message: t("missingComment") }]}
                  />
                </Form.Item>
              </>
            )}
          {isObserve &&
            (data?.selectedActions === null ||
              data?.selectedActions?.selectedObservations.length === 0) && (
              <>
                <Form.Item label={t("within")}>
                  <Form.Item
                    name="observedHours"
                    noStyle
                    rules={[
                      { required: true, message: t("missingObservedHours") },
                    ]}
                  >
                    <InputNumber min={1} max={100} />
                  </Form.Item>
                  <span className="ant-form-text"> {t("shortHours")} </span>
                </Form.Item>
                <Form.Item
                  name="comment"
                  label={t("comment")}
                  rules={[{ required: true, message: t("missingComment") }]}
                >
                  <TextArea
                    autoSize
                    label={t("comment")}
                    rows={3}
                    cols={10}
                    maxLength={100}
                  />
                </Form.Item>
              </>
            )}
          {isInWork && (
            <>
              {myData?.selectedActions?.selectedWorks.map((item, index) => (
                <Space
                  key={index}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    style={{ width: "180px" }}
                    name={"selectedWorkName".concat(index)}
                  >
                    <Select
                      disabled
                      defaultValue={
                        storageTranslationsWorks[currentLanguage][
                          item?.workId - 1
                        ]?.translation
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ width: "340px" }}
                    name={"selectedWorkComment".concat(index)}
                  >
                    <Input
                      disabled
                      placeholder={t("comment")}
                      maxLength={100}
                      defaultValue={item?.comment}
                    />
                  </Form.Item>
                  <CloseCircleOutlined
                    onClick={() =>
                      prepareWorkDataForConfirmationModal(
                        item?.id,
                        item?.comment,
                        item,
                        "close",
                        index
                      )
                    }
                  />
                  <CheckCircleOutlined
                    onClick={() =>
                      prepareWorkDataForConfirmationModal(
                        item?.id,
                        item?.comment,
                        item,
                        "done",
                        index
                      )
                    }
                  />
                </Space>
              ))}
              <Form.List name="chosenWorks">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "workId"]}
                          fieldKey={[fieldKey, "workId"]}
                          rules={[
                            { required: true, message: t("missingWork") },
                          ]}
                          style={{ width: "180px" }}
                        >
                          <Select options={translateWorks()} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "comment"]}
                          fieldKey={[fieldKey, "comment"]}
                          rules={[
                            { required: true, message: t("missingComment") },
                          ]}
                          style={{ width: "350px" }}
                        >
                          <Input placeholder={t("comment")} maxLength={100} />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        {t("addWork")}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </>
          )}
          {isClose && (
            <>
              <Form.Item
                label={t("comment")}
                name="comment"
                rules={[{ required: true, message: t("missingComment") }]}
              >
                <TextArea
                  autoSize
                  label={t("comment")}
                  rows={3}
                  cols={10}
                  maxLength={100}
                  name="comment"
                />
              </Form.Item>
            </>
          )}
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              {t("apply")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AlarmInfoModal;
