/*eslint-disable*/
import useAnalysisChart from "./hooks/useAnalysisChart";
import { observer } from "mobx-react-lite";
import AnalysisChartHeader from "./components/AnalysisChartHeader";
import "./styles.css";

const AnalysisChart = observer(({
  data,
  isLoadingData,
  period,
  width
}) => {

  const {
    chartContextRef,
    chartWidth,
    parameters,
    visibleDatasetsIndexes,
    setVisibleDatasetsIndexes
  } = useAnalysisChart({ data, period, width });

  return (
    <div className="deviation-analysis-chart-container">
      <AnalysisChartHeader
        parameters={parameters}
        visibleDatasetsIndexes={visibleDatasetsIndexes}
        setVisibleDatasetsIndexes={setVisibleDatasetsIndexes}
      />
      <div
        style={{
          width: "100%",
          height: "calc(100% - 60px)",
          overflowX: "auto"
        }}
      >
        <div
          style={{
            position: "relative",
            width: chartWidth !== 0 ? chartWidth + 30 : 0,
            margin: "0px auto",
            height: "100%",
          }}
        >
          <canvas ref={chartContextRef} />
        </div>
      </div>
    </div>
  );
});

export default AnalysisChart;
