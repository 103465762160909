/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import request from "utils/request";

const useGetRequest = (url, deps = []) => {

  const [data, setData] = useState(null);
  const [state, setState] = useState("initial");

  const fetch = async (token = null) => {
    try {
      setState("pending");
      setData(null);

      if (url == null) return;

      const { data } = await request.get(url, {
        cancelToken: token,
      });

      setData(data);
      setState("resolved");
    } catch (error) {
      console.error(error);
      setState("rejected");
    }
  }

  useEffect(() => {
    const source = axios.CancelToken.source();

    fetch(source.token);

    return () => source.cancel();
  }, deps);

  return {
    data,
    state,
    fetch,
    initial: state === "initial",
    pending: state === "pending",
    rejected: state === "rejected",
    resolved: state === "resolved",
  }
}

export default useGetRequest;
