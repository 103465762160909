/* eslint-disable */
import { useCallback } from 'react';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const useTimeConverter = ({ fromOffsetDefault=null, toOffset=null, template='YYYY-MM-DDTHH:mm:ss.SSS' }={}) => {

  if (fromOffsetDefault === null) {
    fromOffsetDefault = new Date().getTimezoneOffset() * -1
  }
  
  if (toOffset === null) {
    toOffset = new Date().getTimezoneOffset() * -1
  }

  const converter = useCallback((t) => {
    if (t == null) return null;
    t = t.toString()
    t = t.replace(/((UTC|GMT|)[+-]\d{2}([:]|)\d{2})|([A-Za-z])/g, '')

    const fromStr = dayjs.utc(t)
      .utcOffset(fromOffsetDefault, true)
      .format()

    const convertedStr = dayjs.utc(fromStr)
      .utcOffset(toOffset)
      .format(template)

    return convertedStr
  }, [fromOffsetDefault, toOffset, template])

  return converter
}

export default useTimeConverter