/* eslint-disable */
import { makeAutoObservable, observable } from 'mobx'

class AccessStore {
  permissionsList = new Map()

  currentWellPermissions = {}

  rolesPermissions = new Map()

  rolesList = []

  currentWellRole = null

  constructor() {
    makeAutoObservable(this, {
        currentWellRole: observable,
      })
  }

   setRolesPermissions(data) {
        this.rolesList = data
        data.forEach(item => {
            this.rolesPermissions.set(item?.id, item)
        })
   }

  findRolePermission(actionName) {
    if (this.rolesPermissions.has(this.currentWellRole)) {
        if (this.rolesPermissions.get(this.currentWellRole).hasOwnProperty(`${actionName}`)) {
            return this.rolesPermissions.get(this.currentWellRole)[`${actionName}`]
        }
    }
  }

  checkPermission(actionName) {
    let result = null

    switch(actionName) {
        case "edit_trend_templates" : {
            result = this.findRolePermission("edit_trend_templates")
            break;
        }
        case "virtual_flow_meter" : {
            result = this.findRolePermission("virtual_flow_meter")
            break;
        }
        case "deviation_analysis" : {
            result = this.findRolePermission("deviation_analysis")
            break;
        }
        case "deviation_analysis_alarms" : {
            result = this.findRolePermission("deviation_analysis_alarms")
            break;
        }
        case "operational_records" : {
            result = this.findRolePermission("operational_records")
            break;
        }
        case "edit_nrh" : {
            result = this.findRolePermission("edit_nrh")
            break;
        }
        case "add_edit_users" : {
            result = this.findRolePermission("add_edit_users")
            break;
        }
        case "add_edit_users_groups" : {
            result = this.findRolePermission("add_edit_users_groups")
            break;
        }
        case "add_edit_locations" : {
            result = this.findRolePermission("add_edit_locations")
            break;
        }
        case "download_report" : {
            result = this.findRolePermission("download_report")
            break;
        }
        case "add_first_priority_well" : {
            result = this.findRolePermission("add_first_priority_well")
            break;
        }
        case "edit_mnemoscheme" : {
            result = this.findRolePermission("edit_mnemoscheme")
            break;
        }
    }

    
    return result
  }

  setCurrentWellPermissions(wellId) {
    if (this.permissionsList.has(wellId)) {
        this.currentWellPermissions = permissionsList.get(wellId)
    } else {
        this.currentWellPermissions = {}
    }
  }

  setCurrentWell(wellId) {
    this.setCurrentWellRole(wellId)
  }

  setCurrentWellRole(wellId) {
    if (this.permissionsList.has(Number(wellId))) {
        if (this.permissionsList.get(Number(wellId))) {
            this.currentWellRole = this.permissionsList.get(Number(wellId))
        }
    } 
  }

  setPermissionsList(newData) {
    this.permissionsList = new Map()

    newData.forEach(item => {
        this.permissionsList.set(item?.wellId, item?.roleId)
    })
  }

  checkWellRole(wellId) {
    let result = null

    if (this.permissionsList.has(wellId)) {
        if (this.permissionsList.get(wellId)) {
            result = this.permissionsList.get(wellId)
        }
    }

    return result
  }

  checkWellPermission(wellId, actionName) {
    let role = this.checkWellRole(wellId)
    let result = null

    if (role != null) {
        if (this.rolesPermissions.get(role)) {
            if (actionName == "virtual_flow_meter") {
                result = this.rolesPermissions.get(role)["virtual_flow_meter"]
            }
            if (actionName == "deviation_analysis") {
                result = this.rolesPermissions.get(role)["deviation_analysis"]
            }
            if (actionName == "download_report") {
                result = this.rolesPermissions.get(role)["download_report"]
            }
        }
    } else {
        return null
    }

    return result
  }

}

export default AccessStore
