/*eslint-disable*/

const commonCheck = (location, callback) => {
  if (location.wells?.some(callback)) return true;

  for (const sub of location.locations) {
    if (commonCheck(sub, callback)) return true;
  }

  return false;
}

export const isChecked = (location) => {
  return commonCheck(location, (well) => well.active);
}

export const isIndeterminate = (location) => {
  return commonCheck(location, (well) => !well.active);
}

export const hasSubLocationsWells = (location, filteredWellIds) => {
  if (location.wells) {
    for (const well of location.wells) {
      if (filteredWellIds.includes(well.id)) return true;
    }
  }

  for (const sub of location.locations) {
    if (hasSubLocationsWells(sub, filteredWellIds)) return true;
  }

  return false;
}

export function* getAllSubLocations(location) {
  yield location;

  for (const sub of location.locations) {
    yield* getAllSubLocations(sub);
  }
}

export function* getAllSubLocationsWithWells(location, path) {
  if (location.wells) {
    yield [location, path];
  }

  let i = 0;
  for (const sub of location.locations) {
    yield* getAllSubLocationsWithWells(sub, `${path}.locations[${i}]`);
    i++;
  }
}