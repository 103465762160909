/*eslint-disable*/
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Table } from "antd";
import { FilterFilled } from "@ant-design/icons";
import Search from "components/Search";
import { useStore } from "hooks/useStore";
import AnalysisTable from "../AnalysisTable";
import { ReactComponent as GreenUpArrow } from "assets/images/greenUpArrow.svg";
import { ReactComponent as RedUpArrow } from "assets/images/redUpArrow.svg";
import { useTranslation } from "react-i18next";
import "./styles.css";

const WellAnalysis = observer(({
  data,
  isLoadingData
}) => {

  const { wellStore } = useStore();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [searchValues, setSearchValues] = useState(
    { wellName: "", locationName: "" }
  );

  const tableDataSource = data ?? [];

  const ArrowWidth = 11;
  const ArrowMargin = 2;

  const onWellClick = (wellId, locationId) => {
    wellStore.handleLocationClick(locationId);
    wellStore.setId(wellId);

    navigate("/analysis");
  }

  const tableColumns = [
    {
      title: t("well"),
      dataIndex: "wellName",
      key: "wellName",
      width: "11%",
      sorter: (a, b) => {
        if (a.wellName === b.wellName) return 0;
        return a.wellName > b.wellName ? 1 : -1;
      },
      showSorterTooltip: false,
      filteredValue: [searchValues.wellName],
      onFilter: (value, record) => record.wellName.toLowerCase()
        .includes(value.toLowerCase()),
      filterDropdown: (props) => {
        const handleChangeSearchValue = (value) => {
          setSearchValues(prev => ({ ...prev, wellName: value }));
        }

        return (
          <Search
            value={props.selectedKeys.at(0)}
            handleChangeValue={handleChangeSearchValue}
            fontSize="18px"
          />
        );
      },
      filterIcon: () => (
        <FilterFilled
          style={{
            color: searchValues.wellName !== "" ? "#1890ff" : "#bfbfbf"
          }}
        />
      ),
      render: (value, record) => (
        <a
          className="deviation-well-analysis-page-well-label"
          onClick={() => onWellClick(record.wellId, record.locationId)}
        >
          {value}
        </a>
      )
    },
    {
      title: t("deposit"),
      dataIndex: "locationName",
      key: "locationName",
      width: "11%",
      sorter: (a, b) => {
        if (a.locationName === b.locationName) return 0;
        return a.locationName > b.locationName ? 1 : -1;
      },
      showSorterTooltip: false,
      filteredValue: [searchValues.locationName],
      onFilter: (value, record) => record.locationName.toLowerCase()
        .includes(value.toLowerCase()),
      filterDropdown: (props) => {
        const handleChangeSearchValue = (value) => {
          setSearchValues(prev => ({ ...prev, locationName: value }));
        }

        return (
          <Search
            value={props.selectedKeys.at(0)}
            handleChangeValue={handleChangeSearchValue}
            fontSize="18px"
          />
        );
      },
      filterIcon: () => <FilterFilled
        style={{
          color: searchValues.locationName !== "" ? "#1890ff" : "#bfbfbf"
        }}
      />
    },
    {
      title: (
        <>
          {t("ppl")}
          <RedUpArrow
            width={ArrowWidth}
            style={{ marginLeft: ArrowMargin, transform: "rotate(180deg)" }}
          />
        </>
      ),
      dataIndex: "pplDecreased",
      key: "pplDecreased",
      align: "right",
      sorter: (a, b) => {
        if (a.pplDecreased === b.pplDecreased) return 0;
        return a.pplDecreased > b.pplDecreased ? 1 : -1;
      },
      showSorterTooltip: false,
      render: (text, record, index) => text.toFixed(2)
    },
    {
      title: (
        <>
          {t("ppl")}
          <GreenUpArrow
            width={ArrowWidth}
            style={{ marginLeft: ArrowMargin }}
          />
        </>
      ),
      dataIndex: "pplIncreased",
      key: "pplIncreased",
      align: "right",
      sorter: (a, b) => {
        if (a.pplIncreased === b.pplIncreased) return 0;
        return a.pplIncreased > b.pplIncreased ? 1 : -1;
      },
      showSorterTooltip: false,
      render: (text, record, index) => text.toFixed(2)
    },
    {
      title: (
        <>
          {t("gno")}
          <RedUpArrow width={ArrowWidth}
            style={{ marginLeft: ArrowMargin, transform: "rotate(180deg)" }}
          />
        </>
      ),
      dataIndex: "gnoDecreased",
      key: "gnoDecreased",
      align: "right",
      sorter: (a, b) => {
        if (a.gnoDecreased === b.gnoDecreased) return 0;
        return a.gnoDecreased > b.gnoDecreased ? 1 : -1;
      },
      showSorterTooltip: false,
      render: (text, record, index) => text.toFixed(2)
    },
    {
      title: (
        <>
          {t("gno")}
          <GreenUpArrow
            width={ArrowWidth}
            style={{ marginLeft: ArrowMargin }}
          />
        </>
      ),
      dataIndex: "gnoIncreased",
      key: "gnoIncreased",
      align: "right",
      sorter: (a, b) => {
        if (a.gnoIncreased === b.gnoIncreased) return 0;
        return a.gnoIncreased > b.gnoIncreased ? 1 : -1;
      },
      showSorterTooltip: false,
      render: (text, record, index) => text.toFixed(2)
    },
    {
      title: t("kakspl"),
      dataIndex: "kakspl",
      key: "kakspl",
      align: "right",
      sorter: (a, b) => {
        if (a.kakspl === b.kakspl) return 0;
        return a.kakspl > b.kakspl ? 1 : -1;
      },
      showSorterTooltip: false,
      render: (text, record, index) => text.toFixed(2)
    },
    {
      title: t("waterCutShort"),
      dataIndex: "obv",
      key: "obv",
      align: "right",
      sorter: (a, b) => {
        if (a.obv === b.obv) return 0;
        return a.obv > b.obv ? 1 : -1;
      },
      showSorterTooltip: false,
      render: (text, record, index) => text.toFixed(2)
    },
    {
      title: t("sum"),
      dataIndex: "sum",
      key: "sum",
      align: "right",
      defaultSortOrder: "ascend",
      sorter: (a, b) => {
        if (a.sum === b.sum) return 0;
        return a.sum > b.sum ? 1 : -1;
      },
      showSorterTooltip: false,
      render: (text, record, index) => text.toFixed(2)
    },
  ];

  const tableSummaryItems = [
    {
      key: "pplDecreased",
    },
    {
      key: "pplIncreased",
    },
    {
      key: "gnoDecreased",
    },
    {
      key: "gnoIncreased",
    },
    {
      key: "kakspl",
    },
    {
      key: "obv",
    },
    {
      key: "sum",
    },
  ];

  return (
    <div className="deviation-well-analysis-page">
      <AnalysisTable
        dataSource={tableDataSource}
        columns={tableColumns}
        loading={isLoadingData}
        summary={(data) => (
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={2}>
              <span className="analysis-table-row-value">Итого</span>
            </Table.Summary.Cell>
            {tableSummaryItems.map((item, index) => (
              <Table.Summary.Cell key={item.key} index={1 + index}>
                <span className="analysis-table-row-value">
                  {data.reduce((acc, val) => acc + val[item.key], 0).toFixed(2)}
                </span>
              </Table.Summary.Cell>
            ))}
          </Table.Summary.Row>
        )}
        onRow={(record, index) => ({
          style: { backgroundColor: (index % 2 == 0 ? "#f5f6fa" : "#ffffff") }
        })}
        rowKey="wellId"
      />
    </div>
  );
});

export default WellAnalysis;
