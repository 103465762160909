/* eslint-disable */
import React, { useState } from "react";
import "antd/dist/antd.min.css";
import {
  Form,
  Button,
  InputNumber,
  Switch,
  Select,
} from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import localeRU from "antd/es/date-picker/locale/ru_RU";
import localeEN from "antd/es/date-picker/locale/en_GB";
import localeES from "antd/es/date-picker/locale/es_ES";
import Tooltip from "components/Tooltip";

const DivergenceCommonSettings = ({ divergenceSettingsData, handleSetNewData }) => {

  const { t } = useTranslation();

  const [buttonText, setButtonText] = useState(t("apply"));

  const makeResultSettings = (data) => {
    let result = {};
    result["state"] = Boolean(data.state);
    result["calculatedHdin"] = Boolean(data.calculatedHdin);
    result["tolerance"] = data.tolerance;
    result["debitRelevanceTime"] = data.debitRelevanceTime;
    result["delaySubmit"] = {
      year: 0,
      month: 0,
      day: 0,
      hour: data.delaySubmit,
      minute: 0,
      second: 0,
    };
    result["frequencyCalculation"] = {
      year: 0,
      month: 0,
      day: 0,
      hour: data.frequencyCalculation,
      minute: 0,
      second: 0,
    }
    result["isVrMLModelEnabled"] = Boolean(data.isVrMLModelEnabled);
    result["isVrPhysModelEnabled"] = Boolean(data.isVrPhysModelEnabled);
    return result;
  };

  const onFinish = (values) => {
    handleSetNewData(makeResultSettings(values.divergenceSettings));
    setButtonText(t("applied"));
  };

  const dateTransformSubmit = () => {
    return (
      Math.trunc((divergenceSettingsData?.delaySubmit.minute +
        divergenceSettingsData?.delaySubmit.hour * 60) / 60)
    )
  };

  const dateTransformFC = () => {
    return (
      Math.trunc((divergenceSettingsData?.frequencyCalculation.minute +
        divergenceSettingsData?.frequencyCalculation.hour * 60) / 60)
    );
  };


  const makeInitialValues = () => {
    return {
      divergenceSettings: {
        state: divergenceSettingsData?.state,
        calculatedHdin: divergenceSettingsData?.calculatedHdin,
        tolerance: divergenceSettingsData?.tolerance,
        delaySubmit: dateTransformSubmit(),
        frequencyCalculation: dateTransformFC(),
        debitRelevanceTime: divergenceSettingsData?.debitRelevanceTime,
        isVrMLModelEnabled: divergenceSettingsData?.isVrMLModelEnabled,
        isVrPhysModelEnabled: divergenceSettingsData?.isVrPhysModelEnabled,
      },
    };
  };

  const items = [
    {
      label: '1',
      value: '1',
    },
    {
      label: '2',
      value: '2',
    },
    {
      label: '3',
      value: '3',
    },
    {
      label: '4',
      value: '4',
    },
    {
      label: '6',
      value: '6',
    },
    {
      label: '8',
      value: '8',
    },
    {
      label: '12',
      value: '12',
    },
    {
      label: '24',
      value: '24',
    },
  ];

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  let locale = "";

  moment.locale("ru");
  switch (currentLanguage) {
    case "ru": {
      locale = localeRU;
      moment.locale("ru");
      break;
    }
    case "en": {
      locale = localeEN;
      moment.locale("en-gb");
      break;
    }
    case "es": {
      locale = localeES;
      moment.locale("es");
      break;
    }
    default: {
      locale = localeRU;
      moment.locale("ru");
      break;
    }
  }

  const layout = {
    labelCol: {
      span: 14,
    },
    wrapperCol: {
      span: 12,
    },
  };

  const handleChangeDelaySubmit = (value) => {
    console.log(`selected ${value}`);
  };

  const handleChangeFrequencyCalc = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={onFinish}
      className
      initialValues={makeInitialValues()}
      onValuesChange={() => setButtonText(t("apply"))}
    >
      <Tooltip
        placement="topLeft"
        zIndex={1000000000001}
        title={t("tooltipAnalysisOnOffCommon")}
        overlayStyle={{ maxWidth: 300 }}
      >
        <Form.Item
          name={["divergenceSettings", "state"]}
          valuePropName="checked"
          label={t("analysisOnOffCommon")}
          labelAlign="left"
          wrapperCol={{
            offset: 0,
            span: 16,
          }}
        >
          <Switch />
        </Form.Item>
      </Tooltip>

      <Tooltip
        placement="topLeft"
        zIndex={1000000000001}
        title={t("tooltipTimeWarning")}
        overlayStyle={{ maxWidth: 300 }}
      >
        <Form.Item
          labelAlign="left"
          label={t("timeWarning")}
        >
          <Form.Item
            noStyle
            name={["divergenceSettings", "delaySubmit"]}
            rules={[{ required: false, message: "Введите часы интервала" }]}
          >
            <Select
              style={{
                width: 90,
              }}
              onChange={handleChangeDelaySubmit}
              options={items}
            />
          </Form.Item>

          <span className="ant-form-text" style={{ paddingLeft: "14px" }}>
            {" "}
            {t("shortHours")}{" "}
          </span>
        </Form.Item>
      </Tooltip>

      {/*<Tooltip*/}
      {/*  placement="topLeft"*/}
      {/*  zIndex={1000000000001}*/}
      {/*  title={t("tooltipDefaultVal")}*/}
      {/*  overlayStyle={{ maxWidth: 300 }}*/}
      {/*>*/}
      {/*  <Form.Item*/}
      {/*    labelAlign="left"*/}
      {/*    label={t("acceptableDeviation")}*/}
      {/*    rules={[{ required: false, message: "Введите интервал" }]}*/}
      {/*  >*/}
      {/*    <Form.Item*/}
      {/*      noStyle*/}
      {/*      name={["divergenceSettings", "tolerance"]}*/}
      {/*      rules={[{ required: false, message: "Введите процент" }]}*/}
      {/*    >*/}
      {/*      <InputNumber*/}
      {/*        min="0"*/}
      {/*        max="100"*/}
      {/*        style={{*/}
      {/*          width: "30%",*/}
      {/*        }}*/}
      {/*      />*/}
      {/*    </Form.Item>*/}

      {/*    <span className="ant-form-text" style={{ paddingLeft: "5px" }}>*/}
      {/*      {" "}*/}
      {/*      {"%"}{" "}*/}
      {/*    </span>*/}
      {/*  </Form.Item>*/}
      {/*</Tooltip>*/}

      {/*<Tooltip*/}
      {/*  placement="topLeft"*/}
      {/*  zIndex={1000000000001}*/}
      {/*  title={t("tooltipTimeDebit")}*/}
      {/*  overlayStyle={{ maxWidth: 300 }}*/}
      {/*>*/}
        <Form.Item
          labelAlign="left"
          label={t("actualTimeDebit")}
          rules={[{ required: false, message: "Введите интервал" }]}
        >
          <Form.Item
            noStyle
            name={["divergenceSettings", "debitRelevanceTime"]}
            rules={[{ required: false, message: "Введите сутки" }]}
          >
            <InputNumber
              min="1"
              max="45"
              style={{
                width: "30%",
              }}
            />
          </Form.Item>

          <span className="ant-form-text" style={{ paddingLeft: "5px" }}>
            {" "}
            {t("shortDays")}{" "}
          </span>
        </Form.Item>
      {/*</Tooltip>*/}

      <Tooltip
        placement="topLeft"
        zIndex={1000000000001}
        title={t("tooltipFrequencyDebitReading")}
        overlayStyle={{ maxWidth: 300 }}
      >
        <Form.Item
          labelAlign="left"
          label={t("frequencyDebitReading")}
          rules={[{ required: false, message: "Введите интервал" }]}
        >
          <Form.Item
            noStyle
            name={["divergenceSettings", "frequencyCalculation"]}
            rules={[{ required: false, message: "Введите часы интервала" }]}
          >
            <Select
              style={{
                width: 90,
              }}
              onChange={handleChangeFrequencyCalc}
              options={items}
            />
          </Form.Item>

          <span className="ant-form-text" style={{ paddingLeft: "14px" }}>
            {" "}
            {t("shortHours")}{" "}
          </span>
        </Form.Item>
      </Tooltip>

      <Tooltip
        placement="topLeft"
        zIndex={1000000000001}
        title={t("tooltipHdin")}
        overlayStyle={{ maxWidth: 300 }}
      >
        <Form.Item
          name={["divergenceSettings", "calculatedHdin"]}
          valuePropName="checked"
          label={t("hdinOnOff")}
          labelAlign="left"
          wrapperCol={{
            offset: 0,
            span: 16,
          }}
        >
          <Switch />
        </Form.Item>
      </Tooltip>

      <Tooltip
        placement="topLeft"
        zIndex={1000000000001}
        // title={t("")}
        overlayStyle={{ maxWidth: 300 }}
      >
        <Form.Item
          name={["divergenceSettings", "isVrMLModelEnabled"]}
          valuePropName="checked"
          label={t("calcVrMLModelOnOff")}
          labelAlign="left"
          wrapperCol={{
            offset: 0,
            span: 16,
          }}
        >
          <Switch />
        </Form.Item>
      </Tooltip>

      <Tooltip
        placement="topLeft"
        zIndex={1000000000001}
        // title={t("")}
        overlayStyle={{ maxWidth: 300 }}
      >
        <Form.Item
          name={["divergenceSettings", "isVrPhysModelEnabled"]}
          valuePropName="checked"
          label={t("calcVrPhysModelOnOff")}
          labelAlign="left"
          wrapperCol={{
            offset: 0,
            span: 16,
          }}
        >
          <Switch />
        </Form.Item>
      </Tooltip>

      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 10 }}>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            backgroundColor: buttonText === t("apply") ? "" : "lightseagreen",
          }}
        >
          {buttonText}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DivergenceCommonSettings;
