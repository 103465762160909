/*eslint-disable*/
import { observer } from "mobx-react-lite";
import { useStore } from "hooks/useStore";

const ReportInfo = observer(({
  wellsCount,
  problemWellsCount
}) => {

  const { translationStore } = useStore();

  switch (translationStore.currentLanguage) {
    case "ru": return (
      <div>
        Всего {wellsCount} скважин.
        Из них имеют отклонения: {problemWellsCount}.
      </div>
    );
    case "en": return (
      <div>
        {wellsCount} wells in total.
        Of these have deviations: {problemWellsCount}.
      </div>
    );
    case "es": return (
      <div>
        Un total de {wellsCount} pozos.
        De estos tienen desviaciones: {problemWellsCount}.
      </div>
    );
    default: return (
      <div>
        Всего {wellsCount} скважин.
        Из них имеют отклонения: {problemWellsCount}.
      </div>
    );
  }
});

export default ReportInfo;
