/* eslint-disable */
import { Menu, Layout } from "antd";
import "./styles.css";

const Sider = ({
  selectedMenuKey,
  items,
  handleMenuClick
}) => (
  <div className="sidebar">
    <Layout.Sider theme="light" width="300px" height="100%">
      <Menu
        items={items}
        onClick={handleMenuClick}
        selectedKeys={selectedMenuKey}
        theme="light"
        mode="inline"
      />
    </Layout.Sider>
  </div>
);

export default Sider;
