/* eslint-disable */
import { InputNumber, Switch } from "antd";

const WellElement = ({
  parameterId,
  wellId,
  wellStatus,
  wellMaxPoints,
  changeWellStatus,
  changeWellPoints,
}) => {

  const handleChangeStatus = () => {
    changeWellStatus(wellId, parameterId);
  }

  const handleChangeWellMaxPoints = (value) => {
    changeWellPoints(wellId, parameterId, value);
  }

  return (
    <div
      className="well-element"
      style={{
        display: "flex",
        flexDirection: "column",
        width: "500px",
        padding: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "Left",
          padding: "10px",
        }}
      >
        <span>Вкл/Выкл прореживание</span>
        <Switch onClick={handleChangeStatus} checked={wellStatus} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "Left",
          padding: "10px",
        }}
      >
        <span>Максимальное количество точек на интервал</span>
        <InputNumber
          min={3}
          value={wellMaxPoints}
          onChange={handleChangeWellMaxPoints}
          disabled={!wellStatus}
        />
      </div>
    </div>
  );
};

export default WellElement;
