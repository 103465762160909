/* eslint-disable */
import useAuth from "hooks/useAuth";
import { useTranslation } from "react-i18next";
import "./styles.css";

const Menu = ({
  onClickProfile
}) => {

  const { logout } = useAuth();
  const { t } = useTranslation();

  const handleLogout = () => {
    logout();
    localStorage.clear();
    window.location.reload();
  }

  return (
    <div className="logout-menu-wrapper">
      <div className="logout-menu">
        <div
          onClick={onClickProfile}
          className="item profile"
        >
          {t("profile")}
        </div>

        {(JSON.parse(localStorage.getItem("AUTH_PROVIDER")) !== "krb") ? <div
          onClick={handleLogout}
          className="item exit"
        >
          {t('exit')}
        </div> : <></>}
      </div>
    </div>
  )
}

export default Menu
