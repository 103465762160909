/* eslint-disable */
import { observer } from "mobx-react-lite";
import HeaderCommon from "layouts/common/HeaderCommon";
import EngineerScreen from "layouts/common/components/EngineerScreen";
import Burger from "./components/Burger";
import CustomBreadcrumbs from "./components/Breadcrumb";
import "./styles.css";
import "../common/styles.css";

const WellAnalysisLayout = observer(({ children }) => {
  return (
    <>
      <header>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            height: "100%"
          }}
        >
          <div className="right-container">
            <div
              className="burger-container"
              onClick={() => {
                document
                  .querySelector(".burger-icon")
                  ?.classList.toggle("with-menu");
                document
                  .querySelector(".well-tree")
                  ?.classList.toggle("with-menu");
              }}
            >
              <Burger />
            </div>
            <div className="breadcrumbs-container">
              <CustomBreadcrumbs />
            </div>
          </div>

          <div className="engineer-screen-header-button">
            <EngineerScreen />
          </div>
        </div>

        <HeaderCommon />
      </header>
      <div className="main-layout">{children}</div>
    </>
  );
});

export default WellAnalysisLayout;
