/* eslint-disable */
import { Input } from "antd";
import React from "react";
import WellLines from "./components/WellLines";

const DivergenceWells = ({
                           handleSearch,
                           wellsData,
                           handleSetNewData,
                           divergenceData,
                           setDivergenceData
                         }) => {
  return (
    <div>
      <Input
        addonBefore={"Поиск"}
        onChange={(e) => handleSearch(e.target.value)}
        style={{ paddingBottom: "20px" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          overflowY: "auto",
          overflowX: "hidden",
          height: "400px",
          flexDirection: "column",
        }}
      >
        <WellLines handleSetNewData={handleSetNewData} divergenceData={divergenceData} wellsData={wellsData} setDivergenceData={setDivergenceData} />
      </div>
    </div>
  );
};
export default DivergenceWells;
