/* eslint-disable */
import React from "react";
import WellBreadcrumbs from "./components/WellBreadcrumbs";
import "./styles.css";

const TrendsHeader = ({ breadcrumbs, children }) => (
  <div className="trends-header-content">
    <WellBreadcrumbs breadcrumbs={breadcrumbs} />
    <div className="trends-icons-container">{children}</div>
  </div>
);

export default TrendsHeader;