/* eslint-disable */
import { makeAutoObservable } from "mobx";
import { task } from "mobx-fetch-task";
import Gradient from "javascript-color-gradient";
import { store } from "hooks/useStore";
import { getGradientHexColor } from "components/Treemap/common";
import { t } from "i18next";
import request from "utils/request";

class WellOilTreemapStore {
  constructor() {
    makeAutoObservable(this, {
      fetchData: false,
    });
  }

  colors = [
    "#f63538",
    ...new Gradient()
      .setColorGradient("#f63538", "#414554", "#30cc5a")
      .setMidpoint(9)
      .getColors(),
    "#30cc5a",
  ];

  fetchData = task(async () => {
    const { data } = await request("/treemap/zamerOil");
    data.root.label = "Локации";
    return data;
  });

  get root() {
    return this.fetchData.result?.root;
  }

  get unitId() {
    return this.fetchData.result?.unitId;
  }

  get labelSettings() {
    return {
      getLabel: (node) => `${node.label}\n${node.realValue.toFixed(2)}`,
    }
  }

  get tooltipSettings() {
    return {
      renderLabel: (node) => (
        <span>{node.id[0] === "w" && t("well")} {node.label}</span>
      ),
      renderValue: (node) => (
        <div>
          <span>Дебит нефти: </span>
          <span className="well-treemap-tooltip-value">{node.realValue?.toFixed(2)}</span>
          <span className="well-treemap-tooltip-unit">{store.translationStore.translateParameterUnit(this.unitId)}</span>
        </div>
      )
    }
  }

  get colorSettings() {
    return {
      getNodeBackgroundColor: (node) => {
        return getGradientHexColor(node.color, this.colors);
      },
      getNodeLabelColor: (node) => {
        return "white";
      }
    }
  }
}

export default WellOilTreemapStore;

