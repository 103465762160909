/*eslint-disable*/
import { observer } from "mobx-react-lite";
import { useStore } from "hooks/useStore";
import RangeDateTimePicker from "components/RangeDateTimePicker";
import Buttons from "./components/Buttons";
import ReportInfo from "./components/ReportInfo";
import "./styles.css";

const Footer = observer(({
  isReportFormationAvailable,
  formReport,
  isExistReportData,
  reportWellsCount,
  reportProblemWellsCount
}) => {

  const { deviationAnalysisStore } = useStore();

  const period = deviationAnalysisStore.period;

  const handleChangePeriod = (newPeriod) => {
    deviationAnalysisStore.setPeriod(newPeriod);
  }

  return (
    <div className="deviation-analysis-footer">
      {isExistReportData &&
        <ReportInfo
          wellsCount={reportWellsCount}
          problemWellsCount={reportProblemWellsCount}
        />
      }

      <RangeDateTimePicker
        period={period}
        handleChangePeriod={handleChangePeriod}
        style={{ marginLeft: "auto", }}
      />

      <Buttons
        isReportFormationAvailable={isReportFormationAvailable}
        formReport={formReport}
      />
    </div>
  );
});

export default Footer;
