/* eslint-disable */
import { observer } from "mobx-react-lite";
import UplotReact from "uplot-react";
import { useResizeDetector } from "react-resize-detector";
import useChart from "./hooks/useChart";
import DataTooltip from "./DataTooltip";
import "uplot/dist/uPlot.min.css";
import "./styles.css";

const UPlotChart = observer(({
  data,
  period,
  handleZoom,
  scalesSettings,
  renderSettings,
  showOnlyTargetScaleTooltip,
}) => {
  const {
    width: containerWidth,
    height: containerHeight,
    ref: containerRef,
  } = useResizeDetector();

  const {
    processedData,
    scaleDatas,
    options,
    tooltipData,
    tooltipDataExist,
    scaleHeight,
    targetScaleIndex,
    handleMouseMove
  } = useChart({
    data,
    period,
    handleZoom,
    scalesSettings,
    renderSettings,
    containerWidth,
    containerHeight
  });

  return (
    <>
      <div
        ref={containerRef}
        style={{ position: "relative", width: "100%", height: "100%" }}
        onMouseMove={handleMouseMove}
      >
        {processedData &&
          processedData.scales.length > 0 &&
          processedData.scales.map((scale, index) => (
            <UplotReact
              key={scale.id}
              options={options[index]}
              data={scaleDatas[index]}
            />
          ))}
      </div>
      {tooltipDataExist && (
        <DataTooltip
          data={tooltipData}
          scaleWidth={containerWidth}
          scaleHeight={scaleHeight}
          showOnlyTargetScaleTooltip={showOnlyTargetScaleTooltip}
          targetScaleIndex={targetScaleIndex}
        />
      )}
    </>
  );
});

export default UPlotChart;
