/* eslint-disable */
import { observer } from "mobx-react-lite";
import { useStore } from "hooks/useStore";
import useTimeConverter from "hooks/useTimeConverter";
import Tooltip from "components/Tooltip";
import moment from "moment";

const TrendInfoValue = observer(({
  value,
  ts,
  isBlinking,
  unitId,
}) => {

  const { translationStore } = useStore();

  const fromUtcToLocale = useTimeConverter({
    toOffset: 0,
    fromOffsetDefault: new Date().getTimezoneOffset() / 60,
  });

  return (
    <div
      className="widthTrend30"
      style={{ display: "flex", flexDirection: "column" }}
    >
      {value != null &&
        <>
          <Tooltip
            placement="top"
            title={moment(
              fromUtcToLocale(ts)
            )
              .format("DD.MM.YYYYTHH:mm:ss")
              .replace("T", " ")}
          >
            <div
              className={`trends-info-realtime-value ${isBlinking && "blink"}`}
            >
              {parseFloat(value)
                .toFixed(2)
                .toString()}
            </div>
          </Tooltip>

          {translationStore.translateParameterUnit(unitId)}
        </>
      }
    </div>
  );
});

export default TrendInfoValue;
