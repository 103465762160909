/*eslint-disable*/

const alarmColors = {
  "increase": {
    0: "#cbffce", //  - бледный
    1: "#75ff7d", //  - средний
    2: "#00ff11", //  - яркий
  },
  "decrease": {
    0: "#ffcccc", //  - бледный
    1: "#ff8181", //  - средний
    2: "#ff3030", //  - яркий
  },
}

export { alarmColors };
