/* eslint-disable */
import { useState } from "react";
import { Modal } from "antd";
import Tooltip from "components/Tooltip";
import { ReactComponent as InfoIcon } from "assets/images/infoIcon.svg";
import { useTranslation } from 'react-i18next';
import "./styles.css";

const About = () => {

  const [isModalAboutOpen, setIsModalAboutOpen] = useState(false);
  const { t } = useTranslation()
  const version = `${t('programVersion')} 1.0.5 07.11.2023`;

  return (
    <>
      <div className="info-container">
        <Tooltip placement="left" zIndex={1000000000001} title="О программе">
          <InfoIcon onClick={() => setIsModalAboutOpen(true)} />
        </Tooltip>
      </div>
      <Modal
        title={t('about')}
        open={isModalAboutOpen}
        destroyOnClose
        onCancel={() => setIsModalAboutOpen(false)}
        footer={null}
      >
        <p>{version}</p>
      </Modal>
    </>
  );
}

export default About;

