/* eslint-disable */
import { Form } from "react-final-form";
import { memo } from "react";
import WellList from "../WellList"

const WellListForm = memo(({ locationsTrees, handleSubmit }) => {
  return (
    <Form
      initialValues={{ locationsTrees: structuredClone(locationsTrees) }}
      onSubmit={handleSubmit}
      render={({ handleSubmit, values, form, pristine }) => (
        <>
          <WellList
            locationsTrees={values.locationsTrees}
            form={form}
            handleSubmit={handleSubmit}
            pristine={pristine}
          />
        </>
      )}
    />
  );
});

export default WellListForm;
