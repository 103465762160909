/* eslint-disable */
import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Menu } from "antd";
import { useStore } from "hooks/useStore";
import useRequest from "hooks/useRequest";
import CustomAlarmModal from "./components/CustomAlarmModal";
import ActiveAlarms from "../ActiveAlarms";
import ArchiveAlarms from "../ArchiveAlarms";
import OperationalRecords from "../OperationalRecords";
import StartStopHistory from "../StartStopHistory";
import request from "utils/request";
import { useTranslation } from "react-i18next";
import "./styles.css";

const Alarms = observer(() => {

  const {
    wellStore,
    homeStore,
    userStore: { accessLevel },
    accessStore,
  } = useStore();

  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const sendNewAlarmRequest = async (values) => {
    await request.post("/alarm/add", values);
    // await fetchActiveAlarmsData();
    await alarmCountMutate();
    wellStore.resetIsAlarmsReload();
  };

  // const { data: activeAlarmsData, mutate: fetchActiveAlarmsData } =
  //   useRequest(
  //     wellStore.id ? `/alarm/${wellStore.id}` : null
  //   );
  const { mutate: alarmCountMutate }
    = useRequest(
      wellStore.id ? `/alarm/${wellStore.id}/count` : null
    );

  const { data: dataDeviationAlarms, mutate: fetchDeviationAlarms } =
    useRequest(
      wellStore.id ? `/alarm/deviationanalysis/get/${wellStore.id}` : null
    );

  const components = {
    1: (
      <ActiveAlarms
        // data={activeAlarmsData}
        // mutate={fetchActiveAlarmsData}
        alarmCountMutate={alarmCountMutate}
        dataDeviationAlarms={dataDeviationAlarms}
        fetchDeviationAlarms={fetchDeviationAlarms}
      />
    ),
    2: <ArchiveAlarms />,
    3: (
      <OperationalRecords
        addButtonAvailable={Boolean(accessStore.checkPermission("operational_records") == 2)} // {accessLevel==='ADMIN' || accessLevel==='TECHNICAL'}
      />
    ),
    4: <StartStopHistory />,
  };

  useEffect(() => {
    if (isModalVisible) {
      homeStore.setDraggableMode(false);

      return;
    }

    homeStore.setDraggableMode(true);
  }, [isModalVisible]);

  const [render, updateRender] = useState("1");

  const handleMenuClick = (menu) => {
    if (menu.key === "1") {
      fetchDeviationAlarms();
      updateRender(menu.key);
    } else if (menu.key === "5") {
      setIsModalVisible(true);
    } else {
      updateRender(menu.key);
    }

    wellStore.resetIsAlarmsReload();
  };

  return (
    <div
      style={{
        wordBreak: "break-all",
        display: "flex",
        flexFlow: "column",
        height: "calc(100% - 12px)",
      }}
    >
      <Menu onClick={handleMenuClick} selectedKeys={[render]} mode="horizontal" style={{ minWidth: "300px" }}>
        <Menu.Item
          key="1"
          className={
            render == 1
              ? "ant-menu-item-selected menu-item-style"
              : "menu-item-style"
          }
        >
          {t("activeAlarms")}
        </Menu.Item>
        <Menu.Item
          key="2"
          className={
            render == 2
              ? "ant-menu-item-selected menu-item-style"
              : "menu-item-style"
          }
        >
          {t("archiveAlarms")}
        </Menu.Item>
        {Boolean(accessStore.checkPermission("operational_records")) && (<Menu.Item
          key="3"
          className={
            render == 3
              ? "ant-menu-item-selected menu-item-style"
              : "menu-item-style"
          }
        >
          {t("operationalRecords")}
        </Menu.Item>)}
        <Menu.Item
          key="4"
          className={
            render == 4
              ? "ant-menu-item-selected menu-item-style"
              : "menu-item-style"
          }
        >
          {t('startStopLog')}
        </Menu.Item>
        {/* (accessLevel === 'ADMIN' || accessLevel === 'TECHNICAL') &&
        true &&
					<Menu.Item
							key="5"
							className={render == 5 ? "ant-menu-item-selected menu-item-style" : "menu-item-style"}
						>
							{t('addAlarm')}
					</Menu.Item>*/}

      </Menu>
      <div style={{ minWidth: "100%", flex: "1", overflowY: "hidden" }}>{components[render]}</div>
      <CustomAlarmModal
        visible={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
        sendNewAlarmRequest={sendNewAlarmRequest}
      />
    </div>
  );
});

export default Alarms;
