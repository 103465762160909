/* eslint-disable */
import { Form } from "react-final-form";
import { toJS } from "mobx";
import ModalFormField from "containers/Admin/components/ModalFormField";
import Modal from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

const EditLocationModal = ({ visible, onSubmit, onCancel, fields, location }) => {

  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={toJS(location)}
      render={({ handleSubmit, form, submitting, pristine, errors, submitErrors }) => (
        <Modal
          bodyStyle={{
            maxHeight: "calc(100vh - 220px)",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column"
          }}
          visible={visible}
          titleText={t("editLocation")}
          className="add-priority-modal"
          onCancel={() => { form.reset(); onCancel(); }}
          submitButton={(
            <Button
              text={t("apply")}
              disabled={submitting || pristine}
              onClick={async (event) => {
                if (Object.keys(errors).length !== 0) {
                  handleSubmit(event);
                  return;
                }

                if (!await handleSubmit(event))
                  form.restart();
              }}
            />
          )}
        >
          <div style={{ padding: "0px 20px", overflowY: "auto" }}>
            {fields.map(field => (
              <ModalFormField key={field.name} field={field} />
            ))}
          </div>
          <div style={{ padding: "20px", textAlign: "center", color: "red" }}>
            {submitErrors && t("errorHasOccured")}
          </div>
        </Modal>
      )}
    />
  );
}

export default EditLocationModal;
