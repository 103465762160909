/* eslint-disable */
import React, {
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from 'hooks/useStore'
import useRequest from 'hooks/useRequest'
import { PlusCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"
import 'antd/dist/antd.min.css'
import './styles.css'
import Tooltip from 'components/Tooltip'
import {
  Table,
  Input,
  Button,
  Form
} from 'antd'
import request from 'utils/request'
import { useTranslation } from 'react-i18next'
import dayjs from "dayjs";

const EditableContext = React.createContext(null)

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef(null)
  const form = useContext(EditableContext)
  const { t } = useTranslation()

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    setEditing(!editing)
    console.log([dataIndex])
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    })
  }

  const save = async () => {
    try {
      const values = await form.validateFields()
      form.setFieldsValue({ values })
      //toggleEdit()
      inputRef.current.blur()
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }

  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: t('missingComment'),
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

const OperationalRecords = observer(({ addButtonAvailable = true }) => {
  const { wellStore, userStore } = useStore()
  const { t } = useTranslation()
  const [columns, setColumns] = useState([
    {
      title: () => <div className="column-title">{t('dateAndTimeOfCreation')}</div>,
      dataIndex: 'createdAt',
      width: '180px',
      render: (text) => {
        // QURNA FIX
        // return <div>{text.replace("T", " ").split(".")[0]}</div>; // date

        // UTC TIME FIX
        const offsetHours = (new Date().getTimezoneOffset() * -1) / 60;

        let date = new Date(text);

        date.setHours(date.getHours() + offsetHours);
        date = dayjs(date).format("YYYY-MM-DDTHH:mm:ss");

        return <div>{text !== null ? date.replace("T", " ").split(".")[0] : "-"}</div>;
      },
    },
    {
      title: () => <div className="column-title">{t('iedcp')}</div>,
      dataIndex: 'uecn',
      width: '170px',
    },
    {
      title: () => <div className="column-title">{t('source')}</div>,
      dataIndex: 'source',
      width: '240px',
      render: text => (
        Number(text) !== 1
          ? <div>{t('manualInput')}</div>
          : <div>{t('fpw')}</div>),
    },
    {
      title: () => <div className="column-title">{t('active')}</div>,
      dataIndex: 'active',
      width: '100px',
      render: (text, record) => Number(record.source) !== 0 &&
        (Number(text) == 1
          ? <div>{t('added')}</div>
          : <div>{t('deleted')}</div>),
    },
    {
      title: () => <div className="column-title">{t('comment')}</div>,
      dataIndex: 'comment',
      width: '250px',
      editable: true,
    },
    {
      title: () => <div className="column-title">{t('user')}</div>,
      dataIndex: 'creator',
      width: '150px',
    },
  ])

  const [dataSource, setDataSource] = useState(null)
  const [count, setCount] = useState(null)
  const [isNewRecord, setIsNewRecord] = useState(false)
  const date = new Date().toISOString().substring(0, 10)
  const { data, mutate } = useRequest(
    wellStore.id ? `/alarm/operationalRecords/get/${wellStore.id}` : null,
  )
  const [rowData, setRowData] = useState(null)

  const sendNewOperationRecord = async values => {
    if (values?.comment !== null) {
      await request.post(`/alarm/operationalRecords/set/${wellStore.id}`, {
        source: 0,
        comment: values?.comment,
        active: 1,
      })
    }

    mutate(`/alarm/operationalRecords/get/${wellStore.id}`)
    await mutate()
  }

  useEffect(() => {
    if (data !== undefined) {
      setDataSource(data)
      setCount(data.length)
    }
    makeColumns()
    setIsNewRecord(false)
  }, [data])

  const handleDelete = () => {
    setRowData(null)
    if (isNewRecord) {
      setDataSource(dataSource.filter(item => item?.key !== count - 1))
      setCount(count - 1)
      setIsNewRecord(!isNewRecord)
    }
  }

  const handleAdd = () => {
    const creatorName = `${userStore.surname} ${userStore.name?.slice(0, 1)}.${userStore.patronymic?.slice(0, 1)}`
    const newData = {
      createdAt: date,
      uecn: wellStore.data.type,
      source: '0',
      active: '',
      comment: '',
      creator: creatorName,
      key: count,
    }

    if (!isNewRecord) {
      setCount(count + 1)
      setDataSource([newData, ...dataSource])
      setIsNewRecord(!isNewRecord)
    }
  }

  const handleSaveRowData = () => {
    if (rowData !== null) {
      sendNewOperationRecord(rowData)
    }
  }

  const handleSave = row => {
    setRowData(row)
  }

  const makeColumns = () => {
    const localColumns = columns.map(col => {
      if (!col.editable) {
        return col
      }

      return {
        ...col,
        onCell: record => ({
          record,
          editable: Number(record.key) >= count - 1 ? col.editable : false,
          dataIndex: col.dataIndex,
          title: col.title,
          /* eslint-disable-next-line */
          handleSave: handleSave,
          /* eslint-disable-next-line */
          handleDelete: handleDelete,
        }),
      }
    })

    setColumns(localColumns)
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  }

  const renderButton = () => {
    return (
      <>
        {
          addButtonAvailable && (
            <Tooltip title={t('addRecord')}>
              <Button
                onClick={handleAdd}
                shape="circle"
                bord={false}
                icon={<PlusCircleOutlined style={{ color: "#4B7EFE" }} />}
                style={{
                  margin: 8,
                  border: "none"
                }}
                disabled={isNewRecord}
              />
              {/* <PlusCircleOutlined /> */}
            </Tooltip>
          )
        }
        {
          isNewRecord && (
            <Tooltip title={t('apply')}>
              <Button
                onClick={handleSaveRowData}
                shape="circle"
                icon={<CheckCircleOutlined style={{ color: "#4B7EFE" }} />}
                style={{
                  margin: 8,
                  border: "none"
                }}
              />
            </Tooltip>
          )
        }
        {
          isNewRecord && (
            <Tooltip title={t('cancel')}>
              <Button
                onClick={handleDelete}
                shape="circle"
                icon={<CloseCircleOutlined style={{ color: "#4B7EFE" }} />}
                style={{
                  margin: 8,
                  border: "none"
                }}
              />
            </Tooltip>
          )
        }
      </>
    )
  }

  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      height: "100%",
      overflowY: "hidden"
    }}
    >
      <div style={{ width: 48 }}>
        {renderButton()}
      </div>
      <div className="alarm-content" style={{ flex: "1" }}>
        <Table
          components={components}
          rowClassName={() => 'editable-row alerts-row'}
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          className="alerts-table"
          sticky
        />
      </div>
    </div>
  )
})

export default OperationalRecords
