/* eslint-disable */
import { useCallback, useMemo, useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Empty } from "antd";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import minMax from "dayjs/plugin/minMax";
import { useStore } from "hooks/useStore";
import DateSetting from "./components/DateSetting";
import useTimeConverter from "hooks/useTimeConverter";
import PredictionModal from "./components/PredictionModal";
import UPlotChart from "./components/UPlotChart";
import { getIntersectionOfLineOnBorder } from "./getIntersectionBorder";
import request from "utils/request";
import { useTranslation } from "react-i18next";
import "./styles.css";

dayjs.extend(advancedFormat, minMax);

const ConfigureChart = observer(
  ({
    minimizedMode,
    expandScreenMode,
    fullScreenMode,
    realtimeSocket,
    paramsList,
    templateId,
    templateData,
    height,
    hoveredTrendsInfoParameter,
    showOnlyTargetScaleTooltip,
    translations,
  }) => {

    const scalesSettings = templateData?.scales;
    const renderSettings = useMemo(() => {
      return {
        parameters: templateData?.trends?.map((item) => {
          return {
            parameterId: item.parameterId,
            isMeander: item.isMeander,
            hovered: item.parameterId === hoveredTrendsInfoParameter?.id,
          };
        }),
        hovering: hoveredTrendsInfoParameter,
      };
    }, [templateData?.trends, hoveredTrendsInfoParameter]);

    const [isRealtime, setRealtime] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [data, setData] = useState(null);
    const { wellStore, trendsStore, homeStore } = useStore();
    const [zoomHistory, setZoomHistory] = useState([]);
    const [isPredictionModalVisible, setIsPredictionModalVisible] =
      useState(false);
    const [predictionDate, setPredictionDate] = useState(null);
    const [predictionMode, setPredictionMode] = useState(false);
    const [predictionPeriod, setPredictionPeriod] = useState(1);

    const fromUtcToLocale = useTimeConverter({
      toOffset: 0,
      fromOffsetDefault: new Date().getTimezoneOffset() / 60,
    });
    const fromLocaleToUtc = useTimeConverter({ toOffset: 0 });

    const memoBody = useMemo(() => {
      return {
        endDate: fromLocaleToUtc(trendsStore.period.endDate),
        startDate: fromLocaleToUtc(trendsStore.period.startDate),
      };
    }, [trendsStore.period]);

    const { i18n } = useTranslation();

    const storageTranslationsUnits = JSON.parse(
      sessionStorage.getItem("translationsUnits")
    );

    const currentLanguage = i18n.language;

    const { t } = useTranslation();

    const [leftMoveArrowHighlight, setLeftMoveArrowHighlight] = useState(false);
    const [rightMoveArrowHighlight, setRightMoveArrowHighlight] = useState(false);

    const leftMoveArrowHighlightTimeoutRef = useRef(null);
    const rightMoveArrowHighlightTimeoutRef = useRef(null);

    const isPeriodValid = (period) => {
      return period?.startDate != null && period?.endDate != null;
    }

    const movePeriodToLeft = useCallback(() => {
      if (!isPeriodValid(trendsStore.period)) return;
      const startDate = dayjs(trendsStore.period.startDate);
      const endDate = dayjs(trendsStore.period.endDate);

      const delta = endDate.diff(startDate, "seconds") / 12;

      const period = {
        startDate: startDate.subtract(delta, "seconds"),
        endDate: endDate.subtract(delta, "seconds")
      }

      setRealtime(false);

      setZoomHistory(prev => prev.concat(trendsStore.period));
      trendsStore.setPeriod(period);
      setLeftMoveArrowHighlight(true);
      clearTimeout(leftMoveArrowHighlightTimeoutRef.current);
      leftMoveArrowHighlightTimeoutRef.current = setTimeout(() => {
        setLeftMoveArrowHighlight(false);
      }, 250);
    }, []);

    const movePeriodToRight = useCallback(() => {
      if (!isPeriodValid(trendsStore.period)) return;
      const startDate = dayjs(trendsStore.period.startDate);
      const endDate = dayjs(trendsStore.period.endDate);

      const delta = endDate.diff(startDate, "seconds") / 12;

      if (endDate.add(delta, "seconds").isAfter(dayjs())) {
        if (isRealtime) return;
        const period = {
          startDate: dayjs().subtract(endDate.diff(startDate)),
          endDate: dayjs()
        }
        trendsStore.setPeriod(period);
      } else {
        const period = {
          startDate: startDate.add(delta, "seconds"),
          endDate: endDate.add(delta, "seconds")
        }
        setZoomHistory(prev => prev.concat(trendsStore.period));
        trendsStore.setPeriod(period);
      }

      setRightMoveArrowHighlight(true);
      clearTimeout(rightMoveArrowHighlightTimeoutRef.current);
      rightMoveArrowHighlightTimeoutRef.current = setTimeout(() => {
        setRightMoveArrowHighlight(false);
      }, 250);
    }, [isRealtime]);

    useEffect(() => {
      const handleKey = (e) => {
        const key = e.key;
        if (key === "ArrowLeft") movePeriodToLeft();
        else if (key === "ArrowRight") movePeriodToRight();
      }

      document.addEventListener("keyup", handleKey);
      return () => document.removeEventListener("keyup", handleKey)
    }, [movePeriodToLeft, movePeriodToRight]);

    const chooseTranslateFromArray = (alias, array, lang) => {
      if (alias === null) return alias;
      if (array == null) return alias;
      let id = array?.parameters?.findIndex((x) => x.alias === alias);
      id = array?.parameters[id].id;
      const translationIndex =
        array && array[lang].findIndex((x) => x.parameterId === id);

      return translationIndex >= 0
        ? array && array[lang][translationIndex].translation
        : alias;
    };

    const chooseTranslateFromArrayUnits = (unitId, array, lang) => {
      if (unitId === null) return "";
      if (array == null) return "";

      const id = array?.units?.findIndex((x) => x.id === unitId);
      const translationIndex = array[lang]?.findIndex(
        (x) => x.unitId === id + 1
      );

      return translationIndex >= 0
        ? array[lang][translationIndex].translation
        : array?.units[id] && array?.units[id].alias;
    };

    const getChartData = async (f) => {
      const { data } = await request.get(
        !(predictionMode && predictionDate)
          ? `/chart/${wellStore.id}/${templateId}/?endDate=${memoBody.endDate}&startDate=${memoBody.startDate}`
          : `/chart/prediction/${wellStore.id}/${templateId}/?endDate=${predictionDate}&startDate=${memoBody.startDate}&predictionDate=${predictionDate}`
      );

      data.forEach((object) => {
        object?.charts?.forEach((chart) => {
          chart.points.forEach((p) => {
            p.date = fromUtcToLocale(p.date);
          });

          if (chart.closestLeftPoint != null) {
            chart.closestLeftPoint.date = fromUtcToLocale(chart.closestLeftPoint.date);
          }

          if (chart.points.at(0) != null) {
            chart.points.at(0).isFakeProbably = true;
          }
          if (chart.points.at(-1) != null) {
            chart.points.at(-1).isFakeProbably = true;
          }

          if (chart.points.length === 2
            && chart.points.at(0).value === chart.points.at(-1).value) {
            chart.points = [chart.points.at(0)];
          }

          chart.name =
            chooseTranslateFromArray(
              chart.parameterAlias,
              translations,
              currentLanguage
            ) +
            ", " +
            chooseTranslateFromArrayUnits(
              chart.unitId,
              storageTranslationsUnits,
              currentLanguage
            );
        });
      });
      f(data);
    };

    useEffect(() => {
      if (isRealtime) return;

      let actualFetching = true;

      if (
        wellStore.id &&
        templateId &&
        memoBody &&
        trendsStore.period.endDate &&
        trendsStore.period.startDate &&
        templateData
      ) {
        setIsDataLoading(true);
        getChartData((data) => {
          if (actualFetching) {
            setData(data);
            setIsDataLoading(false);
          }
        });
      }

      return () => (actualFetching = false);
    }, [memoBody, isRealtime, wellStore.id, predictionDate, predictionMode, translations]);

    useEffect(() => {
      if (!isRealtime) return;

      let actualFetching = true;

      if (
        wellStore.id &&
        templateId &&
        memoBody &&
        trendsStore.period.endDate &&
        trendsStore.period.startDate &&
        templateData
      ) {
        setIsDataLoading(true);
        getChartData((data) => {
          if (actualFetching) {
            setData(data);
            setIsDataLoading(false);
          }
        });
      }

      return () => (actualFetching = false);
    }, [isRealtime]);

    useEffect(() => {
      if (predictionMode) {
        setRealtime(false);
      }
    }, [predictionMode]);

    useEffect(() => {
      setData(null);

      const t = setTimeout(() => {
        if (
          wellStore.id &&
          templateId &&
          memoBody &&
          trendsStore.period.endDate &&
          trendsStore.period.startDate &&
          templateData
        ) {
          getChartData((data) => setData(data));
        }
      }, 500);
      return () => clearTimeout(t);
    }, [templateData, templateId, translations]);

    const meanderParamsIds = useMemo(
      () => renderSettings?.parameters
        ?.filter(x => x.isMeander)
        .map(x => x.parameterId) ?? [],
      [renderSettings]);

    useEffect(() => {
      if (!isRealtime) return;
      if (!data) return;

      const addRealtimePoint = (realtimePoint) => {
        const newEndDate = dayjs();
        const newStartDate = newEndDate.subtract(1, "hour");

        if (!realtimePoint.ts) return;
        realtimePoint.ts = fromUtcToLocale(realtimePoint.ts);
        if (typeof realtimePoint.value === "string")
          realtimePoint.value = parseFloat(realtimePoint.value);
        const realtimePointTime = dayjs(realtimePoint.ts);

        if (realtimePointTime.isBefore(newStartDate)) {
          return;
        }

        const parameterId = paramsList.find(
          (i) => i.alias === realtimePoint.id
        )?.id;
        if (!parameterId) return;

        const newData = [];

        for (const scale of data) {
          const newScaleData = { ...scale };
          newScaleData.charts = [];
          for (const parameter of scale.charts) {
            const newParameterData = { ...parameter };

            const filtredNewPoints = [...parameter.points]
              .filter(point =>
                !dayjs(point.date).isBefore(newStartDate)
              );

            const firstNewPoint = filtredNewPoints.at(0);

            const lastOldPoint = [...parameter.points]
              .filter((point) =>
                dayjs(point.date).isBefore(newStartDate))
              .at(-1);

            const newClosestLeftPoint = [...parameter.points]
              .filter((point) =>
                dayjs(point.date).isBefore(newStartDate))
              .filter((point) => !point.isFakeProbably)
              .at(-1);

            if (newClosestLeftPoint) {
              newParameterData.closestLeftPoint = newClosestLeftPoint;
            }

            if (lastOldPoint && !firstNewPoint) {
              filtredNewPoints.unshift({
                date: newStartDate.format("YYYY-MM-DDTHH:mm:ss"),
                value: lastOldPoint.value,
                isFakeProbably: true
              });
            } else if (
              firstNewPoint &&
              lastOldPoint &&
              !newStartDate.isSame(dayjs(firstNewPoint.date))
            ) {
              if (meanderParamsIds.includes(parameter.parameterId)) {
                filtredNewPoints.unshift({
                  date: newStartDate.format("YYYY-MM-DDTHH:mm:ss"),
                  value: lastOldPoint.value,
                  isFakeProbably: true
                });
              } else {
                const borderPoint = getIntersectionOfLineOnBorder(
                  lastOldPoint,
                  firstNewPoint,
                  newStartDate
                );
                borderPoint.isFakeProbably = true;
                filtredNewPoints.unshift(borderPoint);
              }
            }

            if (parameter.parameterId === parameterId) {

              const lastPointTime = dayjs(filtredNewPoints.at(-1)?.date ?? null);

              let addRealPoint = true;

              if (
                lastPointTime &&
                (realtimePointTime.isBefore(lastPointTime) ||
                  realtimePointTime.isSame(lastPointTime))
              ) {
                addRealPoint = false;
              }

              if (addRealPoint) {
                filtredNewPoints.push({
                  value: realtimePoint.value,
                  date: realtimePoint.ts,
                });
              }
            }

            newParameterData.points = filtredNewPoints;
            newScaleData.charts.push(newParameterData);
          }

          newData.push(newScaleData);
        }

        trendsStore.setPeriod({
          startDate: newStartDate.format("YYYY-MM-DDTHH:mm:ss"),
          endDate: newEndDate.format("YYYY-MM-DDTHH:mm:ss"),
        });
        setData(newData);
      };

      if (!paramsList) return;

      if (!realtimeSocket) return;

      realtimeSocket.subscribe(
        paramsList.map((p) => p.alias),
        addRealtimePoint
      );

      return () => {
        realtimeSocket.unsubscribe(addRealtimePoint);
      };
    }, [paramsList, data, isRealtime, meanderParamsIds]);

    useEffect(() => {
      if (!isRealtime) return;
      if (!data) return;

      const timer = setInterval(() => {
        const newEndDate = dayjs();
        const newStartDate = newEndDate.subtract(1, "hour");

        const newData = [];

        for (const scale of data) {
          const newScaleData = { ...scale };
          newScaleData.charts = [];
          for (const parameter of scale.charts) {
            const newParameterData = { ...parameter };

            const filtredNewPoints = [...parameter.points]
              .filter(point =>
                !dayjs(point.date).isBefore(newStartDate)
              );

            const firstNewPoint = filtredNewPoints.at(0);

            const lastOldPoint = [...parameter.points]
              .filter((point) =>
                dayjs(point.date).isBefore(newStartDate))
              .at(-1);

            const newClosestLeftPoint = [...parameter.points]
              .filter((point) =>
                dayjs(point.date).isBefore(newStartDate))
              .filter((point) => !point.isFakeProbably)
              .at(-1);

            if (newClosestLeftPoint) {
              newParameterData.closestLeftPoint = newClosestLeftPoint;
            }

            if (lastOldPoint && !firstNewPoint) {
              filtredNewPoints.unshift({
                date: newStartDate.format("YYYY-MM-DDTHH:mm:ss"),
                value: lastOldPoint.value,
                isFakeProbably: true
              });
            } else if (
              firstNewPoint &&
              lastOldPoint &&
              !newStartDate.isSame(dayjs(firstNewPoint.date))
            ) {
              if (meanderParamsIds.includes(parameter.parameterId)) {
                filtredNewPoints.unshift({
                  date: newStartDate.format("YYYY-MM-DDTHH:mm:ss"),
                  value: lastOldPoint.value,
                  isFakeProbably: true
                });
              } else {
                const borderPoint = getIntersectionOfLineOnBorder(
                  lastOldPoint,
                  firstNewPoint,
                  newStartDate
                );
                borderPoint.isFakeProbably = true;
                filtredNewPoints.unshift(borderPoint);
              }
            }

            newParameterData.points = filtredNewPoints;
            newScaleData.charts.push(newParameterData);
          }

          newData.push(newScaleData);
        }
        trendsStore.setPeriod({
          startDate: newStartDate.format("YYYY-MM-DDTHH:mm:ss"),
          endDate: newEndDate.format("YYYY-MM-DDTHH:mm:ss"),
        });
        setData(newData);
        trendsStore
      }, 1000);

      return () => clearInterval(timer);
    }, [isRealtime, data, meanderParamsIds]);

    useEffect(() => {
      if (isRealtime && !data) {
        setRealtime(false);
      }
    }, [isRealtime]);

    useEffect(() => {
      if (isRealtime) {
        setPredictionMode(false);
      }
    }, [isRealtime]);

    useEffect(() => {
      if (!data) return [];

      const parameterIdsWithPoints = [];

      for (const scale of data) {
        for (const parameter of scale.charts) {
          if (parameter.points.length > 0) {
            parameterIdsWithPoints.push(parameter.parameterId);
          }
        }
      }

      trendsStore.setActiveTrendsWithPoints(parameterIdsWithPoints);
    }, [data]);

    const hasPoints =
      data &&
      data.find((item) =>
        item.charts.find((chart) => chart.points.length !== 0)
      );

    const handleChangePeriod = (period) => {
      setRealtime(false);
      setZoomHistory([]);
      trendsStore.setPeriod(period);
    };

    const handleZoom = useCallback(
      (period) => {
        setRealtime(false);

        if (zoomHistory.length === 0) {
          trendsStore.setPeriodLabelBeforeZoom(trendsStore.activePeriodLabel);
        }

        setZoomHistory((prev) => prev.concat(trendsStore.period));
        trendsStore.setPeriod({ ...period });
      },
      [zoomHistory]
    );

    const handleRealtime = (newRealTimeStatus) => {
      setRealtime(newRealTimeStatus);
    };

    const handleSetPredictionDate = (newPredictionDate) => {
      setPredictionDate(fromLocaleToUtc(newPredictionDate));
    };

    const handleSetPredictionMode = (newPredictionMode) => {
      setPredictionMode(newPredictionMode);
    };

    const handleSetPredictionPeriod = (newPredictionPeriod) => {
      setPredictionPeriod(newPredictionPeriod);
    };

    const handlePredictionModalClose = () => {
      setIsPredictionModalVisible(false);
    };

    const handlePredictionModalOpen = () => {
      if (homeStore.draggableMode) {
        homeStore.setDraggableMode(false);
      }

      setIsPredictionModalVisible(true);
    };

    const handleZoomRollback = () => {
      if (zoomHistory.length === 0) return;
      trendsStore.setPeriod(zoomHistory[zoomHistory.length - 1]);
      setZoomHistory((prev) => prev.slice(0, prev.length - 1));
    };

    const handleUserKeyPress = (event) => {
      const { key, ctrlKey } = event;
      if (ctrlKey && ["z", "Z", "я", "Я"].includes(key)) {
        handleZoomRollback();
      }
    };

    useEffect(() => {
      window.addEventListener("keydown", handleUserKeyPress);
      return () => {
        window.removeEventListener("keydown", handleUserKeyPress);
      };
    }, [handleUserKeyPress]);

    const filtredData = useMemo(() => {
      if (!data) return [];
      if (data.length === 0) return [];

      const filtred = structuredClone(data);

      filtred.forEach((scale) => {
        scale.charts.forEach((parameter) => {
          if (
            ![...trendsStore.activeTrends].find(
              (paramId) => paramId === parameter.parameterId
            )
          ) {
            parameter.points = [];
            parameter.isDisabled = true;
          } else {
            parameter.isDisabled = false;
          }
        });
      });

      return filtred;
    }, [data, trendsStore.activeTrends]);

    if (!templateId) {
      return (
        <div
          style={{
            color: "#777",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "100px",
          }}
        >
          {t("noTemplateChosen")}
        </div>
      );
    }

    if (paramsList?.length === 0) {
      return (
        <div
          style={{
            color: "#777",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "100px",
          }}
        >
          {t("noParametersInTemplate")}
        </div>
      );
    }

    if (!data) {
      return (
        <div className="block-container">
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div
            style={{
              flex: "0 0 auto",
              marginTop: "auto",
            }}
          >
            <DateSetting
              period={trendsStore.period}
              handleChangePeriod={handleChangePeriod}
              isActive={true}
              realtimeClickable={false}
              onRealtimeClick={handleRealtime}
              isRealtime={isRealtime}
              predictionMode={predictionMode}
              leftMoveArrowHighlight={leftMoveArrowHighlight}
              rightMoveArrowHighlight={rightMoveArrowHighlight}
              handleLeftMoveArrowClick={movePeriodToLeft}
              handleRightMoveArrowClick={movePeriodToRight}
            />
          </div>
        </div>
      );
    }

    if (!hasPoints) {
      const margin = fullScreenMode
        ? (height - 89 - 22 - 64 - 70 - 100) / 2
        : (height - 89 - 22 - 64 - 70 - 100) / 2;
      return (
        <>
          <div style={{ marginTop: margin, marginBottom: margin }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
          <div
            style={{
              flex: "0 0 auto",
              marginTop: "auto",
            }}
          >
            <DateSetting
              period={trendsStore.period}
              handleChangePeriod={handleChangePeriod}
              isActive={true}
              onRealtimeClick={handleRealtime}
              isRealtime={isRealtime}
              predictionMode={predictionMode}
              leftMoveArrowHighlight={leftMoveArrowHighlight}
              rightMoveArrowHighlight={rightMoveArrowHighlight}
              handleLeftMoveArrowClick={movePeriodToLeft}
              handleRightMoveArrowClick={movePeriodToRight}
            />
          </div>
        </>
      );
    }

    return (
      <div className="block-container">
        <div
          className="chart-container"
          onDrag={(e) => e.stopPropagation()}
          style={{
            height: "calc(100% - 100px)",
          }}
        >
          {filtredData && (
            <UPlotChart
              data={filtredData}
              handleZoom={handleZoom}
              period={trendsStore.period}
              scalesSettings={scalesSettings}
              renderSettings={renderSettings}
              showOnlyTargetScaleTooltip={showOnlyTargetScaleTooltip}
            />
          )}
        </div>
        <div
          style={{
            flex: "0 0 auto",
            marginTop: "auto",
          }}
        >
          <DateSetting
            period={trendsStore.period}
            handleChangePeriod={handleChangePeriod}
            isActive={true}
            onRealtimeClick={handleRealtime}
            isRealtime={isRealtime}
            handleChartPredictionVisible={handlePredictionModalOpen}
            predictionMode={predictionMode}
            leftMoveArrowHighlight={leftMoveArrowHighlight}
            rightMoveArrowHighlight={rightMoveArrowHighlight}
            handleLeftMoveArrowClick={movePeriodToLeft}
            handleRightMoveArrowClick={movePeriodToRight}
          />
        </div>

        <PredictionModal
          handleSetPredictionDate={handleSetPredictionDate}
          handleSetPredictionMode={handleSetPredictionMode}
          handleSetPredictionPeriod={handleSetPredictionPeriod}
          predictionMode={predictionMode}
          predictionPeriod={predictionPeriod}
          visible={isPredictionModalVisible}
          closeModal={handlePredictionModalClose}
        />

        {isDataLoading &&
          <div
            className="lds-roller"
            style={{
              zIndex: 1000,
              position: "absolute",
              top: "25%",
              bottom: "0",
              left: "0",
              right: "0",
              margin: "0 auto",
            }}
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        }
      </div>
    );
  }
);

export default ConfigureChart;
