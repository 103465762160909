/* eslint-disable */
import { useMemo, useState } from "react";
import useDataTooltip from "./useDataTooltip";
import useZoom from "./useZoom";
import useSaveCursor from "./useSaveCursor";
import { processData, getSeriesOfScale } from "../dataProcessing";
import { defaultOptions } from "../options";

const useChart = ({
  data,
  period,
  handleZoom,
  scalesSettings,
  renderSettings,
  containerWidth,
  containerHeight
}) => {

  const processedData = useMemo(() => {
    if (data == null) return null;
    const processed = processData(data, period);
    // console.log("processed data:", processed);
    return processed;
  }, [data]);

  const scaleDatas = useMemo(() => {
    if (processData == null) return null;
    const timestamps = processedData.timestamps;
    if (!processedData || processedData.scales.length === 0) return [];
    const scaleDatas = processedData.scales.map((scale) => {
      return [timestamps, ...scale.data];
    });
    return scaleDatas;
  }, [processedData]);

  const scalesCount = processedData.scales.length;
  const [tooltipData, setTooltipData] = useState({ scales: [] });

  const onZoom = (zoom) => {
    const period = {
      startDate: new Date(zoom.min * 1000),
      endDate: new Date(zoom.max * 1000),
    }
    handleZoom(period);
  }

  const tooltipPlugin = useDataTooltip({ setData: setTooltipData });
  const zoomPlugin = useZoom(onZoom);
  const saveCursorPlugin = useSaveCursor();

  const options = useMemo(() => {
    return processedData.scales.map((scale, index) => {
      const series = getSeriesOfScale(scale, renderSettings);
      const scaleParametersClosestLeftPoints = scale
        .parameters.map(param => param.closestLeftPoint);
      const plugins = [
        saveCursorPlugin(),
        tooltipPlugin(index, scaleParametersClosestLeftPoints)
      ];
      if (index === 0) plugins.push(zoomPlugin()); // нужен лишь один для всех шкал

      const yScale = {
        auto: true,
      }
      const currentScaleOpts = scalesSettings?.find(
        (x) => x.scaleNumber === scale.id
      );
      if (currentScaleOpts && !currentScaleOpts.auto) {
        yScale.auto = false;
        yScale.range = [currentScaleOpts.min, currentScaleOpts.max];
      }

      const cursor = {
        ...defaultOptions.cursor,
        dataIdx: (u,
          seriesIndex,
          index,
          timestampAtCursor
        ) => {
          if (seriesIndex === 0) return null;

          const seriesData = u.data[seriesIndex];
          const timestamps = u.data[0];

          if (timestampAtCursor >= timestamps[index] && seriesData[index] != null) {
            if (index !== u.data[seriesIndex].length - 1) {
              if (index === 0) {
                if (scaleParametersClosestLeftPoints[seriesIndex - 1].timestamp === timestamps[0]) {
                  return 0;
                }
                return -1;
              }
              return index;
            }
          }

          while (index > 0) {
            index--;
            if (seriesData[index] != null) break;
          }

          if (index === 0) {
            if (scaleParametersClosestLeftPoints[seriesIndex - 1].timestamp === timestamps[0]) {
              return 0;
            }
            return -1;
          }
          return index;
        },
      }

      const opts = {
        ...defaultOptions,
        series,
        scales: {
          ...defaultOptions.scales,
          y: { ...defaultOptions.scales.y, ...yScale },
        },
        width: Math.floor(containerWidth),
        height: containerHeight / scalesCount,
        plugins,
        cursor
      };

      return opts;
    });
  }, [
    processedData,
    containerWidth,
    containerHeight,
    scalesSettings,
    renderSettings
  ]);

  const scaleHeight = containerHeight / scalesCount;

  const tooltipDataExist = tooltipData?.scales.some((scale) =>
    scale?.points.some((point) => point?.value !== null)
  );

  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    const bounds = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    setMousePos({ x, y });
  };

  const targetScaleIndex = useMemo(() => {
    return Math.floor(mousePos.y / scaleHeight);
  }, [mousePos.y, scaleHeight]);

  return {
    processedData,
    scaleDatas,
    options,
    tooltipData,
    tooltipDataExist,
    scaleHeight,
    targetScaleIndex,
    handleMouseMove
  };

};

export default useChart;
