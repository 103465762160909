import { Tooltip } from 'antd'
import './styles.css'

const CustomTooltip = ({
  tooltipText,
  className,
  children,
  ...rest
}) => (
  <Tooltip
    color="#5B6378"
    placement="topLeft"
    title={tooltipText}
    destroyTooltipOnHide
    overlayClassName={`custom-tooltip ${className || ''}`}
    {...rest}
  >
    {children}
  </Tooltip>
)

export default CustomTooltip
