/*eslint-disable*/
import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useResizeDetector } from "react-resize-detector";
import { useStore } from "hooks/useStore";
import WellTree from "containers/Home/components/WellTree";
import CommonAnalysis from "./components/CommonAnalysis";
import WellAnalysis from "./components/WellAnalysis";
import ActiveAlarms from "./components/ActiveAlarms";
import ArchiveAlarms from "./components/ArchiveAlarms";
import Footer from "./components/Footer";
import request from "utils/request";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import "./styles.css";

dayjs.extend(advancedFormat);

const DeviationAnalysis = observer(({
  activeTab
}) => {

  const { deviationAnalysisStore } = useStore();

  const period = deviationAnalysisStore.period;
  const pickedWellIds = deviationAnalysisStore.pickedWellIds;

  const [data, setData] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const isReportFormationAvailable = pickedWellIds.length > 0
    && period.startDate != null && period.endDate != null;

  const saveLastDataAndPeriodInStore = (data, period) => {
    deviationAnalysisStore.setLastDataPeriod(period);
    deviationAnalysisStore.setLastData(data);
  }

  const loadLastDataFromStore = () => {
    const lastData = deviationAnalysisStore.lastData;
    if (lastData !== null) {
      setData(toJS(lastData));
    }
  }

  useEffect(() => {
    loadLastDataFromStore();
  }, []);

  const clearData = () => {
    setData(null);
    saveLastDataAndPeriodInStore(null, null);
  }

  const fetchData = async (f, body) => {
    const { data } = await request.post(
      "/settings/deviation/analysis/get",
      body
    );
    f(data);
  }

  const formReport = async () => {
    if (!isReportFormationAvailable) return;

    clearData();
    setIsLoadingData(true);

    const startDate = period.startDate + "T00:00:00.000";
    const endDate = dayjs(period.endDate)
      .add(1, "day").format("YYYY-MM-DD") + "T00:00:00.000";

    const body = {
      wellIds: pickedWellIds,
      startInterval: startDate,
      endInterval: endDate,
    }

    try {
      await fetchData((data) => {
        setData(data);
        saveLastDataAndPeriodInStore(data, {
          startDate,
          endDate,
        });
      }, body);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingData(false);
    }
  }

  const dataPeriod = deviationAnalysisStore.lastDataPeriod ?? null;

  const { width: divContentWidth, height: divContentHeight, ref: divContentRef } = useResizeDetector();

  return (
    <>
      <div className="deviation-analysis-container">
        <WellTree showWells={false} />

        {activeTab.name === "activeAlarms" &&
          <div ref={divContentRef} className="deviation-analysis-content">
            <ActiveAlarms />
          </div>
        }

        {activeTab.name === "archiveAlarms" &&
          <div ref={divContentRef} className="deviation-analysis-content">
            <ArchiveAlarms />
          </div>
        }

        {activeTab.name === "deviation" &&
          <>
            <div className="deviation-analysis-container">
              <Footer
                isReportFormationAvailable={isReportFormationAvailable}
                isExistReportData={data !== null}
                reportWellsCount={data?.totalWellsCount ?? null}
                reportProblemWellsCount={data?.totalProblemWellsCount ?? null}
                formReport={formReport}
              />

              <div ref={divContentRef} className="deviation-analysis-content">
                {activeTab.subTabKey === "1" &&
                  <CommonAnalysis
                    data={data}
                    isLoadingData={isLoadingData}
                    dataPeriod={dataPeriod}
                    divContentWidth={divContentWidth}
                    divContentHeight={divContentHeight}
                  />
                }
                {activeTab.subTabKey === "2" &&
                  <WellAnalysis
                    data={data?.problemWellsList ?? null}
                    isLoadingData={isLoadingData}
                  />
                }
              </div>
            </div>
          </>
        }
      </div>
    </>
  );
});

export default DeviationAnalysis;
