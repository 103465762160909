/* eslint-disable */
import { createRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { task } from "mobx-fetch-task";
import { Menu, Dropdown, Button, Space, Input, Upload } from "antd";
import { SnippetsOutlined, PushpinOutlined } from "@ant-design/icons";
import { useStore } from "hooks/useStore";
import Tooltip from "components/Tooltip";
import { useTranslation } from "react-i18next";
import request from "utils/request";

const MnemoschemeTemplateSettings = observer(({

}) => {

  const { t } = useTranslation();

  const { mnemoStore } = useStore();

  const [renameModeOn, setRenameModeOn] = useState(false);

  const renameSelectedTemplate = async () => {
    const name = textFieldRef.current.input.value;

    disableRenameMode();

    await mnemoStore.renameSelectedTemplate(name);
  }

  const deleteSelectedTemplate = async () => {
    await mnemoStore.deleteSelectedTemplate();
  }

  const enableRenameMode = () => {
    setRenameModeOn(true);
  }

  const disableRenameMode = () => {
    setRenameModeOn(false);
  }

  const handleCreateTemplate = async () => {
    let n = 1;
    let name = "Новый шаблон";

    while (mnemoStore.templates
      .find((template) => template.name
        .includes(name + " " + n.toString()))) {
      n += 1;
    }

    name = name + " " + n.toString();

    const template = await mnemoStore.createTemplate(name);

    mnemoStore.selectTemplate(template.id);
  }

  const [uploadImageTask] = useState(() => task(async (file) => {
    const formData = new FormData();
    formData.set("file", file);

    const { data: url } = await request
      .post(
        `/mnemoTemplates/${mnemoStore.selectedTemplateId}/image`,
        formData);

    for (const table of mnemoStore.tableListStore?.tables) {
      mnemoStore.tableListStore.setPosition(
        table.id,
        { x: 0, y: 0 });
    }

    if (mnemoStore.selectedTemplate) {
      mnemoStore.selectedTemplate.url = url;
    }
  }));

  const templateElements = mnemoStore.templates.map(tmpl => (
    <Menu.Item
      onClick={() => mnemoStore.selectTemplateAndSave(tmpl.id)}
      key={tmpl.id}
      icon={tmpl.isDefault ? <PushpinOutlined /> : <SnippetsOutlined />}
    >
      {tmpl.name}
    </Menu.Item>
  ));

  templateElements.unshift(
    <Menu.Item onClick={handleCreateTemplate} key="add_new_btn" danger={true}>
      {t("createNewTemplate")}
    </Menu.Item>
  );

  const menu = <Menu>{templateElements}</Menu>;

  const textFieldRef = createRef();

  if (renameModeOn) {
    return (
      <>
        <Space
          style={{
            display: "flex",
            alignContent: "stretch",
            justifyContent: "space-between",
            padding: "5px 25px",
            width: "650px",
          }}
          direction="horizontal"
        >
          <Space wrap>
            <b>{t("template")}:</b>
            <Input
              ref={textFieldRef}
              placeholder={"Имя шаблона"}
              defaultValue={mnemoStore.selectedTemplate?.name}
              style={{ width: "270px" }}
            />
          </Space>
          <Space wrap>
            <Button onClick={renameSelectedTemplate}>{t("Изменить")}</Button>
            <Button onClick={disableRenameMode}>{t("Отменить")}</Button>
          </Space>
        </Space>
        <Space></Space>
      </>
    );
  } else {
    return (
      <div
        style1={{ display: "flex", flexDirection: "column", width: "700px" }}
      >
        <Space
          style={{
            display: "flex",
            alignContent: "stretch",
            justifyContent: "space-between",
            padding: "5px 25px",
            width: "650px",
          }}
          direction="horizontal"
        >
          <Space wrap>
            <b>{t("template")}:</b>
            <Tooltip title="Название шаблона">
              <Dropdown.Button
                overlay={menu}
                className={"block"}
                trigger={["click"]}
              >
                {mnemoStore.selectedTemplate?.name ?? t("Шаблон не выбран")}
              </Dropdown.Button>
            </Tooltip>
          </Space>
          {mnemoStore.selectedTemplate && !mnemoStore.selectedTemplate?.isDefault ? ( // do not show delete and rename buttons if chosen template is the default template
            <Space wrap>
              <Button onClick={enableRenameMode}>{t("Переименовать")}</Button>
              <Button onClick={deleteSelectedTemplate}>{t("Удалить")}</Button>
            </Space>
          ) : (
            <></>
          )}
        </Space>

        {mnemoStore.selectedTemplate &&
          <Space
            style={{
              display: "flex",
              alignContent: "stretch",
              justifyContent: "flex-start",
              padding: "5px 25px",
              width: "650px",
            }}
          >
            <b>Выбор изображения:</b>
            <Upload
              beforeUpload={() => false}
              onChange={(data) => uploadImageTask(data.file)}
              showUploadList={false}
              multiple={false}
              accept=".jpg, .png, .jpeg"
            >
              <Button>{t("Загрузить изображение")}</Button>
            </Upload>
          </Space>
        }
      </div>
    );
  }
});

export default MnemoschemeTemplateSettings;
