/* eslint-disable */
import uPlot from "uplot";
import { monthsRuNames } from "./common";

const plotSync = uPlot.sync("key");

export const defaultOptions = {
  width: 600,
  height: 600,
  fmtDate: (tpl) => uPlot.fmtDate(tpl, monthsRuNames),
  legend: {
    show: false,
  },
  scales: {
    x: {
      time: true,
    },
    y: {
      range: (u, min, max) => {
        if (min === null) return [0, 100];
        return uPlot.rangeNum(min - 0.25, max + 0.25, 0.1, true);
      },
    },
  },
  axes: [
    {
      font: "12px Roboto",
      stroke: "#6B6C70",
      // show: true,
      // incrs: [ // чтобы не было видно до миллисекунд точность
      //   // minute divisors (# of secs)
      //   1,
      //   5,
      //   10,
      //   15,
      //   30,
      //   // hour divisors
      //   60,
      //   60 * 5,
      //   60 * 10,
      //   60 * 15,
      //   60 * 30,
      //   // day divisors
      //   3600,
      //   3600 * 7,
      //   3600 * 24,
      //   3600 * 7 * 24,
      //   3600 * 24 * 365
      //   // 3600 * 7 * 30
      //   // ...
      // ],
      values: [
        // tick incr          default           year                             month    day                        hour     min                sec       mode
        [3600 * 24 * 365, "{YYYY}", null, null, null, null, null, null, 1],
        [3600 * 24 * 28, "{MMM}", "\n{YYYY}", null, null, null, null, null, 1],
        [3600 * 24, "{DD}.{MM}", "\n{YYYY}", null, null, null, null, null, 1],
        [
          3600,
          "{HH}:{mm}",
          "\n{DD}.{MM}.{YY}",
          null,
          "\n{DD}.{MM}",
          null,
          null,
          null,
          1,
        ],
        [
          60,
          "{HH}:{mm}",
          "\n{DD}.{MM}.{YY}",
          null,
          "\n{DD}.{MM}",
          null,
          null,
          null,
          1,
        ],
        [
          1,
          ":{ss}",
          "\n{DD}.{MM}.{YY} {HH}:{mm}",
          null,
          "\n{DD}.{MM} {HH}:{mm}",
          null,
          "\n{HH}:{mm}",
          null,
          1,
        ],
        [
          0.001,
          ":{ss}.{fff}",
          "\n{DD}.{MM}.{YY} {HH}:{mm}",
          null,
          "\n{DD}.{MM} {HH}:{mm}",
          null,
          "\n{HH}:{mm}",
          null,
          1,
        ],
      ],
    },
    {
      font: "12px Roboto",
      stroke: "#6B6C70"
    }
  ],
  series: [{}],
  cursor: {
    sync: {
      key: plotSync.key,
    },
    drag: {
      x: true,
      y: false,
    },
    points: {
      size: 13,
    },
  },
};
