/* eslint-disable */
import { useEffect, useState } from "react";
import { Table } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "hooks/useStore";
import request from "utils/request";
import config from "./table.config";
import { useTranslation } from "react-i18next";
import AlarmInfoModal from "./components/AlarmInfoModal";
import AlarmDeviationAnalysisModal from "./components/AlarmDeviationAnalysisModal";
import "./styles.css";

const ActiveAlarms = observer(
  ({
    data,
    mutate,
    alarmCountMutate,
    dataDeviationAlarms,
    fetchDeviationAlarms,
  }) => {
    const {
      wellStore,
      userStore: { accessLevel },
    } = useStore();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [tableData, setTableData] = useState(null);
    const handleCloseAlarm = async (id, comment) => {
      result.comment = comment;
      result.alarmId = id;
      await request.post(`/alarm/${id}/close`, result);
      wellStore.resetIsAlarmsReload();
      await mutate();
      await alarmCountMutate();
    };
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const storageTranslationsWorkReasons = JSON.parse(
      sessionStorage.getItem("translationsWorkReasons")
    );

    const result = {
      userId: 1,
      comment: null,
    };

    const sendNewActionsRequest = async (values) => {
      await request.post("/alarm/works/add", values);
      wellStore.resetIsAlarmsReload();
      await mutate();
      await alarmCountMutate();
    };

    const sendNewObservationRequest = async (values) => {
      await request.post("/alarm/observations/add", values);
      wellStore.resetIsAlarmsReload();
      await mutate();
      await alarmCountMutate();
    };

    const handleCloseWork = async (alarmId, workId, comment) => {
      result.comment = comment;
      result.alarmId = alarmId;
      await request.post(`/alarm/works/close/${workId}`, result);
      wellStore.resetIsAlarmsReload();
      await mutate();
      await alarmCountMutate();
    };

    const handleDoneWork = async (alarmId, workId, comment) => {
      result.comment = comment;
      result.alarmId = alarmId;
      await request.post(`/alarm/works/done/${workId}`, result);
      wellStore.resetIsAlarmsReload();
      await mutate();
    };

    const handleCloseObservation = async (alarmId, observationId, comment) => {
      result.comment = comment;
      result.alarmId = alarmId;
      await request.post(`/alarm/observations/close/${observationId}`, result);
      wellStore.resetIsAlarmsReload();
      await mutate();
    };

    const handleDoneObservation = async (alarmId, observationId, comment) => {
      result.comment = comment;
      result.alarmId = alarmId;
      await request.post(`/alarm/observations/done/${observationId}`, result);
      wellStore.resetIsAlarmsReload();
      await mutate();
    };

    const handleCloseDeviationAnalysisAlarm = async (reasons) => {
      await request.post(
        `/alarm/deviationanalysis/update/${wellStore.id}`,
        reasons
      );
      wellStore.resetIsAlarmsReload();
      await fetchDeviationAlarms();
    };

    const getRecord = (record) => {
      setSelectedRecord(record);
    };

    useEffect(() => {
      if (!dataDeviationAlarms) return;
      dataDeviationAlarms.forEach((element) => {
        if (element.type == "CUSTOM") {
          if (
            storageTranslationsWorkReasons["ru"][
              element?.selectedActions?.workReasonId - 1
            ]?.translation
          )
            element.name =
              storageTranslationsWorkReasons["ru"][
                element.selectedActions.workReasonId - 1
              ].translation;
        }
      });
      setTableData(dataDeviationAlarms);
    }, [dataDeviationAlarms]);

    return (
      <div className="alarm-content">
        <Table
          columns={config(
            wellStore?.dataDeviationAlarms?.name,
            handleCloseAlarm,
            wellStore.id,
            handleCloseWork,
            handleDoneWork,
            handleCloseObservation,
            handleDoneObservation,
            sendNewActionsRequest,
            sendNewObservationRequest,
            true,
            getRecord,
            setIsModalVisible,
            isModalVisible
            // (accessLevel === 'ADMIN' || accessLevel === 'TECHNICAL'), //show options icon
          )}
          dataSource={tableData?.map((item) => ({ ...item, key: item.id }))}
          pagination={false}
          className="alerts-table"
          rowClassName="alerts-row"
          sticky
        // onRow={(record) => ({
        //   onClick: () => {
        //     getRecord(record);
        //     setIsModalVisible(true);
        //   },
        // })}
        />
        {/* <AlarmInfoModal
        visible={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
        data={rec}
        wellName={wellStore?.data?.name}
        // index={index}
        well_id={wellStore.id}
        closeWork={handleCloseWork}
        doneWork={handleDoneWork}
        closeObservation={handleCloseObservation}
        doneObservation={handleDoneObservation}
        handleCloseObservation={handleCloseObservation}
        handleDoneObservation={handleDoneObservation}
        sendNewActionsRequest={sendNewActionsRequest}
        handleCloseAlarm={handleCloseAlarm}
        sendNewObservationRequest={sendNewObservationRequest}
        handleCloseDeviationAnalysisAlarm={handleCloseDeviationAnalysisAlarm}
      /> */}

        <AlarmDeviationAnalysisModal
          visible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          data={selectedRecord}
          handleCloseDeviationAnalysisAlarm={handleCloseDeviationAnalysisAlarm}
          isAlarmArchived={false}
        />
      </div>
    );
  }
);

export default ActiveAlarms;
