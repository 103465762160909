/* eslint-disable */
import { InputNumber } from "antd";
import "./styles.css";

const CustomInputNumber = ({ className, label, input, passwordField, ...rest }) => {
  const isError =
    rest.meta.touched &&
    (rest.meta.error ||
      (rest.meta.submitError &&
        !rest.meta.dirtySinceLastSubmit &&
        rest.meta.submitFailed));

  return (
    <div className={`custom-input ${className || ""}`}>
      {label && <div className="input-label">{label}</div>}
      <InputNumber {...input} {...rest} status={isError && "error"} />
      {isError && (
        <div className="input-error">
          {rest.meta.error || rest.meta.submitError}
        </div>
      )}
    </div>
  );
};

export default CustomInputNumber;
