/* eslint-disable */
import { useState, useEffect } from "react";
import { ReactComponent as RedStatusIcon } from "assets/images/redStatusIcon.svg";
import { ReactComponent as YellowStatusIcon } from "assets/images/yellowStatusIcon.svg";
import { ReactComponent as OptionsIcon } from "assets/images/optionsIcon.svg";
import { useTranslation } from "react-i18next";
import { useStore } from "hooks/useStore";
import AlarmInfoModal from "./components/AlarmInfoModal";
import "./styles.css";
import dayjs from "dayjs";

const config = (
  wellName,
  onCloseClick,
  // onAlarmClick,
  wellId,
  handleCloseWork,
  handleDoneWork,
  handleCloseObservation,
  handleDoneObservation,
  sendNewActionsRequest,
  sendNewObservationRequest,
  showOptions = true,
  getRecord,
  setIsModalVisible,
  isModalVisible
) => {
  // const [isModalVisible, setIsModalVisible] = useState(false);
  const [recordLocal, setRecordLocal] = useState(null);

  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const storageTranslationsWorks = JSON.parse(
    sessionStorage.getItem("translationsWorks")
  );
  const storageTranslations = JSON.parse(
    sessionStorage.getItem("translations")
  );

  const { homeStore, accessStore } = useStore();

  useEffect(() => {
    if (isModalVisible) {
      homeStore.setDraggableMode(false);

      return;
    }

    homeStore.setDraggableMode(true);
  }, [isModalVisible]);

  const isRemainTime = (observationItem) => {
    const dateCreation = new Date(observationItem.creationDate);

    let dateNow = new Date();

    dateNow = Date.now();

    // QURNA FIX
    // const offsetHours = 0; // (new Date().getTimezoneOffset() * -1) / 60;

    // UTC TIME FIX
    const offsetHours = (new Date().getTimezoneOffset() * -1) / 60;

    const remainTime =
      dateCreation.getTime() +
      offsetHours * 3600 * 1000 +
      observationItem?.observationHours * 3600 * 1000 * 1 -
      dateNow;

    if (remainTime > 0) {
      return true;
    }

    return false;
  };

  const calculateRemainObservationTime = (observationItem) => {
    const dateCreation = new Date(observationItem.creationDate);

    let dateNow = new Date();

    dateNow = Date.now();

    // QURNA FIX
    // const offsetHours = 0; // (new Date().getTimezoneOffset() * -1) / 60;

    // UTC TIME FIX
    const offsetHours = (new Date().getTimezoneOffset() * -1) / 60;

    const remainTime =
      dateCreation.getTime() +
      offsetHours * 3600 * 1000 +
      observationItem?.observationHours * 3600 * 1000 -
      dateNow;

    const remainTimeInHoursAndMinutes = `${Math.floor(
      new Date(remainTime).getTime() / 3600000
    )}:${new Date(remainTime).getMinutes()}`;

    return remainTimeInHoursAndMinutes;
  };

  const chooseTranslateFromArray = (id, array, lang) => {
    const translationIndex =
      array &&
      array[lang] &&
      array[lang].findIndex((x) => x.parameterId === id);
    const parameterIndex =
      array &&
      array?.parameters &&
      array?.parameters.findIndex((x) => x.id === id);

    if (translationIndex >= 0) {
      return array[lang][translationIndex].translation
        ? array[lang][translationIndex].translation
        : array?.parameters[parameterIndex].alias;
    }

    return parameterIndex >= 0 ? array?.parameters[parameterIndex].alias : id;
  };

  return [
    {
      title: () => (
        <div className="column-title">{t("dateAndTimeOfCreation")}</div>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      width: "180px",
      // render: (text) => <div>{text.replace("T", " ").split(".")[0]}</div>,
      render: (text) => {
        // QURNA FIX
        // return <div>{text.replace("T", " ").split(".")[0]}</div>; // date

        // UTC TIME FIX
        const offsetHours = (new Date().getTimezoneOffset() * -1) / 60;

        let date = new Date(text);

        date.setHours(date.getHours() + offsetHours);
        date = dayjs(date).format("YYYY-MM-DDTHH:mm:ss");

        return <div>{date.replace("T", " ").split(".")[0]}</div>;
      },
    },
    {
      title: <div className="column-title">{t("warningType")}</div>,
      dataIndex: "type",
      key: "type",
      width: "170px",
      // render: (text) => <div>{text}</div>,
      render: (text) => {
        let warningTranslation = "";
        switch (text) {
          case "CUSTOM": {
            warningTranslation = "Пользовательский";
            break;
          }
          case "TREND": {
            warningTranslation = "Трендовый";
            break;
          }
          case "INSTANTANEOUS": {
            warningTranslation = "Анализ отклонений";
            break;
          }
          case "POTENTIAL": {
            warningTranslation = "Потенциальный";
            break;
          }
          default: {
            warningTranslation = text;
          }
        }
        return <div>{warningTranslation}</div>;
      },
    },
    {
      title: <div className="column-title">{t("warningName")}</div>,
      dataIndex: "name",
      key: "name"
    },
    {
      title: <div className="column-title" style={{ wordBreak: "keep-all", overflowWrap: "normal" }}>{t("status")}</div>,
      dataIndex: "status",
      key: "status",
      width: "55px",
      render: (text) =>
        <div style={{ textAlign: "center", height: "18px" }}>
          {text === "OPEN" ? <RedStatusIcon /> : <YellowStatusIcon />}
        </div>,
    },
    {
      title: <div className="column-title">{t("parameter")}</div>,
      dataIndex: "warningParamId",
      key: "warningParamId",
      width: "300px",
      render: (text) => (
        // <div>
        //   {storageTranslations &&
        //     chooseTranslateFromArray(
        //       text,
        //       storageTranslations,
        //       currentLanguage
        //     )}
        // </div>

        <>{t("WATER_DEBIT")}</>
      ),
    },
    // {
    //   title: <div className="column-title">{t("action")}</div>,
    //   dataIndex: "selectedActions",
    //   key: "selectedActions",
    //   width: "35%",
    //   render: (text, record) => (
    //     <div>
    //       {record.selectedActions?.selectedObservations.map((item, index) => (
    //         <div key={index}>
    //           <div>
    //             {t("observe")} {t("within")} {item.observationHours}{" "}
    //             {t("shortHours")}
    //           </div>
    //           <div>
    //             {t("remain")}{" "}
    //             {isRemainTime(item) ? calculateRemainObservationTime(item) : 0}{" "}
    //             {t("shortHours")}
    //           </div>
    //         </div>
    //       ))}
    //       {record.selectedActions?.selectedWorks.map((item, index) => (
    //         <div key={index}>
    //           <div>
    //             {storageTranslationsWorks &&
    //               storageTranslationsWorks[currentLanguage] &&
    //               storageTranslationsWorks[currentLanguage][item.workId - 1]
    //                 ?.translation}
    //           </div>
    //         </div>
    //       ))}
    //     </div>
    //   ),
    // },
    {
      title: "",
      dataIndex: "",
      key: "id",
      align: "right",
      width: "32px",
      render: (record, index) => (
        <>
          {/* <AlarmInfoModal
            visible={isModalVisible}
            closeModal={() => setIsModalVisible(false)}
            data={recordLocal}
            wellName={wellName}
            index={index}
            well_id={wellId}
            closeWork={handleCloseWork}
            doneWork={handleDoneWork}
            closeObservation={handleCloseObservation}
            doneObservation={handleDoneObservation}
            handleCloseObservation={handleCloseObservation}
            handleDoneObservation={handleDoneObservation}
            sendNewActionsRequest={sendNewActionsRequest}
            handleCloseAlarm={onCloseClick}
            sendNewObservationRequest={sendNewObservationRequest}
          /> */}
          {showOptions && Boolean(accessStore.checkPermission("deviation_analysis_alarms")) && (
            <OptionsIcon
              onClick={() => {
                setIsModalVisible(true);
                setRecordLocal(record);
                getRecord(record);
              }}
              style={{
                height: "16px",
                position: "absolute",
                right: "3px",
                top: "4.5px"
              }}
            />
          )}
        </>
      ),
    },
  ];
};

export default config;
