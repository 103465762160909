/*eslint-disable*/
import { useState, useEffect, useMemo } from "react";
import { Collapse } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "hooks/useStore";
import request from "utils/request";
import { useTranslation } from "react-i18next";
import { indexArray, getHierarchy } from "./locationsParse";
import "./styles.css";

const { Panel } = Collapse;

const MenuLocations = observer(() => {
  const [locations, setLocations] = useState([]);
  const { wellStore, accessStore } = useStore();
  const { t } = useTranslation();

  const getWellsPermissionsData = async (...locations) => {
    const { data } = await request.post(`/admin/getPermissions`, {
      locations: locations,
    });
    return data;
  };
  
  const getRolesData = async () => {
    const { data } = await request.get(`/admin/getRolesList`);
    return data;
  };

  const fetchSavedLocations = async (locationsIds) => {
    const { data } = await request.post("/well-tree/locations", {
      locations: locationsIds,
    });

    return data;
  };

  const fetchLocations = async () => {
    const { data } = await request.get("/well-tree");

    const setParent = (l, parent = null) => {
      l.parent = parent;
      l.locations.forEach((child) => setParent(child, l));
    };

    data.forEach((i) => setParent(i));

    wellStore.locationsTree = data;

    setLocations(data);

    getRolesData()
      .then((data) => {
          accessStore.setRolesPermissions(data)
        })

    getWellsPermissionsData(...data.map((item) => item.id))
      .then((data) => {
          accessStore.setPermissionsList(data)
        })

  };

  const ParentLocCircle = ({ active }) => (
    <svg
      fill="none"
      height="6"
      viewBox="0 0 6 6"
      width="6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill={active ? "#FFF" : "#5b6378"} height="6" rx="3" width="6" />
      <rect fill={"#000"} fillOpacity=".16" height="6" rx="3" width="6" />
    </svg>
  );

  const indexLocations = useMemo(() => indexArray(locations), [locations]);
  const hasWell = Object.values(indexLocations).find((index) => index.hasWell);

  useEffect(() => {
    if (
      Object.keys(indexLocations)?.length === 0 ||
      indexLocations?.length === 0
    ) return;

    const breadcrumbs = [];
    let maxBreadcrumbLength = 0;
    for (const locationId of wellStore.selectedLocations) {
      const breadcrumb = getHierarchy(locationId, indexLocations)
      breadcrumbs.push(breadcrumb);
      maxBreadcrumbLength = Math.max(maxBreadcrumbLength, breadcrumb.length);
    };

    let breadcrumb = null;
    let isEqual = true;
    for (let index = 0; index < maxBreadcrumbLength; index++) {
      let value = null;
      breadcrumbs.forEach((breadcrumb) => {
        const locationName = breadcrumb.at(index);
        if (value === null) {
          value = locationName;
        } else if (value !== locationName) {
          isEqual = false;
        };
      });
      if (!isEqual) {
        breadcrumb = breadcrumbs.at(0)?.slice(0, index);
        break;
      };
    };
    if (isEqual) {
      breadcrumb = breadcrumbs.at(0);
    };

    wellStore.setBreadcrumbs(breadcrumb ?? []);

  }, [[...wellStore.selectedLocations].join(""), indexLocations]);

  const handleLocationClick = (location, e) => {
    if (e.ctrlKey) {
      wellStore.handleLocationClickCtrl(location);
      e.preventDefault();
      e.stopPropagation();
      wellStore.resetIsLocationsChanged();
    } else {
      if (!location.hasWell) return;
      if (location.locations.length !== 0) return;
      wellStore.handleLocationClick(location);
      wellStore.resetIsLocationsChanged();
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    const saveChosenWell = (id) => {
      localStorage.setItem("LAST_SET_WELL", id);
    };

    const saveActiveLocations = (activeLocations) => {
      localStorage.setItem(
        "LAST_SET_LOCATIONS",
        JSON.stringify(activeLocations)
      );
    };

    if ([wellStore?.id].every((v) => v)) {
      saveChosenWell(wellStore.id);
    };

    if (wellStore?.selectedLocations && wellStore?.selectedLocations.size !== 0) {
      saveActiveLocations(wellStore.selectedLocations);
    };

  }, [
    wellStore?.id,
    wellStore?.activeLocationId,
    wellStore?.selectedLocations,
    wellStore.isLocationsChanged,
  ]);

  useEffect(() => {
    if (locations.length === 0) return;

    const savedLocationsString = JSON.parse(
      localStorage.getItem("LAST_SET_LOCATIONS")
    );
    let prevChosenLocations = null;

    try {
      prevChosenLocations = savedLocationsString;
    } catch (e) {
      console.log("couldnt parse previously set locations");
    }

    if (prevChosenLocations) {
      fetchSavedLocations(prevChosenLocations).then((data) => {
        data.forEach((item) => {
          if (!wellStore.selectedLocations.has(item.id)) {
            wellStore.handleLocationClickCtrl(item);
          };
        });
      });
      getWellsPermissionsData(...prevChosenLocations)
        .then((data) => {
            accessStore.setPermissionsList(data)
          })
    } else if (hasWell) {
      wellStore.handleLocationClick(hasWell.id);
    }
  }, [hasWell, indexLocations]);

  const renderLocation = (location) => (
    location.locations?.length > 0 ?
      <Collapse
        key={location.id}
        bordered={false}
        destroyInactivePanel
        className="locations-data"
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <svg
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            className={`collapse-icon ${isActive ? "active" : ""}`}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 11L12 14L9 11"
              stroke={isActive ? "rgba(255, 255, 255, 0.24)" : "#fff"}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      >
        <Panel
          header={
            <div
              className={`sublocation-title-container ${wellStore
                .absolutelySelectedLocations.includes(
                  location.id
                ) && "highlighted"
                }`}
              onClick={(e) =>
                location.locations.length > 0 &&
                handleLocationClick(location, e)
              }
            >
              <ParentLocCircle
                active={wellStore.selectedLocations.has(location.id)}
              />
              <span style={{ marginLeft: "5px" }}>{location.name}</span>
            </div>
          }
        >
          {location.locations.map(child => (
            <div key={child.id}>
              {renderLocation(child)}
            </div>
          ))}
        </Panel>
      </Collapse>
      :
      <div
        key={location.id}
        className={`object-item
						${wellStore.absolutelySelectedLocations.includes(location.id) && "highlighted"} 
            ${(wellStore.selectedLocations.has(location.id) && "active") ||
          (!location.hasWell && "disabled")
          }`}
        onClick={(e) =>
          location.hasWell && handleLocationClick(location, e)
        }
      >
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="4"
            width="5.65659"
            height="5.65659"
            transform="rotate(45 4 0)"
            fill={
              wellStore.selectedLocations.has(location.id)
                ? "#4B7EFE"
                : "#5B6378"
            }
          />
          <rect
            x="4"
            width="5.65659"
            height="5.65659"
            transform="rotate(45 4 0)"
            fill="black"
            fillOpacity="0.16"
          />
        </svg>
        <span className="object-item-title">{location.name}</span>
        <div className="object-indicator" />
      </div>
  );

  return (
    <div className="menu-locations-container">
      <div className="menu-locations-title">
        <span className="menu-title">{t("wellTree")}</span>
      </div>
      <Collapse
        bordered={false}
        destroyInactivePanel
        className="locations-data"
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <svg
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            className={`collapse-icon ${isActive ? "active" : ""}`}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 11L12 14L9 11"
              stroke={isActive ? "rgba(255, 255, 255, 0.24)" : "#fff"}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      >
        {locations.map(location => renderLocation(location))}
      </Collapse>
    </div>
  );
});

export default MenuLocations;
