/* eslint-disable */
import "./styles.css";

const NRHChartTooltip = ({ data, scaleWidth }) => {

  const { calc, factory, work } = data;

  if (calc.h == null && factory.h == null && work.h == null) {
    return <></>;
  }

  const hoveredPoint = [calc, factory, work]
    .filter(x => x.h != null)
    ?.at(0);

  const style = getStyles(hoveredPoint, scaleWidth);

  return (
    <>
      <div
        className="nrh-chart-tooltip"
        style={style}
      >
        {calc.h != null &&
          <>
            Расчетная
            <div>Q: {calc.q.toFixed(2)}</div>
            <div>H: {calc.h.toFixed(2)}</div>
          </>
        }
        {factory.h != null &&
          <>
            Заводская
            <div>Q: {factory.q.toFixed(2)}</div>
            <div>H: {factory.h.toFixed(2)}</div>
          </>
        }
        {work.h != null &&
          <>
            Рабочая точка
            <div>Q: {work.q.toFixed(2)}</div>
            <div>H: {work.h.toFixed(2)}</div>
          </>
        }
      </div >
    </>
  );
}

export default NRHChartTooltip;

const getStyles = (obj, scaleWidth) => {
  const baseOffset = 60;

  const styles = {
    top: isFinite(obj.top) ? obj.top : 0
  }

  if (obj.left + 250 >= scaleWidth) {
    styles.right = isFinite(obj.left) ?
      scaleWidth - obj.left - 40 : 0;
  } else {
    styles.left = isFinite(obj.left) ? obj.left + baseOffset : 0;
  }

  return styles;
}