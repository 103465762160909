/*eslint-disable*/
import { useEffect, useMemo, useState } from "react";
import { Field } from "react-final-form";
import { List } from "antd";
import Checkbox from "formAdapters/Checkbox";
import { Checkbox as CheckboxAntd } from "antd";
import { Collapse, Space } from "antd";
import Search from "./components/Search";
import {
  getAllSubLocations,
  getAllSubLocationsWithWells,
  hasSubLocationsWells,
  isChecked,
  isIndeterminate
} from "./common";
import "./styles.css";

const WellListChild = ({
  locationsTree,
  nestedPath,
  form,
  filteredWellIds,
  handleSubmit
}) => {

  const subLocations = locationsTree.locations;
  const wells = locationsTree.wells;

  const hasSubLocationFilteredWells = useMemo(
    () => hasSubLocationsWells(locationsTree, filteredWellIds),
    [locationsTree, filteredWellIds]
  );

  const checked = useMemo(
    () => isChecked(locationsTree),
    [locationsTree]
  );

  const indeterminate = useMemo(
    () => checked && isIndeterminate(locationsTree),
    [locationsTree]
  );

  const onChangeCheckbox = () => {
    form.batch(() => {
      if (checked) {
        for (const [subLocation, path] of
          getAllSubLocationsWithWells(locationsTree, nestedPath)) {
          for (let wellIndex = 0; wellIndex < subLocation.wells.length; wellIndex++) {
            form.change(`${path}.wells[${wellIndex}].active`, false);
          }
        }
      } else {
        for (const [subLocation, path] of
          getAllSubLocationsWithWells(locationsTree, nestedPath)) {
          for (let wellIndex = 0; wellIndex < subLocation.wells.length; wellIndex++) {
            form.change(`${path}.wells[${wellIndex}].active`, true);
          }
        }
      }
    });
    handleSubmit();
  }

  const header = (
    <div style={{ display: "flex" }}>
      <span>{locationsTree.name}</span>
      {(subLocations.length > 0 || wells?.length > 0) &&
        <span onClick={(e) => e.stopPropagation()} style={{ marginLeft: "auto" }}>
          <span style={{ paddingRight: "5px" }}>Выбрать все:</span>
          <CheckboxAntd
            checked={checked}
            indeterminate={indeterminate}
            onChange={onChangeCheckbox}
          />
        </span>}
    </div >
  );

  if (!hasSubLocationFilteredWells) {
    return null;
  }

  return (
    <Collapse>
      <Collapse.Panel header={header}>

        {subLocations.map((location, locationIndex) => (
          <WellListChild
            key={location.id}
            locationsTree={location}
            nestedPath={`${nestedPath}.locations[${locationIndex}]`}
            form={form}
            filteredWellIds={filteredWellIds}
            handleSubmit={handleSubmit}
          />
        ))}

        {wells?.length > 0 && (
          <List
            dataSource={wells}
            renderItem={(well, wellIndex) => {
              if (!filteredWellIds.includes(well.id)) {
                return null;
              }

              return (
                <List.Item>
                  <Field
                    name={`${nestedPath}.wells[${wellIndex}].active`}
                    type="checkbox"
                    component={Checkbox}
                  />
                  {well.name}
                </List.Item>
              )
            }}
          />
        )}

      </Collapse.Panel>
    </Collapse>
  );
}

const WellList = ({ locationsTrees, form, handleSubmit, pristine }) => {
  const [searchValue, setSearchValue] = useState("");

  const filteredWellIds = useMemo(() => {
    const ids = [];

    if (searchValue === "") {
      for (const root of locationsTrees) {
        for (const sub of getAllSubLocations(root)) {
          if (!sub.wells) continue;
          for (const well of sub.wells) {
            ids.push(well.id);
          }
        }
      }
      return ids.sort((a, b) => a - b);
    }

    const searchValueLowerCased = searchValue.toLowerCase();

    for (const root of locationsTrees) {
      for (const sub of getAllSubLocations(root)) {
        if (!sub.wells) continue;

        const filteredWellIds = sub.wells
          .filter(well => well.name.toLowerCase().includes(searchValueLowerCased))
          .map(well => well.id);

        for (const id of filteredWellIds) {
          ids.push(id);
        }
      }
    }

    const sortedIds = ids.sort((a, b) => a - b);
    return sortedIds;

  }, [locationsTrees, searchValue]);

  useEffect(() => {
    if (!pristine) {
      handleSubmit();
    }
  }, [locationsTrees]);

  return (
    <div className="well-list">
      <Space direction="vertical" style={{ width: "100%" }}>

        <Search
          value={searchValue}
          setValue={(value) => setSearchValue(value)}
        />

        {locationsTrees.map((locationTree, locationIndex) => (
          <WellListChild
            key={locationTree.id}
            locationsTree={locationTree}
            nestedPath={`locationsTrees[${locationIndex}]`}
            form={form}
            filteredWellIds={filteredWellIds}
            handleSubmit={handleSubmit}
          />
        ))}
      </Space>
    </div>
  );
}

export default WellList;
