/* eslint-disable */
import React, { useEffect, useState } from 'react'
import 'antd/dist/antd.min.css'
import {
  Form, Radio, Row, Col, Space,
} from 'antd'
import { useTranslation } from 'react-i18next'
import { useStore } from 'hooks/useStore'

const RangingSettings = ({ handleSetNewData }) => {
  const { homeStore } = useStore()
  const [rangingData, setRangingData] = useState(() => homeStore.wellsRangingSettings)

  const { t } = useTranslation()
  const sortByOptions = ['WATER_DEBIT', 'OIL_DEBIT', 'WATER_CUT', 'instantaneous', /*'TREND_ANAL', 'POT_ANAL', 'USER_ANAL',*/ 'NAME']
  const sortByOptionsLabels = ['WATER_DEBIT', 'OIL_DEBIT', 'WATER_CUT', 'instantaneous', /*'TREND_ANAL', 'POT_ANAL', 'USER_ANAL',*/ 'wellName']
  const orderOptions = ['ASCENDING', 'DESCENDING']
  const sortByAnalysisColorOptions = ['RED', /*'YELLOW', 'RED_YELLOW',*/ 'NONE']
  const filterByWellStatusOptions = ['IN_WORK', 'IDLE', 'NONE']
  
  useEffect(() => {
    homeStore.setWellsRangingSettings(Object.assign({}, rangingData))
  }, [rangingData])

  const layout = {
    labelCol: {
      span: 14,
    },
    wrapperCol: {
      span: 12,
    },
  }

  return (
    <Form
      {...layout}
      name="nest-messages"
      onFinish={(x) => { console.log(x); handleSetNewData} }//!!!
      className
      initialValues={rangingData}
    >
      <Row style={{ marginBottom: '2em' }} >
        <Col span={12}>
          <Form.Item
            name={['sortBy']}
            label={t('sortBy')}
            style={{ fontWeight: 'bold' }}
            labelAlign="left"
            wrapperCol={{
              offset: 0,
              span: 12,
            }}
          />
            <Radio.Group onChange={ e => setRangingData(oldData => Object.assign({}, oldData, { sortBy: e.target.value, })) } value={rangingData.sortBy}>
              <Space direction="vertical">
                {
                  sortByOptions.map((option, index) => 
                    <Radio value={option}>{t(sortByOptionsLabels.at(index) ?? option)}</Radio>
              
                  )
                }
              </Space>
            </Radio.Group>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['sortByAnalysisColor']}
            label={t('showFirst')}
            style={{ fontWeight: 'bold', marginTop: '2em' }}
            labelAlign="left"
            wrapperCol={{
              offset: 0,
              span: 12,
            }}
          />
          <Radio.Group onChange={ e => setRangingData(oldData => Object.assign({}, oldData, { sortByAnalysisColor: e.target.value, })) } value={rangingData.sortByAnalysisColor}>
            <Space direction='vertical'>
              {
                sortByAnalysisColorOptions.map(option => 
                    <Radio disabled={!((rangingData.sortBy == 'instantaneous') || (rangingData.sortBy == 'TREND_ANAL') || (rangingData.sortBy == 'POT_ANAL') || (rangingData.sortBy == 'USER_ANAL'))} value={option}>{t(option)}</Radio>
                )
              }
            </Space>
          </Radio.Group>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name={['order']}
            label={t('sortOrder')}
            style={{ fontWeight: 'bold' }}
            labelAlign="left"
            wrapperCol={{
              offset: 0,
              span: 12,
            }}
          />
          <Radio.Group onChange={ e => setRangingData(oldData => Object.assign({}, oldData, { order: e.target.value, })) } value={rangingData.order}>
            <Space direction='horizontal'>
              {
                orderOptions.map(option => 
                  <Radio value={option}>{t(option)}</Radio>
                )
              }
            </Space>
          </Radio.Group>
        </Col>
        {/* <Col span={12}>
          <Form.Item
            name={['filterByWellStatus']}
            label={t('filterByWellStatus')}
            style={{ fontWeight: 'bold' }}
            labelAlign="left"
            wrapperCol={{
              offset: 0,
              span: 12,
            }}
          />
          <Radio.Group onChange={ e => setRangingData(oldData => Object.assign({}, oldData, { filterByWellStatus: e.target.value, })) } value={rangingData.filterByWellStatus}>
            <Space direction='horizontal'>
              {
                filterByWellStatusOptions.map(option => 
                  <Radio value={option}>{t(option)}</Radio>
                )
              }
            </Space>
          </Radio.Group>
        </Col> */}
      </Row>
    </Form>
  )
}

export default RangingSettings
