/* eslint-disable */
import { Collapse } from "antd";
import useGetRequest from "hooks/useGetRequest";
import LoadingSpinner from "components/LoadingSpinner";
import ParameterTable from "./ParameterTable";
import DeviationReasonTable from "./DeviationReasonTable";
import { useTranslation } from "react-i18next";
import "./styles.css";

const Reasons = ({
  alarmId
}) => {

  const { t } = useTranslation();

  const { data, initial, pending, rejected } =
    useGetRequest(
      `/alarm/deviationanalysis/get/alarms/data/${alarmId}`,
      [alarmId]
    );

  const liquidDebitParameters = data?.liquidDebit;
  const dynamicLevelParameters = data?.dynamicLevel;
  const otherParameters = data?.otherParameters;

  const wellPumpType = data?.pump;
  const trendDebitOil = data?.trendDebitOil;
  const trendDynamicLevel = data?.trendDynamicLevel;

  if (initial || pending) {
    return (
      <div style={{ height: "calc(100vh - 285px)" }}>
        <LoadingSpinner />
      </div>
    );
  }

  if (rejected) {
    return (
      <div style={{ height: "calc(100vh - 285px)", color: "red", textAlign: "center" }}>
        {t("errorHasOccured")}
      </div>
    );
  }

  return (
    <div className="alarm-deviation-analysis-reasons">
      <span className="title">
        Значения параметров на основе которых сформировано предупреждение:
      </span>

      <ParameterTable
        title="Дебит жидкости:"
        parameters={liquidDebitParameters}
        typeByAlias={{
          value_regime_liquid_deb: "timestamp",
          accepted_value_liquid_deb: "timestamp",
          permissible_deviation_liquid_deb: "const",
          deviation_liquid_deb: "calculated",
        }}
        trendValueByAlias={{
          deviation_liquid_deb: trendDebitOil,
        }}
      />

      <ParameterTable
        title="Динамический уровень:"
        parameters={dynamicLevelParameters}
        typeByAlias={{
          value_regime_dynamic_level: "timestamp",
          value_dynamic_level: "timestamp",
          calc_value_dynamic_level: "timestamp",
          permissible_deviation_dynamic_level: "const",
          deviation_dynamic_level: "calculated",
        }}
        trendValueByAlias={{
          deviation_dynamic_level: trendDynamicLevel,
        }}
        showRowIfValueNull={false}
      />

      <DeviationReasonTable
        wellPumpType={wellPumpType}
        trendDebitOil={trendDebitOil}
        trendDynamicLevel={trendDynamicLevel}
      />

      <Collapse
        defaultActiveKey={["1"]}
        ghost
      >
        <Collapse.Panel
          header={<span className="title">Прочие параметры, участвующие в расчете:</span>}
        >
          <ParameterTable
            parameters={otherParameters}
            typeByAlias={{
              value_liquid_deb: "timestamp",
              value_ml_deb_virt: "timestamp",
              value_q_vfm: "timestamp",
              value_regime_watercut: "timestamp",
              value_watercut: "timestamp",
              value_p_in: "timestamp",
              value_p_zatr: "timestamp",
              value_oil_density: "const",
              value_water_density: "const",
              value_vert_descent_depth: "const",
              value_lengthening: "calculated",
            }}
            showRowIfValueNull={false}
          />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}

export default Reasons;
