/* eslint-disable */
import { useMemo, useState } from "react";
import useRequest from "hooks/useRequest";
import useTimeConverter from "hooks/useTimeConverter";
import { useTranslation } from "react-i18next";
import "./styles.css";

const LogsNRD = () => {

  const { t } = useTranslation();

  const { data } = useRequest("/log/getUpdateWellScripts");

  const fromUtcToLocale = useTimeConverter({
    toOffset: 0,
    fromOffsetDefault: new Date().getTimezoneOffset() / 60,
    template: "DD.MM.YYYY HH:mm:ss",
  });

  const [activeLogIndex, setActiveLogIndex] = useState(0);

  const activeLogDescription = useMemo(
    () => data?.[activeLogIndex]?.scriptResult,
    [data, activeLogIndex]);

  return (
    <div className="log-uploading-container">
      <div className="log-date-column">
        <div className="log-header">
          {t("dateTimeLog")}
        </div>
        <div className="log-dates">
          {data?.map((log, index) => {
            const date = fromUtcToLocale(log.createdAt);
            const active = index === activeLogIndex;

            return (
              <div
                key={index}
                className={"log-date" + (active ? " log-date-active" : "")}
                onClick={(e) => setActiveLogIndex(index)}
              >
                {date}
              </div>
            );
          })}
        </div>
      </div>

      <div className="log-description-column">
        <div className="log-header">
          {t("logDescription")}
        </div>
        <div className="log-description">
          {activeLogDescription}
        </div>
      </div>
    </div>
  );
}

export default LogsNRD;