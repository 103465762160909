/* eslint-disable */
import { Button } from "antd";
import React from "react";
import "./styles.css";

const CustomButton = ({ type, text, disabled, className, ...rest }) => (
  <Button
    htmlType={type}
    disabled={disabled}
    className={`ant-button ${className || ""}`}
    {...rest}
  >
    {text}
  </Button>
);

export default CustomButton;
