/*eslint-disable*/

import { useEffect, useRef } from "react";

const useWebSocket = ({
  url,
  onMessage,
  onConnected,
}) => {

  const socket = useRef(null);

  const init = (url) => {
    const websocketProtocol =
      window.location.protocol == "http:" ? "ws://" : "wss://";
    if (
      process.env.REACT_APP_ADDRESS_HOST_AS_CLIENT === "true" &&
      process.env.REACT_APP_DYNAMIC_PROTOCOL === "false"
    )
      socket.current = new WebSocket(
        websocketProtocol +
        `${window.location.hostname}` +
        ":8080" +
        process.env.REACT_APP_API_PREFIX +
        url
      );
    if (
      process.env.REACT_APP_ADDRESS_HOST_AS_CLIENT === "true" &&
      process.env.REACT_APP_DYNAMIC_PROTOCOL === "true"
    )
      socket.current = new WebSocket(
        websocketProtocol +
        `${window.location.host}` +
        process.env.REACT_APP_API_PREFIX +
        url
      );
    if (
      process.env.REACT_APP_ADDRESS_HOST_AS_CLIENT === "false" &&
      process.env.REACT_APP_DYNAMIC_PROTOCOL === "true"
    )
      socket.current = new WebSocket(
        websocketProtocol +
        process.env.REACT_APP_API_URL_HOST +
        ":8080" +
        process.env.REACT_APP_API_PREFIX +
        url
      );
    if (
      process.env.REACT_APP_ADDRESS_HOST_AS_CLIENT === "false" &&
      process.env.REACT_APP_DYNAMIC_PROTOCOL === "false"
    )
      socket.current = new WebSocket(
        websocketProtocol +
        process.env.REACT_APP_API_URL_HOST +
        ":8080" +
        process.env.REACT_APP_API_PREFIX +
        url
      );
    socket.current.onopen = (e) => onConnected();
    socket.current.onmessage = (data) => onMessage(data);
  }

  const send = (data) => {
    const jsonData = JSON.stringify(data);
    socket.current.send(jsonData);
  }

  useEffect(() => {
    init(url);
    return () => socket.current.close();
  }, [url]);

  return {
    socket,
    send,
  }
}

export default useWebSocket;
