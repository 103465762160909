import AuthLayout from 'layouts/AuthLayout'
import Login from 'containers/Login'

const LoginPage = () => (
  <AuthLayout>
    <Login />
  </AuthLayout>
)

export default LoginPage
