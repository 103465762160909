import MenuLocations from './components/MenuLocations'
import MenuData from './components/MenuData'
import './styles.css'

const WellTree = ({
  minimizedMode, onMinimizedClick, fullScreenMode, showWells = true,
}) => (
  <div className="well-tree">
    <div
      className="menu-backing"
      onClick={() => {
        document
          .querySelector('.burger-icon')
          ?.classList.toggle('with-menu')
        document
          .querySelector('.well-tree')
          ?.classList.toggle('with-menu')
      }}
    />
    <div className="menu">
      <MenuLocations />
      {showWells && <MenuData fullScreenMode={fullScreenMode} minimizedMode={minimizedMode} onMinimizedClick={onMinimizedClick} />}
    </div>
  </div>
)

export default WellTree
