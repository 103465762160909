/*eslint-disable*/
import './styles.css'

const MenuCard = ({ title, contents, clickHandler }) => {

  return (
    <div onClick={clickHandler} className="menu-card">
      <div style={{ margin: '20px 10px', fontWeight: 'bolder', fontSize: '15px' }}>{title}</div>
      <div>
        {contents}
      </div>
    </div>
  )
}

export default MenuCard
