/* eslint-disable */
import React, { useState } from 'react'
import 'antd/dist/antd.min.css'
import './styles.css'
import useRequest from 'hooks/useRequest'
import { useStore } from 'hooks/useStore'
import {
  Form, Row, Col, Button, Switch, InputNumber,
} from 'antd'
import { useTranslation } from 'react-i18next'

const NRH = ({ handleSetNewData }) => {
  const [form] = Form.useForm()
  const [coefPolynomForm, setCoefPolynomForm] = useState(true)
  const { t } = useTranslation()
  const { wellStore } = useStore()
  const { data } = useRequest(
    wellStore.id ? `/nrh/calcnrh/${wellStore.id}` : null,
  )

  const [buttonText, setButtonText] = useState("apply");

  const getFieldsH = () => {
    const children = []

    for (let i = 0; i < 9; i++) {
      children.push(
        <Col span={24} key={i}>
          <Form.Item
            name={`h${i}`}
            label={`H ${i}`}
            rules={[
              {
                message: '',
              },
            ]}
          >
            <InputNumber
              placeholder={`h-${i}`}
              defaultValue={data?.factoryCoefNRH[i]?.coefValue}
              value={data?.factoryCoefNRH[i]?.coefValue}
              disabled={!coefPolynomForm}
              style={{
                width: 350,
              }}
              stringMode
            />
          </Form.Item>
        </Col>,
      )
    }

    return children
  }

  const getFieldsP = () => {
    const children = []

    for (let i = 0; i < 20; i++) {
      children.push(
        <Row key={i} layout="inline">
          <Form.Item
            name={`q${i}`}
            label={`Q ${i}`}
            rules={[
              {
                message: '',
              },
            ]}
          >
            <InputNumber
              placeholder={`q-${i}`}
              name={`q${i}`}
              defaultValue={data?.factoryNRH[i]?.q}
              disabled={coefPolynomForm}
              style={{ width: '100px', margin: '0 10px' }}
              stringMode
            />
          </Form.Item>
          <Form.Item
            name={`p${i}`}
            label={`P ${i}`}
            rules={[
              {
                message: '',
              },
            ]}
          >
            <InputNumber
              name={`p${i}`}
              placeholder={`p-${i}`}
              defaultValue={data?.factoryNRH[i]?.h}
              disabled={coefPolynomForm}
              style={{ width: '250px', margin: '0 10px' }}
              stringMode
            />
          </Form.Item>
        </Row>,
      )
    }

    return children
  }

  const onFinish = values => {
    handleSetNewData(values)
    setButtonText("applied");
  }

  const onFormChange = () => {
    setCoefPolynomForm(!coefPolynomForm)
  }

  const onFieldsChange = () => {
    setButtonText("apply");
  }

  return (
    <>
      <div className="myForm" style={{ padding: '0px' }}>
        <Form
          form={form}
          name="advanced_search"
          onFinish={onFinish}
          onFieldsChange={onFieldsChange}
        >
          <Form.Item>
            <Row>
              <Col xs={{ span: 5, offset: 1 }} lg={{ span: 8, offset: 2 }} span={12}>
                <Switch checkedChildren="Коэфициенты" unCheckedChildren="Коэфициенты" checked={coefPolynomForm} onChange={onFormChange} />
              </Col>
              <Col xs={{ span: 5, offset: 1 }} lg={{ span: 8, offset: 2 }} span={12}>
                <Switch checkedChildren="Точки" unCheckedChildren="Точки" checked={!coefPolynomForm} onChange={onFormChange} />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item>
            <Row>
              <Col xs={{ span: 5, offset: 1 }} lg={{ span: 8, offset: 2 }} span={12}>
                <Row gutter={24}>{getFieldsH()} </Row>
              </Col>
              <Col xs={{ span: 5, offset: 1 }} lg={{ span: 8, offset: 2 }} span={12}>
                <Row gutter={24}>{getFieldsP()} </Row>
              </Col>
            </Row>
          </Form.Item>
          <Row>
            <Col
              span={12}
              style={{
                textAlign: 'right',
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: buttonText === "apply" ? "" : "lightseagreen",
                }}
              >
                {t(buttonText)}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

export default NRH
