/* eslint-disable */
import { makeAutoObservable } from "mobx";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

class AdminLogHistoryStore {

  period = { startDate: null, endDate: null };

  constructor() {
    makeAutoObservable(this);
  }

  setPeriod(period) {
    if (!period) {
      this.period = {
        startDate: null,
        endDate: null,
      }
    } else {
      this.period = {
        startDate: period.startDate ? dayjs(period.startDate).format("YYYY-MM-DD") : null,
        endDate: period.endDate ? dayjs(period.endDate).format("YYYY-MM-DD") : null,
      }
    }
  }
}

export default AdminLogHistoryStore;
