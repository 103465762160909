/* eslint-disable */
import { makeAutoObservable } from "mobx";
import { task } from "mobx-fetch-task";
import { store } from "hooks/useStore";
import moment from "moment";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import request from "utils/request";

dayjs.extend(advancedFormat);

class ArchiveAlarmsStore {

  pickedWellIds = [];

  period = { startDate: null, endDate: null }

  constructor() {
    makeAutoObservable(this, {
      alarmFetcher: false,
      parameterFetcher: false,
      xlsxFetcher: false,
    });
  }

  setPickedWellIds(wellIds) {
    this.pickedWellIds = wellIds;
  }

  setPeriod(period) {
    this.period = period;
  }

  alarmFetcher = task(async () => {
    const { data } = await request.get(`/alarm/deviationanalysis/get/archiveAlarms?startDate=${this.period.startDate}&endDate=${this.period.endDate}&wellIds=${this.pickedWellIds}`);
    return data;
  });

  parameterFetcher = task(async () => {
    const { data } = await request.get("/settings/trends/get/paramsList");
    return data;
  });

  xlsxFetcher = task(async () => {
    const { data } = await request.get(`/alarm/deviationanalysis/get/archiveAlarms/xlsx?startDate=${this.period.startDate}&endDate=${this.period.endDate}&wellIds=${this.pickedWellIds}`, {
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Archive alarm data - ${moment().locale(store.translationStore.currentLanguage).format("dddd, MMMM Do YYYY, h mm ss a")}.xlsx`);
    link.click();
  });
}

export default ArchiveAlarmsStore;
