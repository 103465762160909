import WellTreemapLayout from 'layouts/WellTreemapLayout'
import Treemap from 'containers/WellTreemapChart'

const WellTreemapChartPage = () => (
  <WellTreemapLayout>
    <Treemap />
  </WellTreemapLayout>
)

export default WellTreemapChartPage
