const ParametersColors = {
  0: '#BF5AC1',
  1: '#7BC657',
  2: '#FEB64B',
  3: '#C780FF',
  4: '#FF6287',
  5: '#71C705',
  6: '#00CDCD',
  7: '#FF8F5F',
  8: '#FE4B8B',
  9: '#9F8FFF',
  10: '#01DA8C',
  11: '#8D9DC8',
  12: '#FE6B4B',
  13: '#16E1D5',
  14: '#CDCF4E',
  15: '#7B79EE',
  16: '#FED74B',
  17: '#FFAD61',
  18: '#48BF69',
  19: '#4BDEFE',
  20: '#E38CF1',
  21: '#94DA00',
  22: '#93A4CD',
  23: '#FF847C',
  24: '#4BBEFE',
  25: '#D2D611',
  26: '#8F6ADC',
  27: '#796DFF',
  28: '#FF5B5B',
  29: '#369AD2',
}

ParametersColors.colorsAmount = Object.keys(ParametersColors).length

export { ParametersColors }
