/* eslint-disable */
import { makeAutoObservable } from "mobx";
import { task } from "mobx-fetch-task";
import Gradient from "javascript-color-gradient";
import { store } from "hooks/useStore";
import { getGradientHexColor } from "components/Treemap/common";
import { t } from "i18next";
import request from "utils/request";

class WellDeviationTreemapStore {
  constructor() {
    makeAutoObservable(this, {
      fetchData: false,
    });
  }

  colors = new Gradient()
    .setColorGradient("#f63538", "#414554", "#30cc5a")
    .setMidpoint(11)
    .getColors();

  errorColor = "#EDFF21";

  fetchData = task(async () => {
    const { data } = await request("/treemap/deviationOil");
    data.root.label = "Локации";
    return data;
  });

  get root() {
    return this.fetchData.result?.root;
  }

  get unitId() {
    return this.fetchData.result?.unitId;
  }

  get labelSettings() {
    return {
      getLabel: (node) => `${node.label}\n${(node.relativeDeviation * 100).toFixed(2)}%`,
    }
  }

  get tooltipSettings() {
    return {
      renderLabel: (node) => (
        <span>{node.id[0] === "w" && t("well")} {node.label}</span>
      ),
      renderValue: (node) => (
        <div>
          <div>
            <span>Замерной дебит: </span>
            <span className="well-treemap-tooltip-value">{node.liquidDebMeas?.toFixed(2)}</span>
            <span className="well-treemap-tooltip-unit">{store.translationStore.translateParameterUnit(this.unitId)}</span>
          </div>
          <div>
            <span>Режимный дебит: </span>
            <span className="well-treemap-tooltip-value">{node.liquidDebRegime?.toFixed(2)}</span>
            <span className="well-treemap-tooltip-unit">{store.translationStore.translateParameterUnit(this.unitId)}</span>
          </div>
          <div>
            <span>Абсолютное отклонение: </span>
            <span className="well-treemap-tooltip-value">{node.deviation?.toFixed(2)}</span>
            <span className="well-treemap-tooltip-unit">{store.translationStore.translateParameterUnit(this.unitId)}</span>
          </div>
          <div>
            <span>Относительное отклонение: </span>
            <span className="well-treemap-tooltip-value">{(node.relativeDeviation * 100)?.toFixed(2)}</span>
            <span className="well-treemap-tooltip-unit">%</span>
          </div>
        </div>
      )
    }
  }

  get colorSettings() {
    return {
      getNodeBackgroundColor: (node) => {
        if (node.hasError) return this.errorColor;
        return getGradientHexColor(node.color, this.colors);
      },
      getNodeLabelColor: (node) => {
        if (node.hasError) return "black";
        return "white";
      }
    }
  }
}

export default WellDeviationTreemapStore;

