/*eslint-disable*/
import { useMemo, useState } from "react";
import { Button } from "antd";
import useWebSocket from "hooks/useWebSocket";
import useDraggableModal from "hooks/useDraggableModal";
import Modal from "components/Modal";
import CustomTooltip from "components/Tooltip";
import EngineerScreenTable from "./EngineerScreenTable";
import { useTranslation } from "react-i18next";
import "./styles.css";

export const IndicatorColorType = {
  green: "green",
  red: "red",
  gray: "gray",
}

const REALTIME_ENGINEER_SCREEN_DATA_SOCKET_URL = `/ws/realtimeEngineerScreenData`;

const EngineerScreen = () => {

  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [dataSource, setDataSource] = useState(null);

  const indicatorColorType = useMemo(() => {
    if (dataSource == null) return IndicatorColorType.gray;
    const isSomeRowDoesntEnterThreshold = dataSource.some(x => !x.isEnterThreshold);
    if (isSomeRowDoesntEnterThreshold) {
      return IndicatorColorType.red;
    } else {
      return IndicatorColorType.green;
    }
  }, [dataSource]);

  const indicatorTooltipText = useMemo(() => {
    if (indicatorColorType === IndicatorColorType.green) return "Данные актуальны";
    if (indicatorColorType === IndicatorColorType.red) return "Данные не актуальны. Все подробности в инженерном экране.";
    if (indicatorColorType === IndicatorColorType.gray) return "Загрузка...";
  }, [indicatorColorType]);

  const lampClassName = useMemo(() => {
    if (indicatorColorType == IndicatorColorType.green) return "engineer-screen-green-circle";
    if (indicatorColorType == IndicatorColorType.red) return "engineer-screen-red-circle";
    if (indicatorColorType == IndicatorColorType.gray) return "engineer-screen-gray-circle";
  }, [indicatorColorType]);

  const {
    send,
  } = useWebSocket({
    url: REALTIME_ENGINEER_SCREEN_DATA_SOCKET_URL,
    onMessage,
    onConnected,
  });

  function onConnected() {
    send({
      action: "OPEN",
      userId: 1,
    });
  }

  function onMessage(message) {
    const data = JSON.parse(message.data);
    setDataSource(data);
  }

  const handleHeaderButtonClick = () => {
    setIsModalOpen(prev => !prev);
  }

  const {
    disabled,
    setDisabled,
    modalRender,
  } = useDraggableModal();

  return (
    <div className="engineer-screen-in-header-container">
      <CustomTooltip
        tooltipText={indicatorTooltipText}
      >
        <div className={lampClassName} />
      </CustomTooltip>

      <Button
        style={{ borderRadius: "2px" }}
        onClick={handleHeaderButtonClick}
      >
        Инж. экран
      </Button>

      <Modal
        bodyStyle={{
          maxHeight: "calc(100vh - 220px)",
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
          paddingTop: "0px"
        }}
        width={800}
        visible={isModalOpen}
        className="add-priority-modal"
        title={
          <div
            style={{
              width: "100%",
              cursor: "grab",
            }}
            onMouseOver={() => {
              if (disabled) setDisabled(false);
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
          >
            {t("engineerScreen")}
          </div>
        }
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        modalRender={modalRender}
      >
        <EngineerScreenTable
          dataSource={dataSource}
        />
      </Modal>
    </div>
  );
}

export default EngineerScreen;
