export const processData = (data) => {
  const tableResult = data.overallResult;

  const decreasedDebitItem = tableResult.find(
    info => info.key === "2"
  );

  const unchangedDebitItem = tableResult.find(
    info => info.key === "0"
  );

  const increasedDebitItem = tableResult.find(
    info => info.key === "1"
  );

  decreasedDebitItem.color = "rgba(245, 39, 39, 0.2)";
  unchangedDebitItem.color = "rgba(245, 245, 39, 0.2)";
  increasedDebitItem.color = "rgba(89, 245, 39, 0.2)";

  const processedData = [
    decreasedDebitItem,
    unchangedDebitItem,
    increasedDebitItem
  ].map(item => {
    const copy = structuredClone(item);

    copy.children = item.analysisData;
    delete copy.analysisData;

    return copy;
  });

  return processedData;
};
