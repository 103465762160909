import MainLayout from 'layouts/MainLayout'
import Admin from 'containers/Admin'

const AdminPage = () => (
  <MainLayout>
    <Admin />
  </MainLayout>
)

export default AdminPage
