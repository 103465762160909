/* eslint-disable */
import uPlot from "uplot";

const { linear, stepped } = uPlot.paths;
const _linear = linear();
const _stepAfter = stepped({ align: 1 });

const pathRenderers = [_linear, _stepAfter];

const getPaths = (isMeander) => {
  const tempType = isMeander ? 1 : 0;
  const renderer = pathRenderers[tempType];

  if (!renderer) {
    return (u, seriesIdx, idx0, idx1, extendGap, buildClip) => {
      return _linear(u, seriesIdx, idx0, idx1, extendGap, buildClip);
    };
  }

  return (u, seriesIdx, idx0, idx1, extendGap, buildClip) => {
    return renderer(u, seriesIdx, idx0, idx1, extendGap, buildClip);
  };
};

export default getPaths;
