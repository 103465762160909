/* eslint-disable */
import HeaderCommon from "layouts/common/HeaderCommon";
import { observer } from "mobx-react-lite";
import "../common/styles.css";

const MainLayout = observer(({ children }) => {
  return (
    <>
      <header>
        <HeaderCommon />
      </header>
      <div className="main-layout">{children}</div>
    </>
  );
});

export default MainLayout;
