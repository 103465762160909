/* eslint-disable */
import { useRef } from "react";

const useSaveCursor = () => {

  const cursorPosition = useRef({ left: -10, top: -10 });

  const saveCursorPlugin = () => {

    const setCursor = (u) => {
      if (u.cursor.left !== 10)
        cursorPosition.current = { left: u.cursor.left, top: u.cursor.top };
    }

    const setData = (u) => {
      u.setCursor({ ...cursorPosition.current }, false);
    }

    return {
      hooks: {
        setCursor,
        setData,
      }
    }
  }

  return saveCursorPlugin;
}

export default useSaveCursor;
