/*eslint-disable*/
import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { List, Collapse, Space, Checkbox as CheckboxAntd } from "antd";
import { useStore } from "hooks/useStore";
import Checkbox from "formAdapters/Checkbox";
import Search from "./components/Search";
import {
  getAllSubLocations,
  getAllSubLocationsWithWells,
  hasSubLocationsWells,
  isChecked,
  isIndeterminate
} from "./common";
import "./styles.css";

const WellListChild = observer(({
  pickedWellIds,
  locationsTree,
  nestedPath,
  form,
  filteredWellIds,
  handleSubmit,
}) => {

  const subLocations = locationsTree.locations;
  const wells = locationsTree.wells;

  const hasSubLocationFilteredWells = useMemo(
    () => hasSubLocationsWells(locationsTree, filteredWellIds),
    [locationsTree, filteredWellIds]
  );

  const checked = useMemo(
    () => isChecked(locationsTree, pickedWellIds),
    [locationsTree, pickedWellIds]
  );

  const indeterminate = useMemo(
    () => checked && isIndeterminate(locationsTree, pickedWellIds),
    [locationsTree, pickedWellIds]
  );

  const onChangeLocationCheckbox = () => {
    form.batch(() => {
      let newPickedWellIds = [...pickedWellIds];
      for (const [subLocation, path] of
        getAllSubLocationsWithWells(locationsTree, nestedPath)) {
        for (let wellIndex = 0; wellIndex < subLocation.wells.length; wellIndex++) {
          const well = subLocation.wells[wellIndex];
          if (!checked) {
            if (!newPickedWellIds.includes(well.id)) {
              newPickedWellIds.push(well.id);
            }
          } else {
            newPickedWellIds = newPickedWellIds.filter(id => id !== well.id);
          }
        }
      }
      form.change("pickedWellIds", newPickedWellIds);
    });
    if (handleSubmit != null) handleSubmit();
  }

  const onChangeWellCheckbox = (wellId, checked) => {
    let newPickedWellIds = null;
    if (checked) {
      newPickedWellIds = [...pickedWellIds, wellId];
    } else {
      newPickedWellIds = pickedWellIds.filter(id => id !== wellId);
    }
    form.change("pickedWellIds", newPickedWellIds);
    if (handleSubmit != null) handleSubmit();
  }

  const header = (
    <div style={{ display: "flex" }}>
      <span>{locationsTree.name}</span>
      {(subLocations.length > 0 || wells?.length > 0) &&
        <span onClick={(e) => e.stopPropagation()} style={{ marginLeft: "auto" }}>
          <span style={{ paddingRight: "5px" }}>Выбрать все:</span>
          <CheckboxAntd
            checked={checked}
            indeterminate={indeterminate}
            onChange={onChangeLocationCheckbox}
          />
        </span>}
    </div >
  );

  if (!hasSubLocationFilteredWells) {
    return null;
  }

  return (
    <Collapse>
      <Collapse.Panel header={header}>

        {subLocations.map((location, locationIndex) => (
          <WellListChild
            key={location.id}
            pickedWellIds={pickedWellIds}
            locationsTree={location}
            nestedPath={`${nestedPath}.locations[${locationIndex}]`}
            form={form}
            filteredWellIds={filteredWellIds}
            handleSubmit={handleSubmit}
          />
        ))}

        {wells?.length > 0 && (
          <List
            dataSource={wells}
            renderItem={(well) => {
              if (!filteredWellIds.includes(well.id)) {
                return null;
              }

              return (
                <List.Item>
                  <Checkbox
                    checked={pickedWellIds.includes(well.id)}
                    onChange={(e) => onChangeWellCheckbox(well.id, e.target.checked)}
                  />
                  {well.name}
                </List.Item>
              )
            }}
          />
        )}

      </Collapse.Panel>
    </Collapse>
  );
});

const WellList = observer(({
  pickedWellIds,
  form,
  handleSubmit,
}) => {

  const { wellTreePickerStore } = useStore();

  const locationsTrees = wellTreePickerStore.locationsTreesFetcher.result ?? [];

  const [searchValue, setSearchValue] = useState("");

  const filteredWellIds = useMemo(() => {
    const ids = [];

    if (searchValue === "") {
      for (const root of locationsTrees) {
        for (const sub of getAllSubLocations(root)) {
          if (!sub.wells) continue;
          for (const well of sub.wells) {
            ids.push(well.id);
          }
        }
      }
      return ids.sort((a, b) => a - b);
    }

    const searchValueLowerCased = searchValue.toLowerCase();

    for (const root of locationsTrees) {
      for (const sub of getAllSubLocations(root)) {
        if (!sub.wells) continue;

        const filteredWellIds = sub.wells
          .filter(well => well.name.toLowerCase().includes(searchValueLowerCased))
          .map(well => well.id);

        for (const id of filteredWellIds) {
          ids.push(id);
        }
      }
    }

    const sortedIds = ids.sort((a, b) => a - b);
    return sortedIds;

  }, [locationsTrees, searchValue]);

  const loading =
    wellTreePickerStore.locationsTreesFetcher.initial
    || wellTreePickerStore.locationsTreesFetcher.pending;

  if (loading) {
    return (
      <span>ЗАГРУЗКА</span>
    );
  }

  return (
    <div className="well-list">
      <Space direction="vertical" style={{ width: "100%" }}>

        <Search
          value={searchValue}
          setValue={(value) => setSearchValue(value)}
        />

        {locationsTrees.map((locationTree, locationIndex) => (
          <WellListChild
            key={locationTree.id}
            pickedWellIds={pickedWellIds}
            locationsTree={locationTree}
            nestedPath={`locationsTrees[${locationIndex}]`}
            form={form}
            filteredWellIds={filteredWellIds}
            handleSubmit={handleSubmit}
          />
        ))}
      </Space>
    </div>
  );
});

export default WellList;
