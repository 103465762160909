/* eslint-disable */
import { observer } from "mobx-react-lite";
import request from "utils/request";
import useRequest from "hooks/useRequest";
import { useStore } from "hooks/useStore";
import useGetRequest from "hooks/useGetRequest";
import useTimeConverter from "hooks/useTimeConverter";
import { useState, useEffect, useMemo, } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "components/Tooltip";
import { Row, Col } from "antd";
import { ReactComponent as SettingsWhiteIcon } from "assets/images/settingsWhiteIcon.svg";
import { EyeOutlined } from "@ant-design/icons";
import { ReactComponent as FullScreenOff } from "assets/images/fullScreenOffIcon.svg";
import { ReactComponent as FullScreenOn } from "assets/images/fullScreenOnIcon.svg";
import { EyeInvisibleOutlined } from "@ant-design/icons";
import NRHChart from "./NRHChart/index";
import ParametersInfo from "./ParametersInfo";
import NRHSettingsModal from "./NRHSettingsModal";
import ParametersModal from "./ParametersModal/index";
import MnemoschemeModal from "./MnemoschemeModal/index";
// import RealtimeConnection from "../../realtimeSocket";
import RealtimeConnection from "containers/Home/realtimeSocket";
import dayjs from "dayjs";
import "./styles.css";

const REALTIME_SOCKET_PATH = `/ws/realtimeData`;

const REALTIME_SOCKET_PATH_SUPPORT = `/ws/realtimeSupportData`;
const userId = 1;
const realtimeSocketSupport = new RealtimeConnection(
  REALTIME_SOCKET_PATH_SUPPORT,
  userId
);

const realtimeSocket = new RealtimeConnection(REALTIME_SOCKET_PATH, userId);

const getPreviouslyChosenTemplate = () => {
  return localStorage.getItem("CHOSEN_TRENDS_TEMPLATE1") || null;
};

const storeChosenTemplate = (templateId) => {
  if (!templateId) return;
  localStorage.setItem("CHOSEN_TRENDS_TEMPLATE1", templateId);
};

const fetchAvailableParams = async (f) => {
  const { data } = await request.get("/settings/trends/get/paramsList");
  f(data);
};

const fetchTemplatesList = async (f) => {
  const { data } = await request.get(
    `/settings/trends/get/templatesList`
  );
  f(data);
};

const fetchTemplateData = async (templateId, f) => {
  const { data } = await request.get(
    `/settings/trends/get/template/${templateId}`
  );
  f(data);
};

const SupportingInformation = observer(
  ({
    menuLocationsMinimizedMode,
    onFullScreenClick,
    fullScreenMode,
    isMnemoMode,
    setMnemoMode
  }) => {
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const { wellStore, homeStore, accessStore, mnemoStore } = useStore();
    const { data } = useRequest(
      wellStore.id ? `/well/${wellStore.id}/data` : null
    );
    const { data: dataNRH } = useRequest(
      wellStore.id ? `/nrh/calcnrh/${wellStore.id}` : null
    );

    const { data: dataFormation } = useRequest(
      wellStore.id ? `/settings/wellplast/${wellStore.id}` : null
    );
    const { data: dataLastBreakPoint } = useGetRequest(wellStore.id != null ? `/alarm/${wellStore.id}/lastBreakpoint` : null, [wellStore.id]);

    const fromUtcToLocale = useTimeConverter({
      toOffset: 0,
      fromOffsetDefault: new Date().getTimezoneOffset() / 60,
    });

    const isDeepWellRodPump = data?.pump?.id === 3;

    const [supportParams, setSupportParams] = useState(null);
    const [realtimeData, setRealtimeData] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [templatesList, setTemplatesList] = useState([]);
    const [currentTemplateData, setCurrentTemplateData] = useState(null);
    const [currentTemplateId, setCurrentTemplateId] = useState(null);
    const [availableParamsList, setAvailableParams] = useState(null);
    // const showSettingsButton = true; // accessLevel === 'ADMIN' || accessLevel === 'TECHNICAL'
    const [tableItems, setTableItems] = useState(null);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const [isMnemoModalVisible, setIsMnemoModalVisible] = useState(false);

    const toggleFullScreen = () => {
      setIsFullScreen(!isFullScreen)
    }

    const displayedParameters = useMemo(
      () =>
        availableParamsList &&
        currentTemplateData?.trends?.map((t) => {
          const info = availableParamsList?.find((p) => p.id == t.parameterId);
          if (!info) return null;

          return {
            id: t.parameterId,
            unitId: info.unit,
            active: true,
            alias: info.alias,
          };
        }),
      [currentTemplateData, availableParamsList]
    );

    const activeParameters = useMemo(
      () => displayedParameters?.filter((item) => item?.active),
      [displayedParameters]
    );

    const translateStatus = (statusName) => {
      switch (statusName) {
        case "STATUS1": {
          return "В работе";
          break;
        }
        case "STATUS2": {
          return "В простое";
          break;
        }
        case "STATUS3": {
          return "В работе";
          break;
        }

        default: {
          return statusName;
        }
      }
    };

    useEffect(() => {
      if (!data) return;
      if (data?.parameters == null) return;

      data.status = translateStatus(data.status);
      setSupportParams(data.parameters.map((parameter) => parameter.alias));
      const supportParamIds = data.parameters.map((parameter) => parameter.alias);

      if (!realtimeSocketSupport) return;

      realtimeSocketSupport
        .start({
          wellId: wellStore.id,
        })
        .then(() => {
          realtimeSocketSupport.listen(supportParamIds);
        });

      return realtimeSocketSupport.end;
    }, [wellStore?.id, data]);

    const realtimeListener = (data) => {
      const newValue = {};
      newValue[data.id] = data.value;
      setRealtimeData((oldValues) => Object.assign({}, oldValues, newValue));
    };

    useEffect(() => {
      if (!supportParams || !realtimeSocketSupport) return;
      realtimeSocketSupport.subscribe(supportParams, realtimeListener);

      return () => realtimeSocketSupport.unsubscribe(realtimeListener);
    }, [supportParams, wellStore.id]);

    useEffect(() => {
      if (!activeParameters || !wellStore?.id || !realtimeSocket) return;

      if (wellStore.id == realtimeSocket.wellId) return;

      realtimeSocket
        .start({
          wellId: wellStore.id,
        })
        .then(() => {
          realtimeSocket.listen(activeParameters.map((p) => p.alias));
        });

      return realtimeSocket.end;
    }, [wellStore?.id, activeParameters, realtimeSocket]);

    // load list of all possible parameters
    useEffect(() => {
      fetchAvailableParams((data) => setAvailableParams(data));
    }, []);

    useEffect(() => {
      if (!availableParamsList) return;

      let tempTableItems = availableParamsList.map((p) => {
        const relatedItem = currentTemplateData?.trends?.find(
          (item) => item.parameterId == p.id
        );
        return Object.assign({}, p, {
          scaleNumber: relatedItem?.scaleNumber || 1,
          active: relatedItem?.active || false,
        });
      });

      setTableItems(
        availableParamsList.map((p) => {
          const relatedItem = currentTemplateData?.trends?.find(
            (item) => item.parameterId == p.id
          );
          return Object.assign({}, p, {
            scaleNumber: relatedItem?.scaleNumber || 1,
            active: relatedItem != null ? true : false,
            isMeander: relatedItem?.isMeander || false,
          });
        })
      );
    }, [currentTemplateData, currentTemplateId, isModalVisible, isMnemoModalVisible]);

    // load list of all available templates
    useEffect(() => {
      fetchTemplatesList((templates) => setTemplatesList(templates));
    }, []);

    // load previously chosen template id from local storage and store it after component mounted
    useEffect(() => {
      const prevTemplateId = getPreviouslyChosenTemplate(); // || deafult_id !!!!!!
      if (!prevTemplateId) return;
      setCurrentTemplateId(parseInt(prevTemplateId, 10)); // Иначе
    }, []);

    // every time user chooses another template store this template's id in local storage
    useEffect(() => {
      storeChosenTemplate(currentTemplateId);
    }, [currentTemplateId]);

    // every time user chooses another template load its data
    useEffect(() => {
      if (!currentTemplateId) return setCurrentTemplateData(null);
      fetchTemplateData(currentTemplateId, (data) => {
        setCurrentTemplateData(data);
      });
    }, [currentTemplateId]);

    useEffect(() => {
      if (mnemoStore.fetchTemplateList.initial) {
        mnemoStore.fetchTemplateList();
      }
    }, []);

    //   useEffect(() => {
    //   if (fullScreenMode || isModalVisible) {
    //     homeStore.setDraggableMode(false);

    //     return;
    //   }

    //   homeStore.setDraggableMode(true);
    // }, [fullScreenMode, isModalVisible]);

    const equipmentStaticCommonContent = [
      {
        title: t("standardType"),
        value: data?.type,
      },
      {
        title: t("wellType"),
        value: data?.pump?.type,
      },
      {
        title: t("wellManufacturer"),
        value: data?.manufacturer,
      },
      {
        title: t("statusTM"),
        value: data?.status,
      },
      {
        title: t("wellState"),
        value: data?.state,
      },
    ];

    const equipmentStaticDeepWellRodPumpContent = [
      {
        title: t("lengthStroke"),
        value: data?.lengthStroke,
      },
      {
        title: t("pumpRate"),
        value: data?.pumpRate,
      },
    ];

    const equipmentRows = isDeepWellRodPump ?
      [...equipmentStaticCommonContent, ...equipmentStaticDeepWellRodPumpContent]
      : [...equipmentStaticCommonContent];

    const lastBreakPointRows = [
      {
        title: t("date"),
        value: dataLastBreakPoint?.date ? dayjs(fromUtcToLocale(dataLastBreakPoint?.date)).format("DD.MM.YYYY HH:mm:ss") : null,
      },
      {
        title: t("reason"),
        value: dataLastBreakPoint?.reason ?
          dataLastBreakPoint.reason === "by program" ?
            "Программно" : dataLastBreakPoint.reason :
          null,
      },
    ];

    const [nrhSettingsModal, setNrhSettingsModal] = useState(false);
    const chooseTranslateFromArray = (alias, array, lang) => {
      if (alias == null) return "";
      if (array == null) return "";
      let id = array?.parameters.findIndex((x) => x.alias === alias);
      id = array?.parameters[id].id;
      const translationIndex =
        array &&
        array[lang] &&
        array[lang].findIndex((x) => x.parameterId === id);

      return translationIndex >= 0
        ? array[lang][translationIndex].translation
          ? array[lang][translationIndex].translation
          : alias
        : alias;
    };

    const chooseTranslateFromArrayUnits = (alias, array, lang) => {
      if (alias == null) return "";
      if (array == null) return "";
      const id = array.units.findIndex((x) => x.id === alias);
      const translationIndex = array[lang].findIndex((x) => x.unitId === id + 1);

      return translationIndex >= 0
        ? array[lang][translationIndex].translation
          ? array[lang][translationIndex].translation
          : array.units[id].alias
        : array.units[id].alias;
    };

    const makeSupportDataTable = () => {
      const result = [];
      const storageTranslations = JSON.parse(
        sessionStorage.getItem("translations")
      );
      const storageTranslationsUnits = JSON.parse(
        sessionStorage.getItem("translationsUnits")
      );
      const currentLanguage = i18n.language;

      if (data?.parameters != null)
        for (let i = 0; i < data?.parameters.length; i++) {
          const name = chooseTranslateFromArray(
            data?.parameters[i].alias,
            storageTranslations,
            currentLanguage
          );
          // const unit = data?.parameters[i].unit // Тут нет поля с единиицами измерения
          const unit = chooseTranslateFromArrayUnits(
            data?.parameters[i].unit,
            storageTranslationsUnits,
            currentLanguage
          );

          // Тут нет поля с единиицами измерения
          let value = null;

          if (data?.parameters[i]?.value !== null) {
            value = data?.parameters[i]?.value.toFixed(2);
          } else {
            value = "NO_DATA";
          }

          if (realtimeData) {
            if (data.parameters) {
              if (realtimeData[data.parameters[i].alias]) {
                value = Number(realtimeData[data.parameters[i].alias]).toFixed(2);
              } else {
                value = "NO_DATA";
              }
            }
          }

          result.push(
            <div className="info-division-row">
              <div className="info-division-item width60">
                {name.length > 20 ? (
                  <Tooltip
                    tooltipText={<div className="tooltip-text">{name}</div>}
                  >
                    {name}
                  </Tooltip>
                ) : (
                  <div> {name} </div>
                )}
              </div>
              <div className="info-division-item width20">{value}</div>
              <div className="info-division-item width20">{unit}</div>
            </div>
          );
        }

      return result;
    }

    const fullScreenWidth = menuLocationsMinimizedMode ?
      "calc(100vw - 80px)" : "calc(100vw - 248px)";

    const fullScreenLeft = menuLocationsMinimizedMode ?
      "80px" : "248px";

    return (
      <div className="info-content">
        <Row
          className={
            isFullScreen === false ? "" : "info-division-fullScreen"
          }
          style={{
            padding: "10px 24px 0px 24px",
            width: isFullScreen ? fullScreenWidth : "100%",
            left: isFullScreen ? fullScreenLeft : "0px",
          }}
          align={"middle"}
          justify={"space-between"}

        >
          <Col>
            <span className="info-division-title">{t("nrhTitle")}</span>
          </Col>

          <div style={{
            display: "flex",
            gap: "12px",
            marginRight: isFullScreen ? "24px" : "0px"
          }}>
            <Col style={{ cursor: "pointer" }}>
              <Tooltip
                zIndex={1000000000001}
                tooltipText="Полноэкранный режим"
              >
                <div>
                  {isFullScreen ? (
                    <FullScreenOff onClick={toggleFullScreen} />
                  ) : (
                    <FullScreenOn onClick={toggleFullScreen} />
                  )}
                </div>
              </Tooltip>
            </Col>

            {Boolean(accessStore.checkPermission("edit_nrh")) &&
              <Col style={{ cursor: "pointer" }}>
                <Tooltip zIndex={1000000000001} tooltipText="Настройка элементов">
                  <SettingsWhiteIcon onClick={() => setNrhSettingsModal(true)} />
                </Tooltip>
              </Col>
            }
          </div>
        </Row>

        <div className="info-division">
          <NRHChart
            isFullScreen={isFullScreen}
            data={dataNRH}
            menuLocationsMinimizedMode={menuLocationsMinimizedMode}
          />
        </div>

        <div className="info-division">
          <Row align={"middle"} justify={"space-between"}>
            <Col>
              <span className="info-division-title">{t("mnemoscheme")}</span>
            </Col>
            <div
              style={{
                display: 'flex',
                gap: "12px"
              }}>
              <Col style={{ cursor: 'pointer' }}>
                {
                  isMnemoMode
                    ? <Tooltip zIndex={1000000000001} title="Закрыть мнемосхему">
                      <EyeInvisibleOutlined style={{ fontSize: '24px', color: '#9c9d9f' }} onClick={() => setMnemoMode && setMnemoMode(!isMnemoMode)} />
                    </Tooltip>
                    : <Tooltip zIndex={1000000000001} title="Открыть мнемосхему">
                      <EyeOutlined style={{ fontSize: '24px', color: '#9c9d9f' }} onClick={() => setMnemoMode && setMnemoMode(!isMnemoMode)} />
                    </Tooltip>
                }
              </Col>

              {Boolean(accessStore.checkPermission("edit_mnemoscheme")) &&
                <Col style={{ cursor: "pointer" }}>
                  <Tooltip zIndex={1000000000001} title="Настройка мнемосхемы">
                    <SettingsWhiteIcon
                      onClick={() => setIsMnemoModalVisible(true)}
                    />
                  </Tooltip>
                </Col>
              }
            </div>
          </Row>
        </div>

        <div
          style={{
            overflowX: "auto",
          }}
        >
          <div className="info-division">
            <span className="info-division-title">{t("equipment")}</span>
            {equipmentRows.map(({ title, value }, index) => {
              return (
                <div className="info-division-row" key={index}>
                  <div className="info-division-item">
                    {title.length > 20 ? (
                      <Tooltip
                        tooltipText={
                          <div className="tooltip-text">{title}</div>
                        }
                      >
                        {title}
                      </Tooltip>
                    ) : (
                      title
                    )}
                  </div>
                  <div className="info-division-item width60">
                    {value?.length > 20 ? (
                      <Tooltip
                        tooltipText={
                          <div className="tooltip-text">{value ?? "Нет данных"}</div>
                        }
                      >
                        {value ?? "Нет данных"}
                      </Tooltip>
                    ) : (
                      value ?? "Нет данных"
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="info-division">
            <span className="info-division-title">{t("lastBreakpoint")}</span>
            {lastBreakPointRows.map(({ value, title }, index) => (
              <div className="info-division-row" key={index}>
                <div className="info-division-item">
                  {title.length > 20 ? (
                    <Tooltip
                      tooltipText={
                        <div className="tooltip-text">{title}</div>
                      }
                    >
                      {title}
                    </Tooltip>
                  ) : (
                    title
                  )}
                </div>
                <div className="info-division-item width60">
                  {value?.length > 20 ? (
                    <Tooltip
                      tooltipText={
                        <div className="tooltip-text">{value ?? "Нет данных"}</div>
                      }
                    >
                      {value ?? "Нет данных"}
                    </Tooltip>
                  ) : (
                    value ?? "Нет данных"
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="info-division">
            <Row>
              <span className="info-division-title">{t("formationInformation")}</span>
              {dataFormation?.map((value, index) => {
                return (
                  <div className={index === Math.floor(index / 2) * 2 ? "info-division-row" : "info-division-row-bg"} key={index}>
                    <div className="info-division-item width60">
                      {value?.nameOis}
                    </div>
                  </div>
                )
              })}
            </Row>
          </div>

          <div className="info-division">
            <Row align={"middle"} justify={"space-between"}>
              <span className="info-division-title">{t("workParameters")}</span>
              <div style={{ display: "block" }}>
                <Col style={{ cursor: "pointer" }}>
                  <Tooltip zIndex={1000000000001} title="Настройка элементов">
                    <SettingsWhiteIcon onClick={() => setIsModalVisible(true)} />
                  </Tooltip>
                </Col>
              </div>
            </Row>

            <ParametersInfo
              paramsList={(() => {
                if (!currentTemplateData) return [];
                displayedParameters?.sort((a, b) => {
                  if (a?.active) {
                    return -1;
                  } else {
                    return 1;
                  }
                });
                return currentTemplateData.isDefault
                  ? displayedParameters
                  : activeParameters;
              })()}
              realtimeSocket={realtimeSocket}
              activeParams={activeParameters}
            />
          </div>
        </div>
        {/* <div className="info-division dynamic">
        <span className="info-division-title">{t("mode")}</span>
        {makeSupportDataTable()}
      </div> */}
        <NRHSettingsModal
          visible={nrhSettingsModal}
          closeModal={() => setNrhSettingsModal(false)}
        />

        <MnemoschemeModal
          visible={isMnemoModalVisible}
          closeModal={() => setIsMnemoModalVisible(false)}
          templatesList={templatesList}
          currentTemplateData={currentTemplateData}
          currentTemplateId={currentTemplateId}
          userId={userId}
          setCurrentTemplateId={setCurrentTemplateId}
          setTemplatesList={setTemplatesList}
          setCurrentTemplateData={setCurrentTemplateData}
          parameterList={tableItems}
        />

        <ParametersModal
          visible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          templatesList={templatesList}
          currentTemplateData={currentTemplateData}
          currentTemplateId={currentTemplateId}
          setCurrentTemplateId={setCurrentTemplateId}
          setTemplatesList={setTemplatesList}
          setCurrentTemplateData={setCurrentTemplateData}
          tableItems={tableItems}
        />
      </div>
    );
  });

export default SupportingInformation;
