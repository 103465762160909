import WellAnalysisLayout from 'layouts/WellAnalysisLayout'
import Home from 'containers/Home'

const HomePage = () => (
  <WellAnalysisLayout>
    <Home />
  </WellAnalysisLayout>
)

export default HomePage
