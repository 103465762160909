import { ReactComponent as BackStepModalIcon } from "assets/images/backStepModalIcon.svg";
import { useTranslation } from "react-i18next";

const BackStepIcon = ({settingNames, setStep, setButtonText}) => {
  const {t} = useTranslation();
  return (
    <div className="settings-modal-title">
      <BackStepModalIcon
        onClick={() => (
          settingNames === "analysisSettings" || settingNames === "reasonsSettings" ? (setStep("choose-settings"), setButtonText(t("apply"))) : setStep("choose-settings")
        )}
      />
      <span>{settingNames}</span>
    </div>
  )
}

export default BackStepIcon;