/* eslint-disable */
import { makeAutoObservable, observable, runInAction, toJS } from "mobx";
import { task } from "mobx-fetch-task";
import LocationTreeNode from "./components/LocationsSettings/locationTree";
import request from "utils/request";
import Input from "formAdapters/Input";
import Select from "formAdapters/Select";
import Checkbox from "formAdapters/Checkbox";
import { required } from "utils/validate";
import { FORM_ERROR } from "final-form";
import { ruCollator } from "utils/collator";

class AdminLocationsSettingsStore {

  locationTrees = [];

  isAddRootLocationModalVisible = false;

  addingLocationParentId = null;
  addingWellLocationId = null;

  currentEditableLocation = null;
  currentEditableWell = null;
  currentEditableWellAllData = null;
  currentEditableWellAllDataLoaded = false;

  pumpTypes = [];

  constructor() {
    makeAutoObservable(this, {
      fetchPumpTypes: false,
      fetchLocationTrees: false
    });
  }

  fetchPumpTypes = task(async () => {
    try {
      const { data } = await request("/well/pumpTypes");
      runInAction(() => {
        this.pumpTypes = observable(
          data.map(t => ({ id: t.id, name: t.type }))
        );
      });
    } catch (error) {
      console.error(error);
    }
  });

  async fetchWellData(id) {
    try {
      const { data } = await request.get(`/well/${Number(id.substring(1))}`);
      data.id = `w${data.id}`;
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  fetchLocationTrees = task(async () => {
    try {
      this.setLocationTrees([]);

      const { data } = await request.get("/locations/settingsTree");

      runInAction(() => {
        this.setLocationTrees(data);
      })
    } catch (error) {
      this.setLocationTrees([]);
    }
  });

  sortLocationTrees() {
    const sortNode = (node) => {
      if (node.children == null) return;

      node.children.sort((a, b) => ruCollator.compare(a.name, b.name));

      for (const child of node.children) {
        sortNode(child);
      }
    }

    this.locationTrees.sort((a, b) => ruCollator.compare(a.name, b.name));

    for (const rootLocation of this.locationTrees) {
      sortNode(rootLocation);
    }
  }

  setLocationTrees(trees) {
    if (!trees) {
      this.locationTrees = null;
      return;
    }

    const createdTrees = [];
    for (const tree of trees) {
      createdTrees.push(makeAutoObservable(
        new LocationTreeNode(
          tree.id,
          tree.name,
          tree.well,
          tree.parentId,
          tree.children
        )));
    }

    for (const tree of createdTrees) {
      for (const node of tree.list) {
        node.id = node.well ? `w${node.id}` : `l${node.id}`;
        if (node.parentId) {
          node.parentId = `l${node.parentId}`;
        }
      }
    }

    this.locationTrees = createdTrees;
    this.sortLocationTrees();
  }

  findLocation(id) {
    for (const tree of this.locationTrees) {
      const location = tree.find(id);
      if (location) return location;
    }
    return null;
  }

  get tableDataSource() {
    return this.locationTrees;
  }

  async addRootLocation(values) {
    const addLocationDTO = {
      name: values.name,
      parentId: null
    }

    try {
      const { data: id } = await request.post("/locations", addLocationDTO);

      const location = {
        id: `l${id}`,
        ...addLocationDTO,
        well: false
      }

      const tree = makeAutoObservable(new LocationTreeNode(
        location.id,
        location.name,
        location.well,
        null,
        []
      ));

      runInAction(() => {
        this.locationTrees.push(tree);
        this.sortLocationTrees();
        this.closeAddRootLocationModal();
      });
    } catch (error) {
      console.error(error);
      return {
        [FORM_ERROR]: null
      }
    }
  }

  async addLocation(values) {
    const addLocationDTO = {
      name: values.name,
      parentId: Number(values.parentId.substring(1))
    }

    try {
      const { data: id } = await request.post("/locations", addLocationDTO);

      const location = {
        id: `l${id}`,
        name: values.name,
        parentId: values.parentId,
        well: false,
        children: []
      }

      const parent = this.findLocation(location.parentId);
      if (!parent) return;

      runInAction(() => {
        if (!parent.children) parent.children = [];
        parent.children.push(location);
        this.sortLocationTrees();
        this.closeAddLocationModal();
      });
    } catch (error) {
      console.error(error);
      return {
        [FORM_ERROR]: null
      }
    }
  }

  async editLocation(values) {
    const editLocationDTO = {
      id: Number(values.id.substring(1)),
      name: values.name
    }

    try {
      await request.put("/locations", editLocationDTO);

      const editableLocation = this.findLocation(values.id);
      if (!editableLocation) return;

      runInAction(() => {
        editableLocation.name = editLocationDTO.name;
        this.sortLocationTrees();
        this.closeEditLocationModal();
      });

    } catch (error) {
      console.error(error);
      return {
        [FORM_ERROR]: null
      }
    }
  }

  async deleteLocation(id) {
    try {
      await request.delete(`/locations/${Number(id.substring(1))}`);

      const deletableLocation = this.findLocation(id);
      if (!deletableLocation) return;

      const parent = this.findLocation(deletableLocation.parentId);
      if (!parent) {
        runInAction(() => {
          this.locationTrees = this.locationTrees.filter(root => root.id !== id);
        });
        return true;
      }

      runInAction(() => {
        parent.children = parent.children.filter(c => c.id !== id);
      });

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async addWell(values) {
    const addWellDTO = {
      locationId: Number(values.locationId.substring(1)),
      name: values.name,
      type: values.type,
      state: values.state,
      manufacturer: values.manufacturer,
      pumpTypeId: values.pumpTypeId,
      periodFond: values.periodFond ?? false
    }

    try {
      const { data: id } = await request.post("/well", addWellDTO);

      const well = {
        id: `w${id}`,
        name: addWellDTO.name,
        well: true
      }

      const location = this.findLocation(values.locationId);
      if (!location) return;

      runInAction(() => {
        location.children.push(well);
        this.sortLocationTrees();
        this.closeAddWellModal();
      });
    } catch (error) {
      console.error(error);
      return {
        [FORM_ERROR]: null
      }
    }
  }

  async editWell(values) {
    const editWellDTO = {
      id: Number(values.id.substring(1)),
      name: values.name,
      type: values.type,
      state: values.state,
      manufacturer: values.manufacturer,
      pumpTypeId: values.pumpTypeId,
      periodFond: values.periodFond ?? false
    }

    try {
      await request.put("/well", editWellDTO);

      const editableWell = this.findLocation(values.id);
      if (!editableWell) return;

      runInAction(() => {
        editableWell.name = editWellDTO.name;
        this.sortLocationTrees();
        this.closeEditWellModal();
      });
    } catch (error) {
      console.error(error);
      return {
        [FORM_ERROR]: null
      }
    }
  }

  async deleteWell(id) {
    try {
      await request.delete(`/well/${Number(id.substring(1))}`);

      const deletableWell = this.findLocation(id);
      if (!deletableWell) return;

      const location = this.findLocation(deletableWell.parentId);
      if (!location) return;

      runInAction(() => {
        location.children = location.children.filter(c => c.id !== id);
      });

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  openAddRootLocationModal() {
    this.isAddRootLocationModalVisible = true;
  }

  openAddLocationModal(parentId) {
    this.addingLocationParentId = parentId;
  }

  openAddWellModal(locationId) {
    this.addingWellLocationId = locationId;
  }

  openEditLocationModal(editableLocation) {
    this.currentEditableLocation = editableLocation;
  }

  async openEditWellModal(editableWell) {
    this.currentEditableWell = editableWell;
    const wellData = await this.fetchWellData(editableWell.id);
    if (wellData && editableWell.id === this.currentEditableWell.id) {
      runInAction(() => {
        this.currentEditableWellAllData = wellData;
        this.currentEditableWellAllDataLoaded = true;
      });
    }
  }

  closeAddRootLocationModal() {
    this.isAddRootLocationModalVisible = false;
  }

  closeAddLocationModal() {
    this.addingLocationParentId = null;
  }

  closeAddWellModal() {
    this.addingWellLocationId = null;
  }

  closeEditLocationModal() {
    this.currentEditableLocation = null;
  }

  closeEditWellModal() {
    runInAction(() => {
      this.currentEditableWell = null;
      this.currentEditableWellAllData = null;
      this.currentEditableWellAllDataLoaded = false;
    });
  }

  get isAddLocationModalVisible() { return this.addingLocationParentId != null; }

  get isAddWellModalVisible() { return this.addingWellLocationId != null; }

  get isEditLocationModalVisible() {
    return this.currentEditableLocation !== null;
  }

  get isEditWellModalVisible() {
    return this.currentEditableWell !== null;
  }

  get addLocationFields() {
    return [
      {
        label: "locationName",
        name: "name",
        component: Input,
        validate: required
      }
    ];
  }

  get addWellFields() {
    return [
      {
        label: "wellName",
        name: "name",
        component: Input,
        validate: required
      },
      {
        label: "pumpMarking",
        name: "type",
        component: Input,
        validate: required
      },
      {
        label: "state",
        name: "state",
        component: Input,
        validate: required
      },
      {
        label: "manufacturer",
        name: "manufacturer",
        component: Input,
        validate: required
      },
      {
        label: "pumpType",
        name: "pumpTypeId",
        component: Select,
        allowClear: true,
        options: this.pumpTypes,
        validate: required
      },
      {
        label: "periodFond",
        name: "periodFond",
        component: Checkbox,
        type: "checkbox"
      }
    ];
  }
}

export default AdminLocationsSettingsStore;
