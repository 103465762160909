/* eslint-disable */
import React, { useRef, useCallback } from "react";
import Modal from "components/Modal";
// import Button from "components/Button";
import { Button } from 'antd';

import { useTranslation } from "react-i18next";
import { useStore } from "hooks/useStore";
import request from "utils/request";
import { useState } from "react";
import { Collapse } from "antd";
import moment from "moment";
import RangeDateTimePicker from "components/RangeDateTimePicker";
import ExportCSV from "./components/Exporter";
import { useEffect } from "react";
import useTimeConverter from "hooks/useTimeConverter";
import ParametersMenu from "./components/ParametersMenu";
import FilterMenu from "./components/FilterMenu";
import LocationsTree from "dataStructures/locationsTree";
import Stack from "dataStructures/stack";
import { observer } from "mobx-react-lite";
import WellListForm from "./components/WellListForm";
import { getAllSubLocations } from "./components/WellList/common";
import Draggable from "react-draggable";
import { toJS } from "mobx";
import axios from "axios";
// import plurRu from "pluralizaHourRu";
// import plurAll from "pluralizaHourAll";
import plurRu from "containers/Home/components/Trends/components/Chart/components/DateSetting/pluralizaHourRu";
import plurAll from "containers/Home/components/Trends/components/Chart/components/DateSetting/pluralizaHourAll";
import dayjs from "dayjs";
import "./styles.css";

const REPORT_EXPIRES_IN = 10 * 60 * 1000;

const { Panel } = Collapse;

const LoadingCircle = () => (
  <div className="lds-roller">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

const loadWellsList = async (chosenLocations) => {
  const { data: wellItems } = await request.post(`/well/allByLocations`, {
    locations: chosenLocations,
  });

  return wellItems;
};

const fetchAvailableParams = async (f) => {
  const { data } = await request.get("/settings/trends/get/paramsList");
  f(data);
};

const ruCollator = new Intl.Collator('ru-RU');

const ReportModal = observer(({ visible, onClose, isActive, }) => {

  const offsetHours = (new Date().getTimezoneOffset() * -1) / 60;
  const utcToLocal = useTimeConverter({
    fromOffsetDefault: 0,
    toOffset: offsetHours * 60,
    template: "DD.MM.YYYY HH:mm:ss",
  });
  const localToUTC = useTimeConverter({ toOffset: 0 });
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { wellStore, accessStore, translationStore } = useStore();
  const [checkedWellsIds, setCheckedWellsIds] = useState([]);
  const [checkedParametersIds, setCheckedParametersIds] = useState([]);
  const [period, setPeriod] = useState({ startDate: null, endDate: null });
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [activePanel, setActivePanel] = useState("1");
  const [wellItems, setWellItems] = useState();
  const [availableParamsList, setAvailableParams] = useState(null);
  const [wellsData, setWellsData] = useState();
  const [reportReduceData, setReportReduceData] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const [fetchDataCancelTokenSource, setFetchDataCancelTokenSource]
    = useState(axios.CancelToken.source());

  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y + 48,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  useEffect(() => {
    if (wellStore.selectedLocations == null) return;
    if (wellStore.selectedLocations?.size == 0) return;
    loadWellsList(wellStore.selectedLocations).then((data) => {
      const result = data.filter((well) => accessStore.checkWellPermission(well?.id, "download_report"));
      setWellItems(result);
      setWellsData(result);
    });
    fetchAvailableParams((data) => {
      setAvailableParams(translateParameters(data).sort((a, b) => ruCollator.compare(a.name, b.name)));
    });
  }, [
    Array.from(wellStore.selectedLocations).join(),
    accessStore.permissionsList
  ]);

  useEffect(() => {
    if (reportData) {
      setReportData(null);
    }
  }, [period, checkedWellsIds, checkedParametersIds, reportReduceData]);

  const translateParameters = (parametersData) => {
    const storageTranslations = JSON.parse(
      sessionStorage.getItem("translations")
    );
    const currentLanguage = i18n.language;

    const chooseTranslateFromArray = (alias, array, lang) => {
      if (alias == null) return "";
      if (array == null) return "";
      let id = array?.parameters.findIndex((x) => x.alias === alias);
      id = array?.parameters[id]?.id;
      const translationIndex =
        array &&
        array[lang] &&
        array[lang].findIndex((x) => x.parameterId === id);

      return translationIndex >= 0
        ? array[lang][translationIndex].translation
          ? array[lang][translationIndex].translation
          : alias
        : alias;
    };

    parametersData?.forEach((element) => {
      element.name = chooseTranslateFromArray(
        element.alias,
        storageTranslations,
        currentLanguage
      );
    });

    return parametersData;
  };

  const ableToSendRequest = () => {
    if (!Array.isArray(checkedWellsIds) || checkedWellsIds.length === 0) {
      return false;
    }
    if (!Array.isArray(checkedParametersIds) || checkedParametersIds.length === 0) {
      return false;
    }
    if (
      !moment(period && period.startDate).isValid() ||
      !moment(period && period.endDate).isValid()
    ) {
      return false;
    }

    return true;
  };

  const fetchData = async (token) => {
    try {

      const { data } = await request.post("/report/getReport", {
        wellIds: checkedWellsIds,
        paramIds: checkedParametersIds,
        from: localToUTC(period.startDate),
        to: localToUTC(period.endDate),
        offsetHours: offsetHours,
        reduceData: reportReduceData,
      }, {
        cancelToken: token,
        responseType: 'blob'
      });

      setFetchDataCancelTokenSource(axios.CancelToken.source());

      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const onPeriodChange = (period) => {
    if (period) {
      setPeriod({
        startDate: period.startDate ? moment(period.startDate).format("YYYY-MM-DDTHH:mm:ss") : null,
        endDate: period.endDate ? moment(period.endDate).format("YYYY-MM-DDTHH:mm:ss") : null,
      });
    } else setPeriod(null);
  };

  const onReportReduceDataChange = (newReportReduceData) => {
    setReportReduceData(newReportReduceData);
  };

  const renderButton = () => {
    return !reportData ? (
      <div>
        <Button
          text={t("DOWNLOAD")}
          htmlType="submit"
          type="primary"
          className="date-report-download-button"
          onClick={() => {
            setIsLoading(true);
            fetchData(fetchDataCancelTokenSource.token)
              .then((response) => {
                //костыль
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Отчёт по скважинам - ${moment().locale(translationStore.currentLanguage).format("DD.MM.YYYY HH:mm:ss")}.xlsx`);
                document.body.appendChild(link);
                link.click();
                //конец костыля
              })
              // .then(handleReportLoaded)
              .then(() => setIsLoading(false))
              .catch((err) => { });
          }}
          disabled={isLoading || !ableToSendRequest()} //  || !accessStore.checkPermission("download_report")
        >{t("DOWNLOAD")}</Button>
      </div>
    ) : (
      <div>
        {!isLoading && (
          <span style={{ margin: "10px", letterSpacing: "3px", color: "grey" }}>
            Данные загружены.
          </span>
        )}
      </div>
    );
  };

  const findLocationById = (id, location) => {
    if (location.id === id) return location;

    for (const child of location.locations) {
      const result = findLocationById(id, child);
      if (result) return result;
    }

    return null;
  };

  const findLocationInLocationsTreesById = (id, locationsTrees) => {
    for (const tree of locationsTrees) {
      const result = findLocationById(id, tree);
      if (result) return result;
    }
    return null;
  };

  const buildLocationsTrees = (activeLocationsIds) => {
    if (!activeLocationsIds || activeLocationsIds.length === 0) {
      return [];
    };

    const trees = [];

    for (const activeLocationId of activeLocationsIds) {
      const activeLocation = wellStore.locationsList.find(
        (location) => location.id === activeLocationId
      );
      if (!activeLocation) continue;

      const stack = new Stack();

      let current = activeLocation;
      while (current) {
        stack.add(current);
        current = current.parent;
      };

      const treeRoot = stack.pop();

      let tree = trees.find((tree) => tree.root.id === treeRoot.id);
      const isExistTree = !!tree;
      if (!isExistTree) {
        tree = new LocationsTree(treeRoot.id, treeRoot.name);
      };

      while (!stack.isEmpty()) {
        const location = stack.pop();
        if (!tree.find(location.id)) {
          tree.insert(location.parent.id, location.id, location.name);
        };
      };

      if (!isExistTree) {
        trees.push(tree);
      };
    };

    const treesSimpled = trees.map((tree) => tree.root);
    const deleteParentProperty = (location) => {
      delete location.parent;
      for (const child of location.locations) {
        deleteParentProperty(child);
      };
    };
    for (const tree of treesSimpled) {
      deleteParentProperty(tree);
    };

    return treesSimpled;
  };

  const activeLeafLocationsIds = wellStore.activeLeafLocations;

  useEffect(() => {
    if (activeLeafLocationsIds.length === 0) {
      setLocationsTrees([]);
      return;
    }

    if (!wellItems) {
      setLocationsTrees([]);
      return;
    }

    const newLocationsTrees = buildLocationsTrees(activeLeafLocationsIds);

    for (const well of wellItems) {
      const location = findLocationInLocationsTreesById(
        well.locationId,
        newLocationsTrees
      );
      if (!location) continue;
      if (!location.wells) {
        location.wells = [];
      }
      location.wells.push({
        id: well.id,
        name: well.name,
        active:
          prevLocationsTrees?.length > 0
            ? findLocationInLocationsTreesById(
              location.id,
              prevLocationsTrees
            )?.wells?.find((prevWellInfo) => prevWellInfo.id === well.id)
              ?.active ?? false
            : false,
      });
    }

    setLocationsTrees(newLocationsTrees);

  }, [[...activeLeafLocationsIds].join(""), wellItems]);

  const [prevLocationsTrees, setPrevLocationsTrees] = useState([]);
  const [locationsTrees, setLocationsTrees] = useState([]);

  useEffect(() => {
    setPrevLocationsTrees(locationsTrees);
  }, [locationsTrees]);

  const checkWells = (locationsTrees) => {
    const activeWellIds = [];

    for (const root of locationsTrees) {
      for (const sub of getAllSubLocations(root)) {
        if (!sub.wells) continue;
        for (const well of sub.wells) {
          if (well.active) {
            activeWellIds.push(well.id);
          }
        }
      }
    }

    setCheckedWellsIds(activeWellIds);
  }

  useEffect(() => {
    checkWells(toJS(locationsTrees));
  }, [locationsTrees]);

  const processReportReduceData = () => {
    let newReducingData = [];
    if (checkedParametersIds != null && checkedWellsIds != null) {
      checkedParametersIds.forEach((parameterElement, index) => {
        let parameterReducingData = {
          parameterId: parameterElement,
          parameterName: availableParamsList
            .filter((p) => checkedParametersIds.find((cp) => cp == p.id))
            .map((p) => p.name)[index],
          status: false,
          maxPoints: 100,
          wellsData: [],
        };
        checkedWellsIds.forEach((wellElement) => {
          let wellReducingData = {
            wellId: wellElement,
            status: false,
            maxPoints: 100,
          };

          parameterReducingData.wellsData.push(wellReducingData);
        });
        newReducingData.push(parameterReducingData);
      });

      setReportReduceData(newReducingData);
    }
  }

  useEffect(() => {
    processReportReduceData();
  }, [checkedWellsIds, checkedParametersIds, wellsData, availableParamsList]);

  const handleSubmit = useCallback((values) => {
    setLocationsTrees(values.locationsTrees);
  }, []);

  useEffect(() => {
    return () => {
      fetchDataCancelTokenSource.cancel();
    }
  }, []);

  const availablePeriods = [
    {
      label: "1 ч",
      seconds: 3600,
    },
    {
      label: "8 ч",
      seconds: 8 * 3600,
    },
    {
      label: "24 ч",
      seconds: 24 * 3600,
    },
    {
      label: "7 дн",
      seconds: 7 * 24 * 3600,
    },
    {
      label: "30 дн",
      seconds: 30 * 24 * 3600,
    },
  ];

  const handleDatePeriodClick = (seconds) => {
    const now = dayjs();
    setPeriod({
      startDate: now.subtract(seconds, "second").format("YYYY-MM-DDTHH:mm:ss"),
      endDate: now.format("YYYY-MM-DDTHH:mm:ss"),
    });
  }

  return (
    <Modal
      title={
        <div
          style={{
            width: '100%',
            cursor: 'grab',
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
        >
          {t(`DOWNLOAD_REPORT`)}
        </div>
      }
      visible={visible}
      // titleText={t(`DOWNLOAD_REPORT`)}
      width={600}
      className="add-priority-modal"
      onCancel={() => {
        setCheckedWellsIds([]);
        setCheckedParametersIds([]);
        setReportReduceData(null);
        onClose();
      }}
      footer={null}
      onClose={() => {
        setCheckedWellsIds([]);
        setCheckedParametersIds([]);
        setReportReduceData(null);
        onClose();
      }}
      destroyOnClose
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
          nodeRef={draggleRef}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      bodyStyle={{
        height: "600px",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          overflowY: "auto",
          textAlign: "center",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "0px 0px 20px 0px",
          boxSizing: "border-box"
        }}
      >
        {isLoading && <LoadingCircle />}
        {!isLoading && (
          <>
            <div style={{
              flex: "1",
              overflowY: "auto"
            }}>
              <Collapse
                accordion
                defaultActiveKey={activePanel}
                onChange={setActivePanel}
              >
                <Panel key="1" header={"Выбор скважин"}>
                  <WellListForm
                    locationsTrees={locationsTrees}
                    handleSubmit={handleSubmit}
                  />
                </Panel>
                <Panel key="2" header={"Выбор параметров"}>
                  <ParametersMenu
                    parametersData={availableParamsList}
                    checkedParams={checkedParametersIds}
                    setCheckedParams={(value) => setCheckedParametersIds(value)}
                  />
                </Panel>
                <Panel key="3" header={"Настройка фильтрации"}>
                  <FilterMenu
                    checkedWellsIds={checkedWellsIds}
                    checkedParametersIds={checkedParametersIds}
                    wellsData={wellsData}
                    parametersData={availableParamsList}
                    reportReduceData={reportReduceData}
                    onReportReduceDataChange={onReportReduceDataChange}
                    visible={visible}
                  />
                </Panel>
                <span
                  style={{
                    display: "block",
                    padding: "10px 0px",
                  }}
                >
                  Период времени
                </span>

                <div className="date-report-buttons">
                  {availablePeriods.map((btnPeriod, index) => {

                    const diff = period?.startDate && period?.endDate ?
                      dayjs(period.endDate).diff(period.startDate, "seconds") : null;

                    const isActive = diff ? btnPeriod.seconds === diff : false;

                    return (
                      <div
                        key={index}
                        className={isActive ? "active" : null}
                        onClick={() => handleDatePeriodClick(btnPeriod.seconds)}
                      >
                        {btnPeriod.label}
                      </div>
                    );
                  })}
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px 0px",
                  }}
                >
                  <RangeDateTimePicker
                    period={period}
                    handleChangePeriod={onPeriodChange}
                    timeSelectable
                  />
                </div>
              </Collapse>
            </div>
            {renderButton()}
          </>
        )}
      </div>
    </Modal>
  );
});

export default ReportModal;
