/* eslint-disable */
import { useMemo } from "react";
import { Button } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "hooks/useStore";
import HeaderCommon from "layouts/common/HeaderCommon";
import { WellTreemapType } from "containers/WellTreemapChart/store";
import Gradient from "./Gradient";
import "../common/styles.css";
import "./styles.css";

const WellTreemapLayout = observer(({ children }) => {

  const { wellTreemapStore } = useStore();

  const type = wellTreemapStore.type;
  const dataStore = wellTreemapStore.dataStore;
  const colors = dataStore?.colors;

  const gradientData = useMemo(() => {
    if (colors == null) return [];

    if (type === WellTreemapType.oil) {
      const workColor = colors[colors.length - 1];
      const stopColor = colors[0];
      return [
        {
          color: stopColor,
          label: "Скв. остановлена",
        },
        {
          color: workColor,
          label: "Скв. в работе",
        },
      ];
    } else if (type === WellTreemapType.deviation) {
      const positiveMax = dataStore.fetchData.result?.max;
      const negativeMax = dataStore.fetchData.result?.min;

      if (positiveMax == null || negativeMax == null) return [];

      const medianIndex = Math.floor(colors.length / 2);

      const positiveStep = positiveMax / (colors.length - medianIndex - 1);
      const negativeStep = negativeMax / (colors.length - medianIndex - 1);

      const gradientData = colors.map((color, index) => {
        const diff = index - medianIndex;
        const absDiff = Math.abs(diff);
        const direction = Math.sign(diff);
        let deviation = 0;
        if (direction < 0) deviation = absDiff * negativeStep;
        else if (direction > 0) deviation = absDiff * positiveStep;
        const getSign = (d) => {
          if (d > 0) return ">";
          else if (d < 0) return "<";
          return "";
        }
        return {
          color,
          label: `${getSign(deviation)} ${(deviation * 100).toFixed(2)}%`,
        }
      });

      return gradientData;
    }
    return [];
  }, [dataStore?.root, colors]);

  return (
    <>
      <header>
        <div className="well-treemap-layout-buttons">
          <Button
            onClick={() => wellTreemapStore.setType(WellTreemapType.oil)}
            data-active={type === WellTreemapType.oil}
          >
            Дебит нефти
          </Button>
          <Button
            onClick={() => wellTreemapStore.setType(WellTreemapType.deviation)}
            data-active={type === WellTreemapType.deviation}
          >
            Отклонение
          </Button>
        </div>

        <Gradient
          data={gradientData}
          extraGradientCell={type === WellTreemapType.deviation ? {
            color: dataStore.errorColor,
            fontColor: "black",
            label: "Ошибка",
          } : null}
          label={type === WellTreemapType.deviation && "Отклонение"}
        />

        <HeaderCommon />
      </header>
      <div className="main-layout">{children}</div>
    </>
  );
});

export default WellTreemapLayout;
