/* eslint-disable */
import { Table } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState, useCallback, useRef } from "react";
import { useStore } from "hooks/useStore";
import useRequest from "hooks/useRequest";
import { useTranslation } from "react-i18next";
import AnalysisModal from "./components/ControlPanel/components/AnalysisModal";
import ControlPanel from "./components/ControlPanel";
import { columns } from "./tableConfig";
import { minimizedColumns } from "./minimizedTableConfig";
import "./styles.css";
import TrendIndividualAnalysisModal from "./components/TrendIndividualAnalysisModal";
import request from "utils/request";
import { getHierarchy, indexArray } from "../MenuLocations/locationsParse";
import RealtimeConnection from "containers/Home/realtimeSocket";

const getWellsData = async (...locations) => {
  const { data } = await request.get(`/well/locations?locations=${locations}`);
  return data;
};

const getWellsPermissionsData = async (...locations) => {
  const { data } = await request.post(`/admin/getPermissions`, {
    locations: locations,
  });
  return data;
};

const getRolesData = async () => {
  const { data } = await request.get(`/admin/getRolesList`);
  return data;
};

const REALTIME_WELL_DATA_SOCKET_PATH = `/ws/realtimeWellData`;
const userId = 1;
const realtimeWellDataSocket = new RealtimeConnection(
  REALTIME_WELL_DATA_SOCKET_PATH,
  userId
);

const MenuData = observer(
  ({ minimizedMode, onMinimizedClick, fullScreenMode }) => {
    const {
      wellStore,
      homeStore,
      userStore: { accessLevel },
      accessStore,
    } = useStore();
    const [data, setData] = useState(null);

    const [wellsList, setWellsList] = useState(null);

    const { data: itemData } = useRequest(
      wellStore.id ? `/well/${wellStore.id}/data` : null
    );

    const [alarmsCountData, setAlarmsCountData] = useState();
    const { data: settings, mutate } = useRequest("/settings");

    const fetchAlarmsData = useCallback(async () => {
      if (!data) return;
      const { data: x } = await request.post(`/alarm/countByWells`, {
        wellIds: data.map((i) => i.id),
      });

      setAlarmsCountData(x);
    }, [wellsList]);

    const fetchWellsData = () => {
      const locs = wellStore.activeLeafLocations;
      if (!locs?.length) return;
      // let wells = [];
      let wellIds = [];
      getWellsData(...locs)
        .then((data) => {
          const result = [];
          data.forEach((i) => {
            result.push(...i.wells);
            // wells.push(
            //   ...i.wells.map((x) => {
            //     return [x.id, i.locationId];
            //   })
            // );
            wellIds.push(
              ...i.wells.map((x) => {
                return x.id;
              })
            );
          });
          setData(result);
          setWellsList(wellIds);
        })
        .then(() => {
          setTimeout(() => {
            if (!realtimeWellDataSocket || !wellIds || !wellStore.id) return;

            realtimeWellDataSocket
              .start({
                wellId: wellStore.id,
                wellIds: wellIds,
              })
              .then(() => {
                realtimeWellDataSocket.listen([
                  "Zamer_Oil",
                  "Liquid_deb_valid",
                  "Watercut_valid",
                  "Status_calc_vr_ml_model",
                  "Status_calc_vr_phys_model",
                ]);
                setSupportParams([
                  "Zamer_Oil",
                  "Liquid_deb_valid",
                  "Watercut_valid",
                  "Status_calc_vr_ml_model",
                  "Status_calc_vr_phys_model",
                ]);
              });

            return realtimeWellDataSocket.end;
          }, 100);
        });

      getRolesData()
        .then((data) => {
          accessStore.setRolesPermissions(data)
        });

      getWellsPermissionsData(...locs)
        .then((data) => {
          accessStore.setPermissionsList(data)
        });
    }

    useEffect(() => {
      fetchAlarmsData();
    }, [
      fetchAlarmsData,
      Array.from(wellStore.selectedLocations).join(""),
      wellStore.isAlarmsReload,
    ]);

    useEffect(() => {
      fetchWellsData();
    }, [
      wellStore.selectedLocations,
      Array.from(wellStore.selectedLocations).join(""),
      wellStore.isAlarmsReload,
    ]);

    const [supportParams, setSupportParams] = useState([
      "Zamer_Oil",
      "Liquid_deb_valid",
      "Watercut_valid",
      "Status_calc_vr_ml_model",
      "Status_calc_vr_phys_model",
    ]);

    const realtimeListener = useCallback((newData) => {
      let localData = structuredClone(data);
      const index = data?.findIndex((x) => x.id == newData.wellId);
      if (index > -1) {
        if (newData.id === "Zamer_Oil")
          localData[index].qn = Math.round(newData.value);
        if (newData.id === "Liquid_deb_valid")
          localData[index].qj = Math.round(newData.value);
        if (newData.id === "Watercut_valid")
          localData[index].obv = Math.round(newData.value);
        if (localData[index].qn !== data[index].qn || localData[index].qj !== data[index].qj || localData[index].obv !== data[index].obv) {
          setData(localData);
        }
      }
    }, [data]);

    useEffect(() => {
      if (!supportParams || !realtimeWellDataSocket) return;
      realtimeWellDataSocket.subscribe(supportParams, realtimeListener);

      return () => realtimeWellDataSocket.unsubscribe(realtimeListener);
    }, [realtimeListener, supportParams]);

    const [searchWells, setSearchWells] = useState(null);
    const [analysisModal, setAnalysisModal] = useState(false);
    const [trendAnalysisModal, setTrendAnalysisModal] = useState(false);
    const { t } = useTranslation();
    const handleSearch = (value) => {
      setSearchWells(
        data?.filter((item) =>
          item.name.toLowerCase().includes(value.toLowerCase())
        )
      );
    };

    const wellsSortedData = useMemo(() => {
      if (!data) return;
      let sortedData = Array(...data);
      const rangingSettings = homeStore.wellsRangingSettings;

      // switch(rangingSettings.filterByWellStatus) {
      //   case 'IN_WORK':
      //     sortedData = sortedData.filter(well => true)
      //     break
      //   case 'IDLE':
      //     sortedData = sortedData.filter(well => true)
      //     break
      //   default:
      //     break
      // }

      sortedData.sort((a, b) => {
        if (rangingSettings.order === "DESCENDING") {
          if (a.name < b.name) return 1;
          else return -1;
        } else {
          if (a.name < b.name) return -1;
          else return 1;
        }
      });

      const sortRedData = (key) => {
        sortedData.sort((a, b) => {
          if (
            a.alarmCount[key] !== 0 &&
            a.alarmCount[`${key}WithActions`] === 0
          )
            return 1;
          else return -1;
        });
        const firstPriorityDataRed = sortedData.filter(
          (a) =>
            a.alarmCount[key] !== 0 && a.alarmCount[`${key}WithActions`] === 0
        );
        if (rangingSettings.order === "DESCENDING")
          firstPriorityDataRed.reverse();
        const notFirstPriorityDataRed = sortedData.filter(
          (a) =>
            !(
              a.alarmCount[key] !== 0 && a.alarmCount[`${key}WithActions`] === 0
            )
        );
        sortedData = [...firstPriorityDataRed, ...notFirstPriorityDataRed];
      };

      const sortYellowData = (key) => {
        sortedData.sort((a, b) => {
          if (
            a.alarmCount[key] !== 0 &&
            a.alarmCount[`${key}WithActions`] !== 0 &&
            a.alarmCount[key] === a.alarmCount[`${key}WithActions`]
          )
            return 1;
          else return -1;
        });
        const firstPriorityDataYellow = sortedData.filter(
          (a) =>
            a.alarmCount[key] !== 0 &&
            a.alarmCount[`${key}WithActions`] !== 0 &&
            a.alarmCount[key] === a.alarmCount[`${key}WithActions`]
        );
        if (rangingSettings.order === "DESCENDING")
          firstPriorityDataYellow.reverse();
        const notFirstPriorityDataYellow = sortedData.filter(
          (a) =>
            !(
              a.alarmCount[key] !== 0 &&
              a.alarmCount[`${key}WithActions`] !== 0 &&
              a.alarmCount[key] === a.alarmCount[`${key}WithActions`]
            )
        );
        sortedData = [
          ...firstPriorityDataYellow,
          ...notFirstPriorityDataYellow,
        ];
      };

      const sortRedYellowData = (key) => {
        sortedData.sort((a, b) => {
          if (
            a.alarmCount[key] !== 0 &&
            a.alarmCount[`${key}WithActions`] !== 0 &&
            a.alarmCount[key] !== a.alarmCount[`${key}WithActions`]
          )
            return 1;
          else return -1;
        });
        const firstPriorityDataRedYellow = sortedData.filter(
          (a) =>
            a.alarmCount[key] !== 0 &&
            a.alarmCount[`${key}WithActions`] !== 0 &&
            a.alarmCount[key] !== a.alarmCount[`${key}WithActions`]
        );
        if (rangingSettings.order === "DESCENDING")
          firstPriorityDataRedYellow.reverse();
        const notFirstPriorityDataRedYellow = sortedData.filter(
          (a) =>
            !(
              a.alarmCount[key] !== 0 &&
              a.alarmCount[`${key}WithActions`] !== 0 &&
              a.alarmCount[key] !== a.alarmCount[`${key}WithActions`]
            )
        );
        sortedData = [
          ...firstPriorityDataRedYellow,
          ...notFirstPriorityDataRedYellow,
        ];
      };

      switch (rangingSettings.sortBy) {
        case "WATER_DEBIT":
          sortedData.sort((a, b) => {
            if (rangingSettings.order === "DESCENDING") {
              if (a.qj < b.qj) return 1;
              else return -1;
            } else {
              if (a.qj < b.qj) return -1;
              else return 1;
            }
          });
          break;
        case "OIL_DEBIT":
          sortedData.sort((a, b) => {
            if (rangingSettings.order === "DESCENDING") {
              if (a.qn < b.qn) return 1;
              else return -1;
            } else {
              if (a.qn < b.qn) return -1;
              else return 1;
            }
          });
          break;
        case "WATER_CUT":
          sortedData.sort((a, b) => {
            if (rangingSettings.order === "DESCENDING") {
              if (a.obv < b.obv) return 1;
              else return -1;
            } else {
              if (a.obv < b.obv) return -1;
              else return 1;
            }
          });
          break;
        case "instantaneous":
          sortedData.sort((a, b) => {
            if (a.alarmCount.instant < b.alarmCount.instant) return -1;
            else return 1;
          });
          switch (rangingSettings.sortByAnalysisColor) {
            case "RED":
              sortedData.sort((a, b) => {
                if (
                  a.alarmCount?.instant !== 0 &&
                  a.alarmCount?.instantWithActions === 0
                )
                  return 1;
                else return -1;
              });
              const firstPriorityDataRed = sortedData.filter(
                (a) =>
                  a.alarmCount?.instant !== 0 &&
                  a.alarmCount?.instantWithActions === 0
              );
              if (rangingSettings.order === "DESCENDING")
                firstPriorityDataRed.reverse();
              const notFirstPriorityDataRed = sortedData.filter(
                (a) =>
                  !(
                    a.alarmCount?.instant !== 0 &&
                    a.alarmCount?.instantWithActions === 0
                  )
              );
              sortedData = [
                ...firstPriorityDataRed,
                ...notFirstPriorityDataRed,
              ];

              break;
            case "YELLOW":
              sortedData.sort((a, b) => {
                if (
                  a.alarmCount?.instant !== 0 &&
                  a.alarmCount?.instantWithActions !== 0 &&
                  a.alarmCount?.instant === a?.alarmCount?.instantWithActions
                )
                  return 1;
                else return -1;
              });
              const firstPriorityDataYellow = sortedData.filter(
                (a) =>
                  a.alarmCount?.instant !== 0 &&
                  a.alarmCount?.instantWithActions !== 0 &&
                  a.alarmCount?.instant === a?.alarmCount?.instantWithActions
              );
              if (rangingSettings.order === "DESCENDING")
                firstPriorityDataYellow.reverse();
              const notFirstPriorityDataYellow = sortedData.filter(
                (a) =>
                  !(
                    a.alarmCount?.instant !== 0 &&
                    a.alarmCount?.instantWithActions !== 0 &&
                    a.alarmCount?.instant === a?.alarmCount?.instantWithActions
                  )
              );
              sortedData = [
                ...firstPriorityDataYellow,
                ...notFirstPriorityDataYellow,
              ];

              break;
            case "RED_YELLOW":
              sortedData.sort((a, b) => {
                if (
                  a.alarmCount?.instant !== 0 &&
                  a.alarmCount?.instantWithActions !== 0 &&
                  a.alarmCount?.instant !== a?.alarmCount?.instantWithActions
                )
                  return 1;
                else return -1;
              });
              const firstPriorityDataRedYellow = sortedData.filter(
                (a) =>
                  a.alarmCount?.instant !== 0 &&
                  a.alarmCount?.instantWithActions !== 0 &&
                  a.alarmCount?.instant !== a?.alarmCount?.instantWithActions
              );
              if (rangingSettings.order === "DESCENDING")
                firstPriorityDataRedYellow.reverse();
              const notFirstPriorityDataRedYellow = sortedData.filter(
                (a) =>
                  !(
                    a.alarmCount?.instant !== 0 &&
                    a.alarmCount?.instantWithActions !== 0 &&
                    a.alarmCount?.instant !== a?.alarmCount?.instantWithActions
                  )
              );
              sortedData = [
                ...firstPriorityDataRedYellow,
                ...notFirstPriorityDataRedYellow,
              ];
              break;

            default:
              if (rangingSettings.order === "DESCENDING") {
                sortedData.reverse();
              }
              break;
          }
          break;
        case "TREND_ANAL":
          sortedData.sort((a, b) => {
            if (a.alarmCount.trending < b.alarmCount.trending) return -1;
            else return 1;
          });
          switch (rangingSettings.sortByAnalysisColor) {
            case "RED":
              sortedData.sort((a, b) => {
                if (
                  a.alarmCount?.trending !== 0 &&
                  a.alarmCount?.trendingWithActions === 0
                )
                  return 1;
                else return -1;
              });
              const firstPriorityDataRed = sortedData.filter(
                (a) =>
                  a.alarmCount?.trending !== 0 &&
                  a.alarmCount?.trendingWithActions === 0
              );
              if (rangingSettings.order === "DESCENDING")
                firstPriorityDataRed.reverse();
              const notFirstPriorityDataRed = sortedData.filter(
                (a) =>
                  !(
                    a.alarmCount?.trending !== 0 &&
                    a.alarmCount?.trendingWithActions === 0
                  )
              );
              sortedData = [
                ...firstPriorityDataRed,
                ...notFirstPriorityDataRed,
              ];

              break;
            case "YELLOW":
              sortedData.sort((a, b) => {
                if (
                  a.alarmCount?.trending !== 0 &&
                  a.alarmCount?.trendingWithActions !== 0 &&
                  a.alarmCount?.trending === a?.alarmCount?.trendingWithActions
                )
                  return 1;
                else return -1;
              });
              const firstPriorityDataYellow = sortedData.filter(
                (a) =>
                  a.alarmCount?.trending !== 0 &&
                  a.alarmCount?.trendingWithActions !== 0 &&
                  a.alarmCount?.trending === a?.alarmCount?.trendingWithActions
              );
              if (rangingSettings.order === "DESCENDING")
                firstPriorityDataYellow.reverse();
              const notFirstPriorityDataYellow = sortedData.filter(
                (a) =>
                  !(
                    a.alarmCount?.trending !== 0 &&
                    a.alarmCount?.trendingWithActions !== 0 &&
                    a.alarmCount?.trending ===
                    a?.alarmCount?.trendingWithActions
                  )
              );
              sortedData = [
                ...firstPriorityDataYellow,
                ...notFirstPriorityDataYellow,
              ];

              break;
            case "RED_YELLOW":
              sortedData.sort((a, b) => {
                if (
                  a.alarmCount?.trending !== 0 &&
                  a.alarmCount?.trendingWithActions !== 0 &&
                  a.alarmCount?.trending !== a?.alarmCount?.trendingWithActions
                )
                  return 1;
                else return -1;
              });
              const firstPriorityDataRedYellow = sortedData.filter(
                (a) =>
                  a.alarmCount?.trending !== 0 &&
                  a.alarmCount?.trendingWithActions !== 0 &&
                  a.alarmCount?.trending !== a?.alarmCount?.trendingWithActions
              );
              if (rangingSettings.order === "DESCENDING")
                firstPriorityDataRedYellow.reverse();
              const notFirstPriorityDataRedYellow = sortedData.filter(
                (a) =>
                  !(
                    a.alarmCount?.trending !== 0 &&
                    a.alarmCount?.trendingWithActions !== 0 &&
                    a.alarmCount?.trending !==
                    a?.alarmCount?.trendingWithActions
                  )
              );
              sortedData = [
                ...firstPriorityDataRedYellow,
                ...notFirstPriorityDataRedYellow,
              ];
              break;

            default:
              if (rangingSettings.order === "DESCENDING") {
                sortedData.reverse();
              }
              break;
          }
          break;
        case "POT_ANAL":
          sortedData.sort((a, b) => {
            if (a.alarmCount.potential < b.alarmCount.potential) return -1;
            else return 1;
          });
          switch (rangingSettings.sortByAnalysisColor) {
            case "RED":
              sortedData.sort((a, b) => {
                if (
                  a.alarmCount?.potential !== 0 &&
                  a.alarmCount?.potentialWithActions === 0
                )
                  return 1;
                else return -1;
              });
              const firstPriorityDataRed = sortedData.filter(
                (a) =>
                  a.alarmCount?.potential !== 0 &&
                  a.alarmCount?.potentialWithActions === 0
              );
              if (rangingSettings.order === "DESCENDING")
                firstPriorityDataRed.reverse();
              const notFirstPriorityDataRed = sortedData.filter(
                (a) =>
                  !(
                    a.alarmCount?.potential !== 0 &&
                    a.alarmCount?.potentialWithActions === 0
                  )
              );
              sortedData = [
                ...firstPriorityDataRed,
                ...notFirstPriorityDataRed,
              ];

              break;
            case "YELLOW":
              sortedData.sort((a, b) => {
                if (
                  a.alarmCount?.potential !== 0 &&
                  a.alarmCount?.potentialWithActions !== 0 &&
                  a.alarmCount?.potential ===
                  a?.alarmCount?.potentialWithActions
                )
                  return 1;
                else return -1;
              });
              const firstPriorityDataYellow = sortedData.filter(
                (a) =>
                  a.alarmCount?.potential !== 0 &&
                  a.alarmCount?.potentialWithActions !== 0 &&
                  a.alarmCount?.potential ===
                  a?.alarmCount?.potentialWithActions
              );
              if (rangingSettings.order === "DESCENDING")
                firstPriorityDataYellow.reverse();
              const notFirstPriorityDataYellow = sortedData.filter(
                (a) =>
                  !(
                    a.alarmCount?.potential !== 0 &&
                    a.alarmCount?.potentialWithActions !== 0 &&
                    a.alarmCount?.potential ===
                    a?.alarmCount?.potentialWithActions
                  )
              );
              sortedData = [
                ...firstPriorityDataYellow,
                ...notFirstPriorityDataYellow,
              ];

              break;
            case "RED_YELLOW":
              sortedData.sort((a, b) => {
                if (
                  a.alarmCount?.potential !== 0 &&
                  a.alarmCount?.potentialWithActions !== 0 &&
                  a.alarmCount?.potential !==
                  a?.alarmCount?.potentialWithActions
                )
                  return 1;
                else return -1;
              });
              const firstPriorityDataRedYellow = sortedData.filter(
                (a) =>
                  a.alarmCount?.potential !== 0 &&
                  a.alarmCount?.potentialWithActions !== 0 &&
                  a.alarmCount?.potential !==
                  a?.alarmCount?.potentialWithActions
              );
              if (rangingSettings.order === "DESCENDING")
                firstPriorityDataRedYellow.reverse();
              const notFirstPriorityDataRedYellow = sortedData.filter(
                (a) =>
                  !(
                    a.alarmCount?.potential !== 0 &&
                    a.alarmCount?.potentialWithActions !== 0 &&
                    a.alarmCount?.potential !==
                    a?.alarmCount?.potentialWithActions
                  )
              );
              sortedData = [
                ...firstPriorityDataRedYellow,
                ...notFirstPriorityDataRedYellow,
              ];
              break;

            default:
              if (rangingSettings.order === "DESCENDING") {
                sortedData.reverse();
              }
              break;
          }
          break;
        case "USER_ANAL":
          sortedData.sort((a, b) => {
            if (a.alarmCount.custom < b.alarmCount.custom) return -1;
            else return 1;
          });
          switch (rangingSettings.sortByAnalysisColor) {
            case "RED":
              sortedData.sort((a, b) => {
                if (
                  a.alarmCount?.custom !== 0 &&
                  a.alarmCount?.customWithActions === 0
                )
                  return 1;
                else return -1;
              });
              const firstPriorityDataRed = sortedData.filter(
                (a) =>
                  a.alarmCount?.custom !== 0 &&
                  a.alarmCount?.customWithActions === 0
              );
              if (rangingSettings.order === "DESCENDING")
                firstPriorityDataRed.reverse();
              const notFirstPriorityDataRed = sortedData.filter(
                (a) =>
                  !(
                    a.alarmCount?.custom !== 0 &&
                    a.alarmCount?.customWithActions === 0
                  )
              );
              sortedData = [
                ...firstPriorityDataRed,
                ...notFirstPriorityDataRed,
              ];

              break;
            case "YELLOW":
              sortedData.sort((a, b) => {
                if (
                  a.alarmCount?.custom !== 0 &&
                  a.alarmCount?.customWithActions !== 0 &&
                  a.alarmCount?.custom === a?.alarmCount?.customWithActions
                )
                  return 1;
                else return -1;
              });
              const firstPriorityDataYellow = sortedData.filter(
                (a) =>
                  a.alarmCount?.custom !== 0 &&
                  a.alarmCount?.customWithActions !== 0 &&
                  a.alarmCount?.custom === a?.alarmCount?.customWithActions
              );
              if (rangingSettings.order === "DESCENDING")
                firstPriorityDataYellow.reverse();
              const notFirstPriorityDataYellow = sortedData.filter(
                (a) =>
                  !(
                    a.alarmCount?.custom !== 0 &&
                    a.alarmCount?.customWithActions !== 0 &&
                    a.alarmCount?.custom === a?.alarmCount?.customWithActions
                  )
              );
              sortedData = [
                ...firstPriorityDataYellow,
                ...notFirstPriorityDataYellow,
              ];

              break;
            case "RED_YELLOW":
              sortedData.sort((a, b) => {
                if (
                  a.alarmCount?.custom !== 0 &&
                  a.alarmCount?.customWithActions !== 0 &&
                  a.alarmCount?.custom !== a?.alarmCount?.customWithActions
                )
                  return 1;
                else return -1;
              });
              const firstPriorityDataRedYellow = sortedData.filter(
                (a) =>
                  a.alarmCount?.custom !== 0 &&
                  a.alarmCount?.customWithActions !== 0 &&
                  a.alarmCount?.custom !== a?.alarmCount?.customWithActions
              );
              if (rangingSettings.order === "DESCENDING")
                firstPriorityDataRedYellow.reverse();
              const notFirstPriorityDataRedYellow = sortedData.filter(
                (a) =>
                  !(
                    a.alarmCount?.custom !== 0 &&
                    a.alarmCount?.customWithActions !== 0 &&
                    a.alarmCount?.custom !== a?.alarmCount?.customWithActions
                  )
              );
              sortedData = [
                ...firstPriorityDataRedYellow,
                ...notFirstPriorityDataRedYellow,
              ];
              break;

            default:
              if (rangingSettings.order === "DESCENDING") {
                sortedData.reverse();
              }
              break;
          }
          break;

        default:
          break;
      }

      const firstPriorityData = sortedData.filter(
        (well) => well.wellReason !== null
      );
      const notFirstPriorityData = sortedData.filter(
        (well) => well.wellReason === null
      );

      sortedData = [...firstPriorityData, ...notFirstPriorityData];

      return sortedData;
    }, [data, homeStore.wellsRangingSettings]);

    useEffect(() => {
      const prevChosenWell = localStorage.getItem("LAST_SET_WELL");

      if (!(data && data.length > 0)) return;
      const id = data.findIndex((x) => x.id == prevChosenWell);
      if (prevChosenWell && id > 0) {
        wellStore.setId(prevChosenWell);
        accessStore.setCurrentWell(prevChosenWell)
        wellStore.setStatus(data[id].wellReason === null ? "add" : "delete");
      } else {
        data && wellStore.setId(data[0].id) && accessStore.setCurrentWell(data[0].id);
        data && accessStore.setCurrentWell(data[0].id)
        data &&
          wellStore.setStatus(data[0].wellReason === null ? "add" : "delete");
      }

      const currentWell = data?.find(well => well.id === wellStore.id);
      if (currentWell) {
        const selectedWellName = currentWell.name;

        wellStore.setWellBreadcrumbs(
          getHierarchy(currentWell.locationId, indexLocations)
            .concat([selectedWellName])
        );
      }

      alarmsCountData && wellStore.setAlarmsCount(alarmsCountData);
    }, [data, alarmsCountData, wellStore.id]);

    const isAdvancedUser = true; // accessLevel ==='ADMIN' || accessLevel==='TECHNICAL'

    const containerRef = useRef();
    const [minimizing, setMinimizing] = useState(false);

    const handleMinimize = () => {
      //containerRef.current.classList.add('instant-transform')
      setMinimizing(true);
      setTimeout(onMinimizedClick, 150);
      setTimeout(() => {
        setMinimizing(false);
      }, 900);
    };

    const changeModalVisible = () => {
      setAnalysisModal(!analysisModal);
    };

    const changeTrendAnalysisModalVisible = () => {
      setTrendAnalysisModal(!trendAnalysisModal);
    };

    const indexLocations = useMemo(
      () => indexArray(wellStore.locationsTree),
      [wellStore.locationsTree]
    );

    return (
      <div
        hidden={fullScreenMode}
        ref={containerRef}
        className={`well-container ${minimizedMode && "minimized"} ${minimizing && "instant-transform"
          }`}
      >
        <ControlPanel
          settingsData={settings}
          data={data}
          mutate={mutate}
          minimizedMode={minimizedMode}
          onArrowClick={handleMinimize}
          onSearchWell={handleSearch}
          onSearchCancel={() => {
            setSearchWells(null);
          }}
          showAddPriorityButton={isAdvancedUser}
          showExtendedMenu={isAdvancedUser}
          showReports={isAdvancedUser}
          onVirtualMeasureApply={fetchWellsData}
        />
        <div
          style={{ overflowY: "auto", overflowX: "hidden" }}
          className="ant-table-wrapper"
        >
          <Table
            columns={
              minimizedMode
                ? minimizedColumns()
                : columns(
                  itemData || [],
                  settings?.analysisTypes,
                  alarmsCountData,
                  changeModalVisible,
                  changeTrendAnalysisModalVisible
                )
            }
            dataSource={
              searchWells !== null
                ? searchWells?.map((item) => ({
                  ...item,
                  isSelected: wellStore.id === item.id,
                  key: item.id,
                }))
                : wellsSortedData?.map((item) => ({
                  ...item,
                  isSelected: wellStore.id === item.id,
                  key: item.id,
                }))
            }
            className="well-data"
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                const status = record.wellReason === null ? "add" : "delete";
                wellStore.setWellBreadcrumbs(
                  getHierarchy(record.locationId, indexLocations)
                    .concat([record.name])
                );
                wellStore.setId(record.id);
                wellStore.setStatus(status);
                wellStore.setData(record);
                accessStore.setCurrentWell(record.id)
              },
            })}
            rowClassName={(record) => (record.isSelected ? "selected" : "")}
          />
          {/*<AnalysisModal
            visible={analysisModal}
            closeModal={() => setAnalysisModal(false)}
          />
          <TrendIndividualAnalysisModal
            visible={trendAnalysisModal}
            closeModal={() => setTrendAnalysisModal(false)}
          />*/}
        </div>
        {!minimizedMode && (
          <div className="well-footer-container">
            <span className="total-wells">{t("totalWells")}:</span>
            <span className="total-count">{data?.length}</span>
          </div>
        )}
      </div>
    );
  }
);

export default MenuData;
