/* eslint-disable */
import { makeAutoObservable, observable, runInAction, toJS } from "mobx";
import { task } from "mobx-fetch-task";
import { store } from "hooks/useStore";
import Input from "formAdapters/Input";
import Select from "formAdapters/Select";
import request from "utils/request";
import { required } from "utils/validate";
import { FORM_ERROR } from "final-form";

class AdminUserStore {

  users = [];

  isAddUserModalVisible = false;

  currentEditableUser = null;

  languages = [];

  constructor() {
    makeAutoObservable(this, {
      fetchLanguages: false,
      fetchUsers: false
    });
  }

  fetchLanguages = task(async () => {
    try {
      const { data } = await request.get("/translations/languagesList");
      runInAction(() => {
        this.languages = observable(
          data.map(lang => ({ id: lang.id, name: lang.language }))
        );
      });
    } catch (error) {
      console.error(error);
      this.languages = [];
    }
  });

  fetchUsers = task(async () => {
    try {
      this.setUsers([]);

      if (!this.fetchLanguages.resolved && !this.fetchLanguages.pending) {
        await this.fetchLanguages();
      }
      const { data } = await request.get("/users");

      runInAction(() => {
        this.setUsers(data);
      });
    } catch (error) {
      this.setUsers([]);
    }
  });

  setUsers(users) {
    if (!users) {
      this.users = [];
      return;
    }
    this.users = observable(users);
  }

  setCurrentEditableUser(user) {
    this.currentEditableUser = user;
  }

  async addUser(values) {
    const addedUser = {
      login: values.login,
      groupId: values.groupId,
      surname: values.surname,
      name: values.name,
      patronymic: values.patronymic,
      phoneNumber: values.phoneNumber,
      email: values.email,
      defaultLanguageId: values.defaultLanguageId,
      comment: values.comment
    }

    const addedUserDTO = {
      ...addedUser,
      password: values.password
    }

    try {
      const { data: addedUserId } = await request.post("/users", addedUserDTO);

      addedUser.id = addedUserId;
      addedUser.editable = true;
      addedUser.deletable = true;

      makeAutoObservable(addedUser);

      runInAction(() => {
        this.users.push(addedUser);
        this.closeAddUserModal();
      });
    } catch (error) {
      console.error(error);
      return {
        [FORM_ERROR]: null
      }
    }
  }

  async editUser(values) {
    const editableUserDTO = {
      id: values.id,
      login: values.login,
      groupId: values.groupId,
      surname: values.surname,
      name: values.name,
      patronymic: values.patronymic,
      phoneNumber: values.phoneNumber,
      email: values.email,
      defaultLanguageId: values.defaultLanguageId,
      comment: values.comment
    }

    try {
      await request.put("/users", editableUserDTO);

      runInAction(() => {
        const edited = this.users.find(user => user.id === editableUserDTO.id);
        if (!edited) return;
        for (const entrie of Object.entries(editableUserDTO)) {
          edited[entrie[0]] = entrie[1];
        }
        this.setCurrentEditableUser(null);
      });
    } catch (error) {
      console.error(error);
      return {
        [FORM_ERROR]: null
      }
    }
  }

  async deleteUser(id) {
    try {
      await request.delete(`/users/${id}`);

      runInAction(() => {
        this.users = this.users.filter(user => user.id !== id);
      });
    } catch (error) {
      console.error(error);
      return {
        [FORM_ERROR]: null
      }
    }
  }

  get isEditUserModalVisible() {
    return this.currentEditableUser !== null;
  }

  get tableDataSource() {
    return this.users;
  }

  get addUserFields() {
    return [
      {
        label: "surname",
        name: "surname",
        component: Input,
        validate: required
      },
      {
        label: "name",
        name: "name",
        component: Input,
        validate: required
      },
      {
        label: "patronymic",
        name: "patronymic",
        component: Input,
        validate: required
      },
      {
        label: "login",
        name: "login",
        component: Input,
        validate: required
      },
      {
        label: "group",
        name: "groupId",
        component: Select,
        showSearch: true,
        allowClear: true,
        options: store.adminGroupStore.listEditableGroup
      },
      {
        label: "email",
        name: "email",
        component: Input,
        validate: required
      },
      {
        label: "phoneNumber",
        name: "phoneNumber",
        component: Input,
        validate: required
      },
      {
        label: "defaultLanguage",
        name: "defaultLanguageId",
        component: Select,
        allowClear: true,
        options: this.languages,
        validate: required
      },
      {
        label: "comment",
        name: "comment",
        component: Input
      },
      {
        label: "password",
        name: "password",
        component: Input,
        type: "password",
        validate: required
      }
    ];
  }

  get editUserFields() {
    return [
      {
        label: "surname",
        name: "surname",
        component: Input,
        validate: required
      },
      {
        label: "name",
        name: "name",
        component: Input,
        validate: required
      },
      {
        label: "patronymic",
        name: "patronymic",
        component: Input,
        validate: required
      },
      {
        label: "login",
        name: "login",
        component: Input,
        validate: required
      },
      {
        label: "group",
        name: "groupId",
        component: Select,
        showSearch: true,
        allowClear: true,
        options: store.adminGroupStore.listEditableGroup
      },
      {
        label: "email",
        name: "email",
        component: Input,
        validate: required
      },
      {
        label: "phoneNumber",
        name: "phoneNumber",
        component: Input,
        validate: required
      },
      {
        label: "defaultLanguage",
        name: "defaultLanguageId",
        component: Select,
        allowClear: true,
        options: this.languages,
        validate: required
      },
      {
        label: "comment",
        name: "comment",
        component: Input
      }
    ];
  }

  openAddGroupModal() {
    this.isAddUserModalVisible = true;
  }

  closeAddUserModal() {
    this.isAddUserModalVisible = false;
  }

  closeEditUserModal() {
    this.currentEditableUser = null;
  }
}

export default AdminUserStore;
