/* eslint-disable */
import { useEffect, useMemo, useRef, useState } from "react";
import { Layout, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "hooks/useStore";
import WellTree from "containers/Home/components/WellTree";
import UsersSettings from "./components/UsersSettings";
import LogsNRD from "./components/LogsNRD";
import LocationGroupsAccessLevelSettings from "./components/LocationGroupsAccessLevelSettings";
import GroupsSettings from "./components/GroupsSettings";
import LocationsSettings from "./components/LocationsSettings";
import Sider from "./components/Sider";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import LogsHistory from "./components/LogsHistory";
import useAuth from 'hooks/useAuth'

const Admin = observer(() => {

  const { t } = useTranslation();
  const { user } = useAuth()
  const navigate = useNavigate();

  const {
    userStore,
    adminUserStore,
    adminGroupStore,
    adminLocationsSettingsStore,
    adminLocationsGroupAccessStore
  } = useStore();

  useEffect(() => {
    if (userStore.fetchIsAdmin.initial) {
      userStore.fetchIsAdmin();
    }
  }, []);

  useEffect(() => {
    adminGroupStore.fetchGroups();
    adminUserStore.fetchUsers();
    adminLocationsSettingsStore.fetchPumpTypes();
    adminLocationsSettingsStore.fetchLocationTrees();
    adminLocationsGroupAccessStore.fetchRoles();
  }, []);

  if (userStore.fetchIsAdmin.resolved && !userStore.isAdmin) {
    navigate("/start");
  }

  const { current: components } = useRef({
    users: {
      label: t("usersSettings"),
      component: <UsersSettings />
    },
    groups: {
      label: t("groupsSettings"),
      component: <GroupsSettings />
    },
    locationAccess: {
      label: t("groupsAccessLevelSettings"),
      component: <LocationGroupsAccessLevelSettings />,
    },
    locations: {
      label: t("locationsSettings"),
      component: <LocationsSettings />,
    },
    logs: {
      label: t("logsNRD"),
      component: <LogsNRD/>,
    },
    logsHistory: {
      label: t("logsHistory"),
      component: <LogsHistory/>,
    }
  });

  const siderItems = useMemo(() => {
    const items = [];
    for (const key in components) {
      if (!(key == "logsHistory" && !(user?.uid == 1 || user?.uid == 16742))) {
        items.push({
          key,
          label: components[key].label
        });
      }
    }
    return items;
  }, [components]);

  const [selectedComponentKey, setSelectedComponentKey] = useState("users");

  const handleSiderMenuClick = (info) => {
    setSelectedComponentKey(info.key);
  }

  return (
    <>
      <WellTree showWells={false} />
      <Layout style={{ height: "100%" }}>
        <Sider
          items={siderItems}
          selectedMenuKey={selectedComponentKey}
          handleMenuClick={handleSiderMenuClick}
        />

        <Layout style={{ marginLeft: "10px", overflow: "hidden" }}>
          {(adminUserStore.fetchUsers.resolved
            && adminGroupStore.fetchGroups.resolved
            && adminLocationsSettingsStore.fetchLocationTrees.resolved) ?
            components[selectedComponentKey].component
            :
            <div style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fff"
            }}>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
          }
        </Layout>
      </Layout>
    </>
  );
});

export default Admin;
