/* eslint-disable */
import { getPaths } from "../common";
import { ParametersColors } from "containers/Home/components/Trends/parametersColors";

const getSeriesOfScale = (scale, renderSettings) => {
  const series = [{}];
  for (const param of scale.parameters) {
    const renderParameterSetting = renderSettings.parameters.find(
      (item) => item.parameterId === param.id
    );
    const isMeander = renderParameterSetting?.isMeander;

    let stroke;
    const strokeHexColor =
      ParametersColors[param.id % ParametersColors.colorsAmount];

    if (renderSettings.hovering) {
      if (renderParameterSetting.hovered) stroke = strokeHexColor + "FF";
      else stroke = strokeHexColor + "33";
    } else {
      stroke = strokeHexColor + "FF";
    }

    const width = 3;

    series.push({
      label: param.label,
      paramId: param.id,
      width,
      stroke,
      spanGaps: true,
      paths: getPaths(isMeander),
      points: {
        size: 10,
        filter: (u, seriesIdx, show) =>
          pointsFilter(u, seriesIdx, show, param.closestLeftPoint.timestamp)
      },
      isDisabled: param.isDisabled
    });
  }
  return series;
};

const pointsFilter = (u, seriesIdx, show, closestLeftPointTimestamp) => {
  if (!show) return [];

  const pointIdxs = u.data[seriesIdx]
    .map((point, index) => index);

  if (closestLeftPointTimestamp !== u.data[0][0]) {
    pointIdxs.shift();
  }
  pointIdxs.pop();

  return pointIdxs;
}

export default getSeriesOfScale;
