/* eslint-disable */
import React from "react";
import { Modal } from "antd";
import { ReactComponent as CloseModal } from "assets/images/closeModal.svg";
import "./styles.css";

const CustomModal = ({
  visible,
  className,
  children,
  onCancel,
  submitButton,
  titleText,
  ...rest
}) => (
    <Modal
      centered
      destroyOnClose={rest.destroyOnClose || false}
      open={visible}
      closeIcon={<CloseModal />}
      onCancel={onCancel}
      className={`custom-ant-modal ${className || ""}`}
      footer={submitButton}
      {...rest}
    >
      {titleText ? <div className="modal-title">{titleText}</div> : <div/>}
      {children}
    </Modal>
);

export default CustomModal;
