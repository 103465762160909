/* eslint-disable */
import { observer } from "mobx-react-lite";
import RangeDateTimePicker from "components/RangeDateTimePicker";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Switch, FormControlLabel } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "./styles.css";

const DateSetting = observer(
  ({
    period,
    handleChangePeriod,
    isActive,
    onRealtimeClick,
    isRealtime,
    realtimeClickable = true,
    predictionMode,
    handleChartPredictionVisible,
    leftMoveArrowHighlight,
    rightMoveArrowHighlight,
    handleLeftMoveArrowClick,
    handleRightMoveArrowClick,
  }) => {

    const { t } = useTranslation();

    const availablePeriods = [
      {
        label: "1 ч",
        hours: 1,
      },
      {
        label: "8 ч",
        hours: 8,
      },
      {
        label: "24 ч",
        hours: 24,
      },
      {
        label: "7 дн",
        hours: 7 * 24,
      },
      {
        label: "30 дн",
        hours: 30 * 24,
      },
    ];

    const highlightedAvailablePeriodIndex = availablePeriods
      .findIndex(availablePeriod => {
        const diff = period?.startDate && period?.endDate ?
          dayjs(period.endDate).diff(period.startDate, "seconds") : null;

        const isHighlighted = diff ? availablePeriod.hours * 3600 === diff : false;
        return isHighlighted;
      });

    const handleAvailablePeriodClick = (hours) => {
      if (isActive) {

        const period = {
          startDate: dayjs().subtract(hours, "hours"),
          endDate: dayjs(),
        }

        handleChangePeriod(period);

        if (isRealtime && hours > 1) {
          changeRealtimeMode();
        }
      }
    };

    const changeRealtimeMode = () => {
      onRealtimeClick(!isRealtime);
    };

    const handleRealtimeClick = () => {
      if (!isActive || !realtimeClickable) return;
      // handleDatePeriodClick(trendsStore.activePeriodLabel >= 24 ? 24 : 1);
      handleAvailablePeriodClick(1); // set 1 hour period
      changeRealtimeMode();
    };

    const realtimeSwitch = (
      <div style={{ padding: "0px 10px 0px 18px", color: isRealtime ? "#444" : "#999" }}>
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={isRealtime}
              onChange={handleRealtimeClick}
            />
          }
          label={t("Real-time")}
          style={{ marginRight: "0px" }}
        />
      </div>
    );

    return (
      <div className="date-container">
        <div className="move-arrows">
          <LeftOutlined
            className={leftMoveArrowHighlight ? "move-arrow-highlight" : null}
            onClick={handleLeftMoveArrowClick}
          />
          <RightOutlined
            className={rightMoveArrowHighlight ? "move-arrow-highlight" : null}
            onClick={handleRightMoveArrowClick}
          />
        </div>

        <div className="date-buttons">
          {availablePeriods.map((availablePeriod, index) => (
            <div
              key={index}
              className={`period-button ${highlightedAvailablePeriodIndex === index &&
                "active"
                }`}
              onClick={() => handleAvailablePeriodClick(availablePeriod.hours)}
            >
              {availablePeriod.label}
            </div>
          ))}
        </div>

        {realtimeSwitch}

        <div
          className={`period-button prediction ${predictionMode && "active"}`}
          onClick={handleChartPredictionVisible}
        >
          {t("prediction")}
        </div>

        <RangeDateTimePicker
          period={period}
          handleChangePeriod={handleChangePeriod}
          timeSelectable
          allowClear={false}
        />
      </div>
    );
  }
);

export default DateSetting;
