/* eslint-disable */
import { Form } from "react-final-form";
import { toJS } from "mobx";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ModalFormField from "containers/Admin/components/ModalFormField";
import Modal from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

const EditWellModal = ({ visible, onSubmit, onCancel, fields, well, loaded }) => {

  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={toJS(well)}
      render={({ handleSubmit, form, submitting, pristine, errors, submitErrors }) => (
        <Modal
          bodyStyle={{
            maxHeight: "calc(100vh - 220px)",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column"
          }}
          visible={visible}
          titleText={t("editWell")}
          className="add-priority-modal"
          onCancel={() => { form.reset(); onCancel(); }}
          submitButton={(
            <Button
              text={t("apply")}
              disabled={submitting || pristine || !loaded}
              onClick={async (event) => {
                if (Object.keys(errors).length !== 0) {
                  handleSubmit(event);
                  return;
                }

                if (!await handleSubmit(event))
                  form.restart();
              }}
            />
          )}
        >
          <div style={{ padding: "0px 20px", overflowY: "auto" }}>
            {loaded ?
              fields.map(field => (
                <ModalFormField key={field.name} field={field} />
              ))
              :
              <div style={{ display: "flex", justifyContent: "center", padding: "25px" }}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
              </div>
            }
          </div>
          <div style={{ padding: "20px", textAlign: "center", color: "red" }}>
            {submitErrors && t("errorHasOccured")}
          </div>
        </Modal>
      )}
    />
  );
}

export default EditWellModal;
