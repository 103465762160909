/*eslint-disable*/
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toJS } from "mobx";
import { Observer, observer } from "mobx-react-lite";
import { VariableSizeGrid } from "react-window";
import { Empty, Spin } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useStore } from "hooks/useStore";
import useTimeConverter from "hooks/useTimeConverter";
import { useResizeDetector } from "react-resize-detector";
import AlarmDeviationAnalysisModal from "containers/Home/components/ActiveAlarms/components/AlarmDeviationAnalysisModal";
import LoadingSpinner from "components/LoadingSpinner";
import CustomTooltip from "components/Tooltip";
import RangeDateTimePicker from "components/RangeDateTimePicker";
import { WellTreePickerModal } from "components/WellTreePicker";
import TableHeader from "./components/TableHeader";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import dayjsDuration from "dayjs/plugin/duration";
import { ReactComponent as OptionsIcon } from "assets/images/optionsIcon.svg";
import XlsxIcon from "assets/images/xlsx.png";
import "./styles.css";

dayjs.extend(dayjsDuration);

const ArchiveAlarms = observer(() => {

  const { wellStore, archiveAlarmsStore, translationStore } = useStore();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const fromUtcToLocale = useTimeConverter({
    toOffset: 0,
    fromOffsetDefault: new Date().getTimezoneOffset() / 60,
  });

  const [isOpenWellTreePickerModal, setIsOpenWellTreePickerModal] = useState(false);

  const handleChangePickedWellIds = (wellIds) => {
    archiveAlarmsStore.setPickedWellIds(wellIds);
  }

  const handleChangePeriod = (period) => {
    archiveAlarmsStore.setPeriod(period);
  }

  const alarms = archiveAlarmsStore.alarmFetcher.result;
  const isAlarmsLoading = archiveAlarmsStore.alarmFetcher.pending;

  const isAlarmFetchAvailable = archiveAlarmsStore.period.startDate != null
    && archiveAlarmsStore.period.endDate != null && archiveAlarmsStore.pickedWellIds.length > 0;

  const [selectedAlarm, setSelectedAlarm] = useState(null);

  const parameters = archiveAlarmsStore.parameterFetcher.result;
  const isParametersLoading = archiveAlarmsStore.parameterFetcher.pending;

  const fetchData = () => {
    archiveAlarmsStore.alarmFetcher();
    archiveAlarmsStore.parameterFetcher();
  }

  const [searchValues, setSearchValues] = useState(
    { wellName: "", locationName: "", name: "" }
  );

  const [activeFilterAlias, setActiveFilterAlias] = useState(null);

  const onWellClick = (wellId, locationId) => {
    wellStore.handleLocationClick(locationId);
    wellStore.setId(wellId);

    navigate("/analysis");
  }

  const { width, height, ref } = useResizeDetector();

  const tableRef = useRef(null);

  const warningTypes = useMemo(() => ({
    CUSTOM: "Пользовательский",
    TREND: "Трендовый",
    INSTANTANEOUS: "Анализ отклонений",
    POTENTIAL: "Потенциальный",
  }), []);

  const commonColumnStyle = {
    display: "flex",
    alignItems: "center",
    borderRight: "1px solid #f0f0f0",
    borderBottom: "1px solid #f0f0f0",
  }

  const CellBodyContainer = useCallback(
    ({ children, style }) => (
      <div
        style={{
          ...style,
          ...commonColumnStyle,
        }}
      >
        {children}
      </div>
    ),
    []
  );

  const columns = useMemo(
    () => {
      return [
        {
          title: t("well"),
          width: 200,
          filter: {
            alias: "wellName",
          },
          render: (record, style) => (
            <CellBodyContainer style={style}>
              <a
                className="alarm-page-well-label"
                onClick={() => onWellClick(record.wellId, record.locationId)}
                style={{ marginLeft: "16px" }}
              >
                {record.wellName}
              </a>
            </CellBodyContainer>
          ),
        },
        {
          title: t("deposit"),
          width: 200,
          filter: {
            alias: "locationName",
          },
          render: (record, style) => (
            <CellBodyContainer style={style}>
              <span
                style={{ marginLeft: "16px" }}
              >
                {record.locationName}
              </span>
            </CellBodyContainer>
          ),
        },
        {
          title: t("dateAndTimeOfCreation"),
          width: 150,
          render: (record, style) => {
            const formattedDateTime = dayjs(fromUtcToLocale(record.createdAt))
              .format("DD.MM.YYYY HH:mm:ss");
            const date = formattedDateTime.split(" ").at(0);
            const time = formattedDateTime.split(" ").at(1);
            return (
              <CellBodyContainer style={style}>
                <div
                  style={{
                    marginLeft: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    height: "100%",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    {date}
                  </span>
                  <span>{time}</span>
                </div>
              </CellBodyContainer>
            );
          },
        },
        {
          title: t("dateAndTimeOfClosure"),
          width: 150,
          render: (record, style) => {
            const formattedDateTime = dayjs(fromUtcToLocale(record.closedAt))
              .format("DD.MM.YYYY HH:mm:ss");
            const date = formattedDateTime.split(" ").at(0);
            const time = formattedDateTime.split(" ").at(1);
            return (
              <CellBodyContainer style={style}>
                <div
                  style={{
                    marginLeft: "16px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    height: "100%",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    {date}
                  </span>
                  <span>{time}</span>
                </div>
              </CellBodyContainer>
            );
          },
        },
        {
          title: t("warningType"),
          width: 235,
          render: (record, style) => (
            <CellBodyContainer style={style}>
              <span
                style={{ marginLeft: "16px" }}
              >
                {warningTypes[record.type] ?? record.type}
              </span>
            </CellBodyContainer>
          ),
        },
        {
          title: t("warningName"),
          width: null,
          filter: {
            alias: "name",
          },
          render: (record, style) => (
            <CellBodyContainer style={style}>
              <span
                style={{ marginLeft: "16px" }}
              >
                {record.name}
              </span>
            </CellBodyContainer>
          ),
        },
        {
          title: t("parameter"),
          width: 295,
          render: (record, style) => {
            if (parameters == null) return null;
            const parameter = parameters.find(param => param.id === record.parameterId);
            if (parameter == null) return null;

            return (
              <CellBodyContainer style={style}>
                <span
                  style={{ marginLeft: "16px" }}
                >
                  {/* <Observer>
                    {() => translationStore.translateParameterAlias(parameter.alias)}
                  </Observer> */}
                  {t("WATER_DEBIT")}
                </span>
              </CellBodyContainer>
            );
          },
        },
        {
          title: t("user"),
          width: 270,
          render: (record, style) => {
            return (
              <CellBodyContainer style={style}>
                <span
                  style={{ marginLeft: "16px" }}
                >
                  {record.closedBy}
                </span>
              </CellBodyContainer>
            );
          },
        },
        {
          title: (
            <div
              style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
            >
              {isAlarmFetchAvailable ?
                <ReloadOutlined
                  onClick={(e) => fetchData()}
                  style={{
                    color: isAlarmsLoading ? "#1890ff" : "#bfbfbf"
                  }}
                  spin={isAlarmsLoading}
                />
                :
                <CustomTooltip
                  tooltipText="Необходимо выбрать хотя бы одну скважину и период формирования отчета"
                >
                  <ReloadOutlined
                    style={{
                      color: isAlarmsLoading ? "#1890ff" : "#bfbfbf"
                    }}
                    spin={isAlarmsLoading}
                  />
                </CustomTooltip>
              }
            </div>
          ),
          width: 72,
          render: (record, style) => {
            return (
              <CellBodyContainer style={style}>
                <span
                  style={{ marginLeft: "16px" }}
                >
                  {record.isAccessed ?
                    <OptionsIcon
                      onClick={() => setSelectedAlarm(record)}
                      height={18}
                      style={{
                        cursor: "pointer",
                      }}
                    /> : null
                  }
                </span>
              </CellBodyContainer>
            );
          },
        },
      ];
    },
    [t, warningTypes, parameters, isAlarmsLoading]
  );

  const columnWidths = useMemo(() => {
    const columnWidths = columns.map(column => column.width);
    for (let index = 0; index < columnWidths.length; index++) {
      const columnWidth = columnWidths[index];
      if (columnWidth == null) {
        const allocatedWidth = columnWidths.reduce((sum, width) => sum += (width != null ? width : 0), 0);
        const freeWidth = width - allocatedWidth;
        columnWidths[index] = freeWidth;
      }
    }
    return columnWidths;
  }, [width, columns]);

  const renderCell = useCallback(() =>
    ({ columnIndex, rowIndex, style, data }) => {
      const record = data[rowIndex];
      const coloredStyle = {
        ...style,
        backgroundColor: rowIndex % 2 === 0 ? "#f5f6fa" : "#ffffff",
      }
      return columns[columnIndex].render(record, coloredStyle);
    },
    [columns]
  );

  useEffect(
    () => tableRef.current?.resetAfterColumnIndex(0),
    [columnWidths]
  );

  const filteredAlarms = useMemo(() => {
    if (alarms == null) return null;
    let result = toJS(alarms);
    Object.entries(searchValues)
      .forEach(([alias, value]) => {
        if (value === "") return;
        result = result.filter(record =>
          record[alias].toLowerCase().includes(value.toLowerCase()));
      });
    return result;
  }, [alarms, searchValues]);

  const handleDatePeriodClick = (seconds) => {
    const now = dayjs();
    handleChangePeriod({
      startDate: now.subtract(seconds, "second").format("YYYY-MM-DDTHH:mm:ss"),
      endDate: now.format("YYYY-MM-DDTHH:mm:ss"),
    });
  }

  const availablePeriods = [
    {
      label: "1 ч",
      seconds: 3600,
    },
    {
      label: "8 ч",
      seconds: 8 * 3600,
    },
    {
      label: "24 ч",
      seconds: 24 * 3600,
    },
    {
      label: "7 дн",
      seconds: 7 * 24 * 3600,
    },
    {
      label: "30 дн",
      seconds: 30 * 24 * 3600,
    },
  ];

  return (
    <>
      <div className="alarm-table-container">
        <div className="deviation-analysis-footer">
          {archiveAlarmsStore.alarmFetcher.resolved &&
            <span>Всего архивных предупреждений: {alarms.length}</span>
          }

          <div
            className="archive-alarm-date-period-buttons"
            style={{ marginLeft: "auto", marginRight: "8px" }}
          >
            {availablePeriods.map((btnPeriod, index) => {

              const diff = archiveAlarmsStore.period?.startDate && archiveAlarmsStore.period?.endDate ?
                dayjs(archiveAlarmsStore.period.endDate).diff(archiveAlarmsStore.period.startDate, "seconds") : null;

              const isActive = diff ? btnPeriod.seconds === diff : false;

              return (
                <div
                  key={index}
                  className={isActive ? "active" : null}
                  onClick={() => handleDatePeriodClick(btnPeriod.seconds)}
                >
                  {btnPeriod.label}
                </div>
              );
            })}
          </div>

          <RangeDateTimePicker
            period={archiveAlarmsStore.period}
            handleChangePeriod={handleChangePeriod}
            timeSelectable
          />

          <div className="deviation-analysis-button-container">
            <div
              className={`deviation-analysis-button ${isOpenWellTreePickerModal && "active"}`}
              onClick={() => setIsOpenWellTreePickerModal(true)}
            >
              {t("chooseWells")}
            </div>

            {isAlarmFetchAvailable ?
              <>
                <div
                  className={"deviation-analysis-button"}
                  onClick={() => fetchData()}
                >
                  {t("Сформировать")}
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "8px", width: "80px" }}>
                  <CustomTooltip
                    tooltipText={"Скачать отчет"}
                    placement="bottom"
                  >
                    <img
                      className="archive-alarm-xlsx-icon"
                      src={XlsxIcon}
                      onClick={() => archiveAlarmsStore.xlsxFetcher()}
                    />
                  </CustomTooltip>
                  {archiveAlarmsStore.xlsxFetcher.pending &&
                    <LoadingSpinner
                      size={16}
                    />
                  }
                </div>
              </>
              :
              <>
                <CustomTooltip
                  tooltipText="Необходимо выбрать хотя бы одну скважину и период формирования отчета"
                >
                  <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <div
                      className={"deviation-analysis-button disabled"}
                      onClick={() => fetchData()}
                    >
                      {t("Сформировать")}
                    </div>
                    <div style={{ width: "80px" }}>
                      <img
                        className="archive-alarm-xlsx-icon"
                        src={XlsxIcon}
                        style={{
                          opacity: 0.5,
                          cursor: "auto",
                        }}
                      />
                    </div>
                  </div>
                </CustomTooltip>
              </>
            }
          </div>

          <WellTreePickerModal
            pickedWellIds={archiveAlarmsStore.pickedWellIds}
            handleSubmit={(values) => handleChangePickedWellIds(values.pickedWellIds)}
            visible={isOpenWellTreePickerModal}
            close={() => setIsOpenWellTreePickerModal(false)}
          />
        </div>

        <div
          ref={ref}
          className={isAlarmsLoading && "ant-spin-blur"}
          style={{
            flex: "1",
            overflowX: "hidden",
          }}
        >
          <TableHeader
            columns={columns}
            columnWidths={columnWidths}
            searchValues={searchValues}
            setSearchValues={setSearchValues}
            activeFilterAlias={activeFilterAlias}
            setActiveFilterAlias={setActiveFilterAlias}
          />

          {isAlarmsLoading ?
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "170px",
                position: "relative",
                borderBottom: "1px solid #f0f0f0",
              }}
            >
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
              <Spin style={{ position: "absolute", top: 40 }} />
            </div>
            :
            filteredAlarms?.length > 0 ?
              <VariableSizeGrid
                ref={tableRef}
                className="archive-alarm-table"
                width={width != null ? width : 0}
                height={height != null ? height - 64 : 700}
                itemData={filteredAlarms}
                columnWidth={(index) => columnWidths[index]}
                rowHeight={(index) => 48}
                columnCount={columns.length}
                rowCount={filteredAlarms?.length}
              >
                {renderCell()}
              </VariableSizeGrid>
              :
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          }
        </div>

      </div >
      <AlarmDeviationAnalysisModal
        visible={selectedAlarm != null}
        closeModal={() => setSelectedAlarm(null)}
        data={selectedAlarm}
        isAlarmArchived
      />
    </>
  );
});

export default ArchiveAlarms;
