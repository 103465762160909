/*eslint-disable*/
import { Table } from "antd";
import "./styles.css";

const AnalysisTable = ({
  dataSource,
  loading,
  columns,
  summary,
  onRow,
  rowKey = "key"
}) => {
  return (
    <Table
      sticky
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      onRow={onRow}
      rowKey={rowKey}
      pagination={false}
      bordered={true}
      summary={summary}
    />
  );
};

export default AnalysisTable;
