/* eslint-disable */
import { useRef, useState } from "react";
import { Form } from "react-final-form";
import Draggable from "react-draggable";
import { Button } from "antd";
import Modal from "components/Modal";
import ParameterTable from "./components/ParameterTable";
import { useTranslation } from "react-i18next";

const EditParametersModal = ({
  visible,
  parameters,
  closeModal,
  onSave
}) => {

  const { t } = useTranslation();

  const [disabled, setDisabled] = useState(true);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y + 48,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const handleClose = (form) => {
    form.reset();
    closeModal();
  }

  const draggleRef = useRef(null);

  const handleSave = async (values) => {
    const activeParameters = values.parameters
      .filter(p => p.active);

    return await onSave(activeParameters);
  }

  return (
    <Form
      onSubmit={handleSave}
      initialValues={{
        parameters
      }}
      initialValuesEqual={(a, b) => {
        return JSON.stringify(a) === JSON.stringify(b);
      }}
      render={({
        handleSubmit,
        submitting,
        values,
        form,
        pristine,
        submitSucceeded,
        submitErrors
      }) => (
        <Modal
          style={{
            zIndex: 10000000000
          }}
          title={
            <div
              style={{
                width: '100%',
                cursor: 'grab',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
            >
              {t("editTableParameters")}
            </div>
          }
          destroyOnClose
          visible={visible}
          onCancel={() => handleClose(form)}
          className="trends-modal"
          width="auto"
          footer={
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
              {submitErrors &&
                <span style={{ color: "red" }}>{t("errorHasOccured")}</span>
              }
              <Button
                key="submit"
                type="primary"
                onClick={handleSubmit}
                loading={submitting}
                disabled={submitting}
                style={{
                  marginLeft: "auto",
                  backgroundColor:
                    submitSucceeded && pristine ? "lightseagreen" : "#40a9ff",
                }}
              >
                {submitSucceeded && pristine ? t("applied") : t("apply")}
              </Button>
            </div>
          }
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
        >
          <ParameterTable
            parameters={values.parameters}
          />
        </Modal>
      )}
    />
  );
}

export default EditParametersModal;
