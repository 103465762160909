/* eslint-disable */
import { useState } from 'react'
import { Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { useStore } from 'hooks/useStore'
import useRequest from 'hooks/useRequest'
import AlarmDeviationAnalysisModal from '../ActiveAlarms/components/AlarmDeviationAnalysisModal'
import configArchive from './tableArchive.config'
import './styles.css'

const ArchiveAlarms = observer(() => {

  const { wellStore } = useStore();

  const { data } = useRequest(
    wellStore.id ? `/alarm/${wellStore.id}/archiveAlarmsData` : null,
  );

  const [selectedRecord, setSelectedRecord] = useState(null);

  const isModalVisible = selectedRecord != null;

  return (
    <div className="alarm-content">
      <Table
        columns={configArchive(setSelectedRecord)}
        dataSource={data}
        pagination={false}
        className="alerts-table custom-borders"
        rowClassName="alerts-row"
        sticky
        rowKey={"id"}
      />

      <AlarmDeviationAnalysisModal
        visible={isModalVisible}
        closeModal={() => setSelectedRecord(null)}
        data={selectedRecord}
        handleCloseDeviationAnalysisAlarm={() => { }}
        isAlarmArchived={true}
      />
    </div>
  );
});

export default ArchiveAlarms
