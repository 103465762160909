/* eslint-disable */
import { useCallback } from "react";

const useZoom = (onZoom) => {
  const zoomPlugin = useCallback(() => {
    const handleSetSelect = (u) => {
      if (u.select.width <= 0) return;

      const min = u.posToVal(u.select.left, "x");
      const max = u.posToVal(u.select.left + u.select.width, "x");

      const actualZoom = { min, max };
      onZoom(actualZoom);
    };

    return {
      hooks: {
        setSelect: handleSetSelect,
      },
    };
  }, []);

  return zoomPlugin;
};
export default useZoom;
