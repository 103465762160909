/* eslint-disable */
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import Tooltip from "components/Tooltip";
import About from "layouts/common/components/About";
import ChangeLanguage from "../components/ChangeLanguage";
import Profile from "../components/Profile";
import "./styles.css";

const HeaderCommon = observer(() => {

  const navigate = useNavigate();

  return (
    <>
      <Profile />

      {/* <ChangeLanguage /> */}
      {/* <div className="info-container">
        <Link to="/admin">
          <SettingsWhiteIcon />
        </Link>
      </div> */}
      {/* <div className="info-container">
        <InfoIcon />
      </div> */}

      <div onClick={() => navigate("/start")} className="home-container">
        <Tooltip
          placement="left"
          zIndex={1000000000001}
          title="Начальный экран"
        >
          <HomeOutlined />
        </Tooltip>
      </div>

      <About />
    </>
  );
});

export default HeaderCommon;
