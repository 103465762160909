/* eslint-disable */
import React from "react";
import { useStore } from "hooks/useStore";

const CheckBoxes = ({ Checkbox, parametersData }) => {
  const result = [];
  const { accessStore } = useStore();
  for (let i = 0; i < parametersData?.length; i++) {
    if (accessStore.checkWellPermission(parametersData[i]?.id, "virtual_flow_meter") === 1 || accessStore.checkWellPermission(parametersData[i]?.id, "virtual_flow_meter") === 0) {
      result.push(
        <div
          key={parametersData[i]?.id}
          className="CheckboxesWrapper"
          style={{
            display: "flex",
            width: "100%",
            textAlign: "Left",
            justifyContent: "space-between",
            background: i % 2 === 0 ? "#F5F6FA" : "#FFFFFF",
            height: "33px",
          }}
        >
          <label style={{ display: "flex", width: "100%" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                padding: "0px 10px",
                boxSizing: "border-box",
                justifyContent: "space-between",
                fontWeight: "bold",
                height: "33px",
                alignItems: "center",
              }}
            >
              {parametersData[i]?.name}
              <Checkbox
                value={parametersData[i]?.id}
                disabled={(accessStore.checkWellPermission(parametersData[i]?.id, "virtual_flow_meter") === 0) ? true : false}
              />
            </div>
          </label>
        </div>
      );
    }
  }
  return result
};

export default CheckBoxes