import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { useResizeDetector } from "react-resize-detector";
import { useStore } from "hooks/useStore";
import Treemap from "components/Treemap";
import LoadingSpinner from "components/LoadingSpinner";
import "./styles.css";

const WellTreemapChart = observer(() => {

  const { wellTreemapStore } = useStore();

  const dataStore = wellTreemapStore.dataStore;
  const root = toJS(dataStore?.root);

  const { ref: containerRef, width: containerWidth, height: containerHeight } = useResizeDetector();

  useEffect(() => {
    dataStore.fetchData();
  }, [dataStore]);

  return (
    <div
      ref={containerRef}
      className="well-treemap-container"
    >
      {root == null &&
        <LoadingSpinner
          size={80}
        />
      }
      {root != null &&
        <Treemap
          data={root}
          minValueCoefficient={0.02}
          colors={dataStore?.colors}
          tooltipSettings={dataStore?.tooltipSettings}
          labelSettings={dataStore?.labelSettings}
          colorSettings={dataStore?.colorSettings}
          width={containerWidth}
          height={containerHeight}
        />
      }
    </div>
  );
});

export default WellTreemapChart;



