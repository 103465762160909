import { Input } from 'antd'
import './styles.css'

const { TextArea } = Input

const CustomTextarea = ({
  className,
  input,
  ...rest
}) => (
  <TextArea
    {...input}
    rows={4}
    className={`custom-textarea ${className || ''}`}
    {...rest}
  />
)

export default CustomTextarea
