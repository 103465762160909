/* eslint-disable */
import { useEffect, useState } from "react";
import { Table, Typography } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
  ReloadOutlined
} from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { useResizeDetector } from "react-resize-detector";
import { useStore } from "hooks/useStore";
import AddLocationModal from "./components/AddLocationModal";
import AddWellModal from "./components/AddWellModal";
import EditLocationModal from "./components/EditLocationModal";
import EditWellModal from "./components/EditWellModal";
import DeletionAlertModal from "../DeletionAlertModal";
import Tooltip from "components/Tooltip";
import { useTranslation } from "react-i18next";
import request from "utils/request";
import "./styles.css";

const LocationsSettings = observer(() => {

  const { t } = useTranslation();

  const { adminLocationsSettingsStore } = useStore();

  const [canAddRootLocation, setCanAddRootLocation] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await request.get("/admin/checkCanCreateRootLocation");
        setCanAddRootLocation(data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const tableDataSource = adminLocationsSettingsStore.tableDataSource;

  const tableColumns = [
    {
      title: () => (
        <div style={{ fontFamily: "Roboto Medium" }}>{t("locationName")}</div>
      ),
      dataIndex: "name",
      key: "name"
    },
    {
      title: canAddRootLocation && (
        <div
          style={{
            display: "flex",
            gap: "18px",
            justifyContent: "flex-end"
          }}
        >
          {/* <Tooltip title={t("addRootLocation")} placement="left">
            <PlusSquareOutlined
              onClick={() =>
                adminLocationsSettingsStore.openAddRootLocationModal()
              }
            />
          </Tooltip> */}

          <ReloadOutlined
            onClick={() =>
              adminLocationsSettingsStore.fetchLocationTrees()
            }
          />
        </div>
      ),
      key: "operations",
      width: "143px",
      render: (_nothing, record) => (
        <div className="admin-locations-settings-operations-column">
          {record.well ? (
            <>
              {/* <Typography.Link
                onClick={
                  () => adminLocationsSettingsStore.openEditWellModal(record)
                }
              >
                <EditOutlined />
              </Typography.Link>

              <Typography.Link
                onClick={
                  () => handleDeleteWell(record.id)
                }
              >
                <DeleteOutlined />
              </Typography.Link> */}
            </>
          ) : (
            <>
              {/* <Tooltip title={t("addLocation")} placement="left">
                <Typography.Link
                  onClick={
                    () => adminLocationsSettingsStore.openAddLocationModal(toJS(record.id))
                  }
                >
                  <PlusCircleOutlined />
                </Typography.Link>
              </Tooltip> */}

              {/* <Tooltip title={t("addWell")} placement="left">
                <Typography.Link
                  onClick={
                    () => adminLocationsSettingsStore.openAddWellModal(toJS(record.id))
                  }
                >
                  < PlusOutlined />
                </Typography.Link>
              </Tooltip> */}

              {/* <Typography.Link
                onClick={
                  () => adminLocationsSettingsStore.openEditLocationModal(record)
                }
              >
                <EditOutlined />
              </Typography.Link> */}

              {/* <Typography.Link
                onClick={
                  () => handleDeleteLocation(record.id)
                }
              >
                <DeleteOutlined />
              </Typography.Link> */}
            </>
          )}
        </div >
      )
    }
  ];

  const [deletionAlertData, setDeletionAlertData] = useState(null);

  const handleDeleteLocation = (id) => {
    const location = adminLocationsSettingsStore.findLocation(id);
    if (!location) return;

    const content = `Будет безвозвратно удалена локация ${location.name}, все ее подлокации и скважины также будут безвозвратно удалены`;

    setDeletionAlertData({
      title: t("areYouSureToDeleteLocation"),
      content: (<>{content}</>),
      onConfirm: () => adminLocationsSettingsStore.deleteLocation(id)
    });
  }

  const handleDeleteWell = (id) => {
    const well = adminLocationsSettingsStore.findLocation(id);
    if (!well) return;

    const content = `Будет безвозвратно удалена ${well.name}`;

    setDeletionAlertData({
      title: t("areYouSureToDeleteWell"),
      content: (<>{content}</>),
      onConfirm: () => adminLocationsSettingsStore.deleteWell(id)
    });
  }

  const clearDeletionAlertData = () => {
    setDeletionAlertData(null);
  }

  const { height: tableContainerHeight, ref: tableContainerRef } = useResizeDetector();

  const tableHeaderHeight = 55;

  return (
    <div className="admin-locations-settings">
      <AddLocationModal
        visible={adminLocationsSettingsStore.isAddRootLocationModalVisible}
        onSubmit={(event) => adminLocationsSettingsStore.addRootLocation(event)}
        onCancel={() => adminLocationsSettingsStore.closeAddRootLocationModal()}
        fields={adminLocationsSettingsStore.addLocationFields}
        parentId={null}
      />

      <AddLocationModal
        visible={adminLocationsSettingsStore.isAddLocationModalVisible}
        onSubmit={(event) => adminLocationsSettingsStore.addLocation(event)}
        onCancel={() => adminLocationsSettingsStore.closeAddLocationModal()}
        fields={adminLocationsSettingsStore.addLocationFields}
        parentId={adminLocationsSettingsStore.addingLocationParentId}
      />

      <AddWellModal
        visible={adminLocationsSettingsStore.isAddWellModalVisible}
        onSubmit={(event) => adminLocationsSettingsStore.addWell(event)}
        onCancel={() => adminLocationsSettingsStore.closeAddWellModal()}
        fields={adminLocationsSettingsStore.addWellFields}
        locationId={adminLocationsSettingsStore.addingWellLocationId}
      />

      <EditLocationModal
        visible={adminLocationsSettingsStore.isEditLocationModalVisible}
        onSubmit={(event) => adminLocationsSettingsStore.editLocation(event)}
        onCancel={() => adminLocationsSettingsStore.closeEditLocationModal()}
        location={adminLocationsSettingsStore.currentEditableLocation}
        fields={adminLocationsSettingsStore.addLocationFields}
      />

      <EditWellModal
        visible={adminLocationsSettingsStore.isEditWellModalVisible}
        onSubmit={(event) => adminLocationsSettingsStore.editWell(event)}
        onCancel={() => adminLocationsSettingsStore.closeEditWellModal()}
        well={adminLocationsSettingsStore.currentEditableWellAllData}
        loaded={adminLocationsSettingsStore.currentEditableWellAllDataLoaded}
        fields={adminLocationsSettingsStore.addWellFields}
      />

      <DeletionAlertModal
        visible={deletionAlertData !== null}
        title={deletionAlertData?.title}
        content={deletionAlertData?.content}
        onConfirm={deletionAlertData?.onConfirm}
        clear={clearDeletionAlertData}
        onCancel={clearDeletionAlertData}
      />

      <div ref={tableContainerRef} className="admin-locations-settings-table">
        <Table
          scroll={{ y: `${tableContainerHeight - tableHeaderHeight}px` }}
          bordered
          dataSource={toJS(tableDataSource)}
          columns={tableColumns}
          pagination={false}
          rowKey={"id"}
          expandable={{
            indentSize: 30,
            expandIcon: (props) =>
              props.record.children?.length > 0 ? (
                props.expanded ?
                  <a onClick={(event) => props.onExpand(props.record, event)}>
                    <MinusSquareOutlined style={{ fontSize: "16px", padding: "3px" }} />
                  </a>
                  :
                  <a onClick={(event) => props.onExpand(props.record, event)}>
                    <PlusSquareOutlined style={{ fontSize: "16px", padding: "3px" }} />
                  </a>
              ) : <div style={{ display: "inline-block", width: "22px" }}></div>
          }}
        />
      </div>
    </div>
  );
});

export default LocationsSettings;
