import { AuthProvider } from 'hooks/useAuth'
import { BrowserRouter as Router } from 'react-router-dom'
import { useEffect } from 'react'
import { StoreContext, store } from 'hooks/useStore'
import Routes from './routes'
import 'antd/dist/antd.min.css'
import './index.css'
import 'react-grid-layout/css/styles.css'
import 'moment/locale/ru'

const App = () => {
  useEffect(() => { // fix ResizeObserver - loop limit exceeded
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        )

        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      } else if (e.message === 'ResizeObserver loop completed with undelivered notifications.' || e.message === 'Script error.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        )

        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    })
  }, [])

  return (
    <StoreContext.Provider value={store}>
      <Router>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </Router>
    </StoreContext.Provider>
  )
}

export default App
