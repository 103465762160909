/* eslint-disable */

const defaultStyles = {
  common: {
    width: 300,
    border: {
      show: true,
      color: "#000000",
      thickness: 1,
      radius: 0
    }
  },
  header: {
    show: true,
    fontSize: 14,
    color: {
      background: "#ffffff",
      font: "#000000"
    }
  },
  parameters: {
    lineHeight: 1,
    fontSize: 14,
    evenColor: {
      background: "#ffffff",
      font: "#000000"
    },
    notEvenColor: {
      background: "#f5f6fa",
      font: "#000000"
    }
  }
}

export default defaultStyles;