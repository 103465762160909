import { Switch } from 'antd'
import './styles.css'

const CustomSwitch = ({
  input,
  label,
  ...rest
}) => (
  <div className="switch-container">
    <Switch checked={input.value} onChange={input.onChange} {...rest} />
    <div className="switch-label">
      {label}
    </div>
  </div>
)

export default CustomSwitch
