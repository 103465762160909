/*eslint-disable*/

class LocationTreeNode {
  constructor(id, name, well, parentId = null, children = [], roleId = null) {
    this.id = id;
    this.parentId = parentId;
    this.name = name;
    this.well = well;
    this.children = children;
    this.roleId = roleId;
  }

  get list() {
    const list = [this];
    const addChildren = (node, list) => {
      if (!node.children) return;
      for (const child of node.children) {
        list.push(child);
        addChildren(child, list);
      }
    }
    addChildren(this, list);
    return list;
  }

  insert(node) {
    const parent = this.find(node.parentId);
    if (!parent) return false;
    if (!parent.children) parent.children = [];
    parent.children.push(node);
    return true;
  }

  remove(id) {
    const node = this.find(id);
    if (!node) return;
    const parent = this.find(node.parentId);
    if (!parent || !parent.children) return;
    parent.children = parent.children.filter(c => c.id !== node.id);
  }

  find(id) {
    for (const node of this.list) {
      if (node.id === id) return node;
    }
    return null;
  }

  get isLeaf() {
    return this.children.length === 0;
  }

  get hasLocations() {
    return !this.isLeaf;
  }
}

export default LocationTreeNode;
