/* eslint-disable */

import { Select } from 'antd'
import { ReactComponent as ArrowDownIcon } from 'assets/images/selectArrowDown.svg'
import './styles.css'

const { Option } = Select

const CustomSelect = ({
  placeholder,
  options,
  input,
  ...rest
}) => {

  const isError =
    rest.meta.touched &&
    (rest.meta.error ||
      (rest.meta.submitError &&
        !rest.meta.dirtySinceLastSubmit &&
        rest.meta.submitFailed));

  const showSearch = rest.showsearch === "true";
  const allowClear = rest.allowclear === "true";
  const filterOption = (inputValue, option) =>
    option.children.toLowerCase().includes(inputValue.toLowerCase())
    ?? false;

  return (
    <>
      <Select
        {...input}
        {...rest}
        value={input.value
          ? typeof value === 'string'
            ? [input.value]
            : input.value
          : []
        }
        className="custom-select"
        placeholder={placeholder}
        suffixIcon={<ArrowDownIcon />}
        showSearch={showSearch}
        allowClear={allowClear}
        filterOption={filterOption}
      >
        {options.map(({ id, name }) => (
          <Option key={id} value={id}>{name}</Option>
        ))}
      </Select>
      {isError && (
        <div className="input-error">
          {rest.meta.error || rest.meta.submitError}
        </div>
      )}
    </>
  )
}

export default CustomSelect
