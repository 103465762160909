import { Checkbox } from 'antd'
import './styles.css'

const CustomCheckbox = ({
  input,
  label,
  ...rest
}) => (
  <Checkbox {...input} {...rest}>
    <span className="checkbox-label">{label}</span>
  </Checkbox>
)

export default CustomCheckbox
