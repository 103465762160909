/* eslint-disable */
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const LoadingSpinner = ({
  size = 48,
}) => (
  <div
    style={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spin style={{ height: "max-content" }} indicator={<LoadingOutlined style={{ fontSize: size }} spin />} />
  </div>
);

export default LoadingSpinner;
