/* eslint-disable */
import { List } from "antd";
// import { MinusCircleOutlined } from "@ant-design/icons";
// import Tooltip from "components/Tooltip";
import { useTranslation } from "react-i18next";
import "./styles.css";

const TableListItem = ({
  items
}) => {

  const { i18n } = useTranslation();
  const storageTranslations = JSON.parse(
    sessionStorage.getItem("translations")
  );
  const storageTranslationsUnits = JSON.parse(
    sessionStorage.getItem("translationsUnits")
  );
  const currentLanguage = i18n.language;
  
  const chooseTranslateFromArray = (id, array, lang) => {
    const translationIndex =
      array &&
      array[lang] &&
      array[lang].findIndex((x) => x.parameterId === id);
    const parameterIndex =
      array &&
      array?.parameters &&
      array?.parameters.findIndex((x) => x.id === id);

    if (translationIndex >= 0) {
      return array[lang][translationIndex].translation
        ? array[lang][translationIndex].translation
        : array?.parameters[parameterIndex].alias;
    }

    return parameterIndex >= 0 ? array?.parameters[parameterIndex].alias : id;
  };

  const chooseTranslateFromArrayUnits = (alias, array, lang) => {
    if (alias == null) return "";
    if (array == null) return "";
    const id = array.units.findIndex((x) => x.id === alias);
    const translationIndex = array[lang].findIndex((x) => x.unitId === id + 1);

    return translationIndex >= 0
      ? array[lang][translationIndex].translation
        ? array[lang][translationIndex].translation
        : array.units[id].alias
      : array.units[id].alias;
  };

  return (
    <List
      dataSource={items}
      renderItem={(item) => {
        let itemName = chooseTranslateFromArray(
          item.id,
          storageTranslations,
          currentLanguage
        );
        itemName += ", ";
        itemName += chooseTranslateFromArrayUnits(
          item.unit,
          storageTranslationsUnits,
          currentLanguage
        );

        return (
          <div
            key={item.id}
            className="mnemoscheme-table-list-item"
          >
            <span>{itemName}</span>
  
            {/* <Tooltip placement="top" tooltipText={"Удалить параметр"}>
              <MinusCircleOutlined onClick={() => { }} />
            </Tooltip> */}
          </div>
        );
      }}
    />
  );
}

export default TableListItem;
