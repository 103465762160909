/* eslint-disable */
import defaultStyles from "./defaultStyles"

export { defaultStyles }

export const toPlainStyles = (objectStyles) => {
  return {
    width: objectStyles.common.width,
    borderShow: objectStyles.common.border.show,
    borderColor: objectStyles.common.border.color,
    borderThickness: objectStyles.common.border.thickness,
    borderRadius: objectStyles.common.border.radius,
    headerShow: objectStyles.header.show,
    headerFontSize: objectStyles.header.fontSize,
    headerBackgroundColor: objectStyles.header.color.background,
    headerFontColor: objectStyles.header.color.font,
    parametersLineHeight: objectStyles.parameters.lineHeight,
    parametersFontSize: objectStyles.parameters.fontSize,
    parametersEvenBackgroundColor: objectStyles.parameters.evenColor.background,
    parametersEvenFontColor: objectStyles.parameters.evenColor.font,
    parametersNotEvenBackgroundColor: objectStyles.parameters.notEvenColor.background,
    parametersNotEvenFontColor: objectStyles.parameters.notEvenColor.font
  }
}

export const toObjectStyles = (plainStyles) => {
  return {
    common: {
      width: plainStyles.width,
      border: {
        show: plainStyles.borderShow,
        color: plainStyles.borderColor,
        thickness: plainStyles.borderThickness,
        radius: plainStyles.borderRadius
      }
    },
    header: {
      show: plainStyles.headerShow,
      fontSize: plainStyles.headerFontSize,
      color: {
        background: plainStyles.headerBackgroundColor,
        font: plainStyles.headerFontColor
      }
    },
    parameters: {
      lineHeight: plainStyles.parametersLineHeight,
      fontSize: plainStyles.parametersFontSize,
      evenColor: {
        background: plainStyles.parametersEvenBackgroundColor,
        font: plainStyles.parametersEvenFontColor
      },
      notEvenColor: {
        background: plainStyles.parametersNotEvenBackgroundColor,
        font: plainStyles.parametersNotEvenFontColor
      }
    }
  }
}