/* eslint-disable */
import { ReactComponent as AlertIcon } from "assets/images/exclamationMarkIcon.svg";
import CustomTooltip from "components/Tooltip";

export const minimizedColumns = () => [
  {
    title: "",
    dataIndex: "name",
    key: "name",
    width: "25px",
    render: (text, record) => {
      return (
        <div className="minimized-row-container">
          <div className="selected-flag" />
          {record.isSelected ? (
            <div className="minimized-container">
              <div className="minimized-name-container">
                <div className="well-name-container minimized">
                  {record.wellReason !== null && <AlertIcon />}
                  <span className="well-text">{text}</span>
                  <div style={{ display: "flex" }}>
                    <CustomTooltip placement="topLeft" arrowPointAtCenter title="ML модель ВР">
                      <div className={record?.vrMlModelStatus ? "blue-circle" : ""} />
                    </CustomTooltip>
                    <CustomTooltip placement="topLeft" arrowPointAtCenter title="Физ. модель ВР">
                      <div className={record?.vrPhysModelStatus ? "brown-circle" : ""} />
                    </CustomTooltip>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="minimized-container">
              <div className="minimized-name-container">
                <div className="well-name-container minimized">
                  {record.wellReason !== null && <AlertIcon />}
                  <span className="well-text">{text}</span>
                  <div style={{ display: "flex" }}>
                    <CustomTooltip placement="topLeft" arrowPointAtCenter title="ML модель ВР">
                      <div className={record?.vrMlModelStatus ? "blue-circle" : ""} />
                    </CustomTooltip>
                    <CustomTooltip placement="topLeft" arrowPointAtCenter title="Физ. модель ВР">
                      <div className={record?.vrPhysModelStatus ? "brown-circle" : ""} />
                    </CustomTooltip>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    },
  },
];
