/* eslint-disable */
import WellElement from "../WellElement";
import { Switch, InputNumber, Collapse } from "antd";

const { Panel } = Collapse;

const ParameterElement = ({
  parameterId,
  wellsList,
  parameterStatus,
  parameterMaxPoints,
  wellsStatusData,
  changeWellStatus,
  changeWellPoints,
  changeParameterStatus,
  changeParameterPoints,
}) => {

  const handleChangeParameterStatus = () => {
    changeParameterStatus(parameterId);
  }

  const handleChangeParameterMaxPoints = (value) => {
    changeParameterPoints(parameterId, value);
  }

  return (
    <div style={{ width: "500px" }}>
      <Collapse accordion ghost>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "500px",
            padding: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "Left",
              padding: "10px",
            }}
          >
            <span>Общее Вкл/Выкл прореживание</span>
            <Switch
              onClick={handleChangeParameterStatus}
              checked={parameterStatus}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "Left",
              padding: "10px",
            }}
          >
            <span>Максимальное количество точек на интервал</span>
            <InputNumber
              value={parameterMaxPoints}
              onChange={handleChangeParameterMaxPoints}
              min={3}
              disabled={!parameterStatus}
            />
          </div>
        </div>
        {wellsList &&
          wellsList.map((value, index) => (
            <Panel header={`${value?.name}`} key={`${value?.id}`}>
              <WellElement
                parameterId={parameterId}
                wellId={value?.id}
                wellStatus={wellsStatusData[index].status}
                wellMaxPoints={wellsStatusData[index].maxPoints}
                changeWellStatus={changeWellStatus}
                changeWellPoints={changeWellPoints}
              />
            </Panel>
          ))}
      </Collapse>
    </div>
  );
};

export default ParameterElement;
