/* eslint-disable */
import "./styles.css";

const VerticalResizeHandle = () => (
  <span
    className="custom-resize-handle"
  />
);

export default VerticalResizeHandle;
