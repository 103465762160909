import { Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { useStore } from 'hooks/useStore'
import useRequest from 'hooks/useRequest'
import config from './table.config'
import './styles.css'

const StartStopHistory = observer(() => {
  const { wellStore } = useStore()
  const { data } = useRequest(
    wellStore.id ? `/alarm/startstophistory/get/${wellStore.id}` : null,
  )

  const calculateDuration = item => {
    let duration

    if (item?.finishTime && item?.startTime) {
      duration = Date.parse(item.finishTime) - Date.parse(item.startTime)
      duration = {
        h: Math.floor(duration / (3600 * 1000)), // Целое количество часов
        m: Math.floor((duration / (60 * 1000)) % 60), // Целое количество минут
      }
    } else duration = ''

    return duration
  }

  return (
    <div style={{height: "100%", overflowY: "scroll"}}>
      <Table
        columns={config()}
        dataSource={data?.map(item => ({ ...item, key: item.id, duration: calculateDuration(item) }))}
        pagination={false}
        className="alerts-table hide-scrollbar custom-borders"
        rowClassName="alerts-row"
        sticky
      />
    </div>
  )
})

export default StartStopHistory
