/* eslint-disable */
import { useMemo } from "react";
import ParameterElement from "./components/ParameterElement";
import { Collapse } from "antd";

const { Panel } = Collapse;

const FilterMenu = ({
  checkedWellsIds,
  checkedParametersIds,
  wellsData,
  parametersData,
  reportReduceData,
  onReportReduceDataChange,
}) => {

  const getWellName = (id) => {
    const wellIndex = wellsData.findIndex((well) => well.id == id);
    if (wellIndex >= 0) return wellsData[wellIndex].name;
  };

  const wells = useMemo(
    () => checkedWellsIds.map((value, index) => {
      return {
        id: value,
        name: getWellName(value),
      };
    }), [checkedWellsIds]);

  const handleReportReduceDataChange = (newReportReduceDataChange) => {
    onReportReduceDataChange(newReportReduceDataChange);
  };

  const getParameterName = (id) => {
    const parameterIndex = parametersData.findIndex(
      (parameter) => parameter.id == id
    );
    if (parameterIndex >= 0) return parametersData[parameterIndex].name;
  };

  const findWellIndex = (wellId, parameterId) => {
    const parameterIndex = findParameterIndex(parameterId);
    if (parameterIndex != null) {
      const wellIndex = reportReduceData[parameterIndex].wellsData.findIndex(
        (data) => data.wellId == wellId
      );

      return wellIndex >= 0 ? wellIndex : null;
    }

    return null;
  };

  const findParameterIndex = (parameterId) => {
    if (!reportReduceData) return null;
    const parameterIndex = reportReduceData.findIndex(
      (data) => data.parameterId == parameterId
    );

    return parameterIndex >= 0 ? parameterIndex : null;
  };

  const changeWellStatus = (wellId, parameterId) => {
    const parameterIndex = findParameterIndex(parameterId);
    const wellIndex = findWellIndex(wellId, parameterId);

    if (wellIndex != null && parameterIndex != null) {
      let localReportData = [...reportReduceData];

      localReportData[parameterIndex].wellsData[wellIndex].status =
        !localReportData[parameterIndex].wellsData[wellIndex].status;

      handleReportReduceDataChange(localReportData);
    }
  };

  const changeParameterStatus = (parameterId) => {
    const parameterIndex = findParameterIndex(parameterId);

    if (parameterIndex != null) {
      let localReportData = [...reportReduceData];

      localReportData[parameterIndex].status =
        !localReportData[parameterIndex].status;

      if (localReportData[parameterIndex].status) {
        localReportData[parameterIndex].wellsData.forEach((element) => {
          element.status = false;
        });
      }

      handleReportReduceDataChange(localReportData);
    }
  };

  const changeWellPoints = (wellId, parameterId, pointsCount) => {
    const parameterIndex = findParameterIndex(parameterId);
    const wellIndex = findWellIndex(wellId, parameterId);

    if (wellIndex != null && parameterIndex != null) {
      let localReportData = [...reportReduceData];

      localReportData[parameterIndex].wellsData[wellIndex].maxPoints =
        pointsCount;

      handleReportReduceDataChange(localReportData);
    }
  };

  const changeParameterPoints = (parameterId, pointsCount) => {
    const parameterIndex = findParameterIndex(parameterId);

    if (parameterIndex != null) {
      let localReportData = [...reportReduceData];

      localReportData[parameterIndex].maxPoints = pointsCount;

      handleReportReduceDataChange(localReportData);
    }
  };

  return (
    <Collapse accordion ghost>
      {reportReduceData &&
        checkedWellsIds.length > 0 &&
        checkedParametersIds.length > 0 &&
        checkedParametersIds.map((value, index) => (
          <Panel
            header={`${getParameterName(value)}`}
            key={index}
            style={{ textAlign: "left" }}
          >
            <ParameterElement
              parameterId={value}
              wellsList={wells}
              parameterStatus={reportReduceData[index]?.status}
              parameterMaxPoints={reportReduceData[index]?.maxPoints}
              wellsStatusData={reportReduceData[index]?.wellsData}
              changeWellStatus={changeWellStatus}
              changeWellPoints={changeWellPoints}
              changeParameterStatus={changeParameterStatus}
              changeParameterPoints={changeParameterPoints}
            />
          </Panel>
        ))}
    </Collapse>
  );
};

export default FilterMenu;
