/* eslint-disable */
import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import request from "utils/request";
import { useStore } from "hooks/useStore";
import { ReactComponent as SettingsWhiteIcon } from "assets/images/settingsWhiteIcon.svg";
import { ReactComponent as FullScreenOn } from "assets/images/fullScreenOnIcon.svg";
import { ReactComponent as FullScreenOff } from "assets/images/fullScreenOffIcon.svg";
import { ReactComponent as LeftArrowIcon } from "assets/images/greyLeftArrowIcon.svg";
import { ReactComponent as RightArrowIcon } from "assets/images/greyRightArrowIcon.svg";
import ComponentHeader from "components/ComponentHeader";
import { useTranslation } from "react-i18next";
import TrendsInfo from "./components/TrendsInfo";
import TrendsSettingModal from "./components/TrendsSettingModal";
import ConfigureChart from "./components/Chart";
import RealtimeConnection from "../../realtimeSocket";
import { Menu, Dropdown } from "antd";
import { SnippetsOutlined, PushpinOutlined } from "@ant-design/icons";
import "./styles.css";
import { useResizeDetector } from "react-resize-detector";
import Tooltip from "components/Tooltip";
import TargetIcon from "components/StyledIcons/TargetIcon";
import TrendsHeader from "./components/TrendsHeader";
import { toJS } from "mobx";

const REALTIME_SOCKET_PATH = `/ws/realtimeData`;
const userId = 1;

const getPreviouslyChosenTemplate = () => {
  return localStorage.getItem("CHOSEN_TRENDS_TEMPLATE") || null;
};

const storeChosenTemplate = (templateId) => {
  if (!templateId) return;
  localStorage.setItem("CHOSEN_TRENDS_TEMPLATE", templateId);
};

const fetchAvailableParams = async (f) => {
  const { data } = await request.get("/settings/trends/get/paramsList");
  f(data);
};

const fetchTemplatesList = async (f) => {
  const { data } = await request.get(
    `/settings/trends/get/templatesList`
  );
  f(data);
};

const fetchTemplateData = async (templateId, f) => {
  const { data } = await request.get(
    `/settings/trends/get/template/${templateId}`
  );
  f(data);
};

const realtimeSocket = new RealtimeConnection(REALTIME_SOCKET_PATH, userId);

const Trends = observer(
  ({ minimizedMode, onFullScreenClick, fullScreenMode, translations }) => {
    const {
      wellStore,
      homeStore,
      userStore: { accessLevel },
    } = useStore();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [expandScreenMode, setExpandScreenMode] = useState(false);
    const { width, height, ref } = useResizeDetector();

    const [templatesList, setTemplatesList] = useState([]);
    const [currentTemplateData, setCurrentTemplateData] = useState(null);
    const [currentTemplateId, setCurrentTemplateId] = useState(null);
    const [availableParamsList, setAvailableParams] = useState(null);
    const showSettingsButton = true; // accessLevel === 'ADMIN' || accessLevel === 'TECHNICAL'
    const [tableItems, setTableItems] = useState(null);
    const [showOnlyTargetScaleTooltip, setShowOnlyTargetScaleTooltip] =
      useState(false);

    const [hoveredTrendsInfoParameter, setHoveredTrendsInfoParameter] =
      useState(null);

    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    //probably will not render all 85 in default mode, use allParrameters insted of currentTemlateData.trends
    const displayedParameters = useMemo(
      () =>
        availableParamsList &&
        currentTemplateData?.trends?.map((t) => {
          const info = availableParamsList?.find((p) => p.id == t.parameterId);
          if (!info) return null;

          return {
            id: t.parameterId,
            unitId: info.unit,
            active: true, // t.active,
            alias: info.alias,
          };
        }),
      [currentTemplateData, availableParamsList]
    );

    const activeParameters = useMemo(
      // () => displayedParameters?.filter((item) => item?.active),
      () => displayedParameters,
      [displayedParameters]
    );

    const { t } = useTranslation();

    useEffect(() => {
      if (!activeParameters || !wellStore?.id || !realtimeSocket) return;

      if (wellStore.id == realtimeSocket.wellId) return;

      realtimeSocket
        .start({
          wellId: wellStore.id,
        })
        .then(() => {
          realtimeSocket.listen(activeParameters.map((p) => p.alias));
        });

      return realtimeSocket.end;
    }, [wellStore?.id, activeParameters, realtimeSocket]);

    // load list of all possible parameters
    useEffect(() => {
      fetchAvailableParams((data) => setAvailableParams(data));
    }, []);

    useEffect(() => {
      if (!availableParamsList) return;

      let tempTableItems = availableParamsList.map((p) => {
        const relatedItem = currentTemplateData?.trends?.find(
          (item) => item.parameterId == p.id
        );
        return Object.assign({}, p, {
          scaleNumber: relatedItem?.scaleNumber || 1,
          active: relatedItem?.active || false,
          isMeander: relatedItem?.isMeander || false,
        });
      });

      setTableItems(
        availableParamsList.map((p) => {
          const relatedItem = currentTemplateData?.trends?.find(
            (item) => item.parameterId == p.id
          );
          return Object.assign({}, p, {
            scaleNumber: relatedItem?.scaleNumber || 1,
            active: relatedItem ? true : false, // relatedItem?.active || false,
            isMeander: relatedItem?.isMeander || false,
          });
        })
      );
    }, [currentTemplateData, currentTemplateId, isModalVisible]);

    // load list of all available templates
    useEffect(() => {
      fetchTemplatesList((templates) => setTemplatesList(templates));
    }, []);

    // load previously chosen template id from local storage and store it after component mounted
    useEffect(() => {
      const prevTemplateId = getPreviouslyChosenTemplate(); // || deafult_id !!!!!!
      if (!prevTemplateId) return;
      setCurrentTemplateId(parseInt(prevTemplateId, 10)); // Иначе
    }, []);

    // every time user chooses another template store this template's id in local storage
    useEffect(() => {
      storeChosenTemplate(currentTemplateId);
    }, [currentTemplateId]);

    // every time user chooses another template load its data
    useEffect(() => {
      if (!currentTemplateId) return setCurrentTemplateData(null);
      fetchTemplateData(currentTemplateId, (data) => {
        setCurrentTemplateData(data);
      });
    }, [currentTemplateId]);

    useEffect(() => {
      if (fullScreenMode || isModalVisible) {
        homeStore.setDraggableMode(false);

        return;
      }

      homeStore.setDraggableMode(true);
    }, [fullScreenMode, isModalVisible]);

    const dropdownElements = templatesList
      .sort((elem1, elem2) => {
        if (elem1.name < elem2.name || elem1.isDefault) return -1;
        else return 1;
      })
      .map((template) => (
        <Menu.Item
          onClick={() => setCurrentTemplateId(template.id)}
          key={template.id}
          icon={template.isDefault ? <PushpinOutlined /> : <SnippetsOutlined />}
        >
          {template.isDefault ? template.name : template.name}
        </Menu.Item>
      ));

    const menu = <Menu>{dropdownElements}</Menu>;

    const handleHoverTrendsInfoParameter = (parameterId) => {
      if (parameterId !== null) {
        if (hoveredTrendsInfoParameter?.id !== parameterId) {
          setHoveredTrendsInfoParameter({ id: parameterId });
        }
      } else {
        setHoveredTrendsInfoParameter(null);
      }
    };

    const handleToggleShowOnlyTargetScaleValue = (event) => {
      setShowOnlyTargetScaleTooltip((prev) => !prev);
    };

    const wellBreadcrumbs = toJS(wellStore.wellBreadcrumbs);

    return (
      <>
        <TrendsHeader
          breadcrumbs={wellBreadcrumbs}
        >
          <Tooltip
            placement="top"
            zIndex={1000000000001}
            title="Выбор шаблона представления данных"
            overlayStyle={{ maxWidth: "500px" }}
          >
            <Dropdown.Button
              overlay={menu}
              trigger={["click"]}
            >
              {currentTemplateData?.isDefault
                ? currentTemplateData?.name || t("noTemplateChosen")
                : currentTemplateData?.name || t("noTemplateChosen")}
            </Dropdown.Button>
          </Tooltip>

          <Tooltip
            placement="topRight"
            zIndex={1000000000001}
            title="Отображать значения на одной шкале"
            overlayStyle={{ maxWidth: "500px" }}
          >
            <div
              style={{
                height: "28.5px",
                marginLeft: "16px",
                marginRight: "16px",
                marginBottom: "10px",
              }}
            >
              <TargetIcon
                onClick={handleToggleShowOnlyTargetScaleValue}
                isSelected={showOnlyTargetScaleTooltip}
              />
            </div>
          </Tooltip>

          <Tooltip
            placement="topRight"
            zIndex={1000000000001}
            title="Полноэкранный режим"
          >
            <div style={{ marginRight: "16px" }}>
              {fullScreenMode ? (
                <FullScreenOff onClick={onFullScreenClick} />
              ) : (
                <FullScreenOn onClick={onFullScreenClick} />
              )}
            </div>
          </Tooltip>

          {showSettingsButton && (
            <Tooltip
              placement="topRight"
              zIndex={1000000000001}
              title="Настройки трендов"
            >
              <div>
                <SettingsWhiteIcon onClick={() => setIsModalVisible(true)} />
              </div>
            </Tooltip>
          )}
        </TrendsHeader>
        <div className="trends-container" ref={ref}>
          <TrendsInfo
            expandScreenMode={expandScreenMode}
            paramsList={(() => {
              if (!currentTemplateData) return [];
              // displayedParameters?.sort((a, b) => {
              //   if (a?.active) {
              //     return -1;
              //   } else {
              //     return 1;
              //   }
              // });
              return currentTemplateData.isDefault
                ? displayedParameters
                : activeParameters;
            })()}
            realtimeSocket={realtimeSocket}
            activeParams={activeParameters}
            onParameterHover={handleHoverTrendsInfoParameter}
            translations={translations}
          />
          <div className="chart-wrapper" style={{ width: width ? width - 300 : 0 }}>
            <div
              className="open-bar"
              onClick={() => setExpandScreenMode(!expandScreenMode)}
            >
              {expandScreenMode ? <RightArrowIcon /> : <LeftArrowIcon />}
            </div>
            <div className="chart-content">
              <ConfigureChart
                minimizedMode={minimizedMode}
                expandScreenMode={expandScreenMode}
                fullScreenMode={fullScreenMode}
                realtimeSocket={realtimeSocket}
                paramsList={activeParameters}
                templateId={currentTemplateId}
                templateData={currentTemplateData}
                height={height}
                hoveredTrendsInfoParameter={hoveredTrendsInfoParameter}
                showOnlyTargetScaleTooltip={showOnlyTargetScaleTooltip}
                translations={translations}
              />
            </div>
          </div>
        </div>
        <TrendsSettingModal
          visible={isModalVisible}
          closeModal={() => setIsModalVisible(false)}
          templatesList={templatesList}
          currentTemplateData={currentTemplateData}
          currentTemplateId={currentTemplateId}
          setCurrentTemplateId={setCurrentTemplateId}
          setTemplatesList={setTemplatesList}
          setCurrentTemplateData={setCurrentTemplateData}
          // tableItems={availableParamsList?.map((p) => {
          //   const relatedItem = currentTemplateData?.trends?.find(
          //     (item) => item.parameterId == p.id
          //   );
          //   return Object.assign({}, p, {
          //     scaleNumber: relatedItem?.scaleNumber || 1,
          //     active: relatedItem?.active || false,
          //   });
          // })}
          tableItems={tableItems}
        />
      </>
    );
  }
);

export default Trends;
