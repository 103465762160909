/* eslint-disable */
import { makeAutoObservable } from "mobx";
import WellDeviationTreemapStore from "./DeviationTreemap/store";
import WellOilTreemapStore from "./OilTreemap/store";

export const WellTreemapType = {
  oil: 1,
  deviation: 2,
}

class WellTreemapStore {
  constructor() {
    makeAutoObservable(this);
  }

  type = WellTreemapType.oil;
  dataStore = new WellOilTreemapStore();

  setType(type) {
    this.type = type;

    switch (this.type) {
      case WellTreemapType.oil: {
        this.dataStore = new WellOilTreemapStore();
        break;
      }
      case WellTreemapType.deviation: {
        this.dataStore = new WellDeviationTreemapStore();
        break;
      }
      default: {
        this.dataStore = null;
      }
    }
  }
}

export default WellTreemapStore;
