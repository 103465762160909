/* eslint-disable */
import { useCallback, useMemo } from "react";
import { VariableSizeGrid } from "react-window";
import useTimeConverter from "hooks/useTimeConverter";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const dateFormat = "DD.MM.YYYY HH:mm:ss";

const ParameterTable = ({
  title,
  parameters,
  typeByAlias,
  trendValueByAlias = {},
  showRowIfValueNull = true,
}) => {

  const { t } = useTranslation();

  const fromUtcToLocale = useTimeConverter({
    toOffset: 0,
    fromOffsetDefault: new Date().getTimezoneOffset() / 60,
  });

  const notNullValueParameters = useMemo(
    () => parameters.filter(parameter => parameter.value != null)
    , [parameters]);

  const columns = useMemo(() =>
    [
      {
        width: 500,
        name: null,
      },
      {
        width: 120,
        name: null,
      },
      {
        width: 140,
        name: null,
      }
    ], []);

  const renderCell = useCallback(() =>
    ({ columnIndex, rowIndex, style, data }) => {
      const item = data[rowIndex];

      const colorByTrendValue = {
        0: "black",
        1: "red",
        2: "red",
      }

      const trendValue = trendValueByAlias[item.alias];
      const valueColor = colorByTrendValue[trendValue] ?? "black";

      const valuePrefix = (item.alias === "permissible_deviation_liquid_deb" ||
        item.alias === "permissible_deviation_dynamic_level") ? "±" : "";

      let thirdColumnValue = null;
      switch (typeByAlias[item.alias]) {
        case "timestamp": {
          thirdColumnValue = item.timestamp != null &&
            dayjs(fromUtcToLocale(item.timestamp)).format(dateFormat);
          break;
        }
        case "const": {
          thirdColumnValue = "константа";
          break;
        }
        case "calculated": {
          thirdColumnValue = "расчетное значение";
          break;
        }
      }

      switch (columnIndex) {
        case 0:
          return (
            <div
              style={{
                ...style,
                whiteSpace: "nowrap",
                background: rowIndex % 2 === 0 ? "#F5F6FA" : "#FFFFFF",
                color: item?.value ? valueColor : "black",
              }}
            >
              {t(item.alias)}
              {/* {item.alias} */}
            </div>
          );
        case 1:
          return (
            <div
              style={{
                ...style,
                background: rowIndex % 2 === 0 ? "#F5F6FA" : "#FFFFFF",
                color: item?.value ? valueColor : "black",
              }}
            >
              {item.value != null ? `${valuePrefix}${item.value.toFixed(2)}` : "Нет данных"}
            </div>
          );
        case 2:
          return (
            <div
              style={{
                ...style,
                background: rowIndex % 2 === 0 ? "#F5F6FA" : "#FFFFFF",
                textAlign: "center",
                color: item?.value ? valueColor : "black",
              }}
            >
              {thirdColumnValue}
            </div>
          );
      }
    }, [t, typeByAlias, trendValueByAlias]);

  const dataSource = showRowIfValueNull ?
    parameters : notNullValueParameters;

  return (
    <div>
      {title &&
        <ul
          style={{
            display: "flex",
            listStyle: "none",
            width: "100%",
            margin: "0px 0px 8px 0px",
            padding: "0px",
          }}
        >
          <span className="title">{title}</span>
        </ul>
      }

      <VariableSizeGrid
        className="data-text"
        columnCount={columns.length}
        columnWidth={(index) => columns[index].width}
        height={dataSource?.length * 33 ?? 0}
        rowCount={dataSource?.length ?? 0}
        rowHeight={(index) => 33}
        width={columns.reduce((sum, column) => sum += column.width, 0)}
        style={{ overflowX: "hidden" }}
        itemData={dataSource}
      >
        {renderCell()}
      </VariableSizeGrid>
    </div>
  );
}

export default ParameterTable;
