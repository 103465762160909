/* eslint-disable */
import ColorPicker from "components/ColorPicker";

const CustomColorPicker = ({
  input,
  ...rest
}) => (
  <div>
    <ColorPicker
      color={input.value}
      onChange={input.onChange}
      {...rest}
    />
  </div>
)

export default CustomColorPicker
