/* eslint-disable */
import { useStore } from "hooks/useStore";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { ReactComponent as OptionsIcon } from "assets/images/optionsIcon.svg";
import "./styles.css";

const configArchive = (
  selectRecord,
) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const storageTranslationsWorks = JSON.parse(
    sessionStorage.getItem("translationsWorks")
  );
  const storageTranslationsActions = JSON.parse(
    sessionStorage.getItem("translationsActions")
  );

  const { accessStore } = useStore();

  const findTranslation = (alias) => {
    let index = storageTranslationsActions?.actions.findIndex(
      (x) => x.alias === alias
    );

    if (index > -1)
      return storageTranslationsActions?.[currentLanguage][index].translation;
    index = storageTranslationsWorks?.works.findIndex((x) => x.alias === alias);
    if (index > -1)
      return storageTranslationsWorks?.[currentLanguage][index].translation;

    return "-";
  };

  return [
    {
      title: () => (
        <div className="column-title">{t("dateAndTimeOfCreation")}</div>
      ),
      dataIndex: "alarmCreatedAt",
      key: "alarmCreatedAt",
      width: "180px",
      // render: text => (
      //   text !== null
      //     ? <div>{text && text.replace('T', ' ').split('.')[0]}</div>
      //     : <div> - </div>),
      render: (text) => {
        if (text == null) return <div> - </div>;

        // QURNA FIX
        // return <div>{text.replace("T", " ").split(".")[0]}</div>;

        // UTC TIME FIX
        const offsetHours = (new Date().getTimezoneOffset() * -1) / 60;

        let date = new Date(text);

        date.setHours(date.getHours() + offsetHours);
        date = dayjs(date).format("YYYY-MM-DDTHH:mm:ss");

        return <div>{date.replace("T", " ").split(".")[0]}</div>;
      },
    },
    {
      title: <div className="column-title">{t("warningType")}</div>,
      dataIndex: "type",
      key: "type",
      width: "170px",
      // render: (text) => (text !== null ? <div>{text}</div> : <div> - </div>),
      render: (text) => {
        let warningTranslation = "";
        switch (text) {
          case "CUSTOM": {
            warningTranslation = "Пользовательский";
            break;
          }
          case "TREND": {
            warningTranslation = "Трендовый";
            break;
          }
          case "INSTANTANEOUS": {
            warningTranslation = "Анализ отклонений";
            break;
          }
          case "POTENTIAL": {
            warningTranslation = "Потенциальный";
            break;
          }
          default: {
            warningTranslation = text;
          }
        }
        return <div>{warningTranslation}</div>;
      },
    },
    {
      title: <div className="column-title">{t("warningName")}</div>,
      dataIndex: "name",
      key: "name",
      render: (text) => (text !== null ? <div>{text}</div> : <div> - </div>),
    },
    // {
    //   title: () => <div className="column-title">{t("plan")}</div>,
    //   width: "35%",
    //   children: [
    //     {
    //       title: () => (
    //         <div className="column-title">{t("dateAndTimeOfCreation")}</div>
    //       ),
    //       dataIndex: "createdAt",
    //       key: "createdAt",
    //       width: "13.5%", // '150px'
    //       // render: text => (
    //       //   text !== null
    //       //     ? <div>{text.replace('T', ' ').split('.')[0]}</div>
    //       //     : <div> - </div>),
    //       render: (text) => {
    //         if (text == null) return <div> - </div>;

    //         // QURNA FIX
    //         // return <div>{text.replace("T", " ").split(".")[0]}</div>;

    //         // UTC TIME FIX
    //         const offsetHours = (new Date().getTimezoneOffset() * -1) / 60;

    //         let date = new Date(text);

    //         date.setHours(date.getHours() + offsetHours);
    //         date = dayjs(date).format("YYYY-MM-DDTHH:mm:ss");

    //         return <div>{date.replace("T", " ").split(".")[0]}</div>;
    //       },
    //     },
    //     {
    //       title: <div className="column-title">{t("action")}</div>,
    //       dataIndex: "actionId",
    //       key: "actionId",
    //       width: "10%", // '120px'
    //       render: (text) =>
    //         text !== null ? <div>{findTranslation(text)}</div> : <div> - </div>,
    //     },
    //     {
    //       title: <div className="column-title">{t("user")}</div>,
    //       dataIndex: "userIdAction",
    //       key: "userIdAction",
    //       width: "10%", // '150px'
    //       render: (text) =>
    //         text !== null ? <div>{text}</div> : <div> - </div>,
    //     },
    //     {
    //       title: () => <div className="column-title">{t("comment")}</div>,
    //       dataIndex: "commentAction",
    //       key: "commentAction",
    //       width: "15%", // '200px'
    //       render: (text) =>
    //         text !== null ? <div>{text}</div> : <div> - </div>,
    //     },
    //   ],
    // },
    {
      title: () => <div className="column-title">{t("dateAndTimeOfAction")}</div>,
      dataIndex: "closedAt",
      key: "closedAt",
      width: "180px", // '150px'
      // render: text => (
      //   text !== null
      //     ? <div>{text.replace('T', ' ').split('.')[0]}</div>
      //     : <div> - </div>),
      render: (text) => {
        if (text == null) return <div> - </div>;

        // QURNA FIX
        // return <div>{text.replace("T", " ").split(".")[0]}</div>;

        // UTC TIME FIX
        const offsetHours = (new Date().getTimezoneOffset() * -1) / 60;

        let date = new Date(text);

        date.setHours(date.getHours() + offsetHours);
        date = dayjs(date).format("YYYY-MM-DDTHH:mm:ss");

        return <div>{date.replace("T", " ").split(".")[0]}</div>;
      },
    },
    {
      title: <div className="column-title">{t("status")}</div>,
      dataIndex: "status",
      key: "status",
      width: "120px", // '120px'
      render: (text) =>
        text !== null ? <div>{t("confirmed")}</div> : <div> - </div>,
    },
    {
      title: <div className="column-title">{t("user")}</div>,
      dataIndex: "closedBy",
      key: "closedBy",
      width: "200px", // '150px'
      render: (text) => <div>{text}</div>
      // text !== null ? <div>{text}</div> : <div> {t("userHasBeenDeleted")} </div>,
    },
    // {
    //   title: () => <div className="column-title">{t("comment")}</div>,
    //   dataIndex: "commentFinal",
    //   key: "commentFinal",
    //   width: "15%", // '200px' 15%
    //   render: (text) =>
    //     text !== null ? <div>{text}</div> : <div> - </div>,
    // },
    {
      title: "",
      dataIndex: "",
      key: "id",
      align: "right",
      width: "32px",
      render: (record, index) => (
        <>
          {/* <AlarmInfoModal
            visible={isModalVisible}
            closeModal={() => setIsModalVisible(false)}
            data={recordLocal}
            wellName={wellName}
            index={index}
            well_id={wellId}
            closeWork={handleCloseWork}
            doneWork={handleDoneWork}
            closeObservation={handleCloseObservation}
            doneObservation={handleDoneObservation}
            handleCloseObservation={handleCloseObservation}
            handleDoneObservation={handleDoneObservation}
            sendNewActionsRequest={sendNewActionsRequest}
            handleCloseAlarm={onCloseClick}
            sendNewObservationRequest={sendNewObservationRequest}
          /> */}
          {Boolean(accessStore.checkPermission("deviation_analysis_alarms")) && (
            <OptionsIcon
              onClick={() => {
                selectRecord(record);
              }}
              style={{
                height: "16px",
                position: "absolute",
                right: "3px",
                top: "4.5px"
              }}
            />
          )}
        </>
      ),
    },
  ];
};

export default configArchive;
