/* eslint-disable */
import { makeAutoObservable } from "mobx";
import { task } from "mobx-fetch-task";
import request from "utils/request";

class WellTreePickerStore {
  constructor() {
    makeAutoObservable(this, {
      locationsTreesFetcher: false,
    });
  }

  locationsTreesFetcher = task(async () => {
    const trees = await fetchLocationsTrees();
    return trees;
  });
}

const fetchLocationsTrees = async () => {
  const { data: locationTrees } = await request.get("/well-tree/expanded");
  return locationTrees;
}

export default WellTreePickerStore;
