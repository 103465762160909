/* eslint-disable */
import request from "utils/request";
import {useTranslation} from "react-i18next";

const usePostSettings = (
  step,
  setButtonText,
  mutate,
  reasonsProps,
  setpointsIndividualData,
  specialSettingsData,
  instantCommonSettings,
  mutateInstantCommonSettings,
  mutateTrendCommonSettingsData,
  mutateSpecialSettingsData,
  specialData,
  setpointsData,
  mutateSetpointsData,
  divergenceSettingsData,
  mutateDivergenceData,
) => {
  const {t} = useTranslation();

  const analysis = async (values) => {
    await request.post("/settings/analysis", values);
    mutate();
    setButtonText(() => t("applied"));
    //   resetModal();
  };

  const reasons = async (values) => {
    const reasonsData = reasonsProps.map((item) => ({
      ...item,
      active: values[`c${item.id}`],
    }));

    await request.post("/settings/reason", reasonsData);
    mutate();
    setButtonText(() => t("applied"));
    //  resetModal();
  };

  const instantCommonSettingsUpdate = async (values) => {
    if (instantCommonSettings) {
      await request.post("/settings/instant/common/update", values);
      mutateInstantCommonSettings();
    }
    /* if (warningData) {
       await request.post(
         `/settings/instant/individual/update`,
         values
       );
       mutate();
     }*/
  }

  //const rangingSettings = () => resetModal();

  const trendAnalysisCommonSettings = async (values) => {
    await request.post("/settings/trend/common/update", values);
    mutateTrendCommonSettingsData();
  };
  const divergenceSettings = async (values) => {
    if (values.wellId) {
      await request.post(
        `/settings/deviation/analysis/individual/settings/update`,
        values
      );
      mutate();
    } else {
      await request.post(
        `/settings/deviation/analysis/common/settings/update`,
        values
      );
      mutateDivergenceData();
    }
  };

  const specialAnalysisSettings = async (values) => {
    if (specialSettingsData) {
      await request.post(
        `/settings/special/common/analysis/update`,
        values
      );
      mutateSpecialSettingsData();
    }
    if (specialData) {
      await request.post(
        `/settings/special/individual/analysis/update`,
        values
      );
      mutate();
    }
  };

  const setpointsSettings = async (values) => {
    if (setpointsData) {
      await request.post(
        `/settings/ustavka/common/analysis/update`,
        values
      );
      mutateSetpointsData();
    }
    if (setpointsIndividualData) {
      await request.post(
        `/settings/ustavka/individual/analysis/update`,
        values
      );
      mutate();
    }
  };

  return (values) => {
    switch (step) {
      case "analysis":
        return analysis(values);
      case "reasons":
        return reasons(values);
      case "instantCommonSettings":
        return instantCommonSettingsUpdate(values)
      //case "rangingSettings": return rangingSettings();
      case "divergenceSettings":
        return divergenceSettings(values);
      case "trendAnalysisCommonSettings":
        return trendAnalysisCommonSettings(values);
      case "specialAnalysisSettings":
        return specialAnalysisSettings(values);
      case "setpointsSettings":
        return setpointsSettings(values);
      default:
        return null
    }
  }
}

export default usePostSettings;