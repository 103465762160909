/* eslint-disable */
import { mutate } from "swr";
import { Form, Field } from "react-final-form";
import { observer } from "mobx-react-lite";
import { useStore } from "hooks/useStore";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal";
import Button from "components/Button";
import Select from "formAdapters/Select";
import Textarea from "formAdapters/Textarea";
import request from "utils/request";
import "./styles.css";

const AddPriorityModal = observer(
  ({
    visible,
    closePriority,
    reasons,
    selectedWellId,
    setDeleteStatus,
    modalInfo,
  }) => {
    const { wellStore, accessStore } = useStore();
    const postPriority = async (values) => {
      if (accessStore.checkPermission("add_first_priority_well")) {
        await request.post("/well/reason", { ...values, wellId: selectedWellId });

        setDeleteStatus();
  
        await request.post(`/alarm/operationalRecords/set/${wellStore.id}`, {
          source: 1,
          wellId: wellStore.id,
          comment: values?.comment,
          creatorId: 1,
          active: 1,
        });
  
        mutate(`/alarm/operationalRecords/get/${wellStore.id}`);
        wellStore.resetIsAlarmsReload();
      }
      
      await closePriority();
    };
    const { t } = useTranslation();

    return (
      visible && (
        <Form
          onSubmit={postPriority}
          initialValues={{ comment: "", reasonId: null }}
          render={({ handleSubmit, values, form }) => (
            <Modal
              open={visible}
              titleText={modalInfo?.name}
              width={272}
              className="add-priority-modal"
              onCancel={() => {
                closePriority();
                form.reset();
              }}
              submitButton={
                <Button
                  text={t("apply")}
                  disabled={reasons?.length < 1 || !values.reasonId}
                  onClick={handleSubmit}
                />
              }
              destroyOnClose
            >
              <form
                id="add-priority"
                onSubmit={handleSubmit}
                className="add-priority-form"
              >
                <div className="select-reason-container">
                  <div className="add-priority-label">{t("addingReason")}</div>
                  <Field
                    name="reasonId"
                    options={reasons}
                    defaultValue={null}
                    placeholder={t("chooseFromList")}
                    disabled={reasons?.length < 1}
                    component={Select}
                  />
                </div>
                <div className="add-priority-label">{t("comment")}</div>
                <Field
                  name="comment"
                  maxLength={300}
                  defaultValue={null}
                  component={Textarea}
                />
              </form>
            </Modal>
          )}
        />
      )
    );
  }
);

export default AddPriorityModal;
