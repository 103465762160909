/* eslint-disable */
import { useState } from "react";
import Modal from "components/Modal";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import "./styles.css";

const DeletionAlertModal = ({ visible, title, content, onConfirm, onCancel, clear }) => {

  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState();

  return (
    <Modal
      visible={visible}
      titleText={title}
      onCancel={onCancel}
      submitButton={(
        <Button
          text={t("apply")}
          loading={submitting}
          onClick={async () => {
            setSubmitting(true);
            await onConfirm();
            setSubmitting(false);
            clear();
          }}
        />
      )}
    >
      <div className="admin-deletion-alert-modal-content">
        {content}
      </div>
    </Modal>
  );
}

export default DeletionAlertModal;
