/* eslint-disable */
import { createContext, useContext } from "react";
import WellTree from "containers/Home/components/WellTree/components/MenuData/store";
import Trends from "containers/Home/components/Trends/store";
import Home from "containers/Home/store";
import DeviationAnalysis from "containers/DeviationAnalysis/store";
import ArchiveAlarmsStore from "containers/DeviationAnalysis/components/ArchiveAlarms/store";
import AccessStore from "containers/Home/accessStore";
import AdminUserStore from "containers/Admin/adminUserStore";
import AdminGroupStore from "containers/Admin/adminGroupStore";
import AdminLocationsGroupAccessStore from "containers/Admin/adminLocationsGroupAccessStore";
import AdminLocationsSettingsStore from "containers/Admin/adminLocationsSettingsStore";
import WellTreePickerStore from "components/WellTreePicker/store";
import TranslationStore from "locales/translationStore";
import WellTreemapStore from "containers/WellTreemapChart/store";
import { enableLogging } from "mobx-logger";
import { makeAutoObservable, runInAction } from "mobx";
import { task } from "mobx-fetch-task";
import useAuth from "./useAuth";
import request from "utils/request";
import AdminLogHistoryStore from "containers/Admin/adminLogHistoryStore";
import MnemoStore from "containers/Home/components/Mnemoscheme/mnemoStore";

class UserStore {
  constructor() {
    makeAutoObservable(this, {
      fetchIsAdmin: false,
      fetchUserInfo: false
    });
  }

  loaded = false;

  surname = null;
  name = null;
  patronymic = null;
  login = null;

  isAdmin = false;

  get accessLevel() { // not implemented
    const { user } = useAuth();

    return user.accessLevel;
  }

  fetchIsAdmin = task(async () => {
    try {
      const { data: isAdminBool } = await request.get("/admin/checkAdminAccessLevel");

      runInAction(() => {
        this.isAdmin = isAdminBool;
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  });

  fetchUserInfo = task(async () => {
    try {
      const { data: userInfo } = await request.get("/users/info");
      this.setUserInfo(userInfo);
    } catch (error) {
      console.error(error);
    }
  });

  setUserInfo(info) {
    runInAction(() => {
      this.loaded = true;

      this.surname = info.surname;
      this.name = info.name;
      this.patronymic = info.patronymic;
      this.login = info.login;
    });
  }

  clearUserInfo() {
    runInAction(() => {
      this.loaded = false;

      this.surname = null;
      this.name = null;
      this.patronymic = null;
      this.login = null;
    });
  }
}

export const store = {
  wellStore: new WellTree(),
  trendsStore: new Trends(),
  homeStore: new Home(),
  userStore: new UserStore(),
  deviationAnalysisStore: new DeviationAnalysis(),
  archiveAlarmsStore: new ArchiveAlarmsStore(),
  accessStore: new AccessStore(),
  adminGroupStore: new AdminGroupStore(),
  adminUserStore: new AdminUserStore(),
  adminLocationsGroupAccessStore: new AdminLocationsGroupAccessStore(),
  adminLocationsSettingsStore: new AdminLocationsSettingsStore(),
  translationStore: new TranslationStore(),
  wellTreePickerStore: new WellTreePickerStore(),
  wellTreemapStore: new WellTreemapStore(),
  adminLogHistoryStore: new AdminLogHistoryStore(),
};

store.mnemoStore = new MnemoStore();

if (process.env.NODE_ENV === "development") {
  enableLogging({ reaction: false, action: false }); // action: true
}

export const StoreContext = createContext(store);
export const useStore = () => useContext(StoreContext);
