/* eslint-disable */
import { useMemo, useState } from "react";
import UplotReact from "uplot-react";
import { useResizeDetector } from "react-resize-detector";
import useNRHTooltip from "./hooks/useNRHTooltip";
import defaultOptions, { doubleSplitsCount } from "./common/defaultOptions";
import processData from "./common/processData";
import NRHChartAxisLabels from "./components/NRHChartAxisLabels";
import NRHChartTooltip from "./components/NRHChartTooltip";
import NRHChartLegend from "./components/NRHChartLegend";
import "uplot/dist/uPlot.min.css";
import "./styles.css";

const NRHChart = ({
  data,
  isFullScreen,
  menuLocationsMinimizedMode
}) => {

  const {
    width: containerWidth,
    height: containerHeight,
    ref: containerRef,
  } = useResizeDetector();

  const [tooltipData, setTooltipData] = useState(
    { calc: {}, factory: {}, work: {} }
  );

  const tooltip = useNRHTooltip({ setData: setTooltipData });

  const legendHeight = 50;

  const processedData = useMemo(() => {
    const processedData = processData(data);

    // console.log("processedData", processedData);
    return processedData;
  }, [data]);

  const options = useMemo(() => {

    const axes = [
      {
        font: "12px Roboto",
        stroke: "#6B6C70",
        splits: doubleSplitsCount
      },
      {
        font: "12px Roboto",
        stroke: "#6B6C70",
        splits: doubleSplitsCount
      }
    ];

    if (isFullScreen) {
      delete axes[0].splits;
      delete axes[1].splits;
    }

    const width = isFullScreen ? 3 : 2;

    const series = [
      {},
      {
        width,
        stroke: "#F12A3A",
        dash: [5, 2],
        spanGaps: true,
        points: {
          size: isFullScreen ? 10 : 2,
          space: 0
        }
      },
      {
        width,
        stroke: "#4B7EFE",
        spanGaps: true,
        points: {
          size: isFullScreen ? 10 : 2,
          space: 0
        }
      },
      {
        width,
        stroke: "#65ab59",
        spanGaps: true,
        points: {
          size: isFullScreen ? 13 : 8,
          fill: "#a8ffb1",
          space: 0
        }
      }
    ];

    const cursor = {
      drag: {
        x: false,
        y: false
      },
      points: {
        size: isFullScreen ? 10 : 3
      }
    }

    const plugins = [tooltip()];

    const options = {
      ...defaultOptions,
      axes,
      series,
      cursor,
      width: Math.floor(containerWidth),
      height: containerHeight - legendHeight,
      plugins
    }

    if (isFullScreen) {
      for (const axe of options.axes) {
        delete axe.splits;
      }
    }

    // console.log("options:", options);
    return options;
  }, [processedData, containerWidth, containerHeight, isFullScreen]);

  const fullScreenWidth = menuLocationsMinimizedMode ?
    "calc(100% - 80px)" : "calc(100% - 248px)";

  const fullScreenLeft = menuLocationsMinimizedMode ?
    "80px" : "248px";

  const calcLegend = {
    name: "Расчетная",
    color: "#F12A3A",
    dash: true
  }

  const factoryLegend = {
    name: "Заводская",
    color: "#4B7EFE"
  }

  const workLegend = {
    name: "Рабочая точка",
    stroke: "#65ab59",
    fill: "#a8ffb1"
  }

  return (
    <div
      className={isFullScreen ? "full-screen-on" : "full-screen-off"}
      style={{
        width: isFullScreen ? fullScreenWidth : "100%",
        left: isFullScreen ? fullScreenLeft : "80px"
      }}
    >
      <div
        ref={containerRef}
        className="nrh-chart-container"
      >
        <NRHChartAxisLabels
          containerWidth={containerWidth ?? 0}
          containerHeight={containerHeight ?? 0}
        />

        <NRHChartTooltip
          data={tooltipData}
          scaleWidth={containerWidth}
        />

        <UplotReact
          options={options}
          data={processedData}
        />

        <div style={{ height: legendHeight }}>
          <NRHChartLegend
            calc={calcLegend}
            factory={factoryLegend}
            work={workLegend}
          />
        </div>
      </div>
    </div>
  );
}

export default NRHChart;
