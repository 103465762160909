/* eslint-disable */
import { useState } from "react";
import moment from "moment";
import { Switch, FormControlLabel } from "@material-ui/core";
import Modal from "components/Modal";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const PredictionModal = ({
  visible,
  closeModal,
  handleSetPredictionMode,
  predictionMode,
  handleSetPredictionDate,
  predictionPeriod,
  handleSetPredictionPeriod,
}) => {
  const [chosenPeriod, setChosenPeriod] = useState(predictionPeriod);
  const [chosenPredictionMode, setChosenPredictionMode] =
    useState(predictionMode);
  const { t } = useTranslation();

  const handleChangeChartPredictionClick = () => {
    setChosenPredictionMode(!chosenPredictionMode);
  };

  const chartPredictionSwitch = (
    <div
      style={{
        padding: "0 11px 11px 11px",
        color: chosenPredictionMode ? "#444" : "#999",
      }}
    >
      <FormControlLabel
        control={
          <Switch
            // labelPlacement="end"
            size="small"
            checked={chosenPredictionMode}
            onChange={handleChangeChartPredictionClick}
          />
        }
        label={t("prediction")}
      />
    </div>
  );

  const setPredictionDate = (period) => {
    setChosenPeriod(period);
    handleSetPredictionPeriod(period);
    let predictionDate = new Date();

    switch (period) {
      case 1: {
        predictionDate.setHours(0, 0, 0, 0);
        predictionDate.setDate(predictionDate.getDate() + 1);
        break;
      }
      case 2: {
        predictionDate.setHours(0, 0, 0, 0);
        predictionDate.setDate(1);
        predictionDate.setMonth(predictionDate.getMonth() + 1);
        break;
      }
    }

    predictionDate = moment(predictionDate, "YYYY/MM/DD HH:mm:ss");

    if (predictionDate?._d && chosenPredictionMode) {
      handleSetPredictionMode(chosenPredictionMode);

      handleSetPredictionDate(
        dayjs(predictionDate._d).format("YYYY-MM-DDTHH:mm:ss")
      );
    }

    closeModal();
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setPredictionDate(chosenPeriod);
        handleSetPredictionPeriod(chosenPeriod);
        handleSetPredictionMode(chosenPredictionMode);
        closeModal();
      }}
      titleText={t("prediction")}
      footer={false}
      width="300px"
    >
      <div style={{ padding: "0px 20px 20px 20px" }}>
        {chartPredictionSwitch}

        <div className="date-buttons">
          <div
            className={`period-button ${chosenPeriod == 1 && "active"}`}
            onClick={() => setPredictionDate(1)}
          >
            {t("endOfDay")}
          </div>
          <div
            className={`period-button ${chosenPeriod == 2 && "active"}`}
            onClick={() => setPredictionDate(2)}
          >
            {t("endOfMonth")}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PredictionModal;
