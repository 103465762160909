/*eslint-disable*/
import { useRef, useState } from "react";
import Draggable from "react-draggable";

const useDraggableModal = () => {
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y + 48,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  }

  const modalRender = (modal) => (
    <Draggable
      disabled={disabled}
      bounds={bounds}
      onStart={(event, uiData) => onStart(event, uiData)}
      nodeRef={draggleRef}
    >
      <div ref={draggleRef}>{modal}</div>
    </Draggable>
  );

  return {
    disabled,
    setDisabled,
    modalRender,
  }
}

export default useDraggableModal;
