/* eslint-disable */
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useRef, useState } from "react";
import { Table } from 'antd';
import dayjs from "dayjs";
import { useStore } from "hooks/useStore";
import useRequest from "hooks/useRequest";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import CustomTooltip from "components/Tooltip";
import RangeDateTimePicker from 'components/RangeDateTimePicker';
import request from "utils/request";
import moment from "moment";
import { ReactComponent as XlsxIcon } from "assets/images/xlsx_icon.svg";
import "./styles.css";

const LogsHistory = observer(() => {
    const { t } = useTranslation();
    const [reportData, setReportData] = useState(null)
    const [reportLogData, setReportLogData] = useState(null)

    const fetchReportLogData = async (f, interval) => {
      const { data } = await request.post(
        "/log/getEventHistoryLogsByPeriod", interval
      );

      f(data);
    }

    const fetchData = async (f, body) => {
      const { data } = await request.post(
        "/log/getEventHistoryLogsByPeriod1",
        body, {
          responseType: 'blob'
        }
      );

      return data
    }

    const formReport = async () => {
      const startDate = period.startDate + "T00:00:00.000";
      const endDate = dayjs(period.endDate)
        .add(1, "day").format("YYYY-MM-DD") + "T00:00:00.000";
  
      const body = {
        startInterval: startDate,
        endInterval: endDate,
        offsetHours: (new Date().getTimezoneOffset() * -1) / 60,
      }
  
      try {
        await fetchData((data) => {
          setReportData(data);
        }, body)
        .then((response) => {
          //костыль
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Отчёт по логам авторизации - ${moment().locale(translationStore.currentLanguage).format("DD.MM.YYYY HH:mm:ss")}.xlsx`);
          document.body.appendChild(link);
          link.click();
          //конец костыля
        })
      } catch (error) {
        console.error(error);
      } 
    }

    const { adminLogHistoryStore, translationStore } = useStore();

    const period = adminLogHistoryStore.period;
  
    const handleChangePeriod = (newPeriod) => {
        adminLogHistoryStore.setPeriod(newPeriod);
    }

    const handleFormReport = () => {
        formReport()
    }

    const handleLogHistoryReport = () => {
      const startDate = period.startDate + "T00:00:00.000";
      const endDate = dayjs(period.endDate)
        .add(1, "day").format("YYYY-MM-DD") + "T00:00:00.000";
  
      const body = {
        startInterval: startDate,
        endInterval: endDate,
        offsetHours: 0, // (new Date().getTimezoneOffset() * -1) / 60,
      }
      fetchReportLogData((data) => setReportLogData(data), body)
  }

    const unique = [...new Set(reportLogData && reportLogData.map(item => item.userName))]; // [ 'A', 'B']

    const filterValues = unique && unique.map(item => ({
        text: item,
        value: item
    }))

    const columns1 = [
        {
            title: <div className="column-title" style={{paddingLeft: 8}}>{t("user")}</div>,
            dataIndex: 'userName',
            key: 'userName',
            filters: filterValues,
            onFilter: (value, record) => {
                return record.userName.toString().indexOf(value) == 0
            },
        },
        {
            title: <div className="column-title" style={{paddingLeft: 8}}>{t("dateAndTimeOfCreation")}</div>,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => {
                const offsetHours = (new Date().getTimezoneOffset() * -1) / 60;

                let date = new Date(text);

                date.setHours(date.getHours() + offsetHours);
                date = dayjs(date).format("YYYY-MM-DDTHH:mm:ss");
                return date.replace("T", " ")
            },
            sorter: (a, b) => {
                if (a.createdAt === b.createdAt) return 0;
                return a.createdAt > b.createdAt ? 1 : -1;
              },
        },
    ];

    return (
    <>
        <div className="log-history-footer">
        <RangeDateTimePicker
        period={period}
        handleChangePeriod={handleChangePeriod}
        style={{ marginLeft: "auto", paddingRight: "100px" }}
      />
      <div className="deviation-analysis-button-container">
      {(period && period?.startDate && period?.endDate) ?
        <div
          className="log-history-button"
          style={{paddingRight: "20px"}}
          onClick={handleFormReport}
        >
          <div style={{display: "flex", alignItems: "center"}}>
            {"Скачать отчет"}
            {<XlsxIcon></XlsxIcon>}
          </div>
        </div>
        :
        <CustomTooltip
          tooltipText="Необходимо выбрать период отчета"
        >
          <div
            className="log-history-button disabled"
          >
            <div style={{display: "flex", alignItems: "center"}}>
            {"Скачать отчет"}
            {<XlsxIcon></XlsxIcon>}
          </div>
          </div>
        </CustomTooltip>
      }
      {(period && period?.startDate && period?.endDate) ?
        <div
          className="log-history-button"
          onClick={handleLogHistoryReport}
        >
          {"Сформировать отчет"}
        </div>
        :
        <CustomTooltip
          tooltipText="Необходимо выбрать период формирования отчета"
        >
          <div
            className="log-history-button disabled"
          >
            {"Сформировать отчет"}
          </div>
        </CustomTooltip>
      }
      </div>
        </div>
        <div className="log-table-container1">
            <Table
                columns={columns1}
                dataSource={reportLogData?.map((item) => ({ ...item, key: item.id }))}
                pagination={false}
                className="alarm-table11"
                bordered
                sticky
                size='small'
                locale={{
                    filterReset: 'Сброс',
                    triggerDesc: 'Сортировать по убыванию',
                    triggerAsc: 'Сортировать по возрастанию',
                    cancelSort: 'Отмена сортировки',
                    filterEmptyText: 'Нет фильтров',
                  }}
            />
        </div>
    </>
    );
})

export default LogsHistory;