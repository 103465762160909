/*eslint-disable*/
import { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { DatePicker } from "antd";
import { useStore } from "hooks/useStore";
import { useOutsideClickExtraRefs } from "hooks/useOutsideClick";
import ruLocale from "antd/es/date-picker/locale/ru_RU";
import enLocale from "antd/es/date-picker/locale/en_US";
import esLocale from "antd/es/date-picker/locale/es_ES";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import moment from "moment";
import "./styles.css";

dayjs.extend(advancedFormat);

const RangeDateTimePicker = observer(({
  period,
  handleChangePeriod,
  timeSelectable = false,
  style = {},
  allowClear = true,
}) => {

  const { translationStore } = useStore();

  const outputFormat = timeSelectable ? "DD.MM.YYYY HH:mm:ss" : "DD.MM.YYYY";

  const selectedDatePrimaryColor = "#1890ff";
  const selectedDateBackgroundColor = "#1890ff66";
  const selectedPeriodBetweenDatesColor = "#e6f7ff";

  const [activePanelIndex, setActivePanelIndex] = useState(0);

  useEffect(() => {
    inputRefs[activePanelIndex].current?.focus();
  }, [activePanelIndex]);

  const [open, setOpen] = useState(false);

  const popupRef1 = useRef(null);
  const popupRef2 = useRef(null);

  const popupRefs = useMemo(
    () => [popupRef1, popupRef2],
    [popupRef1, popupRef2]);

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);

  const inputRefs = useMemo(
    () => [inputRef1, inputRef2],
    [inputRef1, inputRef2]);

  const handleOutsideClick = () => {
    setOpen(false);
  }

  useOutsideClickExtraRefs(handleOutsideClick, popupRefs);

  const localHandleChangeStartPeriod = (value) => {
    if (!value) {
      handleChangePeriod({ ...period, startDate: null })
      return;
    }

    const start = value._d;

    const format = timeSelectable ? "YYYY-MM-DDTHH:mm:ss" : "YYYY-MM-DD";

    setActivePanelIndex(0);

    if (dayjs(start).isAfter(dayjs()) || dayjs(start).isAfter(period?.endDate)) {
      handleChangePeriod({ ...period });
      return;
    }

    handleChangePeriod({
      ...period,
      startDate: dayjs(start).format(format)
    });
  }

  const localHandleChangeEndPeriod = (value) => {
    if (!value) {
      handleChangePeriod({ ...period, endDate: null })
      return;
    }

    const end = value._d;

    const format = timeSelectable ? "YYYY-MM-DDTHH:mm:ss" : "YYYY-MM-DD";

    setActivePanelIndex(1);

    if (dayjs(end).isAfter(dayjs()) || dayjs(end).isBefore(period?.startDate)) {
      handleChangePeriod({ ...period });
      return;
    }

    handleChangePeriod({
      ...period,
      endDate: dayjs(end).format(format)
    });
  }

  const renderStartPeriodPanelCell = (date, today) => {
    if (date.isSame(period?.startDate, "day")) {
      return (
        <div
          style={{
            backgroundColor: selectedDatePrimaryColor,
            color: "white",
            width: "24px",
            margin: "0px auto",
          }}
        >{date.date()}</div>
      );
    }
    if (date.isSame(period?.endDate, "day")) {
      return (
        <div
          style={{
            backgroundColor: selectedDateBackgroundColor,
            color: "white",
            width: "24px",
            margin: "0px auto",
          }}
        >{date.date()}</div>
      );
    }
    if (date.isAfter(period?.startDate, "day")
      && date.isBefore(period?.endDate, "day")) {
      return (
        <div style={{ backgroundColor: selectedPeriodBetweenDatesColor }}>{date.date()}</div>
      );
    }

    return date.date();
  }

  const renderEndPeriodPanelCell = (date, today) => {
    if (date.isSame(period?.endDate, "day")) {
      return (
        <div
          style={{
            backgroundColor: selectedDatePrimaryColor,
            color: "white",
            width: "24px",
            margin: "0px auto",
          }}
        >{date.date()}</div>
      );
    }
    if (date.isSame(period?.startDate, "day")) {
      return (
        <div
          style={{
            backgroundColor: selectedDateBackgroundColor,
            color: "white",
            width: "24px",
            margin: "0px auto",
          }}
        >{date.date()}</div>
      );
    }
    if (date.isAfter(period?.startDate, "day")
      && date.isBefore(period?.endDate, "day")) {
      return (
        <div style={{ backgroundColor: selectedPeriodBetweenDatesColor }}>{date.date()}</div>
      );
    }

    return date.date();
  }

  const isFutureDate = (date) => date.isAfter(dayjs(), "day");

  const isStartDateDisabled = (date) => isFutureDate(date) || date.isAfter(period?.endDate);

  const isEndDateDisabled = (date) => isFutureDate(date) || date.isBefore(period?.startDate);

  const borderStyles = (visible) => (visible ? {
    border: "2px solid #4B7EFE",
  } : {
    border: "2px solid #ffffff",
  });

  const isNowButtonAvailableOnEndPeriod = period?.startDate ? dayjs().isAfter(period.startDate) : true;

  const locale = translationStore.currentLanguage == "ru" ? ruLocale
    : translationStore.currentLanguage == "en" ? enLocale
      : translationStore.currentLanguage == "es" ? esLocale : null;

  const popupClassNames = ["range-date-time-picker-popup"];
  const popupClassNamesStartPeriod = [...popupClassNames,
    "range-date-time-picker-popup-now-button-hide"];
  const popupClassNamesEndPeriod = [...popupClassNames];
  if (!isNowButtonAvailableOnEndPeriod) {
    popupClassNamesEndPeriod.push("range-date-time-picker-popup-now-button-disabled");
  }

  const renderHeader = (title, index) => (
    <>
      <div
        className="range-date-time-picker-header"
        style={{
          ...borderStyles(activePanelIndex === index),
          width: timeSelectable ? "453px" : "284px",
        }}
      >
        {title}
      </div>

      {timeSelectable &&
        <div
          className="range-date-time-picker-header-time"
        >
          <span style={{ flexBasis: "56px" }}>ч.</span>
          <span style={{ flexBasis: "56px" }}>мин.</span>
          <span style={{ flexBasis: "56px" }}>сек.</span>
        </div>
      }
    </>
  );

  return (
    <div
      className="range-date-time-picker-container"
      style={style}
    >
      <DatePicker
        ref={inputRef1}
        open={open}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
          setActivePanelIndex(0);
        }}
        locale={locale}
        format={outputFormat}
        onSelect={(e) => {
          localHandleChangeStartPeriod(e);
          setActivePanelIndex(0);
        }}
        onChange={localHandleChangeStartPeriod}
        value={period?.startDate ? moment(period?.startDate) : null}
        showTime={timeSelectable}
        renderExtraFooter={() => renderHeader("Начало периода", 0)}
        panelRender={(node) => (
          <div
            ref={popupRef1}
            onClick={(e) => setActivePanelIndex(0)}
          >
            {node}
          </div>
        )}
        placement="topRight"
        placeholder="Начало периода"
        disabledDate={isStartDateDisabled}
        dateRender={renderStartPeriodPanelCell}
        popupClassName={popupClassNamesStartPeriod}
        popupStyle={borderStyles(activePanelIndex === 0)}
        mode="date"
        allowClear={allowClear}
        showToday={false}
        inputReadOnly
      />
      <DatePicker
        ref={inputRef2}
        open={open}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
          setActivePanelIndex(1);
        }}
        locale={locale}
        format={outputFormat}
        onSelect={(e) => {
          localHandleChangeEndPeriod(e);
          setActivePanelIndex(1);
        }}
        onChange={localHandleChangeEndPeriod}
        value={period?.endDate ? moment(period?.endDate) : null}
        showTime={timeSelectable}
        renderExtraFooter={() => renderHeader("Конец периода", 1)}
        panelRender={(node) => (
          <div
            ref={popupRef2}
            onClick={(e) => setActivePanelIndex(1)}
          >
            {node}
          </div>
        )}
        placement="topLeft"
        placeholder="Конец периода"
        disabledDate={isEndDateDisabled}
        dateRender={renderEndPeriodPanelCell}
        popupClassName={popupClassNamesEndPeriod}
        popupStyle={borderStyles(activePanelIndex === 1)}
        mode="date"
        allowClear={allowClear}
        showToday={false}
        inputReadOnly
      />
    </div >
  );
});

export default RangeDateTimePicker;
