/* eslint-disable */
import { useEffect, useMemo, useState } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import Draggable from "react-draggable";
import { FORM_ERROR } from "final-form";
import { Table } from "antd";
import { useResizeDetector } from "react-resize-detector";
import { FormOutlined, SettingOutlined } from "@ant-design/icons";
import { useStore } from "hooks/useStore";
import EditParametersModal from "containers/Home/components/SupportingInformation/MnemoschemeModal/components/TableList/components/EditParametersModal";
import EditStyleModal from "containers/Home/components/SupportingInformation/MnemoschemeModal/components/TableList/components/EditStyleModal";
import CustomTooltip from "components/Tooltip";
import "./styles.css";

const MnemoTable = observer(({
  id,
  styles,
  name,
  parameters = [],
  position,
  setPosition,
  setWidth,
  isDraggable
}) => {

  const { mnemoStore } = useStore();

  const [isParameterEditModalVisible,
    setIsParameterEditModalVisible] = useState(false);

  const [isStyleEditModalVisible,
    setIsStyleEditModalVisible] = useState(false);

  const tableParameters = useMemo(
    () => parameters.map(parameter => ({
      ...parameter, valueUnit: `${parameter.value ?
        parameter.value.toFixed(2) : "—"} ${parameter.unit}`
    })), [parameters]);

  const columns = [
    {
      dataIndex: "name",
      render: (name, parameter) => (
        <span style={{ color: parameter.color }}>{name}</span>
      )
    },
    {
      dataIndex: "valueUnit",
      render: (valueUnit, parameter) => (
        <span style={{ color: parameter.color }}>{valueUnit}</span>
      )
    }
  ];

  const onStopDrag = (position) => {
    setPosition(position);
  }

  const saveTableParameters = async (activeParameters) => {
    try {
      await mnemoStore.tableListStore
        ?.editTableParameters(id, activeParameters);
    } catch (error) {
      return {
        [FORM_ERROR]: null
      }
    }
  }

  const saveTableStyles = async (styles) => {
    try {
      await mnemoStore.tableListStore
        ?.editTableStyles(id, styles);
    } catch (error) {
      return {
        [FORM_ERROR]: null
      }
    }
  }

  const { width, ref } = useResizeDetector();

  useEffect(() => {
    if (!width) return;
    if (width === styles?.common?.width) return;

    setWidth(width);
  }, [width]);

  return (
    <>
      <Draggable
        position={position}
        onStop={(e, data) => onStopDrag({ x: data.x, y: data.y })}
        disabled={!isDraggable}
        bounds="parent"
        handle="#handle"
      >
        <div
          ref={ref}
          className="mnemo-table"
          style={{
            width: styles?.common?.width,
            border: styles?.common?.border?.show &&
              `${styles.common?.border.thickness}px solid ${styles.common?.border.color}`,
            borderRadius: styles?.common?.border?.radius ? `${styles?.common?.border?.radius}px` : "0px",
            backgroundColor: "#fff",
            resize: isDraggable ? "horizontal" : "none",
            boxSizing: "content-box"
          }}
        >
          {styles?.header?.show ?
            <div
              id="handle"
              className="mnemo-table-header"
              style={{
                borderWidth: styles?.common?.border?.thickness,
                borderColor: styles?.common?.border?.color,
                cursor: isDraggable ? "grab" : "auto",
                backgroundColor: styles?.header?.color?.background,
                color: styles?.header?.color?.font,
                fontSize: styles?.header?.fontSize
              }}
            >
              <span style={{ lineHeight: "1" }}>{name}</span>
              {isDraggable &&
                <div style={{ display: "flex", gap: "12px" }}>
                  <CustomTooltip placement="top" tooltipText={"Настройки стилей"}>
                    <SettingOutlined
                      onClick={(e) => setIsStyleEditModalVisible(true)}
                    />
                  </CustomTooltip>

                  <CustomTooltip placement="top" tooltipText={"Изменить параметры"}>
                    <FormOutlined
                      onClick={(e) => setIsParameterEditModalVisible(true)}
                    />
                  </CustomTooltip>
                </div>
              }
            </div>
            :
            <div
              id="handle"
              style={{
                height: "10px",
                cursor: isDraggable ? "grab" : "auto",
              }}
            />
          }

          <div className="mnemo-table-parameters">
            <Table
              dataSource={tableParameters}
              columns={columns}
              rowKey={(parameter) => parameter.id}
              onRow={(record, index) => ({
                style: {
                  cursor: "auto",
                  lineHeight: styles?.parameters?.lineHeight,
                  fontSize: styles?.parameters?.fontSize,
                  backgroundColor: index % 2 !== 0 ?
                    styles?.parameters?.evenColor?.background :
                    styles?.parameters?.notEvenColor?.background,
                  color: index % 2 !== 0 ?
                    styles?.parameters?.evenColor?.font :
                    styles?.parameters?.notEvenColor?.font
                }
              })}
              pagination={false}
              showHeader={false}
              size={"small"}
            />
          </div>
        </div>
      </Draggable>

      <EditParametersModal
        visible={isParameterEditModalVisible}
        parameters={toJS(mnemoStore.tableListStore
          ?.getEditParametersTableData(id))}
        closeModal={() => setIsParameterEditModalVisible(false)}
        onSave={saveTableParameters}
      />

      <EditStyleModal
        visible={isStyleEditModalVisible}
        closeModal={(e) => setIsStyleEditModalVisible(false)}
        styles={toJS(styles)}
        onSave={saveTableStyles}
      />
    </>
  );
});

export default MnemoTable;