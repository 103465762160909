import { useTranslation } from 'react-i18next'
import './styles.css'

const Config = () => {
  const { t } = useTranslation()

  return ([
    {
      title: () => <div className="column-title">{t('wellInstallation')}</div>,
      dataIndex: 'wellPumpName',
      key: 'wellPumpName',
      width: '150px',
      render: text => (
        text !== null
          ? <div>{text}</div>
          : <div> - </div>),
    },
    {
      title: () => <div className="column-title">{t('begin')}</div>,
      dataIndex: 'startTime',
      key: 'startTime',
      width: '150px',
      render: text => (
        text !== null
          ? <div>{text.replace('T', ' ').split('.')[0]}</div>
          : <div> - </div>),
    },
    /* {
          title: <div className="column-title">{t('end')}</div>,
          dataIndex: 'finishTime',
          key: 'finishTime',
          width: '120px',
          render: text => (
            text !== null
              ? <div>{text.replace('T', ' ').split('.')[0]}</div>
              : <div> - </div>),
        },
        {
          title: <div className="column-title">{t('duration')}</div>,
          dataIndex: 'duration',
          key: 'duration',
          width: '150px',
          render: text => (
            text !== null
              ? <div>{text?.h} {t('shortHours')} {text?.m} {t('shortMinutes')}</div>
              : <div> - </div>),
        },
    }, */
    {
      title: () => <div className="column-title">{t('reason')}</div>,
      dataIndex: 'stopReason',
      key: 'stopReason',
      width: '150px',
      render: text => (
        text !== null
          ? <div>{text}</div>
          : <div> - </div>),
    },
  ])
}

export default Config
