/*eslint-disable*/
import { useEffect, useMemo, useRef, useState } from "react";
import Chart from "chart.js/auto";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const useAnalysisChart = ({
  data,
  period,
  width
}) => {

  const chartContextRef = useRef(null);

  const { t } = useTranslation();

  const defaultDatasets = [
    {
      order: 0,
      label: t("ppl") + " ↓",
    },
    {
      order: 0,
      label: t("ppl") + " ↑",
    },
    {
      order: 0,
      label: t("gno") + " ↓",
    },
    {
      order: 0,
      label: t("gno") + " ↑",
    },
    {
      order: 0,
      label: t("kakspl"),
    },
    {
      order: 0,
      label: t("waterCutShort")
    },
    {
      order: -1,
      type: "line",
      label: t("sum"),
      backgroundColor: "#11AA84",
      borderColor: "#11AA46",
    }
  ];

  const [visibleDatasetsIndexes, setVisibleDatasetsIndexes] =
    useState(defaultDatasets.map((dataset, index) => index));

  const dataSource = useMemo(() => {
    // console.log(data);
    const dataSource = { labels: [], datasets: [] };
    if (data == null) return dataSource;
    if (period == null) return dataSource;

    dataSource.datasets = structuredClone(defaultDatasets);

    for (let datasetIndex = 0;
      datasetIndex < dataSource.datasets.length;
      datasetIndex++) {
      const dataset = dataSource.datasets[datasetIndex];

      if (!dataset.backgroundColor)
        dataset.backgroundColor = DatasetColors[datasetIndex];
      if (!dataset.borderColor)
        dataset.borderColor = DatasetColors[datasetIndex];

      dataset.hidden = !visibleDatasetsIndexes.includes(datasetIndex);
    };

    const paramKeys = [
      "pplDecreased",
      "pplIncreased",
      "gnoDecreased",
      "gnoIncreased",
      "kakspl",
      "obv",
      "sum"
    ];

    const startDate = dayjs(period.startDate);

    for (
      let monthIndex = 0;
      monthIndex < data.overallResultPerMonth.length;
      monthIndex++) {
      const date = startDate.add(monthIndex, "month");
      const monthString = date.format("MM.YYYY");
      dataSource.labels.push(monthString);
    }

    for (let keyIndex = 0; keyIndex < paramKeys.length; keyIndex++) {
      dataSource.datasets[keyIndex].data = data.overallResultPerMonth
        .map(month => month
          .reduce((sum, debitChanges) =>
            sum += debitChanges[paramKeys[keyIndex]], 0));
    }

    return dataSource;
  }, [data, period, visibleDatasetsIndexes, t]);

  const parameters = useMemo(() => {
    const parameters = dataSource.datasets
      .map(dataset => ({
        label: dataset.label,
        backgroundColor: dataset.backgroundColor
      }));

    return parameters;
  }, [dataSource]);

  const config = useMemo(() => ({
    type: "bar",
    data: dataSource,
    options: {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          titleFont: {
            size: 18
          },
          bodyFont: {
            size: 14
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
          ticks: {
            font: {
              size: 14
            }
          },
        },
        y: {
          stacked: true,
          ticks: {
            font: {
              size: 14
            }
          },
        },
      },
      layout: {
        padding: {
          left: 20,
        },
      },
    },
    plugins: [{
      afterDraw: (chart) => {

        const havePositiveData = (datasets) => {
          const visibleDatasets = datasets.filter(d => !d.hidden);
          for (const dataset of visibleDatasets) {
            if (dataset.data.some(value => value > 0)) return true;
          }
          return false;
        }
        const haveNegativeData = (datasets) => {
          const visibleDatasets = datasets.filter(d => !d.hidden);
          for (const dataset of visibleDatasets) {
            if (dataset.data.some(value => value < 0)) return true;
          }
          return false;
        }

        const ctx = chart.ctx;
        ctx.save();
        const yAxis = chart.scales["y"];
        ctx.fillStyle = "rgba(0, 0, 0, 0.6)";
        ctx.textAlign = "center";
        const fontSize = "16";
        ctx.font = fontSize + "px Roboto";
        ctx.rotate(-Math.PI / 2);
        if (havePositiveData(dataSource.datasets)) {
          ctx.fillText(t("oilIncreaseInTons"), -70, fontSize);
        }
        if (haveNegativeData(dataSource.datasets)) {
          ctx.fillText(t("oilDecreaseInTons"), -yAxis.bottom + 40, fontSize);
        }
        ctx.restore();
      }
    }],
  }), [dataSource, t]);

  useEffect(() => {
    if (!chartContextRef?.current) return;

    const chart = new Chart(chartContextRef.current.getContext("2d"), config);

    return () => chart.destroy();
  }, [config]);

  const yAxisWidth = 30;

  const barWidth = (width - yAxisWidth) / 12;
  const chartWidth = dataSource.labels.length ?
    dataSource.labels.length * barWidth - yAxisWidth : 0;

  return {
    chartContextRef,
    chartWidth,
    parameters,
    visibleDatasetsIndexes,
    setVisibleDatasetsIndexes
  };
};

export default useAnalysisChart;

const DatasetColors = [
  '#BF5AC1',
  '#7BC657',
  '#FEB64B',
  '#00CDCD',
  '#8D9DC8',
  '#FF847C',
  '#4BBEFE', // delete
  '#D2D611',
  '#8F6ADC',
  '#796DFF',
  '#FF5B5B',
  '#369AD2',
];