/* eslint-disable */
import { Button, Space, Input } from "antd";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal";
// import { configScales, configTrends } from "./table.config";
import SettingsPanel from "./ParametersTemplateSettings";
import "./styles.css";
import request from "utils/request";
import { VariableSizeGrid as Grid } from "react-window";
import { Field } from "react-final-form";
import Checkbox from "formAdapters/Checkbox";
import Select from "formAdapters/Select";
import { useEffect, useRef, useMemo, useState } from "react";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import Tooltip from "components/Tooltip";
import Draggable from "react-draggable";

const MAX_ALLOWED_TRENDS_COUNT = 10;

const requestAddNewTemplate = async (templateName, f) => {
  const { data } = await request.post(
    `settings/trends/template/add`,
    { name: templateName }
  ); //server returns id of created template
  f(data);
};

const requestUpdateTemplate = async (templateId, changes, f) => {
  await request.post(
    `settings/trends/template/update/${templateId}`,
    changes
  );
  if (f) f();
};

const requestDeleteTemplate = async (templateId, f) => {
  await request.get(`settings/trends/template/delete/${templateId}`);
  if (f) f();
};

const ParametersModal = ({
  visible,
  closeModal,
  onSubmit,
  templatesList,
  currentTemplateData,
  currentTemplateId,
  setCurrentTemplateId,
  setTemplatesList,
  setCurrentTemplateData,
  tableItems
}) => {
  const [lastTop, setLastTop] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y + 48,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  useEffect(() => {
    if (tableItems != null) {
      tableItems.forEach(
        (itemData) =>
        (itemData.name = chooseTranslateFromArray(
          itemData?.alias,
          storageTranslations,
          currentLanguage
        ))
      );

      let translatedItems = tableItems.filter((item) =>
        /[а-яА-ЯЁё]/.test(item.name)
      );

      let notTranslatedItems = tableItems.filter(
        (item) => !/[а-яА-ЯЁё]/.test(item.name)
      );

      translatedItems.sort((a, b) => {
        if (a.name < b.name) return -1;
        else return 1;
      });

      notTranslatedItems.sort((a, b) => {
        if (a.name < b.name) return -1;
        else return 1;
      });

      // setTableData(tableItems);
      // setItemData(tableItems);

      setTableData([...translatedItems, ...notTranslatedItems]);
    }
  }, [tableItems]);

  const handleSettingsSubmit = (values) => {
    if (!currentTemplateId) return closeModal();

    const changedValues = {
      trends: values.trends
        ?.filter((t) => t.active)
        .map((t) => ({
          parameterId: t.id,
          scaleNumber: t.scaleNumber,
          active: t.active,
          isMeander: t.isMeander
        })),
      scales: values.scales?.map((v) => ({
        scaleNumber: v.scaleNumber,
        min: v.min,
        max: v.max,
        auto: v.auto,
      })),
    };

    const trueScaleParameters = [];
    for (let i = 1; i < 5; i++) {
      trueScaleParameters.push(
        values.trends.filter(
          (item) => item.scaleNumber === i && item.active === true
        )
      );
    }

    if (
      trueScaleParameters.some((item) => item.length > MAX_ALLOWED_TRENDS_COUNT)
    ) {
      setAlertModalData(trueScaleParameters);
      setIsModalVisible(true);
    } else {
      requestUpdateTemplate(currentTemplateId, changedValues);
      setCurrentTemplateData((prevData) =>
        Object.assign({}, prevData, changedValues)
      );
      closeModal();
    }

    onSubmit && onSubmit();
  };

  const { i18n } = useTranslation();
  const storageTranslations = JSON.parse(
    sessionStorage.getItem("translations")
  );
  const storageTranslationsUnits = JSON.parse(
    sessionStorage.getItem("translationsUnits")
  );
  const currentLanguage = i18n.language;

  const { t } = useTranslation();

  const chooseTranslateFromArray = (alias, array, lang) => {
    if (alias === null || !array) return 0;
    if (array == null) return 0;
    let id = array?.parameters.findIndex((x) => x.alias === alias);
    id = array?.parameters[id].id;
    const translationIndex =
      array && array[lang].findIndex((x) => x.parameterId === id);

    return translationIndex >= 0
      ? array[lang][translationIndex].translation
      : alias;
  };

  const chooseTranslateFromArrayUnits = (unitId, array, lang) => {
    if (unitId === null) return "";
    if (array == null) return 0;
    const id = array?.units.findIndex((x) => x.id === unitId);
    const translationIndex =
      array && array[lang] && array[lang].findIndex((x) => x.unitId === id + 1);

    return translationIndex >= 0
      ? array && array[lang][translationIndex]?.translation
      : array?.units[id]?.alias;
  };

  const chooseTemplate = (templateId) => {
    setCurrentTemplateId(templateId);
  };

  const addTemplate = (templateName) => {
    requestAddNewTemplate(templateName, (newTemplateId) => {
      setCurrentTemplateId(newTemplateId);
      setCurrentTemplateData();
      setTemplatesList((prev) => {
        return prev.concat([
          {
            id: newTemplateId,
            name: templateName,
            default: false,
          },
        ]);
      });
    });
  };

  const deleteTemplate = (templateId) => {
    requestDeleteTemplate(templateId, () => {
      setCurrentTemplateId(null);
      setTemplatesList((prev) => prev.filter((item) => item.id !== templateId));
    });
  };

  const updateTemplate = async (templateId, changes) => {
    requestUpdateTemplate(templateId, changes, () => {
      const newTemplateData = Object.assign({}, currentTemplateData, changes);

      setCurrentTemplateData(newTemplateData);
      const newTemplatesList = templatesList.slice();
      if (newTemplateData?.name)
        newTemplatesList[
          newTemplatesList.findIndex((i) => i.id === templateId)
        ].name = newTemplateData.name;
      setTemplatesList(newTemplatesList);
    });
  };

  const inactiveAll = () => {
    setUnselectedAll(true);
  };

  const scalesData = currentTemplateData?.scales;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [alertModalData, setAlertModalData] = useState(null);
  const [unselectedAll, setUnselectedAll] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
    setAlertModalData(null);
  };

  const renderCountAlertModalData = () => {
    const result = [];

    if (alertModalData) {
      for (let i = 0; i < alertModalData.length; i++) {
        if (alertModalData[i].length > MAX_ALLOWED_TRENDS_COUNT)
          result.push(
            <div>
              {t("tooMuchTrendsOnScale")} {i + 1}! {t("selected")}{" "}
              {alertModalData[i].length} {t("maxAllowedCount")}{" "}
              {MAX_ALLOWED_TRENDS_COUNT}
            </div>
          );
      }
      return result;
    }
  };

  const renderActiveCheckbox = (index, id) => (
    <Field
      key={index}
      type="checkbox"
      name={`trends[${index}].active`}
      component={Checkbox}
    />
  );

  const renderParamTitle = (text) => <div className="title">{text}</div>;

  const setInactiveAll = () => {
    inactiveAll();
  }

  const isParameterMatchesSearch = (parameter) => {
    const name = chooseTranslateFromArray(
      parameter?.alias,
      storageTranslations,
      currentLanguage
    );

    if (searchValue !== "" && !name
      .toLowerCase()
      .includes(searchValue.toLowerCase())) return false;
    return true;
  }

  const renderCell = useMemo(() => {
    return ({ columnIndex, rowIndex, style, data }) => {
      if (!tableData) return null;
      if (rowIndex > tableData.length) return null;

      const itemData = data[rowIndex];

      const index = itemData.index;

      switch (columnIndex) {
        case 0:
          let itemName = chooseTranslateFromArray(
            itemData?.alias,
            storageTranslations,
            currentLanguage
          );
          itemName += ", ";
          itemName += chooseTranslateFromArrayUnits(
            itemData?.unit,
            storageTranslationsUnits,
            currentLanguage
          );

          return (
            <div
              style={{
                ...style,
                whiteSpace: "nowrap",
                background: rowIndex % 2 === 0 ? "#F5F6FA" : "#FFFFFF",
              }}
            >
              {itemName.length > 43 ? (
                <Tooltip
                  tooltipText={<div className="tooltip-text">{itemName}</div>}
                >
                  {renderParamTitle(itemName.slice(0, 43) + "...")}
                </Tooltip>
              ) : (
                <div> {renderParamTitle(itemName)} </div>
              )}
            </div>
          );
        case 1:
          return (
            <div
              style={{
                ...style,
                textAlign: "right",
                background: rowIndex % 2 === 0 ? "#F5F6FA" : "#FFFFFF",
              }}
            >
              {renderActiveCheckbox(index, itemData.id)}
            </div>
          );
      }
    };
  }, [tableData]);

  const PerfomanceFriendlyTable = useMemo(() => {
    return ({ data }) => {

      const renderColHeader = (text) => <div className="title">{text}</div>;

      useEffect(() => {
        if (unselectedAll) {
          data.forEach((element) => {
            element.active = false;
          });
          setUnselectedAll(false);
        }
      }, [unselectedAll]);

      const filteredParameters = useMemo(() =>
        data
          ?.map((param, index) => ({ ...param, index }))
          .filter(param => isParameterMatchesSearch(param)),
        [data, searchValue]);

      const columnWidths = [350, 250];

      return (
        <div style={{ margin: "0 29px" }}>
          <ul
            style={{
              display: "flex",
              justifyContent: "space-between",
              listStyle: "none",
              width: "100%",
              margin: "15px 0",
              padding: 0,
            }}
          >
            <li>
              <div>{renderColHeader(t("parameter"))}</div>
            </li>
            <li>
              <Tooltip
                placement="topRight"
                title="Видимость параметра на графике"
              >
                <div style={{ textAlign: "right" }}>
                  {renderColHeader(t("active"))}
                </div>
              </Tooltip>
            </li>
          </ul>

          <Grid
            columnCount={2}
            columnWidth={(index) => columnWidths[index]}
            height={450}
            rowCount={filteredParameters?.length}
            rowHeight={(index) => 33}
            width={600}
            style={{ overflowX: "hidden" }}
            itemData={filteredParameters}
            initialScrollTop={lastTop}
            onScroll={(horizontalScrollDirection) => {
              localStorage.setItem(
                "LAST_SCROLL",
                horizontalScrollDirection.scrollTop
              );
            }}
          >
            {renderCell}
          </Grid>

          <Modal
            visible={isModalVisible}
            onCancel={handleCancel}
            titleText={
              <div>
                <ExclamationCircleTwoTone twoToneColor="#e06d14" /> {t("warning")}
              </div>
            }
            className="trends-modal"
            width="auto"
            footer={null}
          >
            <div className="trends-count-container">
              {renderCountAlertModalData()}
            </div>
          </Modal>
        </div>
      );
    };
  }, [
    unselectedAll,
    isModalVisible,
    renderCell,
    alertModalData,
    searchValue
  ]);

  const formInitialValues = useMemo(() => ({
    trends: tableData,
    scales: scalesData
      ?.sort((a, b) => a.scaleNumber - b.scaleNumber)
  }), [tableData, scalesData]);

  return (
    <Form
      onSubmit={handleSettingsSubmit}
      initialValues={formInitialValues}
      render={({ handleSubmit, values }) => (
        <Modal
          title={
            <div
              style={{
                width: '100%',
                cursor: 'grab',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
            >
              {t("parameterDisplaySettings")}
            </div>
          }
          destroyOnClose={true}
          visible={visible}
          onCancel={() => closeModal()}
          className="trends-modal"
          width="auto"
          footer={
            <Button key="submit" type="primary" onClick={handleSubmit}>
              {t("apply")}
            </Button>
          }
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
              nodeRef={draggleRef}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
        >
          <SettingsPanel
            templates={templatesList}
            activeTemplateId={currentTemplateId}
            chooseTemplate={chooseTemplate}
            addTemplate={addTemplate}
            updateTemplate={updateTemplate}
            deleteTemplate={deleteTemplate}
          />
          <div
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "space-between",
              padding: "0px 25px"
            }}
          >
            <Input
              placeholder={"Название параметра"}
              addonBefore={"Поиск параметров"}
              value={searchValue}
              allowClear
              onChange={(e) => setSearchValue(e.target.value)}
              style={{ flex: "1" }}
            />

            <Button onClick={setInactiveAll}>{t("Снять выделение")}</Button>
          </div>
          <PerfomanceFriendlyTable
            data={values.trends}
          />
        </Modal>
      )}
    />
  );
};

export default ParametersModal;
