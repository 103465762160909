import { makeAutoObservable } from 'mobx'

class Home {
  draggableMode = true

  wellsRangingSettings = {
    order: 'ASCENDING',
    sortBy: 'NAME',
    sortByAnalysisColor: 'NONE',
    filterByWellStatus: 'NONE',
  }

  constructor() {
    makeAutoObservable(this)

    const savedSettings = JSON.parse(localStorage.getItem('RANGING_DATA'))

    if (typeof savedSettings === 'object') this.wellsRangingSettings = { ...this.wellsRangingSettings, ...savedSettings }
  }

  setDraggableMode(mode) {
    this.draggableMode = mode
  }

  setWellsRangingSettings(wellsRangingSettings) {
    this.wellsRangingSettings = wellsRangingSettings
    localStorage.setItem('RANGING_DATA', JSON.stringify(wellsRangingSettings))
  }
}

export default Home
