/* eslint-disable */
import RealtimeConnection from "containers/Home/realtimeSocket";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { defaultStyles, toPlainStyles } from "./components/EditStyleModal/common";
import request from "utils/request";

class TableListStore {

  tables = [];

  templateId = undefined;
  availableParameters = [];

  socket = undefined;

  wellId = undefined;

  constructor(templateId, availableParameters, wellId) {
    this.templateId = templateId;
    this.availableParameters = availableParameters;
    this.wellId = wellId;

    makeAutoObservable(this, {
      fetchAvailableParameters: false,
      socket: false
    });

    const userId = 1;
    this.socket = new RealtimeConnection(
      "/ws/realtimeData",
      userId);
  }

  get sortedTables() {
    return this.tables
      .slice()
      .sort((a, b) => a.name > b.name);
  }

  setTables(tables) {
    this.tables = tables;
  }

  onRealtime(point) {
    for (const table of this.tables) {
      for (const parameter of table.parameters) {
        if (parameter.alias === point.id) {
          if (parameter.value !== Number(point.value)) {
            parameter.value = Number(point.value);
          }
        }
      }
    }
  }

  subscribeRealtime() {
    const parameterAliases = new Set();
    for (const table of this.tables) {
      for (const parameter of table.parameters) {
        parameterAliases.add(parameter.alias);
      }
    }

    this.socket
      .start({
        wellId: this.wellId,
      })
      .then(() => {
        this.socket.listen(Array.from(parameterAliases));
        this.socket.subscribe(
          Array.from(parameterAliases),
          (point) => this.onRealtime(point));
      });
  }

  unsubscribeRealtime() {
    this.socket?.end();
  }

  resubscribeRealtime() {
    this.unsubscribeRealtime();
    this.subscribeRealtime();
  }

  async editTableParameters(tableId, parameters) {
    const table = this.tables.find(table => table.id === tableId);
    if (!table) return;

    const parameterIds = parameters
      .map(parameter => parameter.id);

    await request.put(`mnemoTables/relation/parameters`, {
      tableId,
      parameterIds
    });

    runInAction(() => {
      table.parameters = [...parameters];
    });

    this.resubscribeRealtime();
  }

  async editTableStyles(tableId, styles) {
    const table = this.tables.find(table => table.id === tableId);
    if (!table) return;

    const plainStyles = toPlainStyles(styles);

    await request.put(
      `mnemoTables/${tableId}/styles`,
      plainStyles);

    runInAction(() => {
      table.styles = structuredClone(styles);
    });
  }

  async createTable() {

    let name = "Новая таблица";
    let n = 1;
    while (this.tables
      .find((table) => table.name
        .includes(name + " " + n.toString()))) {
      n += 1;
    }
    name = `${name} ${n}`;

    const styles = structuredClone(defaultStyles);

    const { data: id } = await request.post(`/mnemoTables`, {
      templateId: this.templateId,
      name,
      styles: toPlainStyles(styles)
    });

    const table = {
      id,
      name,
      parameters: [],
      position: { x: 0, y: 0 },
      styles
    }

    this.tables.push(table);
  }

  async deleteTable(tableId) {
    await request.delete(`/mnemoTables/${tableId}`);

    this.tables = this.tables.filter(table => table.id !== tableId);
    this.resubscribeRealtime();
  }

  async renameTable(tableId, name) {
    const table = this.tables.find(table => table.id === tableId);
    if (!table) return;

    await request.put("/mnemoTables/name", {
      id: table.id,
      name
    });

    table.name = name;
  }

  async setPosition(tableId, position) {
    const table = this.tables.find(table => table.id === tableId);
    if (!table) return;

    table.position = { ...position };

    await request.put("/mnemoTables/position", {
      id: table.id,
      positionX: position.x,
      positionY: position.y
    });
  }

  getEditParametersTableData(tableId) {
    const table = this.tables.find(table => table.id === tableId);
    if (!table) return [];

    const activeTableParameterIds = table.parameters.map(p => p.id);

    const parameters = toJS(this.availableParameters);
    for (const param of parameters) {
      param.active = activeTableParameterIds.includes(param.id);
    }

    return parameters;
  }
}

export default TableListStore;
