/* eslint-disable */
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { Table, Typography } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
  ReloadOutlined
} from "@ant-design/icons";
import { useResizeDetector } from "react-resize-detector";
import { useStore } from "hooks/useStore";
import AddGroupModal from "./components/AddGroupModal";
import EditGroupModal from "./components/EditGroupModal";
import DeleteGroupModal from "./components/DeleteGroupModal";
import Tooltip from "components/Tooltip";
import { useTranslation } from "react-i18next";
import "./styles.css";

const GroupsSettings = observer(() => {

  const { t } = useTranslation();

  const { adminGroupStore } = useStore();

  const [currentDeletableGroup, setCurrentDeletableGroup] = useState(null);

  const tableDataSource = adminGroupStore.tableDataSource;

  const tableColumns = [
    {
      title: () => (
        <div style={{ fontFamily: "Roboto Medium" }}>{t("groupName")}</div>
      ),
      dataIndex: "name",
    },
    {
      title: (
        <ReloadOutlined
          style={{ display: "block", width: "max-content", marginLeft: "auto" }}
          onClick={() => adminGroupStore.fetchGroups()}
        />
      ),
      width: "100px",
      render: (_nothing, record) => (
        <div className="admin-groups-settings-operations-column">
          <Typography.Link onClick={() => adminGroupStore.openAddGroupModal(record.id)}>
            <PlusOutlined />
          </Typography.Link>

          {record.editable ?
            <Typography.Link onClick={() => adminGroupStore.setCurrentEditableGroup(record)}>
              <EditOutlined />
            </Typography.Link>
            :
            <Typography.Link>
              <Tooltip title={t("accessDenied")} placement="left">
                <EditOutlined style={{ color: "gray" }} />
              </Tooltip>
            </Typography.Link>
          }

          {record.deletable ?
            <Typography.Link onClick={() => handleDeleteGroup(record.id)}>
              <DeleteOutlined />
            </Typography.Link>
            :
            <Typography.Link>
              <Tooltip title={t("accessDenied")} placement="left">
                <DeleteOutlined style={{ color: "gray" }} />
              </Tooltip>
            </Typography.Link>
          }
        </div>
      )
    }
  ];

  const handleDeleteGroup = (id) => {
    const group = adminGroupStore.groups.find(id);
    if (!group) return;

    setCurrentDeletableGroup(toJS(group));
  }

  const { height: tableContainerHeight, ref: tableContainerRef } = useResizeDetector();

  const tableHeaderHeight = 55;

  return (
    <div className="admin-groups-settings">
      <AddGroupModal
        visible={adminGroupStore.isAddGroupModalVisible}
        onSubmit={(event) => adminGroupStore.addGroup(event)}
        onCancel={() => adminGroupStore.closeAddGroupModal()}
        fields={adminGroupStore.addGroupFields}
        parentId={adminGroupStore.addedGroupParentId}
      />

      <EditGroupModal
        visible={adminGroupStore.isEditGroupModalVisible}
        onSubmit={(event) => adminGroupStore.editGroup(event)}
        onCancel={() => adminGroupStore.closeEditGroupModal()}
        fields={adminGroupStore.editGroupFields}
        group={adminGroupStore.currentEditableGroup}
      />

      <DeleteGroupModal
        visible={currentDeletableGroup !== null}
        onConfirm={(type) => adminGroupStore.deleteGroup(currentDeletableGroup.id, type)}
        onCancel={() => setCurrentDeletableGroup(null)}
        group={currentDeletableGroup}
      />

      <div ref={tableContainerRef} className="admin-groups-settings-table">
        <Table
          scroll={{ y: `${tableContainerHeight - tableHeaderHeight}px` }}
          bordered
          dataSource={toJS(tableDataSource)}
          columns={tableColumns}
          pagination={false}
          rowKey={"id"}
          expandable={{
            indentSize: 30,
            expandIcon: (props) =>
              props.record.children?.length > 0 ? (
                props.expanded ?
                  <a onClick={(event) => props.onExpand(props.record, event)}>
                    <MinusSquareOutlined style={{ fontSize: "16px", padding: "3px" }} />
                  </a>
                  :
                  <a onClick={(event) => props.onExpand(props.record, event)}>
                    <PlusSquareOutlined style={{ fontSize: "16px", padding: "3px" }} />
                  </a>
              ) : <div style={{ display: "inline-block", width: "22px" }}></div>
          }}
        />
      </div>
    </div>
  );
});

export default GroupsSettings;
