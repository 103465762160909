/* eslint-disable */
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Form } from "react-final-form";
import { useStore } from "hooks/useStore";
import useDraggableModal from "hooks/useDraggableModal";
import Modal from "components/Modal";
import Button from "components/Button";
import WellList from "./components/WellList";
import { useTranslation } from "react-i18next";

export const WellTreePickerForm = observer(({
  pickedWellIds,
  handleSubmit,
}) => {

  const { wellTreePickerStore } = useStore();

  useEffect(() => {
    if (wellTreePickerStore.locationsTreesFetcher.initial) {
      wellTreePickerStore.locationsTreesFetcher();
    }
  }, []);

  return (
    <Form
      initialValues={{ pickedWellIds }}
      onSubmit={handleSubmit}
      render={({ handleSubmit, values, form }) => (
        <WellList
          pickedWellIds={values.pickedWellIds}
          form={form}
          handleSubmit={handleSubmit}
        />
      )}
    />
  );
});

export const WellTreePickerModal = observer(({
  pickedWellIds,
  handleSubmit,
  visible,
  close,
}) => {

  const { t } = useTranslation();

  const { wellTreePickerStore } = useStore();

  const {
    disabled,
    setDisabled,
    modalRender,
  } = useDraggableModal();

  useEffect(() => {
    if (wellTreePickerStore.locationsTreesFetcher.initial) {
      wellTreePickerStore.locationsTreesFetcher();
    }
  }, []);

  return (
    <Form
      initialValues={{ pickedWellIds }}
      onSubmit={handleSubmit}
      render={({ handleSubmit, values, form }) => (
        <Modal
          bodyStyle={{
            maxHeight: "calc(100vh - 220px)",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column"
          }}
          visible={visible}
          title={
            <div
              style={{
                width: "100%",
                cursor: "grab",
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
            >
              {t("chooseWells")}
            </div>
          }
          className="add-priority-modal"
          onCancel={() => { form.reset(); close(); }}
          submitButton={(
            <Button
              text={t("apply")}
              onClick={(...args) => { handleSubmit(...args); close(); }}
            />
          )}
          modalRender={modalRender}
        >
          <WellList
            pickedWellIds={values.pickedWellIds}
            form={form}
          />
        </Modal>
      )}
    />
  );
});
