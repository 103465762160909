/* eslint-disable */
import { Button, Select, Table, notification } from "antd";
import { Observer, observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { useResizeDetector } from "react-resize-detector";
import { useStore } from "hooks/useStore";
import Tooltip from "components/Tooltip";
import { getInheritedRoleId } from "./common";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { task } from "mobx-fetch-task";
import "./styles.css";

const LocationGroupsAccessLevelSettings = observer(() => {

  const { t } = useTranslation();

  const { adminGroupStore, adminLocationsGroupAccessStore } = useStore();

  const selectedGroupId = adminLocationsGroupAccessStore.selectedGroupId;

  const groupSelectOptions = adminLocationsGroupAccessStore.groupSelectOptions;

  const tableDataSource = adminLocationsGroupAccessStore.selectedGroupTableDataSource;

  const changes = adminLocationsGroupAccessStore.changes;
  const hasChanges = adminLocationsGroupAccessStore.hasChanges;

  const roles = adminLocationsGroupAccessStore.roles;

  const roleOptions = roles.map(role => ({
    value: role.id, label: role.name
  }));

  // role.value = 1 is ADMIN
  const wellRoleOptions = roleOptions.filter(role => role.value !== 1);

  const tableColumns = [
    {
      title: t("locationName"),
      dataIndex: "name",
      key: "name"
    },
    {
      title: t("accessLevel"),
      dataIndex: "roleId",
      key: "roleId",
      width: "200px",
      render: (roleId, record) => {
        return (
          <Observer>
            {() => {
              const inheritedRoleId = getInheritedRoleId(
                record,
                (id) => adminLocationsGroupAccessStore.findLocation(id),
                toJS(changes)
              );

              const placeholder = inheritedRoleId ?
                roles.find(role => role.id === inheritedRoleId)?.name ?? null
                : t("selectAccessLevel");

              const options = record.well ? toJS(wellRoleOptions) : toJS(roleOptions);

              const change = changes.find(change =>
                change.locationId === record.id
              );

              const value = change ?
                change.roleId
                :
                roles.find(role => role.id === roleId)?.id ?? null;

              const isWell = record.well;

              return (
                <Select
                  className="admin-location-group-access-select"
                  showSearch
                  allowClear
                  placeholder={placeholder}
                  onChange={(value) => setAccessLevel(record.id, selectedGroupId, value, isWell)}
                  value={value}
                  options={options}
                />
              )
            }}
          </Observer>
        );
      }
    }
  ];

  const setAccessLevel = (locationId, groupId, roleId, isWell) => {
    const change = {
      locationId,
      groupId,
      roleId,
      isWell
    }

    adminLocationsGroupAccessStore.addChange(change);
  }

  const renderGroupSelect = () => (
    hasChanges ?
      <Tooltip
        title={t("youShouldCancelOrSaveChanges")}
        placement="right"
      >
        <Select
          className="admin-location-group-access-select-group"
          showSearch
          allowClear
          filterOption={(inputValue, option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
          }
          options={groupSelectOptions}
          value={selectedGroupId}
          onChange={(id) => adminLocationsGroupAccessStore.setSelectedGroupId(id)}
          placeholder={t("selectGroup")}
          disabled={true}
        />
      </Tooltip>
      :
      <Select
        className="admin-location-group-access-select-group"
        showSearch
        allowClear
        filterOption={(inputValue, option) =>
          option.label.toLowerCase().includes(inputValue.toLowerCase())
        }
        options={groupSelectOptions}
        value={selectedGroupId}
        onChange={(id) => adminLocationsGroupAccessStore.setSelectedGroupId(id)}
        placeholder={t("selectGroup")}
        disabled={false}
      />
  );

  const renderParentIsNotSetUp = (selectedGroupId) => {
    const selectedGroup = adminGroupStore.list.find(group =>
      group.id === selectedGroupId);
    if (!selectedGroup) return null;

    const parent = adminGroupStore.list.find(group =>
      group.id === selectedGroup.parentId);
    if (!parent) return null;

    return (
      <div
        style={{
          padding: "20px",
          textAlign: "center"
        }}
      >
        {t("youHaveToSetUpParentGroup")} "{parent.name}"
      </div>
    );
  }

  const onSaveError = () => {
    notification.error({
      message: t("errorHasOccured"),
      top: 80
    });
  }

  const [save] = useState(() => task(async () =>
    await adminLocationsGroupAccessStore.saveChanges(),
    { throw: true }
  ));

  const hasAnyLocationChildrenRole = (location) => {
    if (location.roleId != null) return true;
    if (location.children == null || location.children.length === 0) return false;
    for (const child of location.children) {
      if (hasAnyLocationChildrenRole(child)) return true;
    }

    return false;
  }

  const { height: tableContainerHeight, ref: tableContainerRef } = useResizeDetector();

  const tableHeaderHeight = 55;

  return (
    <div className="admin-location-group-access" >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "0px 20px"
        }}
      >
        {renderGroupSelect()}

        <div className="admin-location-group-access-buttons">
          <Button
            type="primary"
            onClick={() => adminLocationsGroupAccessStore.cancelChanges()}
            disabled={!hasChanges || save.pending}
          >
            {t("cancel")}
          </Button>

          <Button
            type="primary"
            onClick={() => save().catch(err => onSaveError())}
            loading={save.pending}
            disabled={!hasChanges || save.pending}
          >
            {t("save")}
          </Button>
        </div>
      </div>
      {adminLocationsGroupAccessStore.fetchLocationTrees.rejected &&
        adminLocationsGroupAccessStore.fetchLocationTrees
          .error?.response?.data === "parent is not set up" &&
        renderParentIsNotSetUp(adminLocationsGroupAccessStore.selectedGroupId)
      }
      {
        (adminLocationsGroupAccessStore.fetchLocationTrees.pending ||
          adminLocationsGroupAccessStore.fetchLocationTrees.resolved) &&
        <div ref={tableContainerRef} className="admin-locations-groups-access-table">
          <Table
            scroll={{ y: `${tableContainerHeight - tableHeaderHeight}px` }}
            loading={
              adminLocationsGroupAccessStore.fetchLocationTrees.pending &&
              adminLocationsGroupAccessStore.selectedGroupId != null
            }
            dataSource={tableDataSource}
            columns={tableColumns}
            pagination={false}
            bordered
            rowKey={"id"}
            expandable={{
              indentSize: 30,
              expandIcon: (props) =>
                props.record.children?.length > 0 ? (
                  props.expanded ?
                    <a onClick={(event) => props.onExpand(props.record, event)}>
                      <MinusSquareOutlined style={{ fontSize: "16px", padding: "3px" }} />
                    </a>
                    :
                    <a onClick={(event) => props.onExpand(props.record, event)}>
                      <PlusSquareOutlined style={{ fontSize: "16px", padding: "3px" }} />
                    </a>
                ) : <div style={{ display: "inline-block", width: "22px" }}></div>
            }}
            onRow={(data, index) => ({
              style: {
                color: hasAnyLocationChildrenRole(data) ? "#4B7EFE" : null,
              }
            })}
          />
        </div>
      }
    </div >
  );
});

export default LocationGroupsAccessLevelSettings;
