/* eslint-disable */
import { useState, useEffect } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import { observer } from "mobx-react-lite";
import debounce from "lodash/debounce";
import round from "utils/round";
import request from "utils/request";
import VerticalResizeHandle from "components/VerticalResizeHandle";
import WellTree from "./components/WellTree";
import SupportingInformation from "./components/SupportingInformation";
import Alarms from "./components/Alarms";
import Trends from "./components/Trends";
import Mnemoscheme from "./components/Mnemoscheme";
import "./styles.css";

const ReactGridLayout = WidthProvider(RGL);

const Home = observer(() => {
  const resizeElements = (changedLayouts) => {
    const deltaHeight0 = layouts[0].h - changedLayouts[0].h;

    const deltaHeight1 = layouts[1].h - changedLayouts[1].h;

    changedLayouts[0].h += deltaHeight1;
    changedLayouts[1].h += deltaHeight0;

    setLayouts(changedLayouts);
  };

  const [trendFullScreenMode, setTrendFullScreenMode] = useState(false);
  const [minimizedMode, setMinimizedMode] = useState(false);
  const [mnemoMode, setMnemoMode] = useState(false);
  const [translations, setTranslations] = useState()
  const [layouts, setLayouts] = useState([
    {
      x: 0,
      y: 0,
      w: 1,
      h: 38,
      i: "indicators",
      minH: 16,
      maxH: 38,
      isResizable: true,
      resizeHandles: ["se"],
    },
    {
      x: 0,
      y: 1,
      w: 1,
      h: 10,
      minH: 10,
      maxH: 38,
      i: "notifications",
      isResizable: false,
      resizeHandles: [],
    },
  ]);

  const fetchTranslations = async () => {
    const { data } = await request.get("/translations/dynamicRead");

    sessionStorage.setItem(
      "translations",
      JSON.stringify(data?.parametersData)
    );
    setTranslations(data?.parametersData)
    sessionStorage.setItem(
      "translationsUnits",
      JSON.stringify(data?.unitsData)
    );
    sessionStorage.setItem(
      "translationsActions",
      JSON.stringify(data?.actionsData)
    );
    sessionStorage.setItem("translationsWorks", JSON.stringify(data?.workData));
    sessionStorage.setItem(
      "translationsWorkReasons",
      JSON.stringify(data?.workReasonData)
    );
  };

  useEffect(() => {
    onResizeListener();
    window.addEventListener("resize", onResizeListener);
    fetchTranslations();

    return () => window.removeEventListener("resize", onResizeListener);
  }, []);

  const onResizeListener = debounce((e) => {
    const height = e?.target?.innerHeight || window.innerHeight;

    setLayouts((prevLayouts) =>
      prevLayouts.map((i) => ({
        ...i,
        h:
          (round((height - 48) * (i.i === "indicators" ? 0.68 : 0.32), 1) +
            10) /
          20,
      }))
    );
  }, 300);

  return (
    <div className="dashboard-container">
      <WellTree
        fullScreenMode={trendFullScreenMode}
        onMinimizedClick={() => setMinimizedMode(!minimizedMode)}
        minimizedMode={minimizedMode}
      />
      {
        mnemoMode ?
          <Mnemoscheme />
          :
          <ReactGridLayout
            className="dashboard-grid-layout"
            cols={1}
            margin={[0, 10]}
            layout={layouts}
            rowHeight={10}
            isDraggable={false} // homeStore.draggableMode
            onLayoutChange={(layout) => resizeElements(layout)}
            resizeHandle={<span><VerticalResizeHandle /></span>}
          >
            <div
              key="indicators"
              className={trendFullScreenMode && "full-screen"}
            >
              <Trends
                minimizedMode={minimizedMode}
                fullScreenMode={trendFullScreenMode}
                onFullScreenClick={() =>
                  setTrendFullScreenMode(!trendFullScreenMode)
                }
                translations={translations}
              />
            </div>
            <div key="notifications" style={{ overflow: "hidden" }}>
              <Alarms />
            </div>
          </ReactGridLayout>
      }
      <div className="right" style={{ marginLeft: "auto" }}>
        <SupportingInformation
          setMnemoMode={setMnemoMode}
          isMnemoMode={mnemoMode}
          menuLocationsMinimizedMode={minimizedMode}
        />
      </div>
    </div>
  );
});

export default Home;
