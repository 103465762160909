/* eslint-disable */
import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { LoadingOutlined } from "@ant-design/icons";
import { useStore } from "hooks/useStore";
import EditProfileModal from "./components/EditProfileModal";
import CustomTooltip from "components/Tooltip";
import Menu from "./components/Menu";
import { ReactComponent as UserIcon } from "assets/images/userIcon.svg";
import CustomInput from "formAdapters/Input";
import CustomSelect from "formAdapters/Select";
import request from "utils/request";
import { required } from "utils/validate";
import "./styles.css";

const Profile = observer(() => {

  const { userStore } = useStore();

  const userName = (userStore.login && userStore.surname && userStore.name && userStore.patronymic) ?
    `${userStore.login} (${userStore.surname} ${userStore.name?.slice(0, 1)}. ${userStore.patronymic?.slice(0, 1)}.)`
    : <LoadingOutlined />;

  const [isOpenProfileModal, setIsOpenProfileModal]
    = useState(false);

  const [languages, setLanguages] = useState(null);

  const [userData, setUserData] = useState(null);

  const handleOpenProfileModal = () => {
    setIsOpenProfileModal(true);
  }

  const handleCloseProfileModal = () => {
    setIsOpenProfileModal(false);
  }

  const handleSubmitProfile = async (values) => {
    await editUserData(values);

    userStore.fetchUserInfo();
    userStore.fetchIsAdmin();
  }

  const fetchLanguages = async () => {
    try {
      const { data } = await request
        .get("/translations/languagesList");

      const languages = data
        .map(lang => ({
          id: lang.id,
          name: lang.language
        }));

      setLanguages(languages);

    } catch (error) {
      console.error(error);
    }
  }

  const fetchUserData = async () => {
    try {
      const { data: userData } = await request
        .get("/users/personalInformation");

      setUserData(userData);

    } catch (error) {
      console.error(error);
    }
  }

  const editUserData = async (data) => {
    try {
      setUserData(data);

      await new Promise(res => setTimeout(res, 1000));
      await request.put("/users/personalInformation", data);

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchLanguages();
    fetchUserData();
  }, []);

  const fields = useMemo(
    () => [
      {
        label: "surname",
        name: "surname",
        component: CustomInput,
        validate: required
      },
      {
        label: "name",
        name: "name",
        component: CustomInput,
        validate: required
      },
      {
        label: "patronymic",
        name: "patronymic",
        component: CustomInput,
        validate: required
      },
      {
        label: "email",
        name: "email",
        component: CustomInput,
        validate: required
      },
      {
        label: "phoneNumber",
        name: "phoneNumber",
        component: CustomInput,
        validate: required
      },
      {
        label: "defaultLanguage",
        name: "defaultLanguageId",
        component: CustomSelect,
        allowClear: true,
        options: languages,
        validate: required
      }
    ],
    [languages]);

  return (
    <>
      <CustomTooltip
        placement="left"
        zIndex={1000000000001}
        title="Профиль пользователя"
      >
        <div
          className="logout-container"
          onMouseEnter={() =>
            document
              .querySelector(".logout-menu-wrapper")
              .classList.toggle("open")
          }
          onMouseLeave={() =>
            document
              .querySelector(".logout-menu-wrapper")
              .classList.toggle("open")
          }
        >
          <div style={{ display: "flex" }}>
            <UserIcon />
            <div style={{ paddingTop: "5px" }}>{userName}</div>
          </div>
          <Menu onClickProfile={handleOpenProfileModal} />
        </div>
      </CustomTooltip>

      <EditProfileModal
        visible={isOpenProfileModal}
        onSubmit={handleSubmitProfile}
        onCancel={handleCloseProfileModal}
        data={userData}
        fields={fields}
        loaded={userData != null && languages != null}
      />
    </>
  );
});

export default Profile;
