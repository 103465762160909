/*eslint-disable*/
import { useState } from "react";
import DeviationAnalysisLayout from "layouts/DeviationAnalysisLayout"
import DeviationAnalysis from "containers/DeviationAnalysis"

const DEVIATION_ANALYSIS_ACTIVE_TAB = "DEVIATION_ANALYSIS_ACTIVE_TAB";

const getTabFromLS = () => {
  const tab = localStorage.getItem(DEVIATION_ANALYSIS_ACTIVE_TAB) ?? null;
  if (tab === null) return null;
  return JSON.parse(tab);
}

const DeviationAnalysisPage = () => {
  const [activeTab, setActiveTab] = useState(getTabFromLS() ?? {
    name: "deviation", // deviation | activeAlarms | archiveAlarms
    subTabKey: "1"
  });

  const deviationTabs = [
    {
      key: "1",
      label: "Общие"
    },
    {
      key: "2",
      label: "По скважинам"
    }
  ];

  const handleChangeActiveTab = (tab) => {
    setActiveTab(tab);
    saveTabToLS(tab);
  }

  const saveTabToLS = (tab) => {
    localStorage.setItem(DEVIATION_ANALYSIS_ACTIVE_TAB, JSON.stringify(tab))
  }

  return (
    <DeviationAnalysisLayout
      activeTab={activeTab}
      handleChangeActiveTab={handleChangeActiveTab}
      deviationTabs={deviationTabs}
    >
      <DeviationAnalysis
        activeTab={activeTab}
      />
    </DeviationAnalysisLayout>
  )
}

export default DeviationAnalysisPage
