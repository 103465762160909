/* eslint-disable */
import { Translation } from "react-i18next";
import { ReactComponent as AlertIcon } from "assets/images/exclamationMarkIcon.svg";
import Tooltip from "components/Tooltip";

export const columns = (
  itemData,
  analysisTypes,
  alarmsCountData,
  changeModalVisible,
  changeTrendAnalysisModalVisible
) => {
  const defaultColumns = [
    {
      title: () => <div></div>,
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <div className="well-number-container">
          <div className="selected-flag" />
          <span style={{ minHeight: "1.2em" }} className="well-number"></span>
          {record?.wellReason && <AlertIcon className="setted-reason" />}
        </div>
      ),
    },
    {
      title: () => <div></div>,
      dataIndex: "wellStatus",
      key: "wellStatus",
      render: (text, record) => (
        <div className="well-status-container">
        <Tooltip placement="topLeft" arrowPointAtCenter title="ML модель ВР">
          <div className={record?.vrMlModelStatus ? "blue-circle" : ""} />
        </Tooltip>
        <Tooltip placement="topLeft" arrowPointAtCenter title="Физ. модель ВР">
          <div className={record?.vrPhysModelStatus ? "brown-circle" : ""} />
        </Tooltip>
      </div>
      ),
    },
    {
      title: () => (
        <Translation>
          {(t) => (
            <Tooltip placement="top" title="Название скважины">
              <div className="well-tree-table-title name">{t("well")}</div>
            </Tooltip>
          )}
        </Translation>
      ),
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <div className="well-name-container">
            {/* <Tooltip placement="topRight" arrowPointAtCenter title="ML модель ВР">
              <div className={record?.vrMlModel ? "blue-circle" : ""} />
            </Tooltip>
            <Tooltip arrowPointAtCenter placement="top" title="Физ. модель ВР">
              <div className={record?.vrPhysModel ? "brown-circle" : ""} />
            </Tooltip> */}
            <span className="well-text">{text}</span>
          </div>
        )
        // return record.isSelected ? (
        //   <div className="well-name-container">
        //     {/* <div className="light-green" /> */}
        //     <div className="blue-circle" />
        //     <div className="brown-circle" />
        //     <span className="well-text">{text}</span>
        //   </div>
        // ) : (
        //   <div className="well-name-container">
        //     <div className="light-green" />
        //     <span className="well-text">{text}</span>
        //   </div>
        // );
      },
    },
  ];

  analysisTypes?.qj &&
    defaultColumns.push({
      title: () => (
        <Translation>
          {(t) => (
            <Tooltip placement="top" title="Дебит жидкости (м3/сут)">
              <div className="well-tree-table-title info">{t("qj")}</div>
            </Tooltip>
          )}
        </Translation>
      ),
      dataIndex: "qj",
      key: "qj",
      render: (text) => <span className="well-text">{text}</span>,
    });
  analysisTypes?.qn &&
    defaultColumns.push({
      title: () => (
        <Translation>
          {(t) => (
            <Tooltip placement="top" title="Дебит нефти (т/сут)">
              <div className="well-tree-table-title info">{t("qn")}</div>
            </Tooltip>
          )}
        </Translation>
      ),
      dataIndex: "qn",
      key: "qn",
      // width: "25px",
      render: (text) => <span className="well-text">{text}</span>,
    });
  analysisTypes?.obv &&
    defaultColumns.push({
      title: () => (
        <Translation>
          {(t) => (
            <Tooltip placement="top" title="Обводненность (%)">
              <div className="well-tree-table-title info">
                {t("waterCutShort")}
              </div>
            </Tooltip>
          )}
        </Translation>
      ),
      dataIndex: "obv",
      key: "obv",
      render: (text) => <span className="well-text">{text}</span>,
    });
  analysisTypes?.instant &&
    defaultColumns.push({
      title: () => (
        <Translation>
          {(t) => (
            <Tooltip placement="top"title="Предупреждения по анализу отклонений">
              <div
                className="well-tree-table-title info"
                // style={{ cursor: "pointer" }}
                // onClick={changeModalVisible}
              >
                {t("instantaneousShort")}
              </div>
            </Tooltip>
          )}
        </Translation>
      ),
      dataIndex: "instant",
      key: "instant",
      render: (text, record) => {
        if (alarmsCountData) {
          const id = alarmsCountData.findIndex((x) => x.wellId === record.id);

          record.alarmCount = alarmsCountData[id];

          if (!record?.alarmCount) return <div className="green-circle" />;

          return record?.alarmCount?.instant === 0 ? (
            <div className="green-circle" />
          ) : record?.alarmCount?.instantWithActions === 0 ? (
            <div className="red-circle">
              {record?.alarmCount?.instant <= 99
                ? alarmsCountData?.instant > 0
                  ? alarmsCountData?.instant
                  : record?.alarmCount?.instant
                : "99+"}
            </div>
          ) : record?.alarmCount?.instant ===
            record?.alarmCount?.instantWithActions ? (
            <div className="yellow-circle">
              {record?.alarmCount?.instant <= 99
                ? alarmsCountData?.instant > 0
                  ? alarmsCountData?.instant
                  : record?.alarmCount?.instant
                : "99+"}
            </div>
          ) : (
            <div className="red-yellow-circle">
              {record?.alarmCount?.instant <= 99
                ? alarmsCountData?.instant > 0
                  ? alarmsCountData?.instant
                  : record?.alarmCount?.instant
                : "99+"}
            </div>
          );
        }
      },
    });
/*
  analysisTypes?.trending &&
    defaultColumns.push({
      title: () => (
        <Translation>
          {(t) => (
            <Tooltip title="Предупреждения по трендовому анализу">
              <div
                className="well-tree-table-title params"
                style={{ cursor: "pointer" }}
                onClick={changeTrendAnalysisModalVisible}
              >
                {t("trendingShort")}
              </div>
            </Tooltip>
          )}
        </Translation>
      ),
      dataIndex: "trending",
      key: "trending",
      render: (text, record) => {
        if (alarmsCountData) {
          const id = alarmsCountData.findIndex((x) => x.wellId === record.id);

          record.alarmCount = alarmsCountData[id];

          if (!record?.alarmCount) return <div className="green-circle" />;

          return record?.alarmCount?.trending === 0 ||
            !record?.alarmCount?.trending ||
            !record ? (
            <div className="green-circle" />
          ) : record?.alarmCount?.trendingWithActions === 0 ? (
            <div className="red-circle">
              {record?.alarmCount?.trending <= 99
                ? alarmsCountData?.trending > 0
                  ? alarmsCountData?.trending
                  : record?.alarmCount?.trending
                : "99+"}
            </div>
          ) : record?.alarmCount?.trending ===
            record?.alarmCount?.trendingWithActions ? (
            <div className="yellow-circle">
              {record?.alarmCount?.trending <= 99
                ? alarmsCountData?.trending > 0
                  ? alarmsCountData?.trending
                  : record?.alarmCount?.trending
                : "99+"}
            </div>
          ) : (
            <div className="red-yellow-circle">
              {record?.alarmCount?.trending <= 99
                ? alarmsCountData?.trending > 0
                  ? alarmsCountData?.trending
                  : record?.alarmCount?.trending
                : "99+"}
            </div>
          );
        }
      },
    });
  analysisTypes?.potential &&
    defaultColumns.push({
      title: () => (
        <Translation>
          {(t) => (
            <Tooltip title="Предупреждения по потенциальному анализу">
              <div className="well-tree-table-title params">
                {t("potentialShort")}
              </div>
            </Tooltip>
          )}
        </Translation>
      ),
      dataIndex: "potential",
      key: "potential",
      render: (text, record) => {
        if (alarmsCountData) {
          const id = alarmsCountData.findIndex((x) => x.wellId === record.id);

          record.alarmCount = alarmsCountData[id];

          if (!record?.alarmCount) return <div className="green-circle" />;

          return record?.alarmCount?.potential === 0 ? (
            <div className="green-circle" />
          ) : record?.alarmCount?.potentialWithActions === 0 ? (
            <div className="red-circle">
              {record?.alarmCount?.potential <= 99
                ? alarmsCountData?.potential > 0
                  ? alarmsCountData?.potential
                  : record?.alarmCount?.potential
                : "99+"}
            </div>
          ) : record?.alarmCount?.potential ===
            record?.alarmCount?.potentialWithActions ? (
            <div className="yellow-circle">
              {record?.alarmCount?.potential <= 99
                ? alarmsCountData?.potential > 0
                  ? alarmsCountData?.potential
                  : record?.alarmCount?.potential
                : "99+"}
            </div>
          ) : (
            <div className="red-yellow-circle">
              {record?.alarmCount?.potential <= 99
                ? alarmsCountData?.potential > 0
                  ? alarmsCountData?.potential
                  : record?.alarmCount?.potential
                : "99+"}
            </div>
          );
        }
      },
    });
  analysisTypes?.custom &&
    defaultColumns.push({
      title: () => (
        <Translation>
          {(t) => (
            <Tooltip title="Пользовательские предупреждения">
              <div className="well-tree-table-title params">
                {t("customShort")}
              </div>
            </Tooltip>
          )}
        </Translation>
      ),
      dataIndex: "custom",
      key: "custom",
      render: (text, record) => {
        if (alarmsCountData) {
          const id = alarmsCountData.findIndex((x) => x.wellId === record.id);

          record.alarmCount = alarmsCountData[id];

          if (!record?.alarmCount) return <div className="green-circle" />;

          return record?.alarmCount?.custom === 0 ? (
            <div className="green-circle" />
          ) : record?.alarmCount?.customWithActions === 0 ? (
            <div className="red-circle">
              {record?.alarmCount?.custom <= 99
                ? alarmsCountData?.custom > 0
                  ? alarmsCountData?.custom
                  : record?.alarmCount?.custom
                : "99+"}
            </div>
          ) : record?.alarmCount?.custom ===
            record?.alarmCount?.customWithActions ? (
            <div className="yellow-circle">
              {record?.alarmCount?.custom <= 99
                ? alarmsCountData?.custom > 0
                  ? alarmsCountData?.custom
                  : record?.alarmCount?.custom
                : "99+"}
            </div>
          ) : (
            <div className="red-yellow-circle">
              {record?.alarmCount?.custom <= 99
                ? alarmsCountData?.custom > 0
                  ? alarmsCountData?.custom
                  : record?.alarmCount?.custom
                : "99+"}
            </div>
          );
        }
      },
    });*/

  return defaultColumns;
};
