/* eslint-disable */
import { useRef, useState } from "react";
import { Button } from "antd";
import Draggable from "react-draggable";
import Modal from "components/Modal";
import ProcessingForm from "./components/ProcessingForm";
import Reasons from "./components/Reasons";
import { useTranslation } from "react-i18next";
import "./styles.css";

const activeStyles = {
  background: "rgba(75, 126, 254, 0.16)",
  color: "#4b7efe",
}

const AlarmDeviationAnalysisModal = ({
  visible,
  closeModal,
  data,
  handleCloseDeviationAnalysisAlarm,
  isAlarmArchived,
}) => {

  const { t } = useTranslation();

  const [activeTabKey, setActiveTabKey] = useState(isAlarmArchived ? "2" : "1");

  const alarmProcessingTab = {
    label: "Обработка предупреждения",
    component: (
      <ProcessingForm
        data={data}
        handleSubmit={handleCloseDeviationAnalysisAlarm}
        closeModal={closeModal}
      />
    ),
  }

  const alarmReasonTab = {
    label: "Причины предупреждения",
    component: (
      <Reasons alarmId={data?.id} />
    ),
  }

  const tabs = isAlarmArchived ? {
    2: alarmReasonTab
  } : {
    1: alarmProcessingTab,
    2: alarmReasonTab,
  }

  const draggableRef = useRef(null);

  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggableRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y + 48,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <Modal
      bodyStyle={{
        maxHeight: "calc(100vh - 120px)",
        overflowY: "auto",
      }}
      title={
        <div
          style={{
            width: '100%',
            cursor: 'grab',
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
        >
          {t(`alarm`)}
        </div >
      }
      visible={visible}
      onCancel={() => {
        closeModal();
      }}
      centered={false}
      footer={null}
      width={"800px"}
      maskStyle={{ background: "rgba(0, 0, 0, 0.2)" }}
      wrapClassName={"overflow-hidden"}
      destroyOnClose
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
          nodeRef={draggableRef}
          defaultPosition={{ x: 0, y: -40 }}
        >
          <div ref={draggableRef}>{modal}</div>
        </Draggable>
      )}
    >
      <div className="alarm-deviation-analysis-modal">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {Object.entries(tabs).map(([key, tab]) => (
            <Button
              key={key}
              onClick={() => setActiveTabKey(key)}
              style={activeTabKey === key ? activeStyles : {}}
            >
              {tab.label}
            </Button>
          ))}
        </div>
        {tabs[activeTabKey].component}
      </div>
    </Modal >
  );
}

export default AlarmDeviationAnalysisModal;
