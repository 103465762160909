/* eslint-disable */
import { makeAutoObservable } from "mobx";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

class DeviationAnalysis {

  period = { startDate: null, endDate: null };

  pickedWellIds = [];

  lastData = null;
  lastDataPeriod = null;

  lastFetchDataBody = null;

  constructor() {
    makeAutoObservable(this);
  }

  setPeriod(period) {
    if (!period) {
      this.period = {
        startDate: null,
        endDate: null,
      }
    } else {
      this.period = {
        startDate: period.startDate ? dayjs(period.startDate).format("YYYY-MM-DD") : null,
        endDate: period.endDate ? dayjs(period.endDate).format("YYYY-MM-DD") : null,
      }
    }
  }

  setPickedWellIds(pickedWellIds) {
    this.pickedWellIds = pickedWellIds;
  }

  setLastData(lastData) {
    this.lastData = lastData;
  }

  setLastDataPeriod(lastDataPeriod) {
    if (!lastDataPeriod) {
      this.lastDataPeriod = null;
    } else {
      this.lastDataPeriod = {
        startDate: lastDataPeriod.startDate ? dayjs(lastDataPeriod.startDate).format("YYYY-MM-DD") : null,
        endDate: lastDataPeriod.endDate ? dayjs(lastDataPeriod.endDate).format("YYYY-MM-DD") : null,
      }
    }
  }
}

export default DeviationAnalysis;
