/* eslint-disable */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "public/locales/en/translation.json";
import translationRU from "public/locales/ru/translation.json";
import translationES from "public/locales/es/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
  es: {
    translation: translationES,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ru",
  languagesList: ["en", "ru", "es"],
  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
