/* eslint-disable */
import { Breadcrumb } from "antd";
import "./styles.css";

const WellBreadcrumbs = ({ breadcrumbs }) => {
  return (
    <Breadcrumb separator=">">
      {breadcrumbs.map((location, index) => (
        <Breadcrumb.Item key={index} className="trend-well-breadcrumb-item">
          {location}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default WellBreadcrumbs;
