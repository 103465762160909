import { useRef } from "react";
import { FilterFilled } from "@ant-design/icons";
import { useOutsideClick } from "hooks/useOutsideClick";
import Search from "components/Search";

const TableHeader = ({
  columns,
  columnWidths,
  searchValues,
  setSearchValues,
  activeFilterAlias,
  setActiveFilterAlias,
}) => {

  const ref = useRef(null);

  useOutsideClick(
    () => setActiveFilterAlias(null),
    ref
  );

  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        background: "#fafafa",
        height: 64,
        fontWeight: 500,
        color: "rgba(0, 0, 0, 0.85)",
        fontSize: "20px",
        border: "1px solid #f0f0f0",
      }}
    >
      {columns.map((column, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            borderRight: "1px solid #f0f0f0",
            paddingLeft: "16px",
            minWidth: columnWidths[index],
            height: "100%",
            position: "relative",
          }}
        >
          <span>
            {column.title}
          </span>

          {column.filter != null &&
            <>
              <FilterFilled
                onClick={(e) => {
                  if (activeFilterAlias === column.filter.alias) {
                    setActiveFilterAlias(null);
                  } else {
                    setActiveFilterAlias(column.filter.alias);
                  }
                }}
                style={{
                  color: searchValues[column.filter.alias] !== "" ? "#1890ff" : "#bfbfbf",
                  fontSize: 12,
                  marginLeft: "auto",
                  marginRight: "12px",
                  cursor: "pointer",
                }}
              />

              {activeFilterAlias === column.filter.alias &&
                <Search
                  value={searchValues[column.filter.alias]}
                  handleChangeValue={(value) => setSearchValues(prev => {
                    const values = { ...prev }
                    values[column.filter.alias] = value;
                    return values;
                  })}
                  fontSize={18}
                  style={{
                    position: "absolute",
                    width: "300px",
                    top: "60px",
                    right: "-270px",
                    zIndex: 1000,
                  }}
                />
              }
            </>
          }
        </div>
      ))}
    </div>
  );
}

export default TableHeader;
