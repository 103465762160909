/* eslint-disable */

const Gradient = ({
  data,
  extraGradientCell,
  label,
}) => {

  const size = 24;

  if (data == null || data.length === 0) return null;

  return (
    <div className="well-treemap-layout-legend-container">
      <span>{label}</span>
      <div className="well-treemap-layout-legend">
        {data.map((item) => (
          <div
            key={item.color}
            style={{
              height: size,
              backgroundColor: item.color,
            }}
          >
            <span>{item.label}</span>
          </div>
        ))}
      </div>

      {extraGradientCell != null &&
        <div className="well-treemap-layout-legend">
          <div
            key={extraGradientCell.color}
            style={{
              height: size,
              backgroundColor: extraGradientCell.color,
            }}
          >
            <span style={{ color: extraGradientCell.fontColor }}>
              {extraGradientCell.label}
            </span>
          </div>
        </div>
      }
    </div>
  );
}

export default Gradient;
