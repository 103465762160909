/*eslint-disable*/
import { useTranslation } from 'react-i18next'
import { useStore } from 'hooks/useStore'
import './styles.css'

const AnalysisCardContents = ({ title, contents, redirectsTo }) => {
  const { t } = useTranslation()
  const { wellStore } = useStore()

  return (
    <div>
      <div>
      </div>
    </div>
  )
}

export default AnalysisCardContents
