import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";

const ModalFormField = ({ field }) => {

  const { t } = useTranslation();

  return (
    <div style={{ padding: "5px" }}>
      <span className="add-priority-label">{t(field.label)}</span>
      <Field
        name={field.name}
        placeholder={t(field.label)}
        component={field.component}
        options={field.options ?? []}
        type={field.type}
        showsearch={field.showSearch?.toString() ?? "false"}
        allowclear={field.allowClear?.toString() ?? "false"}
        maxLength={field.maxLength ?? null}
        validate={field.validate}
      />
    </div>
  );
}

export default ModalFormField;
