import { observer } from "mobx-react-lite";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const DeleteGroupModal = observer(({ visible, onConfirm, onCancel, group }) => {

  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const title = t("deleteGroup");

  const content = group ? (
    <div>
      <p>{t("group")} "{group.name}" {t("willDeleted").toLowerCase()}.</p>
      <p>{t("areYouSureToDeleteGroup")}.</p>
    </div>
  ) : null;

  const buttons = (
    <div>
      <Button type="primary" danger loading={submitting}
        onClick={async () => {
          setSubmitting(true);

          if (await onConfirm("cascade"))
            onCancel();
          else {
            setError(t("errorHasOccured"));
          }

          setSubmitting(false);
        }}
      >
        {t("cascadeDelete")}
      </Button>

      <Button type="primary" danger loading={submitting}
        onClick={async () => {
          setSubmitting(true);

          if (await onConfirm("normal")) {
            onCancel();
          }
          else {
            setError(t("errorHasOccured"));
          }

          setSubmitting(false);
        }}
      >
        {t("delete")}
      </Button>
    </div>
  );

  return (
    <Modal
      open={visible}
      title={title}
      clear={onCancel}
      footer={buttons}
      onCancel={() => {
        onCancel();
        setError(null);
      }}
      content={content}
    >
      {content}
      {error &&
        <div style={{ color: "red" }}>
          {error}
        </div>
      }
    </Modal>
  );
});

export default DeleteGroupModal;
