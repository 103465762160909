/* eslint-disable */
import { useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import Modal from 'components/Modal'
import Button from 'components/Button'
import { ReactComponent as NextArrowIcon } from 'assets/images/nextArrowIcon.svg'
import { ReactComponent as BackStepModalIcon } from 'assets/images/backStepModalIcon.svg'
import request from 'utils/request'
import NRH from 'containers/NRH'
import { useStore } from 'hooks/useStore'
import Draggable from "react-draggable";

const NRHSettingsModal = ({
  visible,
  closeModal,
}) => {
  const [step, setStep] = useState();
  const { wellStore } = useStore();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y + 48,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const resetModal = () => {
    setStep('choose-settings')
    closeModal()
  }

  const postSettings = async values => {
    switch (step) {
      case 'nrh': {
        const newData = {
          wellId: null,
          locationId: null,
          factoryCoef: [],
          factoryPoints: [],
        }

        newData.wellId = wellStore.id
        newData.locationId = wellStore.activeLocationId

        const qp = { q: null, p: null }

        for (let item in values) {
          if (item.startsWith('h')) {
            newData.factoryCoef.push(values[item])
          }
          if (item.startsWith('q')) {
            qp.q = values[item]
          }
          if (item.startsWith('p')) {
            qp.p = values[item]
            newData.factoryPoints.push({ q: qp.q, p: qp.p })
          }
        }

        values.wellId = wellStore.id
        values.locationId = wellStore.activeLocationId
        await request.post('/nrh/newnrhdata', newData)

        break
      }

      default:
        break
    }
  }

  const renderModalTitle = () => {
    switch (step) {
      case 'nrh': {
        return (
          <div className="settings-modal-title">
            <BackStepModalIcon onClick={() => setStep('choose-settings')} />
            <span>{t('settingNHR')}</span>
          </div>
        )
      }

      default:
        return t('customHelpWindow')
    }
  }

  const renderSteps = form => {
    switch (step) {
      case 'nrh': {
        return (
          <div className="switch-wrapper">
            <NRH handleSetNewData={postSettings} />
          </div>
        )
      }

      default: {
        return (
          <>
            <div
              className="choose-settings-container"
              onClick={() => {
                setStep('nrh')
                form.initialize(null)
              }}
            >
              <span className="choose-title">{t('settingNHR')}</span>
              <NextArrowIcon />
            </div>
          </>
        )
      }
    }
  }

  return (
    <Form
      onSubmit={postSettings}
      render={({ handleSubmit, form }) => (
        <Modal
          bodyStyle={{
            maxHeight: "calc(100vh - 220px)",
            overflowY: "auto",
          }}
          title={
            <div
              style={{
                width: '100%',
                cursor: 'grab',
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
            >
              {renderModalTitle()}
            </div>
          }
          visible={visible}
          onCancel={() => resetModal()}
          submitButton={(step === 'nrh' || step === 'somethingelse1') && (
            <Button
              text={t('apply')}
              onClick={handleSubmit}
              visible={false}
            />
          )}
          width={step === 'nrh' ? 1450 : 600}
          footer={null}
          destroyOnClose
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
              nodeRef={draggleRef}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
        >
          <div className="settings-modal trendModalScroll">
            {renderSteps(form)}
          </div>
        </Modal>
      )}
    />
  )
}

export default NRHSettingsModal
