/*eslint-disable*/
import { useEffect } from "react";

const useOutsideClick = (callback, ref) => {
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (ref?.current && ref.current.contains && !ref.current.contains(e.target)) {
        callback();
      }
    }

    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);

  }, [ref?.current, callback]);
}

const useOutsideClickExtraRefs = (callback, refs) => {
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (refs?.every((ref) => !ref.current?.contains(e.target))) {
        callback();
      }
    }

    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);

  }, [refs, callback]);
}

export { useOutsideClick, useOutsideClickExtraRefs };