/* eslint-disable */
import uPlot from "uplot";

export const doubleSplitsCount = (u, axisIdx, scaleMin, scaleMax, foundIncr, foundSpace) => {
  const length = scaleMax - scaleMin;
  const count = Math.floor(length * 2 / foundIncr) + 1;
  const splits = Array(count)
    .fill()
    .map((_, i) => i * foundIncr / 2);

  return splits;
}

const defaultOptions = {
  width: 360,
  height: 150,
  legend: {
    show: false,
  },
  scales: {
    x: {
      time: false,
    },
    y: {
      range: (u, min, max) => {
        if (min === null) return [0, 100];
        return uPlot.rangeNum(0, max + 0.25, 0.1, true);
      },
    },
  },
  axes: [
    {
      font: "12px Roboto",
      stroke: "#6B6C70"
    },
    {
      font: "12px Roboto",
      stroke: "#6B6C70"
    }
  ],
  series: [{}],
  cursor: {
    drag: {
      x: false,
      y: false
    },
    points: {
      size: 13
    }
  }
}

export default defaultOptions;
