/* eslint-disable */
import React, { useMemo, useState } from "react";
import CheckboxGroup from "react-checkbox-group";
import { Input } from "antd";
import { ruCollator } from "utils/collator";
import "./styles.css";

const ParametersMenu = ({
  parametersData,
  checkedParams,
  setCheckedParams,
}) => {

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (value) => {
    setSearchValue(value);
  }

  const filtredParametersData = useMemo(() => {
    return parametersData
      ?.filter(param => param.name.toLowerCase().includes(searchValue.toLowerCase()))
      .sort((a, b) => ruCollator.compare(a.name, b.name))
  }, [parametersData, searchValue]);

  const checkBoxes = (Checkbox) => {
    const result = [];
    for (let i = 0; i < filtredParametersData?.length; i++) {
      result.push(
        <div
          key={filtredParametersData[i].id}
          style={{
            display: "flex",
            width: "100%",
            textAlign: "Left",
            justifyContent: "space-between",
            background: i % 2 === 0 ? "#F5F6FA" : "#FFFFFF",
            height: "33px",
          }}
        >
          <label style={{ display: "flex", flexDirection: "row-reverse" }}>
            <div
              style={{
                display: "flex",
                width: "550px",
                justifyContent: "space-between",
                flexDirection: "row-reverse",
                fontWeight: "bold",
                height: "33px",
                alignItems: "center",
              }}
            >
              <Checkbox value={filtredParametersData[i]?.id} />
              {filtredParametersData[i]?.name}
            </div>
          </label>
        </div>
      );
    }
    return result;
  };

  const changedData = (newData) => {
    setCheckedParams(newData);
  };

  return (
    <div>
      <Input
        addonBefore={"Поиск"}
        value={searchValue}
        onChange={(e) => handleSearch(e.target.value)}
        style={{ paddingBottom: "20px" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          overflow: "hidden"
        }}
      >
        <CheckboxGroup
          name="parameters"
          value={checkedParams}
          onChange={changedData}
        >
          {(Checkbox) => (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {checkBoxes(Checkbox)}
            </div>
          )}
        </CheckboxGroup>
      </div>
    </div>
  );
};

export default ParametersMenu;
