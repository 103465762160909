/* eslint-disable */
import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Typography,
  InputNumber,
  Space,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Modal from "components/Modal";
import { useTranslation } from "react-i18next";
import { useStore } from "hooks/useStore";
import "./styles.css";

const tailLayout = {
  wrapperCol: { offset: 10, span: 16 },
};

const { TextArea } = Input;

const CustomAlarmModal = ({ visible, closeModal, sendNewAlarmRequest }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isObserve, setIsObserve] = useState(false);
  const [isInWork, setIsInWork] = useState(false);
  const storageTranslationsActions = JSON.parse(
    sessionStorage.getItem("translationsActions")
  );
  const storageTranslationsWorks = JSON.parse(
    sessionStorage.getItem("translationsWorks")
  );
  const storageTranslationsWorkReasons = JSON.parse(
    sessionStorage.getItem("translationsWorkReasons")
  );
  const [form] = Form.useForm();
  const { wellStore } = useStore();

  useEffect(() => {
    form.setFieldsValue({ chosenWorks: null });
    form.setFieldsValue({ action: null });
    form.setFieldsValue({ reason: null });
    form.setFieldsValue({ comment: null });
    form.setFieldsValue({ observedHours: null });
  }, [visible]);

  const onFinish = (values) => {
    const result = {
      userId: 1,
      actionId: null,
      reasonId: null,
      comment: null,
      observedHours: null,
      wellId: wellStore?.id,
      chosenWorks: [], // {workId: workId, comment: comment}
    };

    result.actionId = values?.action;
    result.observedHours = values?.observedHours;
    result.reasonId = values?.reason;
    result.comment = values?.comment;
    result.chosenWorks = values?.chosenWorks;

    sendNewAlarmRequest(result);
    setIsObserve(false);
    setIsInWork(false);
    closeModal();
  };

  useEffect(() => {
    setIsObserve(false);
    setIsInWork(false);
  }, []);

  const translateActions = () => {
    for (let i = 0; i < storageTranslationsActions?.actions.length; i++) {
      const id = storageTranslationsActions[currentLanguage].findIndex(
        (x) => x.actionId === i + 1
      );

      storageTranslationsActions.actions[i].label =
        storageTranslationsActions[currentLanguage][id].translation;
      storageTranslationsActions.actions[i].key =
        storageTranslationsActions.actions[i].alias;
      storageTranslationsActions.actions[i].value =
        storageTranslationsActions.actions[i].id;
    }

    if (storageTranslationsActions) {
      if (storageTranslationsActions.actions.length > 2) {
        storageTranslationsActions.actions.splice(2, 1);
      }
    }

    return storageTranslationsActions?.actions;
  };

  const translateWorks = () => {
    for (let i = 0; i < storageTranslationsWorks?.works.length; i++) {
      const id = storageTranslationsWorks[currentLanguage].findIndex(
        (x) => x.workId === i + 1
      );

      storageTranslationsWorks.works[i].label =
        storageTranslationsWorks[currentLanguage][id].translation;
      storageTranslationsWorks.works[i].key =
        storageTranslationsWorks.works[i].alias;
      storageTranslationsWorks.works[i].value =
        storageTranslationsWorks.works[i].id;
    }

    return storageTranslationsWorks?.works;
  };

  const translateWorkReasons = () => {
    for (
      let i = 0;
      i < storageTranslationsWorkReasons?.workReasons.length;
      i++
    ) {
      const id = storageTranslationsWorkReasons[currentLanguage].findIndex(
        (x) => x.workReasonId === i + 1
      );

      storageTranslationsWorkReasons.workReasons[i].label =
        storageTranslationsWorkReasons[currentLanguage][id].translation;
      storageTranslationsWorkReasons.workReasons[i].key =
        storageTranslationsWorkReasons.workReasons[i].alias;
      storageTranslationsWorkReasons.workReasons[i].value =
        storageTranslationsWorkReasons.workReasons[i].id;
    }

    return storageTranslationsWorkReasons?.workReasons;
  };

  const onSelect1 = (value, key, label) => {
    switch (key?.key) {
      case "#action.observe": {
        setIsObserve(true);
        setIsInWork(false);
        break;
      }
      case "#action.work": {
        setIsObserve(false);
        setIsInWork(true);
        break;
      }
      default: {
        setIsObserve(false);
        setIsInWork(false);
        break;
      }
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={() => {
        setIsObserve(false);
        setIsInWork(false);
        closeModal();
      }}
      titleText={`${wellStore?.data?.name}  ${t("chooseAction")}`}
      footer={null}
      width="600px"
      maskStyle={{ background: "rgba(0, 0, 0, 0.2)" }}
    >
      <div className="alarm-modal">
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item label={t("alarm")}>
            <Typography.Text className="ant-form-text" type="secondary">
              {t("custom")}
            </Typography.Text>
          </Form.Item>
          <Form.Item
            name="reason"
            label={t("reason")}
            required
            rules={[{ required: true, message: t("missingReason") }]}
          >
            <Select options={translateWorkReasons()} />
          </Form.Item>
          <Form.Item
            name="action"
            label={t("action")}
            required
            rules={[{ required: true, message: t("missingAction") }]}
          >
            <Select options={translateActions()} onSelect={onSelect1} />
          </Form.Item>
          {isObserve && (
            <>
              <Form.Item label={t("within")}>
                <Form.Item
                  name="observedHours"
                  noStyle
                  rules={[
                    { required: true, message: t("missingObservedHours") },
                  ]}
                >
                  <InputNumber min={1} max={100} />
                </Form.Item>
                <span className="ant-form-text"> {t("shortHours")} </span>
              </Form.Item>
              <Form.Item name="comment" label={t("comment")}>
                <TextArea
                  autoSize
                  label={t("comment")}
                  rows={3}
                  cols={10}
                  maxLength={100}
                  rules={[{ required: true, message: t("missingComment") }]}
                />
              </Form.Item>
            </>
          )}
          {isInWork && (
            <Form.List name="chosenWorks">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => {
                    return (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "workId"]}
                          fieldKey={[fieldKey, "workId"]}
                          rules={[
                            { required: true, message: t("missingWork") },
                          ]}
                          style={{ width: "180px" }}
                        >
                          <Select options={translateWorks()} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "comment"]}
                          fieldKey={[fieldKey, "comment"]}
                          rules={[
                            { required: true, message: t("missingComment") },
                          ]}
                          style={{ width: "350px" }}
                        >
                          <Input placeholder={t("comment")} maxLength={100} />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      {t("addWork")}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              {t("apply")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default CustomAlarmModal;
