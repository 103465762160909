/* eslint-disable */
import { useState, useEffect, useCallback, useRef } from "react";
import { Form, Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import Draggable from "react-draggable";
import request from "utils/request";
import { useStore } from "../../../../hooks/useStore";
import usePostSettings from "./hooks/usePostSettings";
import useRequest from "hooks/useRequest";
import RenderModalTitle from "./components/RenderModalTitle";
import RenderSteps from "./components/RenderSteps";
import Modal from "components/Modal";
import Button from "components/Button";
import "./styles.css";

const SettingsModal = ({
  visible,
  closeSettings,
  reasonsProps,
  analysis,
  mutate,
  showExtended = true,
  onVirtualMeasureApply
}) => {
  const [step, setStep] = useState();
  const formatedReasons =
    reasonsProps &&
    reasonsProps.reduce(
      (acc, curr) => ({
        ...acc,
        [`c${curr?.id}`]: curr?.active,
      }),
      {}
    );
  const { i18n } = useTranslation();
  const { wellStore } = useStore();
  const { t } = useTranslation();
  const [rangingSettings, setRangingSettings] = useState([]);
  const [wellItems, setWellItems] = useState();
  const [wellsData, setWellsData] = useState();
  const [warningData, setWarningData] = useState([]);
  const [ids, setIds] = useState([]);
  const [divergenceData, setDivergenceData] = useState();
  const [specialData, setSpecialData] = useState([]);
  const [setpointsIndividualData, setSetpointsIndividualData] = useState();
  const [buttonText, setButtonText] = useState(t("apply"));
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const resetModal = () => {
    closeSettings();
    setStep("choose-settings");
    setButtonText(() => t("apply"));
  };

  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y + 48,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };


  const {
    data: instantCommonSettings,
    mutate: mutateInstantCommonSettings,
  } = useRequest("/settings/instant/common/get");

  const {
    data: trendCommonSettingsData,
    mutate: mutateTrendCommonSettingsData,
  } = useRequest("/settings/trend/common/get");

  const { data: divergenceSettingsData, mutate: mutateDivergenceData } =
    useRequest("/settings/deviation/analysis/common/settings/get");

  const { data: specialSettingsData, mutate: mutateSpecialSettingsData } =
    useRequest("/settings/special/common/analysis/get");

  const { data: setpointsData, mutate: mutateSetpointsData } = useRequest(
    "/settings/ustavka/common/analysis/get"
  );

  const {
    data: isSuperAdmin,
  } = useRequest("/admin/checkSuperAdminAccessLevel");

  const loadWellsList = useCallback(
    async (chosenLocations) => {
      const { data: wellItems } = await request.post(`/well/allByLocations`, {
        locations: chosenLocations,
      });
      return wellItems;
    },
    [wellItems]
  );

  useEffect(() => {
    if (wellStore.selectedLocations == null) return;
    if (wellStore.selectedLocations?.size == 0) return;
    loadWellsList(wellStore.selectedLocations).then((data) => {
      setWellItems(data);
      setWellsData(data);
      getVirtualModelSettings(data)
    });
  }, [Array.from(wellStore.selectedLocations).join()]);

  const translateParameters = (parametersData) => {
    const storageTranslations = JSON.parse(
      sessionStorage.getItem("translations")
    );
    const currentLanguage = i18n.language;

    const chooseTranslateFromArray = (alias, array, lang) => {
      if (alias == null) return "";
      if (array == null) return "";
      let id = array?.parameters.findIndex((x) => x.alias === alias);
      id = array?.parameters[id].id;
      const translationIndex =
        array &&
        array[lang] &&
        array[lang].findIndex((x) => x.parameterId === id);

      return translationIndex >= 0
        ? array[lang][translationIndex].translation
          ? array[lang][translationIndex].translation
          : alias
        : alias;
    };

    parametersData.forEach((element) => {
      element.name = chooseTranslateFromArray(
        element.alias,
        storageTranslations,
        currentLanguage
      );
    });

    return parametersData;
  };

  const handleSearchWells = (value) => {
    setWellsData(
      wellItems?.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const getVirtualModelSettings = async (wellItems1) => {
    if (!wellItems1) return;
    const { data: x } = await request.post(`/settings/model/get`, {
      wellIds: wellItems1.map((i) => i.id),
    });
    setIds(x);
  }

  // const getVirtualModelSettings = useCallback(async () => {
  //   if (!wellItems) return;
  //   const { data: x } = await request.post(`/settings/model/get`, {
  //     wellIds: wellItems.map((i) => i.id),
  //   });
  //   setIds(x);
  // }, [wellItems]);
  const handleMouseOver = () => {
    if (disabled) {
      setDisabled(false);
    }
  }

  const handleMouseOut = () => {
    setDisabled(true);
  }


  /*const getIndividualWarningSettings = useCallback(async () => {
    if(!wellItems) return;
    const { data: x } = await request.post(
      `/settings/instant/individual/get/`,
      {
        wellIds: wellItems.map((i) => i.id),
      }
    );
    setWarningData(x);
  }, [wellItems]);*/

  const getSpecialIndividualSettings = useCallback(async () => {
    if (!wellItems) return;
    const { data: x } = await request.post(
      `/settings/special/individual/analysis/get`,
      {
        wellIds: wellItems.map((i) => i.id),
      }
    );
    setSpecialData(x);
  }, [wellItems]);

  const getSetpointsIndividualSettings = useCallback(async () => {
    if (!wellItems) return;
    const { data: x } = await request.post(
      `/settings/ustavka/individual/analysis/get`,
      {
        wellIds: wellItems.map((i) => i.id),
      }
    );
    setSetpointsIndividualData(x);
  }, [wellItems]);

  const getDivergenceSettings = useCallback(async () => {
    if (!wellItems) return;
    const { data: x } = await request.post(`/settings/deviation/analysis/individual/settings/get`, {
      wellIds: wellItems.map((i) => i.id),
    });
    setDivergenceData(x);
  }, [wellItems]);

  useEffect(() => {
    if (!visible) return;
    Promise.all(
      [
        getSetpointsIndividualSettings(),
        getVirtualModelSettings(),
        getSpecialIndividualSettings(),
        getDivergenceSettings()
      ]
    ).then();
  }, [visible]);

  /*useEffect(() => {
    setRangingSettings();
  }, []);*/

  const postSettings = usePostSettings(
    step,
    setButtonText,
    mutate,
    reasonsProps,
    setpointsIndividualData,
    specialSettingsData,
    instantCommonSettings,
    mutateInstantCommonSettings,
    mutateTrendCommonSettingsData,
    mutateSpecialSettingsData,
    specialData,
    setpointsData,
    mutateSetpointsData,
    divergenceSettingsData,
    mutateDivergenceData,
  );

  return (
    <Form
      onSubmit={postSettings}
      render={({ handleSubmit, form }) => (
        <Modal
          bodyStyle={{
            maxHeight: "calc(100vh - 200px)",
            overflowY: "auto"
          }}
          title={
            <div
              style={{
                width: '100%',
                cursor: 'grab',
                fontFamily: 'Roboto Medium',
                fontWeight: '500',
              }}
              onMouseOver={() => handleMouseOver()}
              onMouseOut={() => handleMouseOut()}
            >
              <RenderModalTitle step={step} setStep={setStep} setButtonText={setButtonText} />
            </div>
          }
          centered={false}
          visible={visible}
          onCancel={() => resetModal()}
          submitButton={
            (step === "analysis" || step === "reasons") && (
              <Button
                text={buttonText}
                style={{
                  backgroundColor:
                    buttonText === t("apply") ? "" : "lightseagreen",
                  color:
                    buttonText === t("apply") ? "" : "white",
                  margin: "0px auto",
                  display: "block",
                }}
                onClick={handleSubmit}
              />
            )
          }
          width="800px"
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
              nodeRef={draggleRef}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
        >
          <div className="settings-modal trendModalScroll">
            <RenderSteps
              form={form}
              step={step}
              setStep={setStep}
              analysis={analysis}
              setButtonText={setButtonText}
              formatedReasons={formatedReasons}
              reasonsProps={reasonsProps}
              postSettings={postSettings}
              showExtended={showExtended}
              ids={ids}
              wellsData={wellsData}
              handleSearchWells={handleSearchWells}
              setIds={setIds}
              isSuperAdmin={isSuperAdmin}
              divergenceData={divergenceData}
              setDivergenceData={setDivergenceData}
              divergenceSettingsData={divergenceSettingsData}
              onVirtualMeasureApply={onVirtualMeasureApply}
            //rangingSettings={rangingSettings}
            />
          </div>
        </Modal>
      )}
    />

  );
};

export default SettingsModal
