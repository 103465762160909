/*eslint-disable*/
import { Navigate } from 'react-router-dom'
import useAuth from 'hooks/useAuth'

const PrivatRoute = ({ component }) => { // allowedRoles
  const { user } = useAuth()

  return ((user !== undefined) && (user ? component : <Navigate replace to="/login" />))
  // return ((user !== undefined)
  //   && (user ? component : <Navigate replace to="/login" />)
  //   //     && ((user && allowedRoles.find(role => user?.accessLevel.includes(role))) ? component : <Navigate replace to="/login" />)

  // )
}

export default PrivatRoute
