/* eslint-disable */
import { makeAutoObservable, observable, runInAction, toJS } from "mobx";
import { store } from "hooks/useStore";
import request from "utils/request";
import { task } from "mobx-fetch-task";
import LocationTreeNode from "./components/LocationsSettings/locationTree";
import { ruCollator } from "utils/collator";

class AdminLocationsGroupAccessStore {

  selectedGroupId = null;

  locationTrees = [];

  roles = [];

  changes = [];

  constructor() {
    makeAutoObservable(this, {
      fetchRoles: false,
      fetchLocationTrees: false
    });
  }

  fetchRoles = task(async () => {
    try {
      const { data } = await request.get("/admin/roles");

      runInAction(() => {
        this.roles = observable(data);
      });
    } catch (error) {
      console.error(error);
    }
  });

  fetchLocationTrees = task(async (groupId) => {
    try {
      this.setLocationTrees(null);

      const { data } = await request.get(`/locations/groupAccess/${groupId}`);

      runInAction(() => {
        this.setLocationTrees(data);
      });
    } catch (error) {
      this.setLocationTrees(null);
      throw error;
    }
  });

  sortLocationTrees() {
    const sortNode = (node) => {
      if (node.children == null) return;

      node.children.sort((a, b) => ruCollator.compare(a.name, b.name));

      for (const child of node.children) {
        sortNode(child);
      }
    }

    this.locationTrees.sort((a, b) => ruCollator.compare(a.name, b.name));

    for (const rootLocation of this.locationTrees) {
      sortNode(rootLocation);
    }
  }

  setLocationTrees(trees) {
    if (!trees) {
      this.locationTrees = [];
      return;
    }

    const observedTrees = [];
    for (const tree of trees) {
      observedTrees.push(makeAutoObservable(
        new LocationTreeNode(
          tree.id,
          tree.name,
          tree.well,
          tree.parentId,
          tree.children,
          tree.roleId
        )));
    }

    for (const tree of observedTrees) {
      for (const node of tree.list) {
        node.id = node.well ? `w${node.id}` : `l${node.id}`;
        if (node.parentId) {
          node.parentId = `l${node.parentId}`;
        }
      }
    }

    this.locationTrees = observedTrees;
    this.sortLocationTrees();
  }

  findLocation(id) {
    for (const tree of this.locationTrees) {
      const location = tree.find(id);
      if (location) return location;
    }
    return null;
  }

  setSelectedGroupId(id) {
    this.selectedGroupId = id;
    if (this.selectedGroupId != null) {
      this.fetchLocationTrees(this.selectedGroupId);
      this.cancelChanges();
    } else {
      this.locationTrees = [];
    }
  }

  clearSelectedGroupId() {
    this.selectedGroupId = null;
  }

  addChange(change) {
    const treeRoleId = this.findLocation(change.locationId)?.roleId;
    if (treeRoleId == change.roleId) {
      this.changes = this.changes.filter(prev =>
        prev.locationId !== change.locationId);
      return;
    }

    const prevValue = this.changes.find(prev =>
      prev.locationId === change.locationId);

    if (prevValue) {
      prevValue.roleId = change.roleId;
    } else {
      this.changes.push(change);
    }
  }

  async saveChanges() {
    const body = {
      accessLocationGroup: this.changes
        .filter(c => !c.isWell)
        .map(c => ({
          locationId: Number(c.locationId.substring(1)),
          groupId: c.groupId,
          roleId: c.roleId ?? null
        })),
      accessWellGroup: this.changes
        .filter(c => c.isWell)
        .map(c => ({
          wellId: Number(c.locationId.substring(1)),
          groupId: c.groupId,
          roleId: c.roleId ?? null
        }))
    }

    try {
      await request.put("/locations/groupAccess", body);
      await this.fetchLocationTrees(this.selectedGroupId);
      this.cancelChanges();
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  cancelChanges() {
    this.changes = [];
  }

  get hasChanges() {
    return this.changes.length > 0;
  }

  get selectedGroupTableDataSource() {
    return this.locationTrees;
  }

  get groupSelectOptions() {
    return store.adminGroupStore.listEditableGroup.map(group => ({
      value: group.id, label: group.name
    }));
  }
}

export default AdminLocationsGroupAccessStore;
