import MainLayout from 'layouts/MainLayout'
import StartMenu from 'containers/StartMenu'

const StartMenuPage = () => (
  <MainLayout>
    <StartMenu />
  </MainLayout>
)

export default StartMenuPage
