/* eslint-disable */
import React from "react";
import { Breadcrumb } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "hooks/useStore";
import "./styles.css";

const CustomBreadcrumbs = observer(() => {
  const { wellStore } = useStore();

  return (
    <Breadcrumb separator=">">
      {wellStore.breadcrumbs.map((location, index) => (
        <Breadcrumb.Item key={index} className="breadcrumb-item">
          {location}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
});

export default CustomBreadcrumbs;
